import { Component } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tax-type-discard-modal',
  templateUrl: './tax-type-discard-modal.component.html',
  styleUrls: ['./tax-type-discard-modal.component.scss'],
})
export class TaxTypeDiscardModalComponent {
  constructor(private activeModal: NgbActiveModal) {}

  approve(): void {
    this.activeModal.close(null);
  }

  deny(): void {
    this.activeModal.dismiss(null);
  }
}
