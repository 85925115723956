import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { distinctUntilChanged, map } from 'rxjs/operators';

import { ShortCodeConstants, ValidationConstants } from '@rar/commons/constants';
import { UserHelper } from '@rar/commons/helpers';
import { ModalService } from '@rar/commons/services/modal.service';
import { ArticleType } from '@rar/model/data/enums/ArticleType';
import { Role } from '@rar/model/data/enums/Role';
import { TaxRevisionStatus } from '@rar/model/data/enums/TaxRevisionStatus';
import { TaxTopic } from '@rar/model/data/tax/TaxTopic';
import { TopicComment } from '@rar/model/data/tax/TopicComment';
import { TaxDataService } from '@rar/tax/services/tax-data/tax-data.service';

@Component({
  selector: 'app-tax-topic',
  templateUrl: './tax-topic.component.html',
  styleUrls: ['./tax-topic.component.scss'],
})
export class TaxTopicComponent implements OnInit, OnDestroy {
  public readonly Role = Role;
  public readonly ValidationConstants = ValidationConstants;
  public readonly ArticleType = ArticleType;

  public taxId: number;
  public topic: TaxTopic;
  public topicComments: Array<TopicComment> = [];

  public showRules = false;
  public showRates = false;

  public activeTopicPanelId = '';

  public updateArticlesList = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public taxDataService: TaxDataService,
    private modalService: ModalService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    // resolve route data
    this.route.data.subscribe((data) => {
      if (!data.topic) {
        // user refresh page and new records are not available.
        return this.router.navigate(['.'], { relativeTo: this.route.parent, queryParams: { r: new Date().getTime() } });
      }
      this.topic = data.topic;

      this.showRates = !!(this.topic.rates && this.topic.rates.length);
      this.showRules = !!(this.topic.rules && this.topic.rules.length);
    });

    this.route.params
      .pipe(
        map((params) => +params['id']),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe((topicId) => {
        this.activeTopicPanelId = `topic-${topicId}`;
      });

    this.taxDataService.discard.pipe(untilDestroyed(this)).subscribe(() => {
      // it will trigger resolvers
      this.router.navigate(['.'], { relativeTo: this.route, queryParams: { r: new Date().getTime() } });
    });

    this.taxDataService.tax.pipe(untilDestroyed(this)).subscribe((tax) => {
      this.taxId = tax.id;
    });

    if (this.isEditor) {
      this.taxDataService.getTopicComments(this.taxId).subscribe((comments: TopicComment[]) => {
        this.topicComments = comments;
      });
    }
  }

  ngOnDestroy(): void {}

  public selectTopic(topic: TaxTopic): void {
    this.router.navigate(['topic', topic.id], { relativeTo: this.route.parent });
  }

  public beforeChange($event: NgbPanelChangeEvent) {
    if (!$event.nextState) {
      $event.preventDefault();
    }
  }

  public getTopicCounter(topic: TaxTopic): number {
    const numberOfRates = topic.rates ? topic.rates.length : 0;
    const numberOfRules = topic.rules
      ? topic.rules.reduce((sum, curr) => {
          if (curr.articleTypeId === ArticleType.JSON) {
            return sum;
          }

          return sum + 1;
        }, 0)
      : 0;

    return numberOfRules + numberOfRates;
  }

  public hasJsonRuleType(topic: TaxTopic): boolean {
    return topic && topic.rules && topic.rules[0] && topic.rules[0].articleTypeId === ArticleType.JSON;
  }

  public get isEditor() {
    return UserHelper.canEditTax(this.taxDataService.authUser, this.taxDataService.getTax());
  }

  public addNewTopic() {
    // multiply -999 to get "pseudo-random" non-existing ID of the temporary topic
    const category = this.taxDataService.getTaxTopic(this.taxDataService.taxCloneForEditing, (this.topic.parent || this.topic).id);
    category.subTopics = category.subTopics || [];
    const newTopic = {
      id: Math.min(Math.abs(category.id) * -999, ...category.subTopics.map((st) => st.id)) - 1,
      shortCode: ShortCodeConstants.newPlaceholder, // will be calculated on API
      rates: [],
      rules: [],
      parent: category,
      parentId: category.id,
      name: '',
      taxType: category.taxType,
      taxRevisionId: (category as any).taxRevisionId,
      order: !category.subTopics.length ? 1 : category.subTopics[category.subTopics.length - 1].order + 1,
    } as unknown as TaxTopic;
    category.subTopics.push(newTopic);
    this.taxDataService.setTableArticle([]);
    this.selectTopic(newTopic);
    this.markForCheck();
  }

  private markForCheck(): void {
    this.cdr.markForCheck();
    setTimeout(() => this.cdr.markForCheck());
  }

  public deleteTopic(index: number) {
    this.modalService
      .openConfirmation({
        title: this.translateService.instant('tax.tax-topics.topic-delete-title'),
        yesButtonText: this.translateService.instant('tax.tax-topics.rule-delete-confirm'),
        noButtonText: this.translateService.instant('commons.modal.cancel'),
        content: this.translateService.instant('tax.tax-topics.topic-delete-content'),
      })
      .then(
        () => {
          const tax = this.taxDataService.taxCloneForEditing;
          const category = this.taxDataService.getTaxTopic(tax, (this.topic.parent || this.topic).id);
          category.subTopics.splice(index, 1);

          this.router.navigate(['/tax', tax.id, 'revision', tax.revisionNumber, 'topic', category.id]);
        },
        () => {},
      );
  }

  public sortTopics(topics: TaxTopic[]): TaxTopic[] {
    return topics ? topics.sort((a, b) => a.order - b.order) : [];
  }

  public changeTopicOrder(event: Event, index: number, moveUp: boolean) {
    event.stopPropagation();
    const category = this.taxDataService.getTaxTopic(this.taxDataService.taxCloneForEditing, (this.topic.parent || this.topic).id);
    const currentTopicOrder = category.subTopics[index].order;
    const moveToIndex = moveUp ? index - 1 : index + 1;
    category.subTopics[index].order = category.subTopics[moveToIndex].order;
    category.subTopics[moveToIndex].order = currentTopicOrder;
    //category.subTopics = this.sortTopics(category.subTopics);
    (this.topic.parent || this.topic).subTopics = category.subTopics;

    this.cdr.markForCheck();
  }

  public onAddNewRow(): void {
    type Article = Record<string, string>;
    const articleList: Array<Article> = this.taxDataService.getTableArticle() || [];

    if (articleList && this.taxDataService.editColumns.length) {
      // create new row with all available columns
      const newArticleRow: Article = {};
      this.taxDataService.editColumns.forEach((prop, index) => {
        newArticleRow[index.toString()] = '';
      });

      articleList.push(newArticleRow);
      this.updateArticlesList = articleList;

      this.taxDataService.setTableArticle(articleList);
      this.taxDataService.updateTable();
      this.markForCheck();
    }
  }

  public onArticleTypeChange(type: ArticleType) {
    if (type === ArticleType.JSON) {
      this.taxDataService.initTableArticle(this.topic.id);
    }
  }

  public onUpdateArticlesList(list): void {
    this.updateArticlesList = list;
  }

  public deleteCategory(categoryId: number) {
    this.modalService
      .openConfirmation({
        title: this.translateService.instant('tax.tax-topics.category-delete-title'),
        yesButtonText: this.translateService.instant('tax.tax-topics.rule-delete-confirm'),
        noButtonText: this.translateService.instant('commons.modal.cancel'),
        content: this.translateService.instant('tax.tax-topics.rule-delete-content'),
        isDestructiveWarning: true,
      })
      .then(
        () => {
          const category = this.taxDataService.taxCloneForEditing.taxTopics.find((cat) => cat.id === categoryId);
          this.handleDeleteCategory(category);
        },
        () => {},
      );
  }

  private handleDeleteCategory(category: TaxTopic) {
    const deleteCategoryIndex = this.taxDataService.taxCloneForEditing.taxTopics.indexOf(category);

    let routeCategoryId = null;
    if (deleteCategoryIndex > 0) {
      // select previous category
      routeCategoryId = this.taxDataService.taxCloneForEditing.taxTopics[deleteCategoryIndex - 1].id;
    } else if (deleteCategoryIndex === 0 && this.taxDataService.taxCloneForEditing.taxTopics.length > 1) {
      // select next category
      routeCategoryId = this.taxDataService.taxCloneForEditing.taxTopics[deleteCategoryIndex + 1].id;
    }
    if (deleteCategoryIndex >= 0) {
      this.taxDataService.taxCloneForEditing.taxTopics.splice(deleteCategoryIndex, 1);
    }

    let url = '/tax/' + this.taxDataService.taxCloneForEditing.id + '/revision/' + this.taxDataService.taxCloneForEditing.revisionNumber;
    if (routeCategoryId) {
      url += '/topic/' + routeCategoryId;
    }
    this.router.navigate([url]);
  }

  public getCommentsForTopic(shortCode: string): Array<TopicComment> {
    return this.topicComments.filter((tc) => tc.shortCode === shortCode);
  }

  public getStatusPendingLocal() {
    return TaxRevisionStatus.PENDING_LOCAL;
  }

  public getStatusPendingGlobal() {
    return TaxRevisionStatus.PENDING_GLOBAL;
  }
}
