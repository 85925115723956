<div id="tax-schedule-component">

	<div id="header">
		<p><img class="ui avatar image"
				[src]="'../../../../assets/images/icons/user_admin.svg'">{{"tax.tax-schedule.title" | translate }}</p>
	</div>

	<div id="content">

		<!--Loading indicator-->
		<div class="ui active inline centered loader" *ngIf="loading"></div>

		<div class="ui equal width grid" *ngIf="!loading">
			<!--titles-->
			<div class="row nopad-bottom">
				<div class="column">
					<div>
						<p class="field-title">{{ "tax.tax-schedule.confirm-text" | translate }}</p>
						<p class="description">{{ "tax.tax-schedule.confirm-description" | translate }}</p>
					</div>
				</div>
			</div>

			<!--settings panel-->
			<div class="row nopad-top">
				<div class="column flex-row">

					<!--frequency-->
					<div class="ui input">
						<ng-select
							class="selection"
							[disabled]="(taxService.authUser.role < 4)"
							[(ngModel)]="actualConfirmationFrequency"
							(ngModelChange)="updateNextActivation()"
							bindLabel="title"
							[items]="confirmRulesFrequencies"
							[searchable]="false"
							[placeholder]="actualConfirmationFrequency ? actualConfirmationFrequency.title : 'Frequency'"
						></ng-select>
					</div>

					<!--starting from-->
					<p class="disabled-info">{{"tax.tax-schedule.confirm-set-date" | translate}}</p>

					<!--event date-->
					<div class="ui input">
						<div class="input-group">
							<input
								#dp="ngbDatepicker"
								ngbDatepicker
								autocomplete="off"
								class="form-control"
								[disabled]="(taxService.authUser.role < 4)"
								[(ngModel)]="actualStartDate"
								(ngModelChange)="updateNextActivation()"
								placeholder="Choose start date"
								placement="bottom-right"
							/>
							<div class="input-group-append">
								<button
									class="btn btn-outline-secondary btn-icon"
									(click)="dp.toggle()"
									[attr.aria-expanded]="dp.isOpen"
									type="button"
								>
									<svg-icon
										svgClass="icon"
										src="/assets/images/icons/calendar.svg"
									></svg-icon>
								</button>
							</div>
						</div>
					</div>

					<!--with reminder-->
					<p class="disabled-info">{{"tax.tax-schedule.confirm-reminder" | translate}}</p>

					<!--prior value-->
					<div class="ui input">
						<ng-select
							class="selection"
							[disabled]="(taxService.authUser.role < 4)"
							[(ngModel)]="actualPriorNotification"
							(ngModelChange)="updateNextActivation()"
							bindLabel="title"
							[items]="priorNotification"
							[searchable]="false"
							[placeholder]="actualPriorNotification ? actualPriorNotification.title : 'Choose one'"
						></ng-select>
					</div>

					<!--with reminder-->
					<p class="disabled-info">
						{{"tax.tax-schedule.confirm-before" | translate}}
					</p>

				</div>
			</div>

			<div class="row nopad-top">
				<div class="column flex-col">

					<!--info on creation-->
					<div>
						<p *ngIf="actualPeriodData.createdAt" class="disabled-info">
							Notification saved by
							<a [href]="'mailto:' + actualPeriodData.user.email"
								ngbPopover="Send email" placement="right">
								<b>{{ actualPeriodData.user.name }}</b>
								&lt;{{ actualPeriodData.user.email }}&gt;
								<i class="fas fa-envelope"></i>
							</a>
							on {{actualPeriodData.createdAt | date:'MMM dd, yyyy'}}
						</p>
						<p *ngIf="actualPeriodData.nextActivation" class="disabled-info">
							<span ngbPopover="Next reminder" placement="top">
								Next notification on: {{actualPeriodData.nextActivation | date:'MMM dd, yyyy'}}
							</span>
						</p>
					</div>

					<!--save button-->
					<div *ngIf="!(taxService.authUser.role < 4) && saveButtonEnabled">
						<button class="ui basic button done" (click)="saveReviewPeriod()">Save</button>
					</div>
				</div>
			</div>

		</div>
	</div>


</div>
