import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RoutePaths } from '@rar/commons/constants';

import { TaxTypeStructure } from '../../model/data/tax/TaxTypeStructure';
import { ApiCommunicationService } from '../../model/services/api-communication/api-communication.service';

@Injectable()
export class TaxTypeStructureResolver implements Resolve<TaxTypeStructure> {
  constructor(@Inject(ApiCommunicationService) private api: ApiCommunicationService) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<TaxTypeStructure> | Promise<TaxTypeStructure> | TaxTypeStructure {
    const taxTypeId = +route.params[RoutePaths.taxDesign.editedTaxTypeIdParam];

    const taxTypeStructure = this.api
      .tax()
      .getTaxTypeStructure(taxTypeId)
      .pipe(
        map((tts) => {
          if (!(tts && tts.topics)) {
            return tts;
          }
          tts.topics.forEach((category) => {
            if (!category.subTopics || !category.subTopics.length) {
              return;
            }
            category.subTopics.forEach((topic) => {
              topic.parent = category;
              topic.rules = [];
              topic.rates = [];
              if (!topic.subTopics || !topic.subTopics.length) {
                return;
              }
              topic.subTopics.forEach((ruleOrRate) => {
                ruleOrRate.parent = topic;
                if (ruleOrRate.type === 'rule') {
                  topic.rules.push(ruleOrRate);
                } else if (ruleOrRate.type === 'rate') {
                  topic.rates.push(ruleOrRate);
                }
              });
            });
          });
          return tts;
        }),
      );
    return taxTypeStructure;
  }
}
