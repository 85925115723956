<h4>{{ 'user.user-profile.personal' | translate }}</h4>

<div class="card">
  <div class="card-body">
    <div [formGroup]="personalInformationFormGroup" class="control-with-feedback only-error-feedback">
      <div class="flex-flow flex-flow--lg">
        <div class="user-full-name form-group">
          <label for="user-full-name" class="label-required">{{ 'user.user-profile.name' | translate }}</label>
          <input
            id="user-full-name"
            type="text"
            formControlName="fullName"
            class="form-control"
            placeholder="{{ 'user.user-profile.name-placeholder' | translate }}"
            [required]="true"
            [maxLength]="100"
            data-test="new user name" />
          <app-validation-message [inputControl]="personalInformationFormGroup.get('fullName')"></app-validation-message>
        </div>

        <div class="user-email form-group">
          <label for="user-email" class="label-required">{{ 'user.user-profile.email' | translate }}</label>
          <input
            id="user-email"
            type="text"
            formControlName="email"
            class="form-control"
            placeholder="{{ 'user.user-profile.email-placeholder' | translate }}"
            [required]="true"
            [maxLength]="254"
            data-test="new user email" />
          <app-validation-message [inputControl]="personalInformationFormGroup.get('email')"></app-validation-message>
        </div>
      </div>
    </div>
    <app-validation-message [inputControl]="personalInformationFormGroup"></app-validation-message>
  </div>
</div>
