<div id="upcoming-changes-layout">
  <div class="header">
    <p>{{ 'dashboard.upcoming-changes' | translate | uppercase }}</p>
    <a [routerLink]="['/tax', 'upcoming']">{{ 'dashboard.see-all' | translate }}</a>
  </div>

  <!--Loading indicator-->
  <div class="ui active inline centered loader" *ngIf="!taxes"></div>

  <div class="ui segment" *ngIf="taxes">
    <app-empty-placeholder class="placeholder" *ngIf="taxes.length === 0; else data"></app-empty-placeholder>

    <ng-template #data>
      <div class="ui relaxed divided list">
        <div
          class="item cur-pointer"
          *ngFor="let upcoming of taxes; index as i"
          [routerLink]="['/tax', upcoming.id, 'revision', upcoming.revisionNumber]"
          (click)="onClick()">
          <div class="content">
            <div class="header">
              <div class="index">{{ i + 1 }}</div>
              <div class="tax">{{ upcoming.name }}</div>
            </div>
            <div class="location">
              <img [src]="'../../../../assets/images/icons/location.svg'" />
              {{ upcoming.location.name }}
            </div>
            <div class="date">
              {{ upcoming.effectiveFrom | date: 'MMM dd, yyyy' }} by
              {{ upcoming.approver.name === username ? 'You' : upcoming.approver.name }}
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
