<ng-container [formGroup]="group">
  <ng-select
    #selectComponent
    [formControlName]="controlName"
    [placeholder]="placeholder"
    [closeOnSelect]="false"
    [items]="items"
    [multiple]="true"
    [searchable]="false"
    [bindValue]="bindValue"
    [attr.data-test]="controlLocator"
    [clearable]="clearable"
    class="control-with-feedback">
    <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
      <div *ngIf="sortItems(items) as sortedItems" class="text-truncate" [title]="combineItemLabels(sortedItems)">
        <ng-container *ngIf="sortedItems.length === 1">{{ getItemLabel(sortedItems[0]) }}</ng-container>
        <ng-container *ngIf="sortedItems.length > 1">
          <ng-container>{{ getItemLabel(sortedItems[0]) }}</ng-container
          ><ng-container *ngFor="let item of sortedItems | slice: 1">, {{ getItemLabel(item) }}</ng-container>
        </ng-container>
      </div>
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
      <div class="custom-control custom-checkbox">
        <input
          id="item-{{ instanceId }}-{{ index }}"
          type="checkbox"
          class="custom-control-input"
          [ngModel]="item$.selected"
          [ngModelOptions]="{ standalone: true }"
          [attr.data-test]="itemLocator + ' ' + getItemLabel(item) | lowercase" />
        <label class="custom-control-label" for="item-{{ instanceId }}-{{ index }}">{{ getItemLabel(item) }}</label>
      </div>
    </ng-template>
  </ng-select>
  <app-validation-message [inputControl]="group.get(controlName)"></app-validation-message>
</ng-container>
