<div id="tax-rates-layout">
  <div class="list-edit">
    <div
      class="item edit"
      *ngFor="let rate of taxTypeDataService.getTaxTopic(taxTypeDataService.getTax(), topicId).rates; last as last; index as i">
      <div class="rule-counter">{{ 'tax.tax-topics.rule-label' | translate }} {{ i + 1 }}</div>
      <div class="name">
        <label>{{ 'tax.tax-topics.rule-label' | translate }}</label>
        <input
          #rateName="ngModel"
          type="text"
          class="title form-control show-feedback"
          [(ngModel)]="rate.name"
          required
          [maxLength]="validationConstants.ruleMaxLength" />
        <div class="invalid-feedback" *ngIf="rateName.invalid">
          <div class="invalid-feedback__message">
            <i class="fa fa-exclamation-triangle mr-1"></i>
            {{ 'commons.validation.required' | translate }}
          </div>
        </div>
      </div>

      <div class="hr" *ngIf="!last"></div>
    </div>
  </div>
</div>
