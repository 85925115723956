import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { TaxRecentViews } from '../../../model/data/dashboard/TaxRecentViews';
import { DashboardLoaderService } from '../../services/dashboard-loader.service';

@Component({
  selector: 'app-dashboard-recently-viewed',
  templateUrl: './dashboard-recently-viewed.component.html',
  styleUrls: ['./dashboard-recently-viewed.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardRecentlyViewedComponent {
  @Input('recentViews')
  public taxes: Array<TaxRecentViews>;

  @Input('username')
  public username: string;

  constructor(private dashboardService: DashboardLoaderService) {}

  public onClick() {
    this.dashboardService.load.emit();
  }
}
