import { Observable } from 'rxjs';

import { User } from '../data/user/User';
import { UserPayload } from '../request/user/UserPayload';
import { AbstractApiConnector } from './AbstractApiConnector';

export class UserManagementApiConnector extends AbstractApiConnector {
  // Api base url
  protected readonly apiRoute = '/api/v1/user';

  public updateUser(user: User): Observable<User> {
    return this.apiClient.patch(
      this.apiRoute + '/' + user.id,
      new UserPayload(user.name, user.email, user.status, user.isSuperAdmin, user.userTaxPermissions),
    );
  }

  public createUser(user: User): Observable<User> {
    return this.apiClient.post(
      this.apiRoute,
      new UserPayload(user.name, user.email, user.status, user.isSuperAdmin, user.userTaxPermissions),
    );
  }

  public validateEmailUniqueness(email: string): Observable<boolean> {
    return this.apiClient.get(this.apiRoute + `/validate/email/${email}`);
  }

}
