import { Component, OnDestroy, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { combineLatest } from 'rxjs';
// req 5
import { catchError, map, shareReplay, tap } from 'rxjs/operators';


import { UserHelper } from '@rar/commons/helpers';
// req 5
import { TaxType } from '@rar/model/data/tax/TaxType';

import { UserRolePipe } from '@rar/user/pipes/user-role.pipe';

import { SearchService } from '../../../commons/services/search.service';
import { User } from '../../../model/data/user/User';
import { ApiCommunicationService } from '../../../model/services/api-communication/api-communication.service';
import { UserSessionService } from '../../services/user-session/user-session.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  public user: User;

  private roles: string[] = [];
  public rolesValue: string = String();

  public permittedTaxes: string[];
  public permittedLocations: string[];

  // req 5
  public userProfiles: userProfileModel[] = [];
  public userProfile: userProfileModel;
  public finalUserProfiles: userProfileModel[] = [];

  private taxTypesList: TaxType[];



  constructor(
    private userSessionService: UserSessionService,
    private searchService: SearchService,
    private api: ApiCommunicationService,
    private translateService: TranslateService,
  ) {
    // get authenticated user
    const userRolePipe = new UserRolePipe(this.translateService);
    // req 5
    this.api
      .tax()
      .getTaxTypes()
      .pipe(
        tap((types) => {
          this.taxTypesList = types;
        }),
      )
      .subscribe();
    // req 5
    this.api
      .tax()
      .getTaxTypes()
      .pipe(
        tap((types) => {
          this.taxTypesList = types;
        }),
      )
      .subscribe();

    combineLatest([this.api.tax().getTaxTypes(), this.userSessionService.userData])
      .pipe(untilDestroyed(this))
      .subscribe(([taxTypes, user]) => {
        this.user = user;
        // req 5
        console.log('userTaxPermissions', this.user.userTaxPermissions);

        for (const up of this.user.userTaxPermissions) {
          this.userProfile = {
            id: 0,
            roleId: 0,
            role: '',
            taxTypeId: 0,
            taxType: '',
            jurisdisction: '',
            subdivision: '',
            parentId: 0,
          };
          this.userProfile.id = up.id;
          this.userProfile.roleId = up.role;
          this.userProfile.role = userRolePipe.transform(up.role);
          this.userProfile.taxTypeId = up.taxType;
          let filterTaxType = this.taxTypesList.filter((t) => t.id === this.userProfile.taxTypeId);
          let taxTypeName: string = '';
          if (filterTaxType.length > 0) {
            taxTypeName = filterTaxType[0].name;
          }
          this.userProfile.taxType = taxTypeName;
          if (up.location) {
            this.userProfile.id = up.location.id;
            // @ts-ignore
            if (up.location.parentId) {
              // @ts-ignore
              this.userProfile.parentId = up.location.parentId;
              this.userProfile.subdivision = up.location.name;
            } else {
              this.userProfile.parentId = 0;
              this.userProfile.jurisdisction = up.location.name;
            }
          }
          this.userProfiles.push(this.userProfile);
        }

        // this.userProfiles.sort((a, b) => (a.roleId > b.roleId ? -1 : 1));

        console.log('userProfiles', this.userProfiles);

        this.finalUserProfiles = this.userProfiles.filter((t) => t.parentId === 0);

        for (const up of this.finalUserProfiles) {
          this.userProfile = {
            id: 0,
            roleId: 0,
            role: '',
            taxTypeId: 0,
            taxType: '',
            jurisdisction: '',
            subdivision: '',
            parentId: 0,
          };
          this.userProfile.id = up.id;
          this.userProfile.roleId = up.roleId;
          this.userProfile.taxTypeId = up.taxTypeId;
          // this.userProfile.jurisdisction = up.jurisdisction;
          let filterUserProfiles: userProfileModel[] = this.userProfiles.filter((t) => t.parentId === up.id);
          let childNames: string = '';
          let parentName: string = '';
          let childNamesList: string[] = [];
          for (const profile of filterUserProfiles) {
            childNamesList.push(profile.subdivision);
          }
          if (childNamesList.length > 0) {
            childNames = childNamesList.join(', ');
            parentName = up.jurisdisction;
          } else {
            childNames = 'All subdivisions';
            parentName = up.jurisdisction;
          }
          up.jurisdisction = parentName;
          up.subdivision = childNames;

          if (up.jurisdisction == '') {
            up.jurisdisction = 'All';
          }
        }

        console.log('finalUserProfiles', this.finalUserProfiles);

        if (this.finalUserProfiles.length == 0 && this.user.isSuperAdmin) {
          this.userProfile = {
            id: 0,
            roleId: 0,
            role: '',
            taxTypeId: 0,
            taxType: '',
            jurisdisction: '',
            subdivision: '',
            parentId: 0,
          };
          this.userProfile.role = 'Super admin';
          this.userProfile.taxType = 'All';
          this.userProfile.jurisdisction = 'All';
          this.userProfile.subdivision = 'All';
          this.finalUserProfiles.push(this.userProfile);
        }

        // TODO: to improve later
        for (const role of UserHelper.getRolesForProfile(user)) {
          this.roles.push(userRolePipe.transform(role));
        }

        this.rolesValue = this.roles.join(', ');
        this.permittedLocations =
          user.userTaxPermissions
            ?.map((utp) => utp.location)
            ?.filter((l) => !!l)
            ?.map((l) => l.name) ?? [];
        this.permittedLocations = [...new Set(this.permittedLocations)];
        this.permittedTaxes =
          user.userTaxPermissions
            ?.map((utp) => utp.taxType)
            ?.filter((t) => !!t)
            ?.map((t) => taxTypes.find((tt) => tt.id === t)?.name ?? 'Unknown') ?? [];
        this.permittedTaxes = [...new Set(this.permittedTaxes)];
      });
  }

  ngOnInit() {
    this.searchService.clearSearchFilters();
  }

  ngOnDestroy(): void {}
}

// req 5
export interface userProfileModel {
  id: number;
  roleId: number;
  role: string;
  taxTypeId: number;
  taxType: string;
  jurisdisction: string;
  subdivision: string;
  parentId: number;
  // parentName: string;
  // childName: string;
}

// req 5
export interface userProfileModel {
  id: number;
  roleId: number;
  role: string;
  taxTypeId: number;
  taxType: string;
  jurisdisction: string;
  subdivision: string;
  parentId: number;
  // parentName: string;
  // childName: string;
}
