import { ChangeDetectorRef } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, ValidationErrors } from '@angular/forms';

import { Observable, of } from 'rxjs';
import { delay, distinctUntilChanged, filter, finalize, map, switchMap } from 'rxjs/operators';

import { UserManagementApiConnector } from '@rar/model/connectors/UserManagementApiConnector';

export class UserValidators {
  static uniqueEmail(userManagementApiConnector: UserManagementApiConnector, changeDetector: ChangeDetectorRef): AsyncValidatorFn {
    const emailValidator = new RegExp(
      /^(?=.{1,254}$)(?=.{1,64}@)[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
    );

    return (control: AbstractControl): Observable<ValidationErrors> => {
      return of(control.value).pipe(
        distinctUntilChanged(),
        delay(300),
        filter((value) => emailValidator.test(value)),
        switchMap((value) =>
          userManagementApiConnector
            .validateEmailUniqueness(value)
            .pipe(map((result: boolean) => (result ? { emailAlreadyExists: true } : null))),
        ),
        finalize(() => changeDetector.markForCheck()),
      );
    };
  }
}
