import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ValidationConstants } from '@rar/commons/constants';
import { ShortCodeConstants } from '@rar/commons/constants/short-code.constants';
import { ModalService } from '@rar/commons/services/modal.service';
import { ArticleType } from '@rar/model/data/enums/ArticleType';
import { Role } from '@rar/model/data/enums/Role';
import { TaxRule } from '@rar/model/data/tax/TaxRule';

import { ChangeNoteOccurrence } from '../../../../model/data/enums/ChangeNoteOccurrence';
import { TaxDataService } from '../../../services/tax-data/tax-data.service';

@Component({
  selector: 'app-tax-rule-key-value',
  templateUrl: './tax-rule-key-value.component.html',
  styleUrls: ['./tax-rule-key-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaxRulesKeyValueComponent implements OnInit, OnDestroy {
  public readonly role = Role;
  public readonly validationConstants = ValidationConstants;

  @Input()
  public enableAdd = false;

  public topicId: number;

  constructor(
    private route: ActivatedRoute,
    public taxDataService: TaxDataService,
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.route.params.pipe(untilDestroyed(this)).subscribe((p) => {
      this.topicId = +p['id'];
    });

    this.taxDataService.taxRevisionStatus.pipe(untilDestroyed(this)).subscribe(() => {
      // after changing tax status UI needs to be re-rendered - due to OnPush Strategy there is need of cdr usage
      this.markForCheck();
    });

    this.markForCheck();
  }

  ngOnDestroy(): void {}

  filterKeyValueRules(rules: TaxRule[]): TaxRule[] {
    return rules ? rules.filter((r) => r.articleTypeId === ArticleType.KEY_VALUE).sort((a, b) => a.ruleOrder - b.ruleOrder) : [];
  }

  public isRuleChanged(shortCode: string): boolean {
    return this.taxDataService.isObjectChanged(shortCode, ChangeNoteOccurrence.ARTICLE);
  }

  public getRuleDiff(shortCode: string): any {
    return this.taxDataService.getChangeNoteObject(shortCode, ChangeNoteOccurrence.ARTICLE)[0][ChangeNoteOccurrence.ARTICLE];
  }

  public addNewRuleRow(): void {
    const topic = this.taxDataService.getTaxTopic(this.taxDataService.taxCloneForEditing, this.topicId);
    topic.rules = (topic.rules || []).sort((a, b) => a.ruleOrder - b.ruleOrder);
    topic.rules.push({
      articleTypeId: ArticleType.KEY_VALUE,
      topicShortCode: topic.shortCode,
      shortCode: ShortCodeConstants.newPlaceholder, // will be calculated on API
      ruleOrder: !topic.rules.length ? 1 : topic.rules[topic.rules.length - 1].ruleOrder + 1,
    } as TaxRule);
    this.cdr.markForCheck();
  }

  private markForCheck(): void {
    this.cdr.markForCheck();
    setTimeout(() => this.cdr.markForCheck());
  }

  public deleteRuleRow(rule: TaxRule, index: number) {
    this.modalService
      .openConfirmation({
        title: this.translateService.instant('tax.tax-topics.rule-delete-title'),
        yesButtonText: this.translateService.instant('tax.tax-topics.rule-delete-confirm'),
        noButtonText: this.translateService.instant('commons.modal.cancel'),
        content: this.translateService.instant('tax.tax-topics.rule-delete-content'),
        isDestructiveWarning: true,
      })
      .then(
        () => {
          const topic = this.taxDataService.getTaxTopic(this.taxDataService.taxCloneForEditing, this.topicId);
          topic.rules.splice(index, 1);
          this.cdr.markForCheck();
        },
        () => {},
      );
  }

  public changeRuleOrder(rule: TaxRule, moveUp: boolean) {
    const rules = this.taxDataService.getTaxTopic(this.taxDataService.taxCloneForEditing, this.topicId).rules;
    const currentRuleOrder = rule.ruleOrder;
    const currentRuleIndex = rules.findIndex((r) => r.ruleOrder === rule.ruleOrder);
    let moveToIndex: number;

    moveUp ? (moveToIndex = currentRuleIndex - 1) : (moveToIndex = currentRuleIndex + 1);

    rule.ruleOrder = rules[moveToIndex].ruleOrder;
    rules[moveToIndex].ruleOrder = currentRuleOrder;

    this.taxDataService.getTaxTopic(this.taxDataService.taxCloneForEditing, this.topicId).rules = rules.sort(
      (a, b) => a.ruleOrder - b.ruleOrder,
    );
    this.cdr.markForCheck();
  }
}
