<ng-container *ngIf="isEditMode && !user; then loading; else userForm">
</ng-container>

<ng-template #loading>
  <div class="ui active inline centered loader"></div>
</ng-template>

<ng-template #userForm>
  <app-user-form [isEditMode]="isEditMode" [user]="user"></app-user-form>
</ng-template>
