import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TopicComment } from '@rar/model/data/tax/TopicComment';

interface ITopicCommentsHistoryModalContext {
  shortCode: string;
	comments: Array<TopicComment>,
  approvedComments: Array<number>;
}

@Component({
  selector: 'app-tax-topic-comments-history-modal',
  templateUrl: './tax-topic-comments-history-modal.component.html',
  styleUrls: ['./tax-topic-comments-history-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaxTopicCommentsHistoryModalComponent implements OnInit {

  constructor(
    private activeModal: NgbActiveModal) { }

  approvedComments: Array<number> = [];

  ngOnInit(): void {
    this.approvedComments = this.context.approvedComments;
  }

  @Input()
	context: ITopicCommentsHistoryModalContext;

  close(): void {
    const event = {
      shortCode: this.context.shortCode,
      commentIds: this.approvedComments,
      approvedComments: this.approvedComments
    }

		this.activeModal.close(event);
	}

  public getApprovedComments(): Array<TopicComment> {
    return this.context.comments.filter((tc) => tc.isApproved);
  }

  public getNotApprovedComments(): Array<TopicComment> {
    return this.context.comments.filter((tc) => !tc.isApproved);
  }

  public markAsResolved(commentId: number) {
    this.approvedComments.push(commentId);
  }

  public markAsUnresolved(commentId: number) {
    this.approvedComments = this.approvedComments.filter((value) =>value != commentId);
  }

  public markedAsResolved(commentId: number) {
    return this.approvedComments.some((ac) => ac === commentId);
  }
}
