<div class="modal-header">
	<h4 class="modal-title">
		{{ "tax.tax-info.latest-comments-title" | translate }}
	</h4>
</div>

<div class="modal-body">
	<div *ngIf="getNotApprovedComments().length">
		<div class="comment-row" *ngFor="let comment of getNotApprovedComments();">
			<div class="comment-details">
				<span>{{comment.content}}</span>
				<span class="second-row">{{comment.createdAt | date: 'MMM dd, yyyy hh:mm a'}} by {{comment.user.name}}</span>
			</div>
			<div class="button-group">
				<button class="ui basic button rose" *ngIf="!markedAsResolved(comment.id)" (click)="markAsResolved(comment.id)">Mark as resolved</button>
				<button class="ui basic button green" *ngIf="markedAsResolved(comment.id)" (click)="markAsUnresolved(comment.id)">Mark as unresolved</button>
			</div>
		</div>
	</div>
    <div *ngIf="!getNotApprovedComments().length">
		No comments to display
	</div>
</div>

<div class="modal-header">
	<h4 class="modal-title">
		{{ "tax.tax-info.comments-history-title" | translate }}
	</h4>
</div>
<div class="modal-body">
	<div *ngIf="getApprovedComments().length">
		<div class="comment-row" *ngFor="let comment of getApprovedComments();">
			<div class="comment-details">
				<span>{{comment.content}}</span>
				<span class="second-row">{{comment.createdAt | date: 'MMM dd, yyyy hh:mm a'}} by {{comment.user.name}}</span>
			</div>
		</div>
	</div>
    <div *ngIf="!getApprovedComments().length">
		No comments to display
	</div>
</div>

<div class="modal-footer">
	<div class="button-group">
		<button class="ui basic button silver" (click)="close()">
			Close
		</button>
	</div>
</div>
