import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ChangeNoteOccurrence } from '../../../model/data/enums/ChangeNoteOccurrence';
import { ChangeEntry } from '../../../model/data/tax/ChangeEntry';
import { TaxChangeNotes } from '../../../model/data/tax/TaxChangeNotes';
import { TaxTopic } from '../../../model/data/tax/TaxTopic';
import { User } from '../../../model/data/user/User';
import { ApiCommunicationService } from '../../../model/services/api-communication/api-communication.service';
import { TaxDetailedChangeNotesComponent } from '../../modals/tax-detailed-change-notes/tax-detailed-change-notes.component';
import { ModalService } from '../../services/modal.service';
import { TopicService } from '../../services/topic.service';

enum Property {
  OLD = 'old',
  NEW = 'new',
  ORIGINAL = 'original',
  TOPIC_SHORT_CODE = 'topicShortCode',
  CATEGORY_SHORT_CODE ='categoryShortCode'
}

@Component({
  selector: 'app-tax-change-notes-table',
  templateUrl: './tax-change-notes-table.component.html',
  styleUrls: ['./tax-change-notes-table.component.scss'],
})
export class TaxChangeNotesTableComponent implements OnInit {
  public changes: Array<ChangeEntry> = [];

  private readonly EFFECTIVE_TO = 'effective to';
  private readonly EFFECTIVE_FROM = 'effective from';
  public dataClick:string ='';

  @Input()
  public topics: Array<TaxTopic>;

  @Input()
  public changeNotes: Array<TaxChangeNotes>;

  public isTaxChange = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private topicService: TopicService,
    private api: ApiCommunicationService,
    private modalService: ModalService,
  ) {}

  ngOnInit() {
    if (this.changeNotes) {
      this.processChangeList(this.changeNotes);
    }
  }

  private processChangeList(notes: Array<TaxChangeNotes>) {
    if (!notes || !notes.length) {
      return;
    }

    for (const changes of notes) {
      const json = JSON.parse(notes[0].changeNotes);
      const editor = changes.user;

      this.isTaxChange = changes.taxId !== null;
      //req 7
      if (this.isTaxChange) {
        if(changes.message === 'alternative'){
          this.dataClick = 'no';
        }
        this.processTaxChanges(json, editor, false);
        //this.processTaxChanges(json, editor, changes.message === 'alternative');
      } else {
        this.processUserChanges(json, editor);
      }
    }
  }

  public onTopicClick(shortCode: string) {
    // skip if no short code or no topics
    if (!shortCode || !this.topics || !this.topics.length ) {
      return;
    }

    const topicId = this.findTopic(this.topics, shortCode);

    //req 7
    if (topicId && this.dataClick!='no') {

      this.topicService.selectTopic.emit(this.getTopic(topicId));
      this.router.navigate(['..', 'topic', topicId], { relativeTo: this.route });
    }
  }

  private findTopic(topics: Array<TaxTopic>, shortCode: string): number {
    if (!topics || !topics.length) {
      return null;
    }

    const directHit = topics.find((t) => t.shortCode === shortCode);

    if (directHit) {
      return directHit.id;
    }

    const subHit = topics.map((t) => this.findTopic(t.subTopics, shortCode)).find((t) => t !== null);

    if (subHit) {
      return subHit;
    }

    return null;
  }

  private getTopic(id: number): TaxTopic {
    let rv: TaxTopic = new TaxTopic();
    this.topics.forEach((topic: TaxTopic) => {
      if (topic.id === id) {
        rv = topic;
      }

      if (topic.subTopics) {
        topic.subTopics.forEach((subTopic: TaxTopic) => {
          if (subTopic.id === id) {
            subTopic.parent = topic;
            rv = subTopic;
          }
        });
      }
    });

    return rv;
  }
  private produceTopicOccurrence(object:any): string {
    const shortCode = object[Property.CATEGORY_SHORT_CODE];
    let occurrence = '';

    this.topics.forEach((topic: TaxTopic) => {
      if (topic.shortCode === shortCode) {
        occurrence = occurrence.concat(topic.name, ' -> ',"TOPIC");
      }

  });
  return occurrence;
}

  private produceOccurrence(object: any): string {
    console.log(object)
    const shortCode = object[Property.TOPIC_SHORT_CODE];
    let occurrence = '';

    this.topics.forEach((topic: TaxTopic) => {
      if (topic.shortCode === shortCode) {
        console.log(topic.shortCode)
        occurrence = occurrence.concat(topic.name);
      }

      if (topic.subTopics) {
        topic.subTopics.forEach((subTopic: TaxTopic) => {
          if (subTopic.shortCode === shortCode) {
            occurrence = occurrence.concat(topic.name, ' >> ', subTopic.name);
          }
        });
      }
    });

    return occurrence;
  }

  private registerTaxChanges(
    object: any,
    occurrence: ChangeNoteOccurrence,
    editor: User,
    alternativeDisplay: boolean,
    alternativeOccurrence?: string,
  ) {
    console.log(occurrence)
    this.changes = this.changes.concat({
      editor: editor,
      occurrence: alternativeOccurrence ? alternativeOccurrence : occurrence,
      new: object[occurrence][Property.NEW] ? object[occurrence][Property.NEW] : undefined,
      original: object[occurrence][Property.OLD] ? object[occurrence][Property.OLD] : undefined,
      topicShortCode: object[occurrence][Property.TOPIC_SHORT_CODE],
      alternativeDisplay: !object[occurrence][Property.OLD] || !object[occurrence][Property.NEW] ? true : alternativeDisplay,
    });
  }

  private registerUserChanges(object: any, key: string, editor: User, alternativeDisplay: boolean) {
    this.changes = this.changes.concat({
      editor: editor,
      occurrence: key,
      new: object[Property.NEW] ? object[Property.NEW] : undefined,
      original: object[Property.ORIGINAL] ? object[Property.ORIGINAL] : undefined,
      alternativeDisplay: alternativeDisplay,
    });
  }

  private processTaxChanges(json: any, editor: User, alternative?: boolean) {
    for (const key of Object.keys(json)) {
      const object = json[key];

      // if description changed
      if (object.hasOwnProperty(ChangeNoteOccurrence.DESCRIPTION)) {
        this.registerTaxChanges(object, ChangeNoteOccurrence.DESCRIPTION, editor, false);
      }

      // if status changed
      if (object.hasOwnProperty(ChangeNoteOccurrence.STATUS)) {
        this.registerTaxChanges(object, ChangeNoteOccurrence.STATUS, editor, true);
      }

      // if effective to changed
      if (object.hasOwnProperty(ChangeNoteOccurrence.EFFECTIVE_TO)) {
        this.registerTaxChanges(object, ChangeNoteOccurrence.EFFECTIVE_TO, editor, true, this.EFFECTIVE_TO);
      }

      // if effective from changed
      if (object.hasOwnProperty(ChangeNoteOccurrence.EFFECTIVE_FROM)) {
        this.registerTaxChanges(object, ChangeNoteOccurrence.EFFECTIVE_FROM, editor, true, this.EFFECTIVE_FROM);
      }

      // if something change in rule name
      if (object.hasOwnProperty(ChangeNoteOccurrence.RULE_TITLE)) {
        this.registerTaxChanges(
          object,
          ChangeNoteOccurrence.RULE_TITLE,
          editor,
          false,
          alternative ? ChangeNoteOccurrence.RULE_TITLE : this.produceOccurrence(object[ChangeNoteOccurrence.RULE_TITLE]),
        );
      }

      // if something change in rate name
      if (object.hasOwnProperty(ChangeNoteOccurrence.RATE_TITLE)) {
        this.registerTaxChanges(
          object,
          ChangeNoteOccurrence.RATE_TITLE,
          editor,
          false,
          alternative ? ChangeNoteOccurrence.RATE_TITLE : this.produceOccurrence(object[ChangeNoteOccurrence.RATE_TITLE]),
        );
      }

      // if something change inside a rule
      if (object.hasOwnProperty(ChangeNoteOccurrence.ARTICLE)) {
        this.registerTaxChanges(
          object,
          ChangeNoteOccurrence.ARTICLE,
          editor,
          false,
          alternative ? ChangeNoteOccurrence.ARTICLE : this.produceOccurrence(object[ChangeNoteOccurrence.ARTICLE]),
        );
      }

      if (object.hasOwnProperty(ChangeNoteOccurrence.RULE_SHORTCODE)) {
        this.registerTaxChanges(
          object,
          ChangeNoteOccurrence.RULE_SHORTCODE,
          editor,
          false,
          alternative ? ChangeNoteOccurrence.RULE_SHORTCODE : this.produceOccurrence(object[ChangeNoteOccurrence.RULE_SHORTCODE]),
        );
      }

      // if something change inside a rate
      if (object.hasOwnProperty(ChangeNoteOccurrence.VALUE)) {
        this.registerTaxChanges(
          object,
          ChangeNoteOccurrence.VALUE,
          editor,
          true,
          alternative ? ChangeNoteOccurrence.VALUE : this.produceOccurrence(object[ChangeNoteOccurrence.VALUE]),
        );
      }

      // if something change in topic name
      if (object.hasOwnProperty(ChangeNoteOccurrence.TOPIC_NAME)) {
        this.registerTaxChanges(object, ChangeNoteOccurrence.TOPIC_NAME, editor, false,
          this.produceTopicOccurrence(object[ChangeNoteOccurrence.TOPIC_NAME])
          );
      }

      // if something change in topic short code
      if (object.hasOwnProperty(ChangeNoteOccurrence.TOPIC_SHORTCODE)) {
        this.registerTaxChanges(object, ChangeNoteOccurrence.TOPIC_SHORTCODE, editor, false);
      }

      // if something change in category name
      if (object.hasOwnProperty(ChangeNoteOccurrence.CATEGORY_NAME)) {
        this.registerTaxChanges(object, ChangeNoteOccurrence.CATEGORY_NAME, editor, false);
      }

      // if something change in category short code
      if (object.hasOwnProperty(ChangeNoteOccurrence.CATEGORY_SHORTCODE)) {
        this.registerTaxChanges(object, ChangeNoteOccurrence.CATEGORY_SHORTCODE, editor, false);
      }

      // if something change in rule order
      if (object.hasOwnProperty(ChangeNoteOccurrence.RULE_ORDER)) {
        this.registerTaxChanges(object, ChangeNoteOccurrence.RULE_ORDER, editor, true);
      }
    }
  }

  private processUserChanges(json, editor) {
    for (const key of Object.keys(json)) {
      this.registerUserChanges(json[key], key, editor, true);
    }
  }

  public openDetailsModal() {
    // check data
    if (this.changeNotes && this.changeNotes[0] && this.changeNotes[0].taxRevision) {
      const changes = this.api.changeNotes().getRevisionChangeLog(this.changeNotes[0].taxRevision.id, { isSummary: false });

      const modalRef = this.modalService.open(TaxDetailedChangeNotesComponent, { modalDialogClass: 'modal-margin' });
      modalRef.componentInstance.context = { changes, topics: this.topics };
    }
  }
}
