import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { TaxTypeStructure } from '../../model/data/tax/TaxTypeStructure';
import { TaxTypeTopic } from '../../model/data/tax/TaxTypeTopic';

@Injectable()
export class TaxTypeTopicResolver implements Resolve<TaxTypeTopic> {
  constructor() {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TaxTypeTopic> | Promise<TaxTypeTopic> | TaxTypeTopic {
    const topicId = +route.params['id'];
    let categoryOrTopic: TaxTypeTopic = null;

    (<TaxTypeStructure>route.parent.data['taxTypeStructure']).topics.forEach((category) => {
      if (category.id === topicId) {
        categoryOrTopic = category;
      } else {
        if (category.subTopics) {
          category.subTopics.forEach((topic) => {
            topic.parent = category;

            if (topic.id === topicId) {
              categoryOrTopic = topic;
            }
          });
        }
      }
    });

    return categoryOrTopic;
  }
}
