<div id="audit-display-layout">
  <!--Loading indicator-->
  <div class="ui active inline centered loader" *ngIf="!auditEntriesToDisplay"></div>

  <app-empty-placeholder
    *ngIf="auditEntriesToDisplay && auditEntriesToDisplay.length === 0"
    text="Your filter settings didn't match any results"></app-empty-placeholder>

  <div *ngFor="let year of years">
    <div class="year">
      <h2>{{ year.index }}</h2>

    </div>

    <div id="container" *ngFor="let entry of year.entries">
      <div id="date-header">
        <div class="block">
          <h2>{{ entry.month | monthAsString: entry.month | uppercase }}</h2>
          <h2 class="day">{{ entry.day }}</h2>
        </div>
      </div>

      <div id="message-container">
        <div *ngFor="let audit of auditEntriesToDisplay | auditDateFilterPipe: year.index:entry.month:entry.day">
          <div class="icon">
            <!-- req 3 -->
            <img *ngIf="audit.eventType === 4 "
                  [src]="'../../../../assets/images/icons/user_approver.svg'" />
            <img *ngIf="audit.eventType === 5 || audit.eventType === 6 "
                  [src]="'../../../../assets/images/icons/history-delete.svg'" />
            <img
              *ngIf="audit.eventType === 1 || audit.eventType === 2 || audit.eventType === 10"
              [src]="'../../../../assets/images/icons/user_admin.svg'" />
            <img
              *ngIf="audit.eventType === 3 || audit.eventType === 9"
              [src]="'../../../../assets/images/icons/user_editor.svg'" />
              <!-- *ngIf="audit.eventType === 3 || audit.eventType === 9 || audit.eventType === 6 " -->
          </div>
          <div class="triangle"></div>
          <div class="message">
            {{ audit.message }}

            <button class="ui button" [disabled]="audit.eventType === 9 || audit.eventType === 10" (click)="onClickViewChanges(audit)">
              {{ 'audit.view-changes' | translate }}
            </button>
          </div>
          <div class="current-date">{{ audit.createdAt | date: 'MMMM dd, yyyy hh:mm a' }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
