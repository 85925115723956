import {Injectable} from '@angular/core';
import {TopicDifference} from '@rar/model/data/tax/TaxStructureDifferences';
import {Subject} from 'rxjs';

@Injectable()
export class SelectTreeElementService {

  private selectedElement = new Subject<TopicDifference>();
  selectedElement$ = this.selectedElement.asObservable();

  select(category: TopicDifference) {
    this.selectedElement.next(category);
  }
}
