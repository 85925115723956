<div class="d-flex flex-column">
  <div class="d-flex justify-content-between align-items-center">
    <div class="rule-counter mb-3">
      {{ 'tax.tax-topics.rule-label' | translate }} {{ topic?.rules[0]?.shortCode }}
    </div>
    <ng-container *ifHasRole="[role.ADMIN, role.SUPER_ADMIN]; requestedTax: taxService.getTax()">
      <button *ngIf="topic?.rules[0]?.shortCode" class="btn btn-sm btn-primary btn-icon btn-delete-rule" (click)="deleteRule(topic)">
        <svg-icon class="icon" src="../../../../assets/images/icons/trash.svg"></svg-icon>
        <span>{{ 'tax.tax-topics.rule-delete' | translate }}</span>
      </button>
    </ng-container>
  </div>
  <h3 class="column-labels-text">{{ 'tax.tax-topics.column-labels' | translate }}</h3>

  <div class="table-container-list">
    <app-tax-table-column
      class="list-item padded"
      *ngFor="let column of taxService.editColumns; let i = index; trackBy: trackByFn"
      [columnName]="column"
      [columnIndex]="i"
      (deleteColumn)="deleteColumnHandler($event)"
      (updateColumnName)="onUpdateColumnName($event)">
    </app-tax-table-column>
  </div>

  <button class="btn btn-outline-secondary btn-icon align-self-end mb-2"
          (click)="addNewColumnHandler()">
    <svg-icon class="cancel"
              [src]="'../../../../assets/images/icons/add.svg'"></svg-icon>
    <span>{{ 'tax.tax-topics.add-new-column' | translate }}</span>
  </button>
</div>
