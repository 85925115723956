import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule, Routes } from "@angular/router";
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from "@ng-select/ng-select";
import { TranslateModule } from "@ngx-translate/core";
import { CommonsModule } from "../commons/commons.module";
import { AuthGuardService } from '../user/guard/auth-guard.service';
import { AuditButtonComponent } from "./components/audit-button/audit-button.component";
import { AuditDisplayComponent } from "./components/audit-display/audit-display.component";
import { AuditFilterComponent } from "./components/audit-filter/audit-filter.component";
import { AuditLegendComponent } from "./components/audit-legend/audit-legend.component";
import { ShowChangeNotesModalComponent } from "./modals/show-change-notes-modal/show-change-notes-modal.component";
import { AuditTrailPageComponent } from "./pages/audit-trail-page/audit-trail-page.component";
import { AuditDateFilterPipe } from "./pipes/audit-date-filter-.pipe";
import { MonthAsStringPipe } from "./pipes/month-as-string.pipe";
import { AuditFilterService } from "./services/audit-filter.service";

const routes: Routes = [
  {
    path: "audit",
    component: AuditTrailPageComponent,
    canActivate: [AuthGuardService],
  },
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule.forChild(),
        RouterModule.forChild(routes),
        CommonsModule,
        NgSelectModule,
        NgbPopoverModule
    ],
    declarations: [
        AuditTrailPageComponent,
        AuditButtonComponent,
        AuditDisplayComponent,
        AuditFilterComponent,
        AuditDateFilterPipe,
        MonthAsStringPipe,
        ShowChangeNotesModalComponent,
        AuditLegendComponent,
    ],
    providers: [AuditFilterService],
    exports: [AuditDisplayComponent, AuditButtonComponent, AuditFilterComponent]
})
export class AuditModule { }
