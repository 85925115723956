import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, tap } from 'rxjs/operators';

import { RoutePaths } from '@rar/commons/constants';
import { JSONHelper } from '@rar/commons/helpers';
import { ModalService } from '@rar/commons/services/modal.service';
import { User } from '@rar/model/data/user/User';
import { ApiCommunicationService } from '@rar/model/services/api-communication/api-communication.service';
import { UserFormDataService } from '@rar/user/services/user-form/user-form-data.service';
import { UserFormFieldsService } from '@rar/user/services/user-form/user-form-fields.service';
import { UserFormMapperService } from '@rar/user/services/user-form/user-form-mapper.service';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UserFormFieldsService, UserFormDataService],
})
export class UserFormComponent implements OnInit, OnDestroy {
  constructor(
    private userFormFieldService: UserFormFieldsService,
    private userFormDataService: UserFormDataService,
    private userFormMapperService: UserFormMapperService,
    private modalService: ModalService,
    private translateService: TranslateService,
    @Inject(ApiCommunicationService) private api: ApiCommunicationService,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {}

  @Input()
  public isEditMode = false;

  @Input()
  public user!: User;

  roles$ = this.userFormDataService.roles$;

  readOnly: boolean;

  ngOnInit() {
    this.readOnly = this.user ? this.userFormDataService.calculateReadOnlyMode(this.user) : false;
    const initialUserEmail = this.user ? this.user.email : String();
    this.userFormFieldService.initForm(this.readOnly);
    this.userFormFieldService.initializeFormValue(this.user);

    this.userFormFieldService.emailValueChanged$
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        tap((value) => {
          if (this.isEditMode && value === initialUserEmail) {
            this.userFormFieldService.removeUniqueEmailValidator();
          } else {
            this.userFormFieldService.addUniqueEmailValidator(this.api.userManagement(), this.cdr);
          }
        }),
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.userFormFieldService.ngOnDestroy();
  }

  public get userForm(): UntypedFormGroup {
    return this.userFormFieldService.userFormGroup;
  }

  public onAddPermissionClicked(): void {
    this.userFormFieldService.addPermissionRow();
  }

  public onDeletePermissionClicked(index: number): void {
    this.userFormFieldService.deletePermissionRow(index);
  }

  public onSubmit(confirmationTemplate: TemplateRef<any>): void {
    this.userFormFieldService.userFormGroup.markAllAsTouched();

    if (!this.userFormFieldService.userFormGroup.valid) {
      return;
    }

    this.modalService
      .openConfirmation({
        title: this.translateService.instant('user.form.save-changes-title'),
        yesButtonText: this.translateService.instant('commons.modal.confirm'),
        noButtonText: this.translateService.instant('commons.modal.cancel'),
        contentTemplate: confirmationTemplate,
      })
      .then(
        () => this.saveUser(),
        () => {},
      );
  }

  public onCancel(): void {
    this.router.navigate([RoutePaths.users.list]);
  }

  private saveUser() {
    const user = {
      ...this.user,
      ...this.userFormMapperService.mapFormToModel(this.userFormFieldService.userFormGroup.value),
    };

    let observable: Observable<User>;
    if (this.isEditMode) {
      observable = this.api.userManagement().updateUser(user);
    } else {
      observable = this.api.userManagement().createUser(user);
    }

    observable
      .pipe(
        untilDestroyed(this),
        tap(() => this.router.navigate([RoutePaths.users.list])),
        catchError((err: HttpErrorResponse) => {
          if (!err.ok) {
            try {
              const errorMessage = err?.error?.message ?? err.message ?? err;
              if (JSONHelper.isJSON(errorMessage)) {
                const errors = JSON.parse(errorMessage);
                if (errors.email) {
                  this.userFormFieldService.personalInformation.get('email').setErrors({ email: true }, { emitEvent: true });
                  return;
                }
              }

              this.userFormFieldService.personalInformation.setErrors({ serverError: errorMessage }, { emitEvent: true });
            } finally {
              this.cdr.markForCheck();
            }
          }
          return of(undefined);
        }),
      )
      .subscribe();
  }
}
