import { ArticleType } from '@rar/model/data/enums/ArticleType';

import { TaxRevisionStatus } from '../../data/enums/TaxRevisionStatus';
import { TaxRevision } from '../../data/tax/TaxRevision';
import { TaxTopic } from '../../data/tax/TaxTopic';

export class TaxEditRequest {
  constructor(isEditing: boolean, taxId: number, revisionData: TaxRevision) {
    if (isEditing) {
      this.editing = {
        id: revisionData.id,
        description: revisionData.description,
        taxTopics: this.transformRevisionData(revisionData.taxTopics),
      };

      // update status if pending or closed
      if (revisionData.status === TaxRevisionStatus.PENDING_LOCAL || revisionData.status === TaxRevisionStatus.CLOSED) {
        Object.assign(this.editing, { status: revisionData.status });
      }

      if (revisionData.effectiveTo) {
        Object.assign(this.editing, { effectiveTo: revisionData.effectiveTo });
      }
    } else {
      // approval
      this.approval = {
        isApproved: revisionData.isApproved,
        isLocalApprove: revisionData.isLocalApprove,
      };

      if (!revisionData.isApproved && !revisionData.isLocalApprove) {
        Object.assign(this.approval, { rejectionReason: revisionData.rejectionReason });
      } else {
        Object.assign(this.approval, { approvalNotes: revisionData.approvalNotes });
      }
    }
  }

  approval?: any;
  editing?: any;

  private transformRevisionData(topics: Array<TaxTopic>) {
    const result: Array<any> = [];

    // map subtopics and rules
    if (topics) {
      for (let i = 0; i < topics.length; i++) {
        const topic = topics[i];
        const rules = topics[i].rules;
        const rates = topics[i].rates;

        const mappedRules: Array<any> = [];
        const mappedRates: Array<any> = [];

        if (rules) {
          // map the rules to payload format
          for (let j = 0; j < rules.length; j++) {
            const rule = rules[j];
            const isKeyValueType = rule.articleTypeId === ArticleType.KEY_VALUE;

            mappedRules.push({
              id: rule.id,
              title: rule.title,
              article: isKeyValueType && rule.richArticle ? null : rule.article,
              richArticle: isKeyValueType && rule.richArticle ? JSON.stringify(rule.richArticle) : null,
              articleTypeId: rule.articleTypeId,
              shortCode: rule.shortCode,
              topicShortCode: rule.topicShortCode,
              ruleOrder: rule.ruleOrder,
            });
          }
        }

        if (rates) {
          // map the rates to payload format
          for (let j = 0; j < rates.length; j++) {
            const rate = rates[j];
            const ratePayload = {
              id: rate.id,
              name: rate.name,
              valueTypeId: rate.valueTypeId,
              shortCode: rate.shortCode,
              topicShortCode: rate.topicShortCode,
            };

            if (rate.description !== null) {
              Object.assign(ratePayload, { description: rate.description });
            }

            if (rate.max !== null) {
              Object.assign(ratePayload, { max: rate.max });
            }

            if (rate.min !== null) {
              Object.assign(ratePayload, { min: rate.min });
            }

            if (rate.value !== null) {
              Object.assign(ratePayload, { value: rate.value });
            }

            mappedRates.push(ratePayload);
          }
        }

        const topicPayload = {
          id: topic.id > 0 ? topic.id : null,
          shortCode: topic.shortCode,
          name: topic.name,
          description: topic.description,
          order: topic.order,
        };

        if (mappedRates.length > 0) {
          Object.assign(topicPayload, { rates: mappedRates });
        }

        if (mappedRules.length > 0) {
          Object.assign(topicPayload, { rules: mappedRules });
        }

        if (topic.subTopics) {
          Object.assign(topicPayload, { subTopics: this.transformRevisionData(topic.subTopics) });
        }

        result.push(topicPayload);
      }
    }

    return result;
  }
}
