import {AbstractApiConnector} from "./AbstractApiConnector";
import {Observable} from "rxjs";
import {ChangeNotesParam} from "../request/notes/ChangeNotesParam";
import {TaxChangeNotes} from "../data/tax/TaxChangeNotes";
import {UserChangeNotes} from "../data/tax/UserChangeNotes";
import {ValidationError} from "../exception/ValidationError";

export class ChangeNotesApiConnector extends AbstractApiConnector {

	protected readonly apiRoute = "/api/v1/change";

	/**
	 * GET /api/v1/change/{revisionId}/tax?isSummary=true&userId=1
	 * Returns a list of change notes for the provided revision id.
	 * @param revisionId
	 * @param param
	 */
	public getRevisionChangeLog(revisionId: number, param: ChangeNotesParam): Observable<Array<TaxChangeNotes>> {
		if (typeof revisionId !== "number") {
			throw new ValidationError();
		} else {
			return this.apiClient.get(`${this.apiRoute}/${revisionId}/tax`, param);
		}
	}

	/**
	 * GET /api/v1/change/{taxChangeLogId}/tax/note
	 * Returns a change note by its id.
	 * @param taxChangeLogId
	 */
	public getTaxChangeLog(taxChangeLogId: number): Observable<TaxChangeNotes> {
		if (typeof taxChangeLogId !== "number") {
			throw new ValidationError();
		} else {
			return this.apiClient.get(`${this.apiRoute}/${taxChangeLogId}/tax/note`);
		}
	}

	/**
	 * GET /api/v1/change/{userChangeLogId}/user
	 * Returns a change note by its id.
	 * @param userChangeLogId
	 */
	public getUserChangeLog(userChangeLogId: number): Observable<UserChangeNotes> {
		if (typeof userChangeLogId !== "number") {
			throw new ValidationError();
		} else {
			return this.apiClient.get(`${this.apiRoute}/${userChangeLogId}/user`);
		}
	}

}
