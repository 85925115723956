import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-user-personal-info',
  templateUrl: './user-personal-info.component.html',
  styleUrls: ['./user-personal-info.component.scss'],
})
export class UserPersonalInfoComponent {
  @Input()
  public personalInformationFormGroup!: UntypedFormGroup;
}
