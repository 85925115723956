import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';

import { ApiCommunicationService } from '@rar/model/services/api-communication/api-communication.service';
import { UserSessionService } from '@rar/user/services/user-session/user-session.service';

@Component({
  selector: 'app-no-access-page',
  templateUrl: './no-access-page.component.html',
  styleUrls: ['./no-access-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NoAccessPageComponent {
  constructor(private userSessionService: UserSessionService, @Inject(ApiCommunicationService) private api: ApiCommunicationService) {}

  public logoutUser() {
    this.userSessionService.logoutAction();
  }
}
