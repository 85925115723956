import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { untilDestroyed } from 'ngx-take-until-destroy';

import { ChangeNoteOccurrence } from '../../../../model/data/enums/ChangeNoteOccurrence';
import { TaxDataService } from '../../../services/tax-data/tax-data.service';

@Component({
  selector: 'app-tax-rule-json',
  templateUrl: './tax-rule-json.component.html',
  styleUrls: ['./tax-rule-json.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaxRulesJsonComponent implements OnInit, OnDestroy {
  @Input()
  public enableAdd = false;

  private topicId: number;
  public shortCode: string;

  public newJson: unknown;
  public oldJson: unknown;

  constructor(private route: ActivatedRoute, public taxDataService: TaxDataService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.route.params.pipe(untilDestroyed(this)).subscribe((p) => {
      this.topicId = +p['id'];
      this.shortCode = this.taxDataService.initTableArticle(this.topicId);
      this.cdr.markForCheck();
    });

    this.taxDataService.discard.pipe(untilDestroyed(this)).subscribe(() => {
      this.taxDataService.initTableArticle(this.topicId);
      this.cdr.markForCheck();
    });

    this.taxDataService.taxRevisionStatus.pipe(untilDestroyed(this)).subscribe(() => {
      // after changing tax status UI needs to be re-rendered - due to OnPush Strategy there is need of cdr usage
      this.cdr.markForCheck();
    });

    this.taxDataService.tableUpdate$.pipe(untilDestroyed(this)).subscribe(() => {
      // rerender after table structure change
      this.cdr.markForCheck();
    });
  }

  ngOnDestroy(): void {
    this.taxDataService.saveTmpJson(this.topicId);
  }

  public isRuleChanged(): boolean {
    const exist = this.taxDataService.isObjectChanged(this.shortCode, ChangeNoteOccurrence.ARTICLE);
    if (exist) {
      this.getRuleDiff();
    }

    return exist;
  }

  public getRuleDiff() {
    const object = this.taxDataService.getChangeNoteObject(this.shortCode, ChangeNoteOccurrence.ARTICLE)[0][ChangeNoteOccurrence.ARTICLE];
    this.newJson = JSON.parse(object.new);
    this.oldJson = JSON.parse(object.old);
    this.cdr.markForCheck();
  }

  public deleteRowHandler(index: number) {
    this.taxDataService.getTableArticle().splice(index, 1);
    const article = this.taxDataService.getTaxTopic(this.taxDataService.taxCloneForEditing, this.topicId).rules[0]?.article;
    if (article) {
      this.taxDataService.getTaxTopic(this.taxDataService.taxCloneForEditing, this.topicId).rules[0].article =
        JSON.stringify(this.taxDataService.updateTableArticles(this.taxDataService.getTableArticle(), this.taxDataService.editColumns));
    }
    this.cdr.markForCheck();
  }

  trackByFn(index, article) {
    return article.id;
  }
}
