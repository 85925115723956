// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiUrl: '#{web.apiUrl}#',
  sessionTimeoutInSeconds: 3600,
  sessionActivityEvents: [
    { target: window, event: 'mousemove' },
    { target: document, event: 'keydown' },
  ],
  applicationInsights: '',
  tax: {
    attachments: {
      allowedExtensions: '.xlsx,.csv,.docx,.txt,.pdf,.jpeg',
      allowedSize: 20 * 1024 * 1024,
      allowedMaxFiles: 10,
    },
  },
};
