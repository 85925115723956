import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { ShortCodeConstants, ValidationConstants } from '@rar/commons/constants';
import { ModalService } from '@rar/commons/services/modal.service';
import { Role } from '@rar/model/data/enums/Role';
import { TaxTypeTopic } from '@rar/model/data/tax/TaxTypeTopic';

import { TaxTypeDataService } from '../../services/tax-type-data.service';

@Component({
  selector: 'app-tax-type-topic',
  templateUrl: './tax-type-topic.component.html',
  styleUrls: ['./tax-type-topic.component.scss'],
})
export class TaxTypeTopicComponent implements OnInit, OnDestroy {
  public readonly role = Role;
  public readonly validationConstants = ValidationConstants;

  public topic: TaxTypeTopic;

  public showRules = false;
  public showRates = false;

  public activeTopicPanelId = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public taxTypeDataService: TaxTypeDataService,
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    // resolve route data
    this.route.data
      .pipe(
        filter((data) => data.topic),
        untilDestroyed(this),
      )
      .subscribe((data) => {
        this.topic = data.topic;

        this.showRates = !!(this.topic.rates && this.topic.rates.length);
        this.showRules = !!(this.topic.rules && this.topic.rules.length);
      });

    this.route.params
      .pipe(
        map((params) => +params['id']),
        distinctUntilChanged(),
        untilDestroyed(this),
      )
      .subscribe((topicId) => {
        this.activeTopicPanelId = `topic-${topicId}`;
      });
  }

  ngOnDestroy(): void {}

  public selectTopic(topic: TaxTypeTopic): void {
    this.router.navigate(['topic', topic.id], { relativeTo: this.route.parent });
  }

  public beforeChange($event: NgbPanelChangeEvent) {
    if (!$event.nextState) {
      $event.preventDefault();
    }
  }

  public getTopicCounter(topic: TaxTypeTopic): number {
    const numberOfRates = topic.rates ? topic.rates.length : 0;
    const numberOfRules = topic.rules ? topic.rules.length : 0;

    return numberOfRules + numberOfRates;
  }

  public addNewTopic() {
    // multiply -999 to get "pseudo-random" non-existing ID of the temporary topic
    const category = this.taxTypeDataService.getTaxTopic(this.taxTypeDataService.getTax(), (this.topic.parent || this.topic).id);
    category.subTopics = category.subTopics || [];
    const newTopic: TaxTypeTopic = {
      id: Math.min(Math.abs(category.id) * -999, ...category.subTopics.map((st) => st.id)) - 1,
      shortCode: ShortCodeConstants.newPlaceholder, // will be calculated on API
      type: null,
      subTopics: [],
      rules: [],
      rates: [],
      parent: category,
      parentId: category.id,
      name: '',
      taxType: category.taxType,
      order: !category.subTopics || category.subTopics.length === 0 ? 1 : category.subTopics[category.subTopics.length - 1].order + 1,
    };
    category.subTopics.push(newTopic);
    this.selectTopic(newTopic);
    this.markForCheck();
  }

  private markForCheck(): void {
    this.cdr.markForCheck();
    setTimeout(() => this.cdr.markForCheck());
  }

  public deleteCategory(categoryId: number) {
    this.modalService
      .openConfirmation({
        title: this.translateService.instant('tax.tax-topics.category-delete-title'),
        yesButtonText: this.translateService.instant('tax.tax-topics.rule-delete-confirm'),
        noButtonText: this.translateService.instant('commons.modal.cancel'),
        content: this.translateService.instant('tax-design.messages.tax-category-delete-content'),
        isDestructiveWarning: true,
      })
      .then(
        () => {
          const category = this.taxTypeDataService.getTax().topics.find((cat) => cat.id === categoryId);
          this.handleDeleteCategory(category);
        },
        () => {},
      );
  }

  private handleDeleteCategory(category: TaxTypeTopic) {
    const deleteCategoryIndex = this.taxTypeDataService.getTax().topics.indexOf(category);

    let routeCategoryId = null;
    if (deleteCategoryIndex > 0) {
      // select previous category
      routeCategoryId = this.taxTypeDataService.getTax().topics[deleteCategoryIndex - 1].id;
    } else if (deleteCategoryIndex === 0 && this.taxTypeDataService.getTax().topics.length > 1) {
      // select next category
      routeCategoryId = this.taxTypeDataService.getTax().topics[deleteCategoryIndex + 1].id;
    }
    if (deleteCategoryIndex >= 0) {
      this.taxTypeDataService.getTax().topics.splice(deleteCategoryIndex, 1);
    }
    let url = '/tax-design/' + this.taxTypeDataService.getTax().id;

    if (routeCategoryId) {
      url += '/topic/' + routeCategoryId;
    }
    this.router.navigate([url]);
  }

  public deleteTopic(index: number) {
    this.modalService
      .openConfirmation({
        title: this.translateService.instant('tax.tax-topics.topic-delete-title'),
        yesButtonText: this.translateService.instant('tax.tax-topics.rule-delete-confirm'),
        noButtonText: this.translateService.instant('commons.modal.cancel'),
        content: this.translateService.instant('tax-design.messages.tax-topic-delete-content'),
        isDestructiveWarning: true,
      })
      .then(
        () => {
          const tax = this.taxTypeDataService.getTax();
          const category = this.taxTypeDataService.getTaxTopic(tax, (this.topic.parent || this.topic).id);
          category.subTopics.splice(index, 1);
        },
        () => {},
      );
  }

  public sortTopics(topics: TaxTypeTopic[]): TaxTypeTopic[] {
    return topics ? topics.sort((a, b) => a.order - b.order) : [];
  }

  public changeTopicOrder(event: Event, index: number, moveUp: boolean, parent?: TaxTypeTopic) {
    event.stopPropagation();
    const parentTopic = parent ?? this.topic;
    const currentTopicOrder = parentTopic.subTopics[index].order;
    const moveToIndex = moveUp ? index - 1 : index + 1;
    parentTopic.subTopics[index].order = parentTopic.subTopics[moveToIndex].order;
    parentTopic.subTopics[moveToIndex].order = currentTopicOrder;
    (this.topic.parent || this.topic).subTopics = parentTopic.subTopics;

    this.cdr.markForCheck();
  }
}
