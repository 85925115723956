import { Inject, Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Observable, Subscriber, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpStatusCodes } from '@rar/commons/enums/http-status.enum';
import { ModalService } from '@rar/commons/services/modal.service';
import { HandledHttpErrorResponse } from '@rar/commons/util/handled-http-error-response';
import { ApiCommunicationService } from '@rar/model/services/api-communication/api-communication.service';
import { RichTextEditorComponent } from '@rar/rich-text-editor/components/rich-text-editor/rich-text-editor.component';

import { environment } from '../../../environments/environment';
import { AttachmentEmbedData } from './attachment-embed-data';

/**
 * Angular service for quill attachments support (singleton - it's not connected to quill editor).
 */
@Injectable({ providedIn: 'root' })
export class AttachmentHandlerService {
  constructor(
    private modalService: ModalService,
    private translateService: TranslateService,
    @Inject(ApiCommunicationService) private api: ApiCommunicationService,
  ) {}

  private static readonly removeButtonHtmlTemplate = `
<button class='ql-attachment__btn-remove' aria-label='Remove'>
  <svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='times' class='svg-inline--fa fa-times fa-w-11'
       role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 352 512'>
    <path fill='currentColor' d='M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176
                                 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256
                                 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07
                                 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z'></path>
  </svg>
</button>`;

  private static readonly attachmentHtmlTemplate = `
<span class='ql-attachment__uploading'>Uploading<span class='ql-attachment__uploading-failed'>&nbsp;failed</span>:&nbsp;</span>
<a class='ql-attachment__link-download' href='#' target='_blank' download></a>
<div class='ql-attachment__spinner-uploading spinner-border spinner-border-sm'></div>
${AttachmentHandlerService.removeButtonHtmlTemplate}`;

  private static readonly attachmentErrorHtmlTemplate = `
<span class='ql-attachment__error'></span>
${AttachmentHandlerService.removeButtonHtmlTemplate}`;

  private deactivationDialogVisibleSubject = new BehaviorSubject<boolean>(false);

  private attachmentsWithFilesUploading = new Set<string>();
  private areFilesBeingUploadedSubject = new BehaviorSubject<boolean>(false);
  public areFilesBeingUploaded$ = this.areFilesBeingUploadedSubject.asObservable();

  public serialize(node: HTMLElement): AttachmentEmbedData {
    const elementId = node.getAttribute('data-element-id');
    const name = node.getAttribute('data-filename');
    const size = node.getAttribute('data-size');
    const type = node.getAttribute('data-type');
    const attachmentId = node.getAttribute('data-attachment-id') || null;
    const isUploading = !!node.getAttribute('data-is-uploading');

    return {
      ...(elementId ? { elementId } : {}),
      elementId,
      file: {
        name,
        size: size === null || isNaN(+size) ? null : +size,
        type,
      },
      attachmentId,
      ...(isUploading ? { isUploading: true } : {}),
    };
  }

  public render(element: HTMLDivElement, valueOrError: AttachmentEmbedData | string): void {
    element.contentEditable = 'false';

    if (typeof valueOrError === 'string') {
      // valueOrError contains error
      element.innerHTML = AttachmentHandlerService.attachmentErrorHtmlTemplate;
      const span = element.querySelector<HTMLSpanElement>('.ql-attachment__error');
      if (span) {
        span.innerText = valueOrError;
      }
      return;
    }

    // valueOrError contains value
    element.innerHTML = AttachmentHandlerService.attachmentHtmlTemplate;
    if (valueOrError.elementId) {
      element.setAttribute('data-element-id', valueOrError.elementId);
    }
    if (valueOrError.isUploading) {
      element.setAttribute('data-is-uploading', 'true');
    }
    element.setAttribute('data-filename', valueOrError.file.name);
    element.setAttribute('data-size', `${valueOrError.file.size}`);
    element.setAttribute('data-type', valueOrError.file.type);

    if (valueOrError.attachmentId) {
      this.updateNodeAttributesAfterFileUpload(element, valueOrError.attachmentId);
    }

    const link = element.querySelector('.ql-attachment__link-download');
    if (link) {
      link.setAttribute('data-filename', valueOrError.file.name);
    }
  }

  private updateNodeAttributesAfterFileUpload(element: HTMLElement, attachmentId: string): void {
    element.setAttribute('data-attachment-id', attachmentId);
    element.removeAttribute('data-is-uploading');
    element.removeAttribute('data-element-id');
  }

  public onFileUploaded(element: HTMLElement, attachmentId: string): void {
    this.updateNodeAttributesAfterFileUpload(element, attachmentId);
  }

  public handleClick($event: MouseEvent): void {
    if (!$event?.target || !($event.target instanceof Element)) {
      return;
    }

    const attachment = $event.target.closest<HTMLDivElement>('div.ql-attachment');
    const link = $event.target.closest<HTMLAnchorElement>('a.ql-attachment__link-download');
    const button = $event.target.closest<HTMLButtonElement>('button.ql-attachment__btn-remove');
    if (!attachment) {
      return;
    }

    $event.preventDefault();
    $event.stopImmediatePropagation();

    if (link) {
      this.handleDownloadClick(attachment);
    } else if (button) {
      this.handleRemoveClick(attachment);
    }
  }

  private handleDownloadClick(attachmentElement: HTMLDivElement): void {
    const attachmentId = attachmentElement.getAttribute('data-attachment-id');

    if (!attachmentId) {
      this.modalService
        .showInformation({
          title: this.translateService.instant('tax.attachments.error-not-saved-yet'),
        })
        .then();
      return;
    }

    this.api
      .tax()
      .downloadAttachment(attachmentId)
      .pipe(
        catchError((err: HandledHttpErrorResponse) => {
          if (err.status === HttpStatusCodes.NotFound) {
            this.modalService
              .showInformation({
                title: this.translateService.instant('tax.attachments.error-not-saved-yet'),
              })
              .then();
            err.handled = true;
          }

          return of(null);
        }),
      )
      .subscribe((file: File | null) => {
        if (file) {
          saveAs(file, file.name);
        }
      });
  }

  private handleRemoveClick(attachmentElement: HTMLDivElement): void {
    const root = attachmentElement.closest('.ql-attachment');
    const elementToFocus = root?.nextElementSibling || root?.previousElementSibling;
    attachmentElement.remove();
    setTimeout(() => {
      if (elementToFocus && elementToFocus instanceof HTMLElement) {
        const range = document.createRange();
        const sel = window.getSelection();

        range.setStart(elementToFocus, 0);
        range.collapse(true);

        sel.removeAllRanges();
        sel.addRange(range);
      }
    });
  }

  public async upload(editorComponent: RichTextEditorComponent, elementId: string, file: File): Promise<string> {
    try {
      this.addAttachmentWithFileUploading(elementId);
      const attachmentId = await this.api.tax().uploadTaxAttachment(file).pipe(untilDestroyed(editorComponent)).toPromise();
      return attachmentId;
    } finally {
      this.removeAttachmentWithFileUploading(elementId);
    }
  }

  private addAttachmentWithFileUploading(elementId: string) {
    this.attachmentsWithFilesUploading.add(elementId);
    this.areFilesBeingUploadedSubject.next(this.attachmentsWithFilesUploading.size > 0);
    if (this.attachmentsWithFilesUploading.size === 1) {
      // Prevent page to be closed while file is uploading
      window.addEventListener('beforeunload', this.beforeUnloadListener, { capture: true });
    }
  }

  private beforeUnloadListener = (event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = this.translateService.instant('tax.attachments.confirmation-uploading');
    return event.returnValue;
  };

  private removeAttachmentWithFileUploading(elementId: string) {
    this.attachmentsWithFilesUploading.delete(elementId);
    this.areFilesBeingUploadedSubject.next(this.attachmentsWithFilesUploading.size > 0);
    if (this.attachmentsWithFilesUploading.size === 0) {
      // Allow page to be closed
      window.removeEventListener('beforeunload', this.beforeUnloadListener, { capture: true });
    }
  }

  public showInvalidExtensionMessage(): void {
    this.modalService
      .showInformation({
        title: this.translateService.instant('tax.attachments.validation-title'),
        content: this.translateService
          .instant('tax.attachments.validation-error-extension', {
            extensions: environment.tax.attachments.allowedExtensions.replace(/\./g, ' '),
          })
          .split('\n'),
      })
      .then();
  }

  public showInvalidSizeMessage(): void {
    this.modalService
      .showInformation({
        title: this.translateService.instant('tax.attachments.validation-title'),
        content: this.translateService
          .instant('tax.attachments.validation-error-size', {
            maxSize: `${Math.round((environment.tax.attachments.allowedSize / 1024 / 1024) * 100) / 100}MB`,
          })
          .split('\n'),
      })
      .then();
  }

  public showMaxFilesMessage(): void {
    this.modalService
      .showInformation({
        title: this.translateService.instant('tax.attachments.validation-title'),
        content: this.translateService
          .instant('tax.attachments.validation-error-max-files', {
            maxFiles: `${environment.tax.attachments.allowedMaxFiles}`,
          })
          .split('\n'),
      })
      .then();
  }

  public showAddingNotUploadedMessage(): void {
    this.modalService
      .showInformation({
        title: this.translateService.instant('tax.attachments.validation-title'),
        content: this.translateService.instant('tax.attachments.validation-error-adding-not-uploaded').split('\n'),
      })
      .then();
  }

  public canDeactivate(): Observable<boolean> {
    if (!this.areFilesBeingUploadedSubject.value) {
      return of(true);
    }
    return new Observable((observer: Subscriber<boolean>) => {
      this.deactivationDialogVisibleSubject.next(true);
      this.modalService
        .openConfirmation({
          title: this.translateService.instant('tax.attachments.confirmation-uploading'),
        })
        .then(
          () => observer.next(true),
          () => observer.next(false),
        )
        .finally(() => {
          this.deactivationDialogVisibleSubject.next(false);
        });
    });
  }

  public isDeactivationDialogVisible(): Observable<boolean> {
    return this.deactivationDialogVisibleSubject.asObservable();
  }
}
