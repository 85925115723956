import {Component, OnInit} from "@angular/core";
import {User} from "../../../model/data/user/User";
import {AuditFilterService} from "../../services/audit-filter.service";
import {Observable} from "rxjs";
import {AuditTax} from "../../../model/data/audit/AuditTax";

@Component({
	selector: "app-audit-trail-page",
	templateUrl: "./audit-trail-page.component.html",
	styleUrls: ["./audit-trail-page.component.scss"]
})
export class AuditTrailPageComponent implements OnInit {

	public users: Observable<Array<User>>;
	public taxes: Observable<Array<AuditTax>>;


	constructor(private filterService: AuditFilterService) {
		filterService.fixedTaxId = undefined;
		this.users = filterService.users;
		this.taxes = filterService.taxes;
	}

	ngOnInit() {

	}

}
