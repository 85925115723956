import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { TaxChanges } from '../../../model/data/dashboard/TaxChanges';
import { DashboardLoaderService } from '../../services/dashboard-loader.service';

@Component({
  selector: 'app-dashboard-latest-changes',
  templateUrl: './dashboard-latest-changes.component.html',
  styleUrls: ['./dashboard-latest-changes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardLatestChangesComponent {
  @Input('latestChanges')
  public taxes: Array<TaxChanges>;

  @Input('username')
  public username: string;

  constructor(private dashboardService: DashboardLoaderService) {}

  onClick() {
    this.dashboardService.load.emit();
  }
}
