<div id="tax-design-layout">
  <!--Page title-->
  <div id="header" class="padding-bottom">
    <div>
      <h1>{{ 'tax-design.list.title' | translate }}</h1>
    </div>

    <div class="flex-flow">
      <button class="btn btn-sm btn-primary btn-icon" (click)="addNewTaxType(newTaxTypeDialogTemplate)" data-test="add new tax type">
        <svg-icon [src]="'/assets/images/icons/add2.svg'"></svg-icon>
        <span>{{ 'tax-design.actions.add' | translate }}</span>
      </button>
    </div>
  </div>

  <!--Page content-->
  <div>
    <!--Tax types table-->
    <app-tax-types-table></app-tax-types-table>
  </div>
</div>

<ng-template #newTaxTypeDialogTemplate>
  <form #newTaxTypeForm="ngForm">
    <div class="form-group">
      <label for="new-tax-type-name" class="label-required">{{ 'tax-design.add-new-form.name-label' | translate }}</label>
      <input
        id="new-tax-type-name"
        name="newTaxTypeName"
        #newTaxTypeNameModel="ngModel"
        type="text"
        class="form-control"
        [(ngModel)]="newTaxTypeName"
        required
        [minlength]="ValidationConstants.taxTypeMinLength"
        [maxlength]="ValidationConstants.taxTypeMaxLength"
        [pattern]="ValidationConstants.categoryTopicNamePattern"
        (focusout)="newTaxTypeName = newTaxTypeName.trim()"
        data-test="new tax type name" />
        <!-- // Req 6 -->
        <label for="new-tax-type-approvalLevel" class="label-required">{{ 'tax-design.add-new-form.approvalLevel-label' | translate }}</label>
        <ng-select
          id="new-tax-type-approvalLevel"
          #newTaxTypeApprovalModel="ngModel"
          name="newTaxTypeApprovalLevel"
          [items]="newTaxTypeApprovalLevelList" 
           bindLabel="text" 
           bindValue="id" 
           [(ngModel)]="newTaxTypeApprovalLevel"
           class="selection"
           [searchable]="false"
           required
        >
        </ng-select>
      <div class="invalid-feedback" *ngIf="newTaxTypeNameModel.invalid">
        <div class="invalid-feedback__message">
          <i class="fa fa-exclamation-triangle mr-1"></i>
          <ng-container *ngIf="newTaxTypeNameModel.errors.pattern && !newTaxTypeNameModel.errors.minlength">
            <span>{{ 'commons.validation.category-topic-pattern' | translate }}</span>
          </ng-container>
          <ng-container *ngIf="newTaxTypeNameModel.errors.minlength">
            <span>{{
              'commons.validation.minlength' | translate: { minlength: newTaxTypeNameModel.errors.minlength.requiredLength }
            }}</span>
          </ng-container>
          <ng-container *ngIf="newTaxTypeNameModel.errors.required">
            <span>{{ 'commons.validation.required' | translate }}</span>
          </ng-container>
        </div>
      </div>
    </div>
  </form>
</ng-template>
