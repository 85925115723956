import { AfterContentInit, Component, OnDestroy, OnInit, ViewChild, forwardRef } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';

import { ContentChange, QuillEditorComponent } from 'ngx-quill';
import { Delta } from 'quill';
import { environment } from 'src/environments/environment';

import { AttachmentEmbedData } from '@rar/rich-text-editor/attachments/attachment-embed-data';
import { AttachmentHandlerService } from '@rar/rich-text-editor/attachments/attachment-handler.service';
import { QuillWithEditor } from '@rar/rich-text-editor/helpers/quill-extensions';

@Component({
  selector: 'app-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => RichTextEditorComponent),
    },
    {
      multi: true,
      provide: NG_VALIDATORS,
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      useExisting: forwardRef(() => RichTextEditorComponent),
    },
  ],
})
export class RichTextEditorComponent implements AfterContentInit, ControlValueAccessor, OnInit, Validator, OnDestroy {
  @ViewChild(QuillEditorComponent, { static: true })
  quill: QuillEditorComponent;

  private disableContentChecking = false;

  constructor(private attachmentHandlerService: AttachmentHandlerService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  ngAfterContentInit(): void {
    // wait for next JS cycle after quill internals are rendered
    setTimeout(() => {
      (this.quill.quillEditor as QuillWithEditor).editorComponent = this;
      const el: HTMLElement = this.quill.elementRef.nativeElement;
      el.querySelector('.ql-tooltip a.ql-action')?.setAttribute('href', '#');
      el.querySelector('.ql-tooltip a.ql-remove')?.setAttribute('href', '#');
    });
  }

  public submitTextEditor(): void {
    const text = this.quill.quillEditor.getText();
    const content = this.quill.quillEditor.getContents();
  }

  private getFilesFromDelta(delta: Delta): AttachmentEmbedData[] {
    return delta.filter((op) => op && op.insert && op.insert.attachment).map((op) => op.insert);
  }

  private notUploadedFilesExist(delta: Delta): boolean {
    return delta
      .filter((op) => op && op.insert && op.insert.attachment)
      .map((op) => <Partial<AttachmentEmbedData>>op.insert.attachment)
      .some((att) => !att.attachmentId || !att.file || !att.file.name);
  }

  private ensureNoMoreThanMaxFilesAttached(delta: Delta) {
    let files = 0;
    delta.ops = delta.ops.filter((op) => {
      if (!!op.insert.attachment) {
        files++;
        if (files > environment.tax.attachments.allowedMaxFiles) {
          return false;
        }
      }
      return true;
    });
    return delta;
  }

  public onContentChanged(event: ContentChange) {
    if (this.disableContentChecking) {
      return;
    }
    if (this.getFilesFromDelta(event.content).length > environment.tax.attachments.allowedMaxFiles) {
      this.attachmentHandlerService.showMaxFilesMessage();
      this.quill.quillEditor.setContents(this.ensureNoMoreThanMaxFilesAttached(event.oldDelta), 'user');
    } else if (this.notUploadedFilesExist(event.delta)) {
      this.attachmentHandlerService.showAddingNotUploadedMessage();
      this.restoreQuillContent(event.oldDelta);
    }
  }

  private restoreQuillContent(oldDelta: Delta) {
    try {
      this.disableContentChecking = true;
      this.quill.quillEditor.setContents(oldDelta, 'user');
    } finally {
      this.disableContentChecking = false;
    }
  }

  writeValue(currentValue: any): void {
    this.quill.writeValue(currentValue);
  }

  registerOnChange(fn: (modelValue: any) => void): void {
    this.quill.registerOnChange(fn);
  }

  registerOnTouched(fn: () => void): void {
    this.quill.registerOnTouched(fn);
  }

  setDisabledState(isDisabled?: boolean): void {
    this.quill.setDisabledState(isDisabled);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.quill.validate();
  }

  registerOnValidatorChange(fn: () => void): void {
    this.quill.registerOnValidatorChange(fn);
  }

  public insertAttachment(index: number, value: AttachmentEmbedData) {
    try {
      this.disableContentChecking = true;
      this.quill.quillEditor.insertEmbed(index, 'attachment', value, 'user');
    } finally {
      this.disableContentChecking = false;
    }
  }
}
