import { AbstractApiConnector } from "./AbstractApiConnector";
import { Observable } from "rxjs";
import { AuditTrailListResponse } from "../response/AuditTrailListResponse";
import { User } from "../data/user/User";
import { Tax } from "../data/tax/Tax";
import { ValidationError } from "../exception/ValidationError";

export class AuditApiConnector extends AbstractApiConnector {

	// Api base url
	protected readonly apiRoute = "/api/v1/audit";

	public getAuditPage(pageNumber: number, filter: any = {}): Observable<AuditTrailListResponse> {

		if (typeof pageNumber !== "number") {
			throw new ValidationError();
		} else {
			return this.apiClient.get(this.apiRoute + "/page/" + pageNumber, { filter: JSON.stringify(filter) });
		}

	}

	public getAuditUsers(taxId?: number): Observable<Array<User>> {

		// optional query param
		let q;
		if (taxId) {

			if (typeof taxId !== "number") {
				throw new ValidationError();
			}

			q = {
				taxId: taxId
			};
		}

		return this.apiClient.get(this.apiRoute + "/users", q);
	}

	public getAuditTaxes(): Observable<Array<Tax>> {
		return this.apiClient.get(this.apiRoute + "/taxes");
	}
}
