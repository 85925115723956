import { Component, TemplateRef } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  constructor(public activeModal: NgbActiveModal) {}

  public title!: string;

  public content!: string[];

  public yesButtonText!: string;

  public noButtonText!: string;

  public isInformationDialog = false;

  public contentTemplate!: TemplateRef<any>;

  public validator!: () => boolean;

  public isDestructiveWarning!: boolean;

  public close(result: boolean) {
    if (result) {
      if (!this.validator || this.validator()) {
        this.activeModal.close();
      }
      return;
    }

    this.activeModal.dismiss();
  }
}
