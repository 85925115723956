import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, of } from 'rxjs';

import { RoutePaths } from '@rar/commons/constants';
import { ModalService } from '@rar/commons/services/modal.service';

import { TaxLock } from '../../../model/data/tax/TaxLock';
import { TaxTypeStructure } from '../../../model/data/tax/TaxTypeStructure';
import { ApiCommunicationService } from '../../../model/services/api-communication/api-communication.service';
import { UserSessionService } from '../../../user/services/user-session/user-session.service';
import { TaxTypeDataService } from '../../services/tax-type-data.service';
import { TaxTypeModalService } from '../../services/tax-type-modal.service';

@Component({
  selector: 'app-tax-type-structure-page',
  templateUrl: './tax-type-structure-page.component.html',
  styleUrls: ['./tax-type-structure-page.component.scss'],
  providers: [TaxTypeDataService, TaxTypeModalService],
})
export class TaxTypeStructurePageComponent implements OnInit, OnDestroy {
  public taxTypeStructure: TaxTypeStructure = undefined;

  private lockTimeoutId: any;
  private lockInfoIntervalId: any;
  private lockRequestIntervalId: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modalService: ModalService,
    private translateService: TranslateService,
    public taxTypeDataService: TaxTypeDataService,
    private userSessionService: UserSessionService,
    @Inject(ApiCommunicationService) private api: ApiCommunicationService,
  ) {}

  async ngOnInit() {
    // resolve route data
    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.taxTypeStructure = data.taxTypeStructure;
      this.taxTypeDataService.setTax(this.taxTypeStructure);
    });

    // get tax lock info
    const info = await this.getTaxTypeLockInfo();
    const isTaxTypeEdited = !info || (!info.ok && info.action === 'locked by another user');
    const isTaxEdited = info && !info.ok && info.action === 'tax-editing';

    if (isTaxTypeEdited || isTaxEdited) {
      await this.modalService.showInformation({
        title: this.translateService.instant('tax-design.messages.cannot-edit-tax-type'),
        content: this.translateService
          .instant(isTaxTypeEdited ? 'tax-design.messages.lock-tax-type-is-edited' : 'tax-design.messages.lock-tax-is-edited')
          .split('\n'),
      });
      this.router.navigate(['/' + RoutePaths.taxDesign.list]);
      return;
    }

    this.taxTypeDataService.askForDeactivation = true;

    if (info.action == null || info.action === 'open') {
      this.taxTypeDataService.lock();
    }

    // send lock request every 4 min if is editing
    this.lockRequestIntervalId = setInterval(() => {
      this.taxTypeDataService.lock();
    }, 240000);

    this.lockTimeoutId = setTimeout(() => {
      // poll lock info every half min
      this.lockInfoIntervalId = setInterval(() => {
        this.getTaxTypeLockInfo();
      }, 30000);
    }, 30000);
  }

  ngOnDestroy(): void {
    // clear timeouts
    if (this.lockTimeoutId) {
      clearTimeout(this.lockTimeoutId);
    }

    // clear intervals
    if (this.lockRequestIntervalId) {
      clearInterval(this.lockRequestIntervalId);
    }

    if (this.lockInfoIntervalId) {
      clearInterval(this.lockInfoIntervalId);
    }
  }

  public canDeactivate(): Observable<boolean> {
    if (!this.taxTypeDataService.askForDeactivation) {
      return of(true);
    }

    return this.taxTypeDataService.onClickDiscardModal();
  }

  private async getTaxTypeLockInfo(): Promise<TaxLock> {
    const info: TaxLock = await this.api.tax().getTaxTypeLockInfo(this.taxTypeDataService.getTax().id).toPromise();

    // if current tax type is not locked
    if (info.ok) {
      info.ok = false;
      info.action = null;
      info.user = null;
    }

    // re-fetch tax if ...
    if (this.taxTypeDataService.taxLockInfo && !this.taxTypeDataService.taxLockInfo.ok && info.ok) {
      this.api
        .tax()
        .getTaxTypeStructure(this.taxTypeDataService.getTax().id)
        .pipe(untilDestroyed(this))
        .subscribe((taxTypeStructure: TaxTypeStructure) => {
          this.taxTypeDataService.setTax(taxTypeStructure);
        });
    }

    this.taxTypeDataService.taxLockInfo = info;
    return info;
  }
}
