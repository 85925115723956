<div class="modal-header">
  <h4 class="modal-title" [class.destructive-warning]="isDestructiveWarning">{{ title }}</h4>
  <button type="button" class="btn close" aria-label="Close" (click)="close(false)">
    <svg-icon [src]="'/assets/images/icons/cross.svg'"></svg-icon>
  </button>
</div>

<div class="modal-body" [class.destructive-warning]="isDestructiveWarning">
  <ng-container *ngIf="content; else contentTemplate">
    <p *ngFor="let paragraph of content">{{ paragraph }}</p>
  </ng-container>
</div>

<div class="modal-footer">
  <button *ngIf="!isInformationDialog" type="button" class="btn btn-outline-secondary" (click)="close(false)">
    {{ noButtonText }}
  </button>
  <button type="button" class="btn btn-primary" (click)="close(true)">
    {{ yesButtonText }}
  </button>
</div>
