/**
 * Enumeration of [[TaxRevision]] Statuses.
 * Should be the same as the contents of the [TaxRevisionStatus] table in the database.
 * If those do not line up than this enum should be the representative.
 *
 *
 * Tax revision statuses will help in filtering tax revisions for active ones to display.
 */

/*
* TODO Need to rethink revision statuses for the following transitions:
  - editor updates the current active revision
  - editor creates new revision for a tax in the past , for historical purposes
  - editor creates new revision for a tax in the future , a tax revision that will true in the future
  - approver accepts tax revision
  - approver rejects tax revision
  - editor updates rejected tax revision
  - tax revision expires
  - tax revision activates   > we don't even have an active/live status


*/
export enum TaxRevisionStatus {
  PENDING_LOCAL = 1,
  REJECTED = 2,
  CLOSED = 3,
  APPROVED = 4,
  PENDING_GLOBAL = 5,
}
