/**
 * Object that bundles together [[TaxRate]] objects and [[TaxRule]] for the parent [[TaxRevision]] in tree structure.
 */
import { DatabaseEntity } from '../DatabaseEntity';
import { TaxRate } from './TaxRate';
import { TaxRevision } from './TaxRevision';
import { TaxRule } from './TaxRule';

export class TaxTopic extends DatabaseEntity {
  /**
   * Unique identifier for a topic
   */
  public id: number;

  /**
   * Tax topic text identifier.
   */
  public shortCode: string;

  /**
   * Parent of the topic, points to [[TaxRevision]]'s id
   */
  public taxRevision: TaxRevision;

  /**
   * Tax type ID.
   */
  public taxType: number;

  /**
   * Array of all related rates to the [[TaxTopic]]
   */
  public rates: Array<TaxRate>;

  /**
   * Array of all related rules to the [[TaxTopic]]
   */
  public rules: Array<TaxRule>;

  /**
   * The tax topic id of the current node's parent, if there is any
   */
  public parent: TaxTopic;

  /**
   * The tax topic id collection of the current node's children, if present.
   */
  public subTopics: Array<TaxTopic>;

  /**
   * Tax topic full name, displayed on tax page
   */
  public name: string;

  /**
   * The description of the tax topic
   */
  public description: string;

  /**
   * Flag for a deleted topic
   */
  public isDeleted: boolean;

  /**
   * Topic order on local level
   */
  public order: number;
}
