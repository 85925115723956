import {ObjectWithIdentity} from "./ObjectWithIdentity";

export abstract class DatabaseEntity implements ObjectWithIdentity {

	// object identifier
	id: number;

	// creation and update date
	createdAt?: Date;
	updatedAt?: Date;
}
