import {TaxRevision} from "./TaxRevision";
import {User} from "../user/User";

export class TaxChangeNotes {

	id: number;
	changeNotes: string;
	isSummary: boolean;
	taxId: number;
	message: string;

	taxRevision: TaxRevision;
	user: User;

}
