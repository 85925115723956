import { Component, OnDestroy, OnInit } from '@angular/core';

import { untilDestroyed } from 'ngx-take-until-destroy';
import { timer } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UserSessionService } from '@rar/user/services/user-session/user-session.service';

@Component({
  selector: 'app-login-dimmer',
  templateUrl: './login-dimmer.component.html',
  styleUrls: ['./login-dimmer.component.scss'],
})
export class LoginDimmerComponent implements OnInit, OnDestroy {
  public dim = false;

  constructor(private userSessionService: UserSessionService) {}

  ngOnInit() {
    timer(1000, 500) // run every 0.5 second
      .pipe(
        untilDestroyed(this),
        tap(() => (this.dim = !this.userSessionService.isUserLoggedIn())),
      )
      .subscribe();
  }

  ngOnDestroy(): void {}

  public onClickLogin() {
    this.userSessionService.redirectToLogin();
  }
}
