import {Component, EventEmitter, OnInit, Output} from "@angular/core";

@Component({
	selector: "app-error-display",
	templateUrl: "./error-display.component.html",
	styleUrls: ["./error-display.component.scss"]
})
export class ErrorDisplayComponent implements OnInit {

	@Output()
	public onRefresh: EventEmitter<void> = new EventEmitter();

	constructor() {
	}

	ngOnInit() {
	}

	public onClickRefresh() {
		this.onRefresh.emit();
	}

}
