<h3>{{ 'tax.tax-topics.topic-type' | translate }}</h3>

<div class="types">
  <button type="button" [disabled]="true" class="selected">
    <svg-icon class="icon" src="../../../../assets/images/icons/list.svg"></svg-icon>
    <p>
      <strong>{{ 'tax.tax-topics.type-list' | translate }}</strong>
      <span>{{ 'tax.tax-topics.type-list-details' | translate }}</span>
    </p>
    <span class="indicator"></span>
  </button>

  <button type="button" [disabled]="true">
    <svg-icon class="icon" src="../../../../assets/images/icons/table.svg"></svg-icon>
    <p>
      <strong>{{ 'tax.tax-topics.type-table' | translate }}</strong>
      <span>{{ 'tax.tax-topics.type-table-details' | translate }}</span>
    </p>
    <span class="indicator"></span>
  </button>
</div>

<p class="mb-3">{{ 'tax.tax-topics.tables-local-only' | translate }}</p>
