<h3>{{ 'tax.tax-topics.topic-type' | translate }}</h3>

<div class="types" title="{{ isDisabled ? ('tax.tax-topics.empty-topic-can-be-changed' | translate) : '' }}">
  <button
    type="button"
    [disabled]="isDisabled"
    [class.selected]="articleType === ArticleType.KEY_VALUE"
    (click)="changeArticleType(ArticleType.KEY_VALUE)">
    <svg-icon class="icon" src="../../../../assets/images/icons/list.svg"></svg-icon>
    <p>
      <strong>{{ 'tax.tax-topics.type-list' | translate }}</strong>
      <span>{{ 'tax.tax-topics.type-list-details' | translate }}</span>
    </p>
    <span class="indicator"></span>
  </button>

  <button
    type="button"
    [disabled]="isDisabled"
    [class.selected]="articleType === ArticleType.JSON"
    (click)="changeArticleType(ArticleType.JSON)">
    <svg-icon class="icon" src="../../../../assets/images/icons/table.svg"></svg-icon>
    <p>
      <strong>{{ 'tax.tax-topics.type-table' | translate }}</strong>
      <span>{{ 'tax.tax-topics.type-table-details' | translate }}</span>
    </p>
    <span class="indicator"></span>
  </button>
</div>
