export const JSONHelper = {
  isJSON(text: string): boolean {
    try {
      JSON.parse(text);
      return true;
    } catch {
      return false;
    }
  },
} as const;
