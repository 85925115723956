import { EventEmitter, Inject, Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

import { Role } from '@rar/model/data/enums/Role';
import { UserSessionService } from '@rar/user/services/user-session/user-session.service';

import { AuditTax } from '../../model/data/audit/AuditTax';
import { AuditTrail } from '../../model/data/audit/AuditTrail';
import { TaxListing } from '../../model/data/tax/TaxListing';
import { User } from '../../model/data/user/User';
import { AuditTrailListResponse } from '../../model/response/AuditTrailListResponse';
import { ApiCommunicationService } from '../../model/services/api-communication/api-communication.service';

interface AuditFilter {
  userId?: number[];

  taxId?: number[];
}

@Injectable()
export class AuditFilterService {
  public fixedTaxId = undefined;
  public selectedTaxId = undefined;

  public allAuditTrails: AuditTrailListResponse;
  private _auditTrails: BehaviorSubject<Array<AuditTrail>> = new BehaviorSubject<Array<AuditTrail>>(undefined);

  // are the next and prev pages available
  private _isNextPage = false;
  private _isPreviousPage = false;

  private _filter = <any>{};

  private _users: BehaviorSubject<Array<User>> = new BehaviorSubject<Array<User>>(undefined);
  private _taxes: BehaviorSubject<Array<AuditTax>> = new BehaviorSubject<Array<AuditTax>>(undefined);
  private _taxListings: BehaviorSubject<Array<TaxListing>> = new BehaviorSubject<Array<TaxListing>>(undefined);

  constructor(@Inject(ApiCommunicationService) private api: ApiCommunicationService, private userSessionService: UserSessionService) {}

  public filter = new EventEmitter<AuditFilter>();
  public clear = new EventEmitter<void>();

  public onFilter(filter: AuditFilter) {
    this._filter = filter;
    this.filter.emit(filter);
    this.getPage();
  }

  public onClear() {
    this.clear.emit();
    this.selectedTaxId = undefined;
    this._filter = <any>{};
    this.getPage();
  }

  public getInitData() {
    if (
      this.userSessionService.userDataValue.isSuperAdmin ||
      this.userSessionService.userDataValue.userTaxPermissions.some((utp) => utp.role === Role.ADMIN && !utp.location)
    ) {
      this.api
        .audit()
        .getAuditUsers()
        .subscribe((response) => {
          this._users.next(response);
        });
      this.api
        .audit()
        .getAuditTaxes()
        .subscribe((response) => {
          this._taxes.next(response);
        });
    }
  }

  public getPage(page: number = 1) {
    let endpoint: Observable<AuditTrailListResponse>;

    if (this.fixedTaxId) {
      // history case
      endpoint = this.api.tax().getTaxHistory(this.fixedTaxId, page, this._filter);
    } else {
      // audit case
      endpoint = this.api.audit().getAuditPage(page, this._filter);
    }

    // fetch data
    endpoint.subscribe((response) => {
      // extract data
      this.allAuditTrails = response;

      // update paging
      this._isNextPage = !!response._links.next;
      this._isPreviousPage = !!response._links.previous;

      // update filters
      this.update();
    });
  }

  private update() {
    this._auditTrails.next(this.allAuditTrails._embedded.audit_trails.filter((a) => this.filterAuditTrail(a)));
  }

  private filterAuditTrail(a: AuditTrail): boolean {
    return true;
  }

  get auditTrails(): Observable<Array<AuditTrail>> {
    return this._auditTrails.asObservable();
  }

  get users(): Observable<Array<User>> {
    return this._users.asObservable();
  }

  get taxes(): Observable<Array<AuditTax>> {
    return this._taxes.asObservable();
  }

  get taxListings(): Observable<Array<TaxListing>> {
    return this._taxListings.asObservable();
  }

  public fetchNextPage() {
    if (this.isNextPage) {
      this.getPage(this.allAuditTrails._info.current + 1);
    } else {
      console.error('Fetching next page is not allowed.');
    }
  }

  public fetchPreviousPage() {
    if (this.isPreviousPage) {
      this.getPage(this.allAuditTrails._info.current - 1);
    } else {
      console.error('Fetching previous page is not allowed.');
    }
  }

  get isNextPage(): boolean {
    return this._isNextPage;
  }

  get isPreviousPage(): boolean {
    return this._isPreviousPage;
  }

  get currentPage(): number {
    return this.allAuditTrails._info.current;
  }

  get totalPages(): number {
    return Math.max(1, this.allAuditTrails._info.total);
  }
}
