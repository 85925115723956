<div class="modal-header">
	<h4 class="modal-title">Start assessment?</h4>
</div>

<div class="modal-body">
	Starting the assessment will prevent making any further changes to the
	revision.

	<br />

	Do you want to start assessment of this pending revision?
</div>

<div class="modal-footer">
	<div></div>

	<div class="button-group">
		<!--Deny-->
		<button class="ui basic button silver" (click)="deny()">
			{{ "user.user-edit-modal.cancel" | translate }}
		</button>

		<!--Approve-->
		<button class="ui basic button rose" (click)="approve()">Yes</button>
	</div>
</div>
