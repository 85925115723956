import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { TaxSearchParam } from '../../model/request/tax/TaxSearchParam';

@Injectable()
export class TaxListingResolverService implements Resolve<TaxSearchParam> {
  constructor() {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<TaxSearchParam> | Promise<TaxSearchParam> | TaxSearchParam {
    const jurisdiction = route.queryParams['j'];
    const jurisdictionName = route.queryParams['l'];
    const date = route.queryParams['d'];
    const taxType = route.queryParams['t'];
    const taxName = route.queryParams['n'];
    const all = route.queryParams['a'];

    const rv = new TaxSearchParam(taxType, taxName, jurisdiction, jurisdictionName, date, all);
    return TaxSearchParam.isEmpty(rv) ? rv : undefined;
  }
}
