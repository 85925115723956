import { Inject, Injectable, TemplateRef } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, shareReplay, startWith } from 'rxjs/operators';

import { ngSelectConstants } from '@rar/commons/constants';
import { ModalService } from '@rar/commons/services/modal.service';
import { Location } from '@rar/model/data/location/Location';
import { ApiCommunicationService } from '@rar/model/services/api-communication/api-communication.service';

@Injectable()
export class TaxTypeLocationsFormDataService {
  private confirmationDeleteTemplateRef: TemplateRef<any>;
  private confirmationDeleteTemplateRefFiller: (locationsToDelete) => void;

  constructor(
    @Inject(ApiCommunicationService) private api: ApiCommunicationService,
    private translateService: TranslateService,
    private modalService: ModalService,
  ) {
    this.initObservables();
  }

  private jurisdictions$: Observable<Location[]>;
  isCommonDataLoaded$: Observable<boolean>;

  private initObservables(): void {
    this.jurisdictions$ = this.getJurisdictions();

    this.isCommonDataLoaded$ = this.jurisdictions$.pipe(
      filter((jurisdictions) => jurisdictions && jurisdictions.length > 0),
      map(() => true),
      startWith(false),
    );
  }

  getJurisdictionsForForm(): Observable<Location[]> {
    return this.jurisdictions$;
  }

  getSubdivisionsForForm(jurisdictions$: Observable<Location[]>, selectedJurisdiction$: Observable<number>) {
    return combineLatest([jurisdictions$, selectedJurisdiction$]).pipe(
      map(([jurisdictions, selectedJurisdiction]) => {
        if (!jurisdictions || jurisdictions.length === 0) {
          return [];
        }

        const locations = [...(jurisdictions.find((j) => j.id === selectedJurisdiction)?.children ?? [])];
        locations.unshift({
          id: ngSelectConstants.countryLevelValue,
          name: this.translateService.instant('tax-design.locations.form.country-level'),
        } as Location);

        return locations;
      }),
    );
  }

  private getJurisdictions(): Observable<Location[]> {
    return this.api.location().getAllCountriesTree().pipe(shareReplay(1));
  }

  public initDeleteTemplate(
    confirmationDeleteTemplateRef: TemplateRef<any>,
    confirmationDeleteTemplateRefFiller: (locationsToDelete) => void,
  ) {
    this.confirmationDeleteTemplateRef = confirmationDeleteTemplateRef;
    this.confirmationDeleteTemplateRefFiller = confirmationDeleteTemplateRefFiller;
  }

  public askLocationsDelete(locationsToDelete: string): Promise<boolean> {
    this.confirmationDeleteTemplateRefFiller(locationsToDelete);

    return new Promise<boolean>((resolve, reject) => {
      this.modalService
        .openConfirmation({
          title: this.translateService.instant('tax-design.locations.delete-locations-title'),
          yesButtonText: this.translateService.instant('tax-design.actions.confirm'),
          noButtonText: this.translateService.instant('tax-design.actions.cancel'),
          contentTemplate: this.confirmationDeleteTemplateRef,
        })
        .then(
          () => {
            resolve(true);
          },
          () => {
            resolve(false);
          },
        );
    });
  }
}
