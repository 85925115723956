import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';

import { RoutePaths } from '@rar/commons/constants/route-paths';
import { HttpStatusCodes } from '@rar/commons/enums/http-status.enum';

import { HandledHttpErrorResponse } from '../util/handled-http-error-response';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let error: HandledHttpErrorResponse | undefined;
    return next.handle(request).pipe(
      tap(
        () => {},
        (err: HttpErrorResponse) => {
          error = err;
        },
      ),
      finalize(() => {
        if (!error || error.handled) {
          return;
        }

        if (error.status === HttpStatusCodes.Unauthorized || error.status === HttpStatusCodes.Forbidden) {
          this.router.navigate([RoutePaths.noAccess]);
        } else if (error.status === HttpStatusCodes.NotFound) {
          this.router.navigate([RoutePaths.notFound]);
        }
      }),
    );
  }
}
