<div
  class="ui active inline centered loader"
  *ngIf="!(tax && (taxDataService.taxLockInfo || taxDataService.taxLockInfo === null)) || taxDataService.isLoading"></div>

<div
  id="tax-revision-layout"
  *ngIf="tax && (taxDataService.taxLockInfo || taxDataService.taxLockInfo === null) && !taxDataService.isLoading">
  <!--Info component-->
  <div id="info" class="padding-top-bottom">
    <app-tax-info (importFile)="handleImportFile($event)"></app-tax-info>
  </div>

  <!--Topic sub-menu component-->
  <div id="topics">
    <app-tax-sub-menu></app-tax-sub-menu>
  </div>

  <!--Content-->
  <div id="rules-rates">
    <router-outlet></router-outlet>
  </div>

  <!--Approval modal, appears only for approvers-->
  <div id="approve">
    <app-tax-approve></app-tax-approve>
  </div>
</div>
