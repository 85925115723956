import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter, switchMap } from 'rxjs/operators';

import { UserHelper } from '@rar/commons/helpers';
import { TaxTopic } from '@rar/model/data/tax/TaxTopic';
import { TopicComment } from '@rar/model/data/tax/TopicComment';
import { TaxDataService } from '@rar/tax/services/tax-data/tax-data.service';
import { TaxModalService } from '@rar/tax/services/tax-modal/tax-modal.service';

@Component({
  selector: 'app-tax-topic-comment-bar',
  templateUrl: './tax-topic-comment-bar.component.html',
  styleUrls: ['./tax-topic-comment-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaxTopicCommentBarComponent implements OnInit, OnDestroy {
  @Input() taxId: number;
  @Input() topic: TaxTopic;
  @Input() topicComments: Array<TopicComment>;

  constructor(public taxDataService: TaxDataService, public taxModalService: TaxModalService) {}

  ngOnInit(): void {
    this.taxModalService.addTopicComment
      .pipe(
        untilDestroyed(this),
        filter((event: AddTopicCommentEvent) => event.shortCode === this.topic.shortCode),
        switchMap((event: AddTopicCommentEvent) => this.taxDataService.addTopicComment(event.content, this.taxId, this.topic.shortCode)),
      )
      .subscribe();

    this.taxModalService.approveTopicComments.pipe(untilDestroyed(this)).subscribe((event: ApproveTopicCommentsEvent) => {
      if (event.shortCode === this.topic.shortCode) {
        this.taxModalService.openConfirmTopicCommentsChangesModal(this.topic.shortCode, event.commentIds);
      }
    });

    this.taxModalService.confirmApproveTopicComments.pipe(untilDestroyed(this)).subscribe((event: ConfirmApproveTopicCommentsEvent) => {
      if (event.shortCode === this.topic.shortCode) {
        if (event.isConfirmed) {
          this.taxDataService.approveTopicComments(this.taxId, event.commentIds);
        } else {
          this.taxModalService.openTopicCommentsHistoryModal(this.topic.shortCode, this.topicComments, event.commentIds);
        }
      }
    });
  }

  ngOnDestroy(): void {}

  public hasNotApprovedComments(): boolean {
    return this.topicComments.some((item) => item.isApproved === false);
  }

  public getNotApprovedCountMessage(): string {
    if (this.hasNotApprovedComments()) {
      return '(' + this.topicComments.filter((item) => item.isApproved === false).length + ' new)';
    } else {
      return '';
    }
  }

  onClickAddTopicComment() {
    this.taxDataService.onClickAddTopicComment(this.topic.shortCode);
  }

  onClickShowTopicComments() {
    this.taxDataService.onClickShowTopicComments(this.topic.shortCode, this.topicComments, []);
  }

  public get isEditor() {
    return UserHelper.canEditTax(this.taxDataService.authUser, this.taxDataService.getTax());
  }
}

export interface AddTopicCommentEvent {
  shortCode: string;
  content: string;
}

export interface ApproveTopicCommentsEvent {
  shortCode: string;
  commentIds: Array<number>;
}

export interface ConfirmApproveTopicCommentsEvent {
  shortCode: string;
  commentIds: Array<number>;
  isConfirmed: boolean;
}
