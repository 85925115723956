import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

import { QUILL_CONFIG_TOKEN, QuillModule } from 'ngx-quill';

import { AttachmentEmbed } from '@rar/rich-text-editor/attachments/attachment-embed';
import { AttachmentHandlerService } from '@rar/rich-text-editor/attachments/attachment-handler.service';
import { AttachmentsQuillModule } from '@rar/rich-text-editor/attachments/attachments-quill-module';
import { RichTextEditorComponent } from '@rar/rich-text-editor/components/rich-text-editor/rich-text-editor.component';

export function quillConfigTokenFactory(attachmentHandlerService: AttachmentHandlerService) {
  return {
    customModules: [
      { path: 'modules/attachments', implementation: AttachmentsQuillModule },
      { path: 'formats/attachment', implementation: AttachmentEmbed },
    ],
    modules: {
      syntax: false,
      toolbar: [
        ['clean'], // remove formatting button
        [{ font: [] }],
        [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        [{ align: [] }],
        ['blockquote'],
        ['link'],
        ['attachment'],
      ],
      attachments: attachmentHandlerService,
    },
    // prettier-ignore
    formats: [
      // allow only what is seen in toolbar
      'font', 'size',
      'bold', 'italic', 'underline', 'strike',
      'list', 'indent',
      'align',
      'blockquote',
      'link',
      'attachment',
    ],
  };
}

export function createQuillModule(): ModuleWithProviders<QuillModule> {
  return {
    ngModule: QuillModule,
    providers: [
      {
        provide: QUILL_CONFIG_TOKEN,
        useFactory: quillConfigTokenFactory,
        deps: [AttachmentHandlerService],
      },
    ],
  };
}

@NgModule({
  declarations: [RichTextEditorComponent],
  imports: [CommonModule, FormsModule, createQuillModule()],
  exports: [QuillModule, RichTextEditorComponent],
})
export class RichTextEditorModule {}
