import { Component, Input } from "@angular/core";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TaxChangeNotes } from "../../../model/data/tax/TaxChangeNotes";
import { TaxTopic } from '../../../model/data/tax/TaxTopic';
//req 7
interface IShowChangeNotesModalContext {
	changeNotes: TaxChangeNotes[];
  topics: TaxTopic[];
	updatedAt: Date;
}

@Component({
	selector: "app-show-change-notes-modal",
	templateUrl: "./show-change-notes-modal.component.html",
	styleUrls: ["./show-change-notes-modal.component.scss"]
})
export class ShowChangeNotesModalComponent {

	constructor(private activeModal: NgbActiveModal) { }

	@Input()
	context: IShowChangeNotesModalContext;

	deny(): void {
		this.activeModal.dismiss();
	}
}
