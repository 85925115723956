<div id="tax-changes-page-layout">
  <!--Page title-->
  <div id="header" class="padding-bottom">
    <div class="title-group">
      <h1 *ngIf="param === 'views'; else approval">{{ 'tax.tax-listing.recently-viewed' | translate }}</h1>

      <ng-template #approval>
        <h1 *ngIf="param === 'approval'; else editor">{{ 'tax.tax-listing.tax-changes-approval' | translate }}</h1>
      </ng-template>

      <ng-template #editor>
        <h1 *ngIf="+status === getStatusPendingLocal()">{{ 'dashboard.pending_local' | translate }}</h1>
        <h1 *ngIf="+status === getStatusPendingGlobal()">{{ 'dashboard.pending_global' | translate }}</h1>
        <h1 *ngIf="+status === getStatusRejected()">{{ 'dashboard.rejected' | translate }}</h1>
        <h1 *ngIf="+status === getStatusApproved()">{{ 'dashboard.approved' | translate }}</h1>
        <h1 *ngIf="param === 'changes'; else changes">&nbsp;{{ 'tax.tax-listing.tax-changes' | translate }}</h1>
      </ng-template>

      <ng-template #changes>
        <h1>{{ param | titlecase }} {{ 'tax.tax-listing.tax-changes' | translate }}</h1>
      </ng-template>
    </div>

    <div class="button-group">
      <!--
						<p>{{ "tax.tax-listing-admin.sort" | translate }}:</p>

						<button class="ui basic button" style="margin-right: 1rem">
							{{ "tax.tax-listing-admin.recently-viewed" | translate }}
							<img class="left" [src]="'../../../../assets/images/icons/arrow_down.svg'">
						</button>
			-->

      <button (click)="taxFilter.setShowFilter(!taxFilter.showFilter)" class="ui basic button">
        <img [src]="'../../../../assets/images/icons/filter.svg'" />
        {{ (taxFilter.showFilter ? 'tax.tax-listing-admin.close' : 'tax.tax-listing-admin.filter') | translate }}
      </button>
    </div>
  </div>

  <!--Page content-->
  <div>
    <!--Tax table-->
    <app-tax-table [statusFilter]="status"></app-tax-table>
  </div>
</div>
