import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';

import { NgbDateAdapter, NgbDateStruct, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, tap } from 'rxjs/operators';

import { Location } from '@rar/model/data/location/Location';
import { LocationWithTaxTypes } from '@rar/model/data/location/LocationWithTaxTypes';
import { TaxType } from '@rar/model/data/tax/TaxType';
import { User } from '@rar/model/data/user/User';
import { TaxSearchParam } from '@rar/model/request/tax/TaxSearchParam';
import { ApiCommunicationService } from '@rar/model/services/api-communication/api-communication.service';

import { UserSessionService } from '../../../user/services/user-session/user-session.service';
import { CollapseService } from '../../services/collapse.service';
import { SearchService } from '../../services/search.service';
import { UserEncryptionService } from '@rar/user/services/user-encryption/user-encryption.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit, OnDestroy {
  public readonly minSearchDate: NgbDateStruct = { day: 1, month: 1, year: 1900 };
  public readonly maxSearchDate: NgbDateStruct = { day: 1, month: 1, year: 2100 };

  public user: User;

  public taxes: Array<TaxType> = [];
  public allJurisdictions: Array<LocationWithTaxTypes> = [];
  public jurisdictions: Array<Location> = [];

  public selectedTax: TaxType = null;
  public selectedJurisdiction: Location = null;
  public selectedDate: string | Date;

  @ViewChild('form', { static: true }) searchForm: NgForm;

  @ViewChild('tax', { static: true }) taxInput: HTMLInputElement;
  @ViewChild('jurisdiction', { static: true }) jurisdictionInput: HTMLInputElement;

  @ViewChild(NgbInputDatepicker, { static: true }) datePicker: NgbInputDatepicker;

  searchTax: OperatorFunction<string, readonly TaxType[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) => (term.length < 1 ? [] : this.taxes.filter((t) => t.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))),
    );

  searchJurisdiction: OperatorFunction<string, readonly Location[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 1 ? [] : this.jurisdictions.filter((t) => t.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10),
      ),
    );

  nameSelector: (item: TaxType | Location) => string = (item) => item.name;

  constructor(
    private dateAdapter: NgbDateAdapter<Date>,
    private collapseService: CollapseService,
    private userSessionService: UserSessionService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private searchService: SearchService,
    private userEncryption: UserEncryptionService,
    @Inject(ApiCommunicationService) private api: ApiCommunicationService,
  ) {
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((x) => x instanceof NavigationStart),
        tap(() => {
          if (this.router.getCurrentNavigation()?.extras?.state?.refreshSearch) {
            this.setLocations();
          }
        }),
      )
      .subscribe();
  }

  ngOnInit() {
    this.searchService.clear.pipe(untilDestroyed(this)).subscribe(() => {
      this.taxInput.value = null;
      this.selectedTax = null;

      this.jurisdictionInput.value = null;
      this.selectedJurisdiction = null;

      this.selectedDate = null;
    });

    // get authenticated user
    this.userSessionService.userData.pipe(untilDestroyed(this)).subscribe((u) => (this.user = u));

    this.taxes = [];

    // tax types
    this.api
      .tax()
      .getTaxTypes()
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.taxes = response;
      });

    // set locations
    this.setLocations();
  }

  ngOnDestroy(): void {}

  public onSubmitSearchForm() {
    const validDate = this.isValidDate();
    const param = new TaxSearchParam(
      this.selectedTax ? this.selectedTax.id : undefined,
      this.selectedTax ? this.selectedTax.name : undefined,
      this.selectedJurisdiction ? this.selectedJurisdiction.id : undefined,
      this.selectedJurisdiction ? this.selectedJurisdiction.name : undefined,
      validDate ? this.getSelectedDate() : undefined,
      this.selectedJurisdiction && this.selectedJurisdiction.name.includes('All'),
    );
    this.router.navigate(['tax'], { queryParams: param });
  }

  public onClickHamburger() {
    this.collapseService.changeStatus();
  }

  public onLogout() {
    // let token1: string=''; 
    // let token2: string ='';
    // let token3: string='';

    // token1 = this.userSessionService.getToken();
    // if (token1)
    // {
    //   token2 = token1.replace('EnCrYpTeD','').replace('Bearer','').trim();
    //   token3 = this.userEncryption.decrypt(token2);
    //   sessionStorage.setItem('access_token', token3);
    // }
    let id_token = sessionStorage.getItem('id_token');
    if (id_token) {
      id_token = this.userEncryption.decrypt(id_token);
      sessionStorage.setItem('id_token', id_token);
    }

    let session = sessionStorage;
    
    this.userSessionService.logoutAction();
  }

  private setLocations(): void {
    this.api
      .location()
      .getCountriesTreeForSearch()
      .pipe(untilDestroyed(this))
      .subscribe((locations) => {
        this.allJurisdictions = this.flattenCountries(locations);
        this.updateJurisdictionList();
      });
  }

  private updateJurisdictionList(): void {
    this.jurisdictions = this.selectedTax
      ? this.allJurisdictions.filter((j) => j.taxTypes?.includes(this.selectedTax.id))
      : this.allJurisdictions;
  }

  private flattenCountries(countries: LocationWithTaxTypes[]): LocationWithTaxTypes[] {
    const countriesList = [];
    if (countries.length) {
      countries.forEach((country) => {
        if (country.children?.length) {
          const countryAll = Object.assign({}, country);
          const countryName = countryAll.name;
          countryAll.name = countryName + ' – All';
          country.name = countryName + ' – Country level';
          countriesList.push(countryAll);
          countriesList.push(country);

          country.children.forEach((child) => {
            child.name = countryName + ' – ' + child.name;
            countriesList.push(child);
          });
        } else {
          country.name = country.name + ' – Country level';
          countriesList.push(country);
        }
      });
    }
    return countriesList;
  }

  public isValidDate() {
    const selectedDate = this.getSelectedDate();
    const validDate = !!selectedDate && !isNaN(selectedDate.getDate());
    if (!validDate) {
      return false;
    }

    return selectedDate.getFullYear() >= this.minSearchDate.year && selectedDate.getFullYear() < this.maxSearchDate.year;
  }

  private getSelectedDate() {
    if (!this.selectedDate) {
      return undefined;
    }

    if (this.selectedDate instanceof Date) {
      return this.selectedDate;
    }

    const momentDate = moment(this.selectedDate, 'DD-MM-YYYY');
    const date = momentDate.isValid() ? momentDate.toDate() : new Date(this.selectedDate);

    return date;
  }
}
