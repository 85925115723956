import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TopicService } from '../../../commons/services/topic.service';
import { TaxTopic } from '../../../model/data/tax/TaxTopic';
import { TaxDataService } from '../../services/tax-data/tax-data.service';

@Component({
  selector: 'app-tax-search-result',
  templateUrl: './tax-search-result.component.html',
  styleUrls: ['./tax-search-result.component.scss'],
})
export class TaxSearchResultComponent implements OnInit {
  constructor(public taxDataService: TaxDataService, private taxTopicService: TopicService, private router: Router) {}

  ngOnInit() {
    this.taxTopicService.selectTopic.emit(undefined);
  }

  public onSelectTopic(topic: TaxTopic) {
    this.router.navigate([
      '/tax',
      this.taxDataService.getTax().id,
      'revision',
      this.taxDataService.getTax().revisionNumber,
      'topic',
      topic.id,
    ]);
    this.taxTopicService.selectTopic.emit(topic);
  }
}
