import { EventEmitter, Injectable } from "@angular/core";
import {TaxTopic} from "../../model/data/tax/TaxTopic";

@Injectable()
export class TopicService {

	public selectTopic = new EventEmitter<TaxTopic>();

	constructor() {
	}

}
