<div class="modal-header">
  <h4 class="modal-title">Save changes?</h4>
</div>

<div class="modal-body">
  <div>
    You are about to make changes to the following tax:
    <strong>{{ context.taxTypeStructure.name }}.</strong>
  </div>

  <br />

  <div><strong>Note: </strong>The changes will be saved and automatically approved for all taxes of this type.</div>
</div>

<div class="modal-footer">
  <div></div>

  <div class="button-group">
    <button class="ui basic button silver" (click)="deny()">
      {{ 'user.user-edit-modal.cancel' | translate }}
    </button>
    <button class="ui basic button rose" (click)="approve()">
      {{ 'user.user-edit-modal.ok' | translate | uppercase }}
    </button>
  </div>
</div>
