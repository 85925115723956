<div class="modal-header">
	<h4 class="modal-title">Reject changes?</h4>
</div>

<div class="modal-body">
	Please state your reason/s for disapproval.

	<br />
	<br />

	<div>
		<textarea [(ngModel)]="reason" placeholder="Reason..."></textarea>
	</div>
</div>

<div class="modal-footer">
	<div></div>

	<div class="button-group">
		<!--Deny-->
		<button class="ui basic button silver" (click)="deny()">
			{{ "user.user-edit-modal.cancel" | translate }}
		</button>

		<!--Approve-->
		<button
			class="ui basic button rose"
			[disabled]="reason === undefined || reason === ''"
			(click)="approve(reason)"
		>
			Send
		</button>
	</div>
</div>
