/**
 * Enumeration of [[ArticleType]] Types.
 * Should be the same as the contents of the [ArticleType] table in the database.
 * If those do not line up than this enum should be the representative.
 *
 *
 * Article Types helps to determine the data structure stored in [[TaxRule]]'s article column.
 */

export enum ArticleType {
  /**
   * Represents a key value pair structure
   */
  KEY_VALUE = 1,
  /**
   * Represents an HTML content - not used anymore since rules support richArticle
   */
  _DEPRECATED_HTML = 2,
  /**
   * Represents a json object. This value should be chosen if data structure cannot be identified
   */
  JSON = 3,
}
