import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserHelper } from '@rar/commons/helpers';
import { TaxRevisionStatus } from '@rar/model/data/enums/TaxRevisionStatus';
import { DashboardParam } from '@rar/model/request/dashboard/DashboardParam';

import { TaxChanges } from '../../../model/data/dashboard/TaxChanges';
import { TaxRecentViews } from '../../../model/data/dashboard/TaxRecentViews';
import { Role } from '../../../model/data/enums/Role';
import { Location } from '../../../model/data/location/Location';
import { User } from '../../../model/data/user/User';
import { DashboardRequest } from '../../../model/request/dashboard/DashboardRequest';
import { TaxSearchParam } from '../../../model/request/tax/TaxSearchParam';
import { TaxChangeListResponse } from '../../../model/response/TaxChangeListResponse';
import { TaxRecentViewListResponse } from '../../../model/response/TaxRecentViewListResponse';
import { ApiCommunicationService } from '../../../model/services/api-communication/api-communication.service';
import { UserSessionService } from '../../../user/services/user-session/user-session.service';
import { DashboardLoaderService } from '../../services/dashboard-loader.service';

@Component({
  selector: 'app-dashboard-page',
  templateUrl: './dashboard-page.component.html',
  styleUrls: ['./dashboard-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardPageComponent implements OnInit, OnDestroy {
  // authenticated user
  public user: User;

  public loading = false;

  public upcomingChanges: Array<TaxChanges>;
  public latestChanges: Array<TaxChanges>;
  public recentViews: Array<TaxRecentViews>;
  public taxesToGlobalApprove$: Observable<Array<TaxChanges>>;
  public taxesToLocalApprove$: Observable<Array<TaxChanges>>;
  public taxesToEdit$: Observable<Array<TaxChanges>>;

  public upcomingChangeLocations: Array<Location>;

  readonly role = Role;

  constructor(
    @Inject(ApiCommunicationService) private api: ApiCommunicationService,
    private userSessionService: UserSessionService,
    private dashboardService: DashboardLoaderService,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    // get authenticated user
    this.userSessionService.userData.pipe(untilDestroyed(this)).subscribe((u) => {
      this.user = u;
    });

    this.dashboardService.load.pipe(untilDestroyed(this)).subscribe(() => {
      this.loading = true;
    });

    this.dashboardService.mapClickEvent.pipe(untilDestroyed(this)).subscribe((locationId: number) => {
      this.loading = true;

      const current = [];
      this.latestChanges.forEach((latest) => {
        this.add(locationId, latest, current);
      });

      this.upcomingChanges.forEach((upcoming) => {
        this.add(locationId, upcoming, current);
      });

      this.recentViews.forEach((recent) => {
        this.add(locationId, recent.tax, current);
      });

      if (current.length === 1) {
        this.router.navigate(['/tax', current[0].id, 'revision', current[0].revisionNumber]);
      } else {
        const param = new TaxSearchParam(
          undefined,
          undefined,
          locationId,
          this.upcomingChangeLocations.find((location: Location) => location.id === locationId).name,
          undefined,
          undefined,
        );

        this.router.navigate(['tax'], { queryParams: param });
      }
    });

    // fetch tax changes for global approval
    this.taxesToGlobalApprove$ = this.api
      .dashboard()
      .getAwaitingTaxesForApproval(DashboardRequest.SHORT, 1, new DashboardParam(TaxRevisionStatus.PENDING_GLOBAL))
      .pipe(map((response) => response._embedded.taxes));

    // fetch tax changes for local approval
    this.taxesToLocalApprove$ = this.api
      .dashboard()
      .getAwaitingTaxesForApproval(DashboardRequest.SHORT, 1, new DashboardParam(TaxRevisionStatus.PENDING_LOCAL))
      .pipe(map((response) => response._embedded.taxes));

    // fetch tax changes for editor
    // by default get pending taxes
    this.taxesToEdit$ = this.api
      .dashboard()
      .getAwaitingTaxesForEditor(DashboardRequest.SHORT, 1, new DashboardParam(TaxRevisionStatus.PENDING_LOCAL))
      .pipe(map((response) => response._embedded.taxes));

    forkJoin([
      this.api.dashboard().getUpcomingChanges(DashboardRequest.SHORT, 1),
      this.api.dashboard().getLatestChanges(DashboardRequest.SHORT, 1),
      this.api
        .dashboard()
        .getRecentViews(
          UserHelper.isUserOnlyInOneRoleForAllTaxes(this.user, Role.VIEWER) ? DashboardRequest.MED : DashboardRequest.SHORT,
          1,
        ),
    ])
      .pipe(untilDestroyed(this))
      .subscribe((data: Object[]) => {
        this.upcomingChanges = (<TaxChangeListResponse>data[0])._embedded.taxes;
        this.latestChanges = (<TaxChangeListResponse>data[1])._embedded.taxes;
        this.recentViews = (<TaxRecentViewListResponse>data[2])._embedded.taxes;

        this.upcomingChangeLocations = [];

        this.upcomingChanges.forEach((upcoming) => this.upcomingChangeLocations.push(upcoming.location));
        this.latestChanges.forEach((latest) => this.upcomingChangeLocations.push(latest.location));
        this.recentViews.forEach((recent) => this.upcomingChangeLocations.push(recent.tax.location));

        this.cdr.markForCheck();
      });
  }

  ngOnDestroy(): void {}

  getRoleClass() {
    const isOnlyViewer = this.user.userTaxPermissions.length > 0 && this.user.userTaxPermissions.every((utp) => utp.role === Role.VIEWER);

    return {
      viewer: isOnlyViewer,
    };
  }

  private add(locationId: number, tax: TaxChanges, array: Array<TaxChanges>) {
    if (tax.location.id === locationId && !array.some((i) => i.id === tax.id && i.revisionNumber === tax.revisionNumber)) {
      array.push(tax);
    }
  }
}
