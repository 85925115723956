import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { TaxRevision } from '../../model/data/tax/TaxRevision';
import { ApiCommunicationService } from '../../model/services/api-communication/api-communication.service';

@Injectable()
export class TaxRevisionResolverService implements Resolve<TaxRevision> {
  constructor(@Inject(ApiCommunicationService) private api: ApiCommunicationService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TaxRevision> | Promise<TaxRevision> | TaxRevision {
    const taxId = +route.params['id'];
    const revisionId = +route.params['revisionId'];

    return this.api.tax().getTaxRevision(taxId, revisionId);
  }
}
