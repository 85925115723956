<div class="modal-header">
	<h4 class="modal-title">{{ "tax.tax-change-notes.title" | translate }}</h4>
</div>

<div class="modal-body">
	<p>
		{{ "audit.change-notes-modal.time" | translate }}:&nbsp;<strong>{{
			context.updatedAt | date: "MMMM dd, yyyy hh:mm a"
		}}</strong>
	</p>
<!-- req 7 [topics]="context.topics"-->
	<app-tax-change-notes-table
		[changeNotes]="context.changeNotes"
    [topics]="context.topics"
	></app-tax-change-notes-table>
</div>
<div class="modal-footer">
	<div></div>

	<div class="button-group">
		<button class="ui basic button" (click)="deny()">
			{{ "commons.modal.close" | translate }}
		</button>
	</div>
</div>
