<div>
  <!--loader-->
  <div class="ui active inline centered loader" *ngIf="!taxTypes && taxTypes !== null"></div>

  <!--error message-->
  <app-error-display *ngIf="taxTypes === null" (onRefresh)="updateTable()"></app-error-display>

  <!--tables-->
  <table class="ui table custom-table" *ngIf="taxTypes">
    <thead>
      <tr>
        <th>{{ 'commons.table.tax-design.tax-type' | translate | uppercase }}</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <!--Content-->
      <tr *ngFor="let taxType of taxTypes">
        <td [title]="taxType.name">{{ taxType.name }}</td>

        <td>
          <div class="d-flex justify-content-end flex-flow">

            <!--status button-->
            <div>
              <button class="ui basic button btn-icon" [disabled]="taxType.propagationStatus?.status !== 'failed'"
                      (click)="showTaxTypeStatus(taxType)" [attr.data-test]="'status button'">
                <div class="status-icon mr-2" [ngClass]="{'green-status': taxType.propagationStatus?.status === 'ok',
                     'red-status': taxType.propagationStatus?.status === 'failed',
                     'yellow-status': taxType.propagationStatus?.status === 'pending'}"></div>
                <span>{{ 'commons.table.tax-design.status' | translate }}</span>
              </button>
            </div>

            <!--edit button-->
            <div>
              <button class="ui basic button btn-icon" (click)="editTaxType(taxType.id)" [attr.data-test]="'edit button'">
                <img [src]="'../../../../assets/images/icons/edit.svg'" />
                <span>{{ 'commons.table.tax-design.edit' | translate }}</span>
              </button>
            </div>

            <!--assign locations button-->
            <div>
              <button
                class="ui basic button btn-icon"
                [routerLink]="'/' + routePaths.getLocationsPath(taxType.id)"
                [attr.data-test]="'assign locations icon'">
                <img [src]="'../../../../assets/images/icons/location.svg'" />
                <span>{{ 'commons.table.tax-design.assign' | translate }}</span>
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <!--empty message-->
  <app-empty-placeholder class="footer" *ngIf="taxTypes && !taxTypes.length"></app-empty-placeholder>
</div>
