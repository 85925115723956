import { Component, Input } from "@angular/core";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from "../../../model/data/user/User";

interface ITaxConfirmModalContext {
	approvers: Array<User>;
}

@Component({
	selector: "app-tax-approvers-list",
	templateUrl: "./tax-approvers-list.component.html",
	styleUrls: ["./tax-approvers-list.component.scss"]
})
export class TaxApproversListComponent {

	constructor(private activeModal: NgbActiveModal) { }

	@Input()
	context: ITaxConfirmModalContext;

	approve(): void {
		this.activeModal.close(null);
	}
}
