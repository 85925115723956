<form [formGroup]="taxTypeLocationsForm" (ngSubmit)="onSubmit(confirmation)" class="flex-flow-v flex-flow--md">
  <div class="d-md-flex justify-content-md-between align-items-md-start">
    <h1>{{ 'tax-design.locations.title' | translate: { taxType: taxTypeLocations.name } }}</h1>

    <div class="flex-flow">
      <button type="button" class="btn btn-sm btn-outline-secondary" (click)="onCancel()" data-test="tax type locations cancel">
        {{ 'tax-design.actions.cancel' | translate }}
      </button>
      <button type="submit" class="btn btn-sm btn-primary" data-test="tax type locations save">
        {{ 'tax-design.actions.save' | translate }}
      </button>
    </div>
  </div>

  <app-tax-type-locations
    *ngIf="isCommonDataLoaded$ | async"
    (addLocationClicked)="onAddLocationClicked()"
    (deleteLocationClicked)="onDeleteLocationClicked($event)"
    [locationsFormGroup]="taxTypeLocationsForm"></app-tax-type-locations>
</form>

<ng-template #confirmation>
  <p>
    {{ 'tax-design.locations.confirmation-save' | translate }}:
    <strong class="medium">{{ taxTypeLocations.name }}</strong>
  </p>
</ng-template>

<ng-template #confirmationDelete>
  <p [innerHTML]="'tax-design.locations.delete-locations-content' | translate: { taxType: htmlEscape(taxTypeLocations.name) }"></p>
  <p>
    <strong>{{ locationsToDelete }}</strong>
  </p>
</ng-template>
