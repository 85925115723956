import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { GlobalErrorHandler } from '@rar/commons/services/global-error-handler';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MonitoringService {
  constructor(private router: Router) {}

  public init() {
    const angularPlugin = new AngularPlugin();
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.applicationInsights,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: {
            router: this.router,
            errorServices: [new GlobalErrorHandler()],
          },
        },
      },
    });
    appInsights.loadAppInsights();
  }
}
