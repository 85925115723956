<div id="tax-settings-layout">
  <div id="header">
    <h2>{{ 'tax.tax-settings.title' | translate }}</h2>
  </div>

  <div id="settings" *ngIf="revision">
    <!--users-->
    <div id="users">
      <app-tax-settings-users [role]="2" [taxId]="revision.id" [taxType]="revision.type"></app-tax-settings-users>
      <app-tax-settings-users [role]="3" [taxId]="revision.id" [taxType]="revision.type"></app-tax-settings-users>
    </div>

    <!--schedule-->
    <div id="tax-schedule">
      <app-tax-schedule [taxRevision]="revision"></app-tax-schedule>
    </div>

    <!--events-->
    <div id="tax-events">
      <app-tax-events [taxRevision]="revision"></app-tax-events>
    </div>
  </div>
</div>
