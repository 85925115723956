export class TaxSearchParam {
  constructor(taxType: number, taxName: string, jurisdiction: number, jurisdictionName: string, date: Date, all: boolean) {
    if (taxType) {
      this.t = taxType;
    }

    if (taxName) {
      this.n = taxName;
    }

    if (jurisdiction) {
      this.j = jurisdiction;
    }

    if (jurisdictionName) {
      this.l = jurisdictionName;
    }

    if (date) {
      this.d = new Date(date).toISOString().substr(0, 10);
    }
    if (all) {
      this.a = all;
    }
  }

  t?: number;
  n?: string;
  j?: number;
  l?: string;
  d?: Date | string;
  a?: boolean;

  public static isEmpty(taxSearchParam: TaxSearchParam) {
    return !!taxSearchParam.n || !!taxSearchParam.j || !!taxSearchParam.d;
  }
}
