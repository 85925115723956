import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';

import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { ShortCodeConstants } from '@rar/commons/constants';
import { ModalService } from '@rar/commons/services/modal.service';
import { TaxTypeReorderCategoryModalComponent } from '@rar/tax-design/modals/tax-type-reorder-category-modal/tax-type-reorder-category-modal.component';

import { TaxTypeTopicService } from '../../../commons/services/tax-type-topic.service';
import { TaxTypeStructure } from '../../../model/data/tax/TaxTypeStructure';
import { TaxTypeTopic } from '../../../model/data/tax/TaxTypeTopic';
import { TaxTypeDataService } from '../../services/tax-type-data.service';
import { TaxTypeModalService } from '../../services/tax-type-modal.service';

@Component({
  selector: 'app-tax-type-sub-menu',
  templateUrl: './tax-type-sub-menu.component.html',
  styleUrls: ['./tax-type-sub-menu.component.scss'],
})
export class TaxTypeSubMenuComponent implements OnInit, OnDestroy {
  public topics: TaxTypeTopic[] = [];

  // to be able to show subtopics
  public selectedTopic: TaxTypeTopic;
  // to be able to bind active class to buttons
  public activeTopic: TaxTypeTopic;
  public activeTopicPanelId = '';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public taxDataService: TaxTypeDataService,
    private taxModalService: TaxTypeModalService,
    private topicService: TaxTypeTopicService,
    private cdr: ChangeDetectorRef,
    public modalService: ModalService,
    private translateService: TranslateService,
  ) {
    // MUST BE HERE
    // subscribe to tax edit flow, save changes
    this.taxModalService.save.pipe(untilDestroyed(this)).subscribe((status: boolean) => {
      this.taxDataService.save(status);
    });

    this.taxDataService.tax.pipe(untilDestroyed(this)).subscribe((tax: TaxTypeStructure) => {
      this.topics = tax.topics;

      if (this.route.children[0]) {
        this.route.children[0].params
          .pipe(
            map((params) => +params['id']),
            distinctUntilChanged(),
            untilDestroyed(this),
          )
          .subscribe((topicId) => {
            this.setTopicState(topicId);
            this.setActiveTopicPanelId();
          });
      }
    });
  }

  ngOnInit() {
    if (!this.route.children.length && this.topics.length) {
      const topicToNavigate =
        this.topics[0].subTopics && this.topics[0].subTopics.length > 0 ? this.topics[0].subTopics[0] : this.topics[0];

      this.navigateToTopic(topicToNavigate);
      this.selectedTopic = this.topics[0];
      this.setActiveTopicPanelId();
    }

    this.topicService.selectTopic.pipe(untilDestroyed(this)).subscribe((selectedTopic: TaxTypeTopic) => {
      if (selectedTopic === null) {
        if (!+this.route.children[0].snapshot.params['id']) {
          this.onSelectTopic(this.topics[0]);
        }
      } else {
        if (selectedTopic) {
          if (selectedTopic.parent) {
            this.onSelectTopic(selectedTopic.parent);
          }
          this.onSelectTopic(selectedTopic);
        } else {
          this.selectedTopic = undefined;
          this.clearActiveTopic();
        }
      }
    });

    this.router.events
      .pipe(
        filter((ev) => ev instanceof NavigationStart),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.clearActiveTopic();
      });
  }

  ngOnDestroy(): void {}

  public onSelectTopic(selectedTopic: TaxTypeTopic) {
    if (this.activeTopic === selectedTopic) {
      return;
    }

    const t = this.topics.find((topic) => topic.name === selectedTopic.name);
    if (t !== undefined && t.subTopics !== undefined && t.subTopics.length !== 0) {
      this.navigateToTopic(t.subTopics[0]);
      this.selectedTopic = t.subTopics[0];
    } else {
      if (t !== undefined) {
        this.navigateToTopic(t);
        this.selectedTopic = undefined;
      } else {
        this.navigateToTopic(selectedTopic);
      }
    }
  }

  private navigateToTopic(selectedTopic: TaxTypeTopic) {
    this.router.navigate(['topic', selectedTopic.id], { relativeTo: this.route });
    this.activeTopic = selectedTopic;
  }

  public clearActiveTopic() {
    this.activeTopic = undefined;
  }

  public beforeChange($event: NgbPanelChangeEvent) {
    if (!$event.nextState) {
      $event.preventDefault();
    }
  }

  private setTopicState(currentTopicState: number) {
    this.topics.forEach((topic: TaxTypeTopic) => {
      if (topic.id === currentTopicState) {
        this.activeTopic = topic;
        this.selectedTopic = topic;
      }

      if (topic.subTopics) {
        topic.subTopics.forEach((subTopic: TaxTypeTopic) => {
          if (subTopic.id === currentTopicState) {
            this.selectedTopic = topic;
            this.activeTopic = subTopic;
          }
        });
      }
    });
  }

  private setActiveTopicPanelId() {
    this.activeTopicPanelId = this.selectedTopic ? `topic-${this.selectedTopic.id}` : '';
  }

  public addNewCategory() {
    // as a new id we take highest (abs) id - 1 (as all negatives are temporary ids)
    const taxTypeStructure = this.taxDataService.getTax();
    const newCategory: TaxTypeTopic = {
      id: Math.min(0, ...taxTypeStructure.topics.map((category) => -Math.abs(category.id))) - 1,
      shortCode: ShortCodeConstants.newPlaceholder, // will be calculated on API
      rates: [],
      rules: [],
      subTopics: [],
      parent: null,
      parentId: null,
      name: '',
      taxType: taxTypeStructure.id,
      type: null, // null for categories and topics,
      order: !this.topics.length ? 1 : this.topics[this.topics.length - 1].order + 1,
    };
    this.topics.push(newCategory);
    this.onSelectTopic(newCategory);
    this.markForCheck();
  }

  private markForCheck(): void {
    this.cdr.markForCheck();
    setTimeout(() => this.cdr.markForCheck());
  }

  public sortTopics(topics: TaxTypeTopic[]): TaxTypeTopic[] {
    return topics ? topics.sort((a, b) => a.order - b.order) : [];
  }

  public openReorderCategoriesModal() {
    const modalRef = this.modalService.open(TaxTypeReorderCategoryModalComponent);
    const topicsForEdit = cloneDeep(this.taxDataService.getTax().topics);
    modalRef.componentInstance.topics = topicsForEdit;
    modalRef.result.then(
      //confirmed
      () => {
        const tax = this.taxDataService.getTax();
        this.topics = this.topics.map((topic) => {
          topic.order = topicsForEdit.find((reorderedTopic) => topic.id === reorderedTopic.id).order;
          return topic;
        });
        this.topics = this.sortTopics(this.topics);
        tax.topics = this.topics;
        this.taxDataService.setTax(tax);
      },
      //cancelled
      () => {},
    );
  }
}
