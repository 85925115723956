import { EventEmitter, Injectable } from "@angular/core";

@Injectable()
export class SearchService {

	private _clear = new EventEmitter<void>();

	constructor() {
	}

	public clearSearchFilters() {
		this._clear.emit();
	}

	get clear(): EventEmitter<void> {
		return this._clear;
	}
}
