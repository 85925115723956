import { Component, Input, OnInit } from '@angular/core';

import { Tax } from '../../../model/data/tax/Tax';
import { TaxFilter, TaxFilterService } from '../../services/tax-filter/tax-filter.service';

type TaxTable = Tax & { typeName: string };

@Component({
  selector: 'app-tax-table',
  templateUrl: './tax-table.component.html',
  styleUrls: ['./tax-table.component.scss'],
})
export class TaxTableComponent implements OnInit {
  /**
   * We are checking if status filter is 2, because then we have to show the rejector instead of the approver.
   */
  @Input()
  public statusFilter: number;

  public taxes: Array<TaxTable>;

  public filter: TaxFilter = {};

  constructor(public taxFilterService: TaxFilterService) {}

  ngOnInit() {
    this.taxFilterService.taxes.subscribe((taxes) => (this.taxes = taxes));
  }

  public onChange() {
    this.taxFilterService.updateFilter(this.filter);
  }

  public onClickTax() {
    this.taxes = undefined;
  }
}
