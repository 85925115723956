import { Injectable, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { untilDestroyed } from 'ngx-take-until-destroy';
import { pairwise, startWith } from 'rxjs/operators';

import { ngSelectConstants } from '@rar/commons/constants';
import { LocationChildType } from '@rar/model/data/enums/LocationChildType';
import { Location } from '@rar/model/data/location/Location';
import { TaxTypeLocations } from '@rar/model/data/tax/TaxTypeLocations';

import { TaxTypeLocationFormRow } from '../../models/tax-type-location-form-row';
import { TaxTypeLocationsFormDataService } from './tax-type-locations-form-data.service';
import { TaxTypeLocationsFormMapperService } from './tax-type-locations-form-mapper.service';

@Injectable()
export class TaxTypeLocationsFormFieldsService implements OnDestroy {
  constructor(
    private locationsFormMapperService: TaxTypeLocationsFormMapperService,
    private locationsFormDataService: TaxTypeLocationsFormDataService,
    private formBuilder: UntypedFormBuilder,
  ) {}

  private taxTypeLocationsForm: UntypedFormGroup;

  private locationsTable: UntypedFormArray;

  private dataSourcesPerRow: TaxTypeLocationFormRow[] = [];

  ngOnDestroy() {}

  get locationsFormArray(): UntypedFormArray {
    return this.locationsTable;
  }

  get taxTypeLocationsFormGroup(): UntypedFormGroup {
    return this.taxTypeLocationsForm;
  }

  getDataSourcesForRow(index: number): TaxTypeLocationFormRow {
    return this.dataSourcesPerRow && this.dataSourcesPerRow[index] ? this.dataSourcesPerRow[index] : null;
  }

  initForm(): void {
    this.locationsTable = this.formBuilder.array([]);

    this.taxTypeLocationsForm = new UntypedFormGroup({
      locationsTable: this.locationsTable,
    });
  }

  initializeFormValue(taxTypeLocations: TaxTypeLocations): void {
    if (taxTypeLocations && taxTypeLocations.locations && taxTypeLocations.locations.length) {
      this.addGroupedLocationsRows(taxTypeLocations.locations);
    } else {
      this.addLocationRow();
    }
  }

  private addGroupedLocationsRows(locations: Location[]) {
    const groupedLocations: Location[][] = [];
    locations.forEach((location) => {
      // find the country ID for this utp
      const jurisdictionId = location.parent?.id ?? location.id;
      // check if it's already in the group list
      const groupedLocation = groupedLocations.find((g) => g[0].id === jurisdictionId || g[0].parent?.id === jurisdictionId);
      if (!groupedLocation) {
        groupedLocations.push([location]);
      } else {
        if (location.id !== jurisdictionId) {
          // we found a subdivision
          groupedLocation.push(location);
        } else if (!location.parent) {
          // we found country level selection
          groupedLocation.unshift(location);
        }
      }
    });
    groupedLocations.sort((gl1, gl2) => (gl1[0].parent || gl1[0]).name.localeCompare((gl2[0].parent || gl2[0]).name));
    groupedLocations.forEach((locationsGroup) => {
      this.addLocationRow(locationsGroup);
    });
  }

  addLocationRow(locations: Location[] = []): void {
    const location = locations && locations.length ? locations[0] : undefined;
    const jurisdictionField = new UntypedFormControl(
      { value: this.locationsFormMapperService.mapJurisdictionToForm(location), disabled: !!location },
      [Validators.required],
    );
    const subdivisionField = new UntypedFormControl(
      { value: this.locationsFormMapperService.mapSubdivisionsToForm(locations), disabled: !location },
      [Validators.required],
    );

    const rowForm = new UntypedFormGroup({
      jurisdiction: jurisdictionField,
      subdivisions: subdivisionField,
    });

    subdivisionField.valueChanges
      .pipe(startWith(subdivisionField.value), pairwise(), untilDestroyed(this))
      .subscribe(([previousSubdivisions, currentSubdivisions]: [number[], number[]]) => {
        let deletedLocationId = previousSubdivisions?.find(
          (id) => !currentSubdivisions || currentSubdivisions.every((currId) => currId !== id),
        );
        if (!deletedLocationId) {
          // added, so nothing found to be deleted
          return;
        }

        if (deletedLocationId === ngSelectConstants.countryLevelValue) {
          deletedLocationId = rowForm.getRawValue().jurisdiction;
        }

        this.locationsFormDataService
          .getJurisdictionsForForm()
          .toPromise()
          .then((jurisdictions) => {
            const jurisdiction = jurisdictions.find(
              (j) => j.id === deletedLocationId || (j.children && j.children.some((sub) => sub.id === deletedLocationId)),
            );
            const deletedLocationName =
              jurisdiction.id === deletedLocationId
                ? jurisdiction.name
                : jurisdiction.children.find((l) => l.id === deletedLocationId).name;

            this.locationsFormDataService.askLocationsDelete(deletedLocationName).then((canDelete) => {
              if (!canDelete) {
                subdivisionField.patchValue(previousSubdivisions);
              }
            });
          });
      });

    jurisdictionField.valueChanges
      .pipe(startWith(jurisdictionField.value), pairwise(), untilDestroyed(this))
      .subscribe(([previousJurisdiction, currentJurisdiction]: [number, number]) => {
        if (previousJurisdiction === currentJurisdiction) {
          return;
        }

        subdivisionField.enable({ emitEvent: false });
        jurisdictionField.disable({ emitEvent: false });

        if (!subdivisionField.value || subdivisionField.value.length === 0) {
          subdivisionField.patchValue([ngSelectConstants.countryLevelValue]);
        }
      });

    const jurisdictions$ = this.locationsFormDataService.getJurisdictionsForForm();
    const subdivisions$ = this.locationsFormDataService.getSubdivisionsForForm(
      jurisdictions$,
      jurisdictionField.valueChanges.pipe(startWith(jurisdictionField.value)),
    );

    this.dataSourcesPerRow.push({
      jurisdictions$,
      subdivisions$,
    });

    this.locationsTable.push(rowForm);
  }

  deleteLocationRow(index: number): void {
    this.locationsFormArray.removeAt(index);
    this.dataSourcesPerRow.splice(index, 1);
  }
}
