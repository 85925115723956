import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ValidationConstants } from '@rar/commons/constants';
import { Role } from '@rar/model/data/enums/Role';

import { TaxTypeDataService } from '../../services/tax-type-data.service';

@Component({
  selector: 'app-tax-type-rates',
  templateUrl: './tax-type-rates.component.html',
  styleUrls: ['./tax-type-rates.component.scss'],
})
export class TaxTypeRatesComponent implements OnInit {
  public readonly role = Role;
  public readonly validationConstants = ValidationConstants;

  public topicId: number;

  constructor(private route: ActivatedRoute, public taxTypeDataService: TaxTypeDataService) {}

  ngOnInit() {
    this.topicId = +this.route.snapshot.params['id'];
  }
}
