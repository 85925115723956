import { Component, OnInit } from '@angular/core';
import { TaxChangeNotes } from '@rar/model/data/tax/TaxChangeNotes';
import { TaxTopic } from '@rar/model/data/tax/TaxTopic';
import { TaxDataService } from '@rar/tax/services/tax-data/tax-data.service';


import { TaxRevision } from '../../../model/data/tax/TaxRevision';

import { AuditTrail } from '../../../model/data/audit/AuditTrail';
import { AuditFilterService } from '../../services/audit-filter.service';
import { AuditModalService } from '../../services/audit-modal.service';

class Year {
  constructor(year: number) {
    this.index = year;
    this.entries = [];
  }

  index: number;
  entries: Entry[];
}

class Entry {
  constructor(month: number, day: number) {
    this.month = month;
    this.day = day;
  }

  month: number;
  day: number;
}

@Component({
  selector: 'app-audit-display',
  templateUrl: './audit-display.component.html',
  styleUrls: ['./audit-display.component.scss'],
  providers: [AuditModalService],
})
//,OnDestroy
export class AuditDisplayComponent implements OnInit {
  public years: Array<Year>;

  public auditEntries: Array<AuditTrail>;
  public auditEntriesToDisplay: Array<AuditTrail>;
  public changeNotes: Array<TaxChangeNotes>;
  public topics: Array<TaxTopic>;

  constructor(public taxDataService: TaxDataService, public auditFilterService: AuditFilterService, private auditModalService: AuditModalService) {
    this.auditFilterService.auditTrails.subscribe((response) => {
      if (response) {
        this.auditEntries = response;
        this.auditEntries.map((value) => {
          value.createdAt = new Date(value.createdAt);
        });
        this.init();
      }
    });
  }

  ngOnInit() {
    this.init();


    // filter by user or tax
    this.auditFilterService.filter.subscribe(({ userId, taxId }) => {
      // later filter will be on back end
      if (userId !== undefined) {
        this.auditEntriesToDisplay = this.auditEntries.filter((audit) => userId.includes(audit.user.id));
      } else {
        this.auditEntriesToDisplay = this.auditEntries;
      }

      if (taxId !== undefined) {
        this.auditEntriesToDisplay = this.auditEntriesToDisplay.filter((audit) => taxId.includes(audit.relatedEntityId));
      }

      this.group();
    });

    // clear filter
    this.auditFilterService.clear.subscribe(() => this.init());

  }

  public onClickViewChanges(audit: AuditTrail) {
    //req 7
    this.taxDataService.tax.subscribe((tax: TaxRevision) => {
      this.changeNotes = this.taxDataService.changeNotes;
      this.topics = tax.taxTopics;
    });
    this.auditModalService.openShowChangeNotesModal(audit,this.topics);
   //this.auditModalService.openShowChangeNotesModal(audit);
     }

  private init() {
    this.auditEntriesToDisplay = this.auditEntries;
    this.group();
  }

  private group() {
    this.years = [];

    if (this.auditEntriesToDisplay) {
      this.auditEntriesToDisplay.forEach((audit) => {
        const currentYear = new Date(audit.createdAt).getFullYear();

        if (!this.years.some((i) => i.index === currentYear)) {
          const Y = new Year(currentYear);

          this.auditEntriesToDisplay
            .filter((i) => i.createdAt.getFullYear() === currentYear)
            .forEach((j) => {
              if (!Y.entries.some((k) => k.month === j.createdAt.getMonth() && k.day === j.createdAt.getDate())) {
                Y.entries.push(new Entry(j.createdAt.getMonth(), j.createdAt.getDate()));
              }
            });
          this.years.push(Y);
        }
      });
    }
  }
}
