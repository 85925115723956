import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { TaxTypeStructure } from '@rar/model/data/tax/TaxTypeStructure';

interface ITaxTypeConfirmModalContext {
  taxTypeStructure: TaxTypeStructure;
}

@Component({
  selector: 'app-tax-type-confirm-modal',
  templateUrl: './tax-type-confirm-modal.component.html',
  styleUrls: ['./tax-type-confirm-modal.component.scss'],
})
export class TaxTypeConfirmModalComponent {
  constructor(private activeModal: NgbActiveModal) {}

  @Input()
  context: ITaxTypeConfirmModalContext;

  approve(): void {
    this.activeModal.close(null);
  }

  deny(): void {
    this.activeModal.dismiss(null);
  }
}
