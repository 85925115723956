import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TopicCommentApiConnector } from '@rar/model/connectors/TopicCommentApiConnector';

import { environment } from '../../../../environments/environment';
import { AbstractApiConnector } from '../../connectors/AbstractApiConnector';
import { AuditApiConnector } from '../../connectors/AuditApiConnector';
import { AuthApiConnector } from '../../connectors/AuthApiConnector';
import { ChangeNotesApiConnector } from '../../connectors/ChangeNotesApiConnector';
import { DashboardApiConnector } from '../../connectors/DashboardApiConnector';
import { EventApiConnector } from '../../connectors/EventApiConnector';
import { LocationApiConnector } from '../../connectors/LocationtApiConnector';
import { PermissionsApiConnector } from '../../connectors/PermissionsApiConnector';
import { RevisionsApiConnector } from '../../connectors/RevisionsApiConnector';
import { TaxApiConnector } from '../../connectors/TaxApiConnector';
import { UserApiConnector } from '../../connectors/UserApiConnector';
import { UserManagementApiConnector } from '../../connectors/UserManagementApiConnector';
import { ApiConnectorWithIdAlreadyPresentError } from '../../exception/ApiConnectorWithIdAlreadyPresentError';
import { BaseHttpService } from '../../utility/base-http.service';

/**
 * API Connectors have a unique enum identifier assigned to them.
 */
export enum Connector {
  AUDIT = 'Audit',
  AUTH = 'Auth',
  CHANGENOTES = 'ChangeNotes',
  EVENT = 'Event',
  LOCATION = 'Location',
  PERMISSIONS = 'Permissions',
  REVISIONS = 'Revisions',
  TAX = 'Tax',
  USER = 'User',
  USERMANAGEMENT = 'UserManagement',
  DASHBOARD = 'Dashboard',
  TOPICCOMMENT = 'TopicComment'
}

/**
 * This service is responsible for all the api communication towards the backend, acting as a hub for all the different connectors.
 */
@Injectable()
export class ApiCommunicationService {
  // api base url
  private apiBaseUrl: string = environment.apiUrl;

  // connector list
  private connectors: Map<Connector, AbstractApiConnector>;

  constructor(@Inject(HttpClient) private http: HttpClient, @Inject(BaseHttpService) private apiClient: BaseHttpService) {
    // create the map of connectors
    this.connectors = new Map<Connector, AbstractApiConnector>();

    // register all the connectors
    this.registerConnector(Connector.AUDIT, new AuditApiConnector(http, apiClient, this.apiBaseUrl));
    this.registerConnector(Connector.AUTH, new AuthApiConnector(http, apiClient, this.apiBaseUrl));
    this.registerConnector(Connector.CHANGENOTES, new ChangeNotesApiConnector(http, apiClient, this.apiBaseUrl));
    this.registerConnector(Connector.EVENT, new EventApiConnector(http, apiClient, this.apiBaseUrl));
    this.registerConnector(Connector.LOCATION, new LocationApiConnector(http, apiClient, this.apiBaseUrl));
    this.registerConnector(Connector.PERMISSIONS, new PermissionsApiConnector(http, apiClient, this.apiBaseUrl));
    this.registerConnector(Connector.REVISIONS, new RevisionsApiConnector(http, apiClient, this.apiBaseUrl));
    this.registerConnector(Connector.TAX, new TaxApiConnector(http, apiClient, this.apiBaseUrl));
    this.registerConnector(Connector.USER, new UserApiConnector(http, apiClient, this.apiBaseUrl));
    this.registerConnector(Connector.USERMANAGEMENT, new UserManagementApiConnector(http, apiClient, this.apiBaseUrl));
    this.registerConnector(Connector.DASHBOARD, new DashboardApiConnector(http, apiClient, this.apiBaseUrl));
    this.registerConnector(Connector.TOPICCOMMENT, new TopicCommentApiConnector(http, apiClient, this.apiBaseUrl));
  }

  /**
   * This function registers a connector to the connector pool.
   * @param {Connector} id The unique identifier for a connector.
   * @param {AbstractApiConnector} connector The connector to register.
   */
  private registerConnector(id: Connector, connector: AbstractApiConnector) {
    // check if id is not already registered
    if (this.connectors.has(id)) {
      throw new ApiConnectorWithIdAlreadyPresentError("A connector with ID '" + id + "' has already been registered.");
    }

    // register connector with the given ID.
    try {
      this.connectors.set(id, connector);
    } catch (e) {
      console.error('Could not register connector: ' + e);
    }
  }

  protected getConnector(connector: Connector): AbstractApiConnector {
    // check if connector is registered
    if (!this.connectors.has(connector)) {
      throw new Error('No connector is registered for: ' + connector);
    }

    return this.connectors.get(connector);
  }

  /*** API connector getters ***/

  public audit(): AuditApiConnector {
    return <AuditApiConnector>this.getConnector(Connector.AUDIT);
  }

  public auth(): AuthApiConnector {
    return <AuthApiConnector>this.getConnector(Connector.AUTH);
  }

  public changeNotes(): ChangeNotesApiConnector {
    return <ChangeNotesApiConnector>this.getConnector(Connector.CHANGENOTES);
  }

  public event(): EventApiConnector {
    return <EventApiConnector>this.getConnector(Connector.EVENT);
  }

  public location(): LocationApiConnector {
    return <LocationApiConnector>this.getConnector(Connector.LOCATION);
  }

  public permissions(): PermissionsApiConnector {
    return <PermissionsApiConnector>this.getConnector(Connector.PERMISSIONS);
  }

  public revisions(): RevisionsApiConnector {
    return <RevisionsApiConnector>this.getConnector(Connector.REVISIONS);
  }

  public tax(): TaxApiConnector {
    return <TaxApiConnector>this.getConnector(Connector.TAX);
  }

  public user(): UserApiConnector {
    return <UserApiConnector>this.getConnector(Connector.USER);
  }

  public userManagement(): UserManagementApiConnector {
    return <UserManagementApiConnector>this.getConnector(Connector.USERMANAGEMENT);
  }

  public dashboard(): DashboardApiConnector {
    return <DashboardApiConnector>this.getConnector(Connector.DASHBOARD);
  }

  public topicComment(): TopicCommentApiConnector {
    return <TopicCommentApiConnector>this.getConnector(Connector.TOPICCOMMENT);
  }
}
