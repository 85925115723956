import { EventEmitter, Inject, Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscriber } from 'rxjs';

import { ModalService } from '@rar/commons/services/modal.service';
import { Role } from '@rar/model/data/enums/Role';
import { TaxEvent } from '@rar/model/data/event/TaxEvent';
import { TaxLock } from '@rar/model/data/tax/TaxLock';
import { TaxRevision } from '@rar/model/data/tax/TaxRevision';
import { User } from '@rar/model/data/user/User';
import { FileUploadResponse } from '@rar/model/response/FileUploadResponse';
import { ApiCommunicationService } from '@rar/model/services/api-communication/api-communication.service';

import { TaxApproveModalComponent } from '../../modals/tax-approve-modal/tax-approve-modal.component';
import { TaxApproversListComponent } from '../../modals/tax-approvers-list/tax-approvers-list.component';
import { TaxAssessmentModalComponent } from '../../modals/tax-assessment-modal/tax-assessment-modal.component';
import { TaxCloseModalComponent } from '../../modals/tax-close-modal/tax-close-modal.component';
import { TaxConfirmModalComponent } from '../../modals/tax-confirm-modal/tax-confirm-modal.component';
import { TaxDiscardModalComponent } from '../../modals/tax-discard-modal/tax-discard-modal.component';
import { TaxEditModalComponent, TaxEditModalData } from '../../modals/tax-edit-modal/tax-edit-modal.component';
import { TaxEventModalComponent } from '../../modals/tax-event-modal/tax-event-modal.component';
import { TaxFileUploadModalComponent } from '../../modals/tax-file-upload-modal/tax-file-upload-modal.component';
import { TaxLockModalComponent } from '../../modals/tax-lock-modal/tax-lock-modal.component';
import { TaxMultiEditModalComponent } from '../../modals/tax-multi-edit-modal/tax-multi-edit-modal.component';
import { TaxNotificationUpdatedModalComponent } from '../../modals/tax-notification-updated-modal/tax-notification-updated-modal.component';
import { TaxRejectModalComponent } from '../../modals/tax-reject-modal/tax-reject-modal.component';
import { TopicDifference } from '@rar/model/data/tax/TaxStructureDifferences';
import { TaxRestoreModalComponent } from '@rar/tax/modals/tax-restore-modal/tax-restore-modal.component';
import { Router } from '@angular/router';
import { TopicComment } from '@rar/model/data/tax/TopicComment';
import { TaxTopicCommentModalComponent } from '@rar/tax/modals/tax-topic-comment-modal/tax-topic-comment-modal.component';
import { TaxTopicCommentsHistoryModalComponent } from '@rar/tax/modals/tax-topic-comments-history-modal/tax-topic-comments-history-modal.component';
import { TaxTopicCommentConfirmChangesModalComponent } from '@rar/tax/modals/tax-topic-comment-confirm-changes-modal/tax-topic-comment-confirm-changes-modal.component';
import { ApproveTopicCommentsEvent } from '@rar/tax/components/tax-topic-comment-bar/tax-topic-comment-bar.component';
import { TaxTopicCommentSuccessModalComponent } from '@rar/tax/modals/tax-topic-comment-success-modal/tax-topic-comment-success-modal.component';

@Injectable()
export class TaxModalService {
  public edit = new EventEmitter<TaxEditModalData>();
  public save = new EventEmitter<boolean>();

  public close = new EventEmitter<void>();

  public assessment = new EventEmitter<void>();
  public approve = new EventEmitter<string>();
  public reject = new EventEmitter<string>();
  public import = new EventEmitter<void>();

  public addTopicComment = new EventEmitter<object>();
  public approveTopicComments = new EventEmitter<object>();
  public confirmApproveTopicComments = new EventEmitter<object>();

  public eventsChanged = new EventEmitter<boolean>();

  constructor(
    private modalService: ModalService,
    @Inject(ApiCommunicationService) private api: ApiCommunicationService,
    private translateService: TranslateService,
    private router: Router,
  ) {}

  public openTaxEditModal(tax: TaxRevision, effectiveFromValidator: Date) {
    const modalRef = this.modalService.open(TaxEditModalComponent);
    modalRef.componentInstance.context = { tax, effectiveFromValidator };
    modalRef.result.then(
      (response: TaxEditModalData) => {
        this.api
          .tax()
          .lockTaxRevision(tax.id, tax.revisionNumber)
          .subscribe(
            (lock: TaxLock) => {
              // console.log("lock");
              // console.log(lock);
              // open modal if revision is under editing, approval or has a pending revision
              if (!lock.ok) {
                this.openTaxLockModal(lock, Role.EDITOR);
              } else {
                this.edit.emit(response);
              }
            },
            () => console.log('Error during locking tax to edit.'),
          );
      },
      () => {},
    );
  }

  public openTaxMultiEditModal(tax: TaxRevision, editors: Array<User>) {
    const modalRef = this.modalService.open(TaxMultiEditModalComponent);
    modalRef.componentInstance.context = { tax, editors };
    modalRef.result.then(
      () => {
        this.api
          .tax()
          .lockTaxRevision(tax.id, tax.revisionNumber)
          .subscribe(
            (lock: TaxLock) => {
              // console.log("lock");
              // console.log(lock);
              // open modal if revision is under editing, approval or has a pending revision
              if (!lock.ok) {
                this.openTaxLockModal(lock, Role.EDITOR);
              } else {
                this.edit.emit({ checkbox: 2 });
              }
            },
            () => console.log('Error during locking tax to edit.'),
          );
      },
      () => {},
    );
  }

  public openTaxConfirmModal(tax: TaxRevision, approvers: Array<User> = [], autoApprove: boolean) {
    const modalRef = this.modalService.open(TaxConfirmModalComponent);
    modalRef.componentInstance.context = { tax, approvers, autoApprove };
    modalRef.result.then(
      () => this.save.emit(true),
      () => this.save.emit(false),
    );
  }

  public openTaxApproverListModal(approvers: Array<User>) {
    const modalRef = this.modalService.open(TaxApproversListComponent);
    modalRef.componentInstance.context = { approvers };
  }

  public openTaxLockModal(lock: TaxLock, forRole: Role) {
    const modalRef = this.modalService.open(TaxLockModalComponent);
    modalRef.componentInstance.context = { lock, forRole };
  }

  public openTaxRejectModal() {
    const modalRef = this.modalService.open(TaxRejectModalComponent);
    modalRef.result.then(
      (response) => this.reject.emit(response),
      () => {},
    );
  }

  public openTaxDiscardModal(): Observable<boolean> {
    return new Observable((observer: Subscriber<boolean>) => {
      const modalRef = this.modalService.open(TaxDiscardModalComponent);
      modalRef.result.then(
        () => observer.next(true),
        () => observer.next(false),
      );
    });
  }

  public openTaxStartAssessmentModal(tax: TaxRevision) {
    const modalRef = this.modalService.open(TaxAssessmentModalComponent);
    modalRef.componentInstance.context = { tax };
    modalRef.result.then(
      () => {
        this.api
          .tax()
          .lockTaxRevision(tax.id, tax.revisionNumber)
          .subscribe((lock: TaxLock) => {
            // open modal if revision is under editing, approval or has a pending revision
            if (!lock.ok) {
              this.openTaxLockModal(lock, Role.APPROVER);
            } else {
              this.assessment.emit();
            }
          });
      },
      () => {},
    );
  }

  public openTaxApproveModal(tax: TaxRevision, editors: Array<User>) {
    const modalRef = this.modalService.open(TaxApproveModalComponent);
    modalRef.componentInstance.context = { tax, editors };
    modalRef.result.then(
      (response: string) => this.approve.emit(response),
      () => {},
    );
  }

  public openTaxCloseModal() {
    const modalRef = this.modalService.open(TaxCloseModalComponent);
    modalRef.result.then(
      () => this.close.emit(),
      () => {},
    );
  }

  public openTaxNotificationUpdatedModalComponent() {
    this.modalService.open(TaxNotificationUpdatedModalComponent);
  }

  public openTaxEventModal(taxId: number, event?: TaxEvent) {
    const modalRef = this.modalService.open(TaxEventModalComponent);
    modalRef.componentInstance.context = { taxId, event };
    modalRef.result.then(
      () => this.eventsChanged.emit(true),
      () => {},
    );
  }

  public openFileUploadModal(fileUploadResponse: FileUploadResponse) {
    const modalRef = this.modalService.open(TaxFileUploadModalComponent);
    modalRef.componentInstance.fileResponse = fileUploadResponse;
    modalRef.result.then(
      () => this.import.emit(),
      () => {},
    );
  }

  public openExportFailedModal() {
    this.modalService.showInformation({
      title: this.translateService.instant('tax.tax-export.title-failed'),
      content: this.translateService.instant('tax.tax-export.content-failed').split('\n'),
    });
  }

  public openTaxRestoreModal(categories: TopicDifference[], taxId: number) {
    const modalRef = this.modalService.open(TaxRestoreModalComponent);
    modalRef.componentInstance.categories = categories;
    modalRef.result.then(
      (taxesToRestore) => this.api.tax().propagateTaxTypeStructure(taxId, taxesToRestore).subscribe(() => this.router.navigate([''])),
      () => {},
    );
  }

  public openAddTopicCommentModal(shortCode: string) {
    const modalRef = this.modalService.open(TaxTopicCommentModalComponent);
    modalRef.componentInstance.context = { shortCode };
    modalRef.result.then(
      (response) => this.addTopicComment.emit(response),
      () => {},
    );
  }

  public openTopicCommentsHistoryModal(shortCode: string, comments: Array<TopicComment>, approvedComments: Array<number>) {
    const modalRef = this.modalService.open(
      TaxTopicCommentsHistoryModalComponent,
      {
        modalDialogClass: "",
        backdrop: 'static',
        keyboard: false
      });
    modalRef.componentInstance.context = { shortCode, comments, approvedComments };
    modalRef.result.then(
      (response: ApproveTopicCommentsEvent) => {
        if (response.commentIds && response.commentIds.length) {
          this.approveTopicComments.emit(response);
        }
      },
      () => {},
    );
  }

  public openConfirmTopicCommentsChangesModal(shortCode: string, approvedComments: Array<number>) {
    const modalRef = this.modalService.open(TaxTopicCommentConfirmChangesModalComponent);
    modalRef.componentInstance.context = { shortCode, approvedComments };
    modalRef.result.then(
      (response: ApproveTopicCommentsEvent) => {
        this.confirmApproveTopicComments.emit(response);
      },
      () => {},
    );
  }

  public openCommentSuccessModal() {
    const modalRef = this.modalService.open(TaxTopicCommentSuccessModalComponent);
    modalRef.result.then(
      () => {},
      () => {},
    );
  }
}
