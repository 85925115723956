import { EventEmitter, Inject, Injectable, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { saveAs } from 'file-saver';
import { cloneDeep, uniq } from 'lodash-es';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, map, shareReplay, tap } from 'rxjs/operators';

import { ValidationConstants } from '@rar/commons/constants';
import { UserHelper } from '@rar/commons/helpers';
import { ModalService } from '@rar/commons/services/modal.service';
import { TaxStructureDifferences } from '@rar/model/data/tax/TaxStructureDifferences';
import { TaxType } from '@rar/model/data/tax/TaxType';
import { TaxTypeStructure } from '@rar/model/data/tax/TaxTypeStructure';
import { TopicComment } from '@rar/model/data/tax/TopicComment';
import { ApproveTopicCommentsRequest } from '@rar/model/request/TopicComment/ApproveTopicCommentsRequest';
import { TopicCommentCreateRequest } from '@rar/model/request/TopicComment/TopicCommentCreateRequest';
import { TaxTopicResolverService } from '@rar/tax/resolvers/tax-topic-resolver.service';
import { UserSessionService } from '@rar/user/services/user-session/user-session.service';

import { ArticleType } from '../../../model/data/enums/ArticleType';
import { ChangeNoteOccurrence } from '../../../model/data/enums/ChangeNoteOccurrence';
import { Role } from '../../../model/data/enums/Role';
import { TaxRevisionStatus } from '../../../model/data/enums/TaxRevisionStatus';
import { TaxChangeNotes } from '../../../model/data/tax/TaxChangeNotes';
import { TaxLock } from '../../../model/data/tax/TaxLock';
import { TaxRate } from '../../../model/data/tax/TaxRate';
import { TaxRevision } from '../../../model/data/tax/TaxRevision';
import { TaxRule } from '../../../model/data/tax/TaxRule';
import { TaxSearchResult } from '../../../model/data/tax/TaxSearchResult';
import { TaxTopic } from '../../../model/data/tax/TaxTopic';
import { User } from '../../../model/data/user/User';
import { ChangeNotesParam } from '../../../model/request/notes/ChangeNotesParam';
import { TaxCreateRequest } from '../../../model/request/tax/TaxCreateRequest';
import { TaxEditRequest } from '../../../model/request/tax/TaxEditRequest';
import { TaxEditResponse } from '../../../model/response/TaxEditResponse';
import { TaxEffectiveRangeResponse } from '../../../model/response/TaxEffectiveRangeResponse';
import { ApiCommunicationService } from '../../../model/services/api-communication/api-communication.service';
import { EditOption, TaxEditModalData } from '../../modals/tax-edit-modal/tax-edit-modal.component';
import { TaxModalService } from '../tax-modal/tax-modal.service';

export type TableArticle = Array<Record<string, string>>;

// God object
@Injectable()
export class TaxDataService implements OnInit {
  public authUser: User;
  public isPermitted: boolean;

  public isLoading = false;

  private _tax = new BehaviorSubject<TaxRevision>(undefined);
  private _taxCloneForEditing: TaxRevision;

  private _isEditingMode = false;
  private _isApprovingMode = false;
  private _create = false;

  private _taxLockInfo: TaxLock;

  // json rule related properties
  private _id: number;
  private _columns: Array<string>;
  private _editColumns: Array<string>;
  private _tableArticle = new BehaviorSubject<TableArticle>(undefined);

  // search related
  public searchCriteria: string;
  public searchResult: TaxSearchResult;

  // change notes
  private _changeNotes: Array<TaxChangeNotes>;
  private _parsedChangeNotes: Array<any>;

  // emitters
  public taxRevisionStatus = new EventEmitter<TaxRevisionStatus>();
  public taxRevisionEffectiveFrom = new EventEmitter<Date>();
  public readonly editStarted = new EventEmitter<void>();
  public readonly discard = new EventEmitter<void>();

  private updateTableState = new Subject<void>();
  readonly tableUpdate$ = this.updateTableState.asObservable();

  public taxType: TaxTypeStructure;

  private taxTypesList: TaxType[];

  public readonly taxExportAvailable$ = this._tax.asObservable().pipe(
    shareReplay(1),
    map((tax) => tax && tax.taxTopics && tax.taxTopics.length > 0),
  );

  constructor(
    private taxModalService: TaxModalService,
    private router: Router,
    private userSessionService: UserSessionService,
    private modalService: ModalService,
    private translateService: TranslateService,
    @Inject(ApiCommunicationService) private api: ApiCommunicationService,
  ) {
    // manual "injection" because resolver is instantiated before this service
    TaxTopicResolverService.taxDataService = this;

    // Req 6
    this.api
      .tax()
      .getTaxTypes()
      .pipe(
        tap((types) => {
          this.taxTypesList = types;
        }),
      )
      .subscribe();
  }

  ngOnInit() {}

  public enableEditingMode(response: TaxEditModalData) {
    if (!this.isEditingMode) {
      // dirty copy
      this.taxCloneForEditing = cloneDeep(this.getTax());

      this.taxCloneForEditing.status = TaxRevisionStatus.PENDING_LOCAL;
      this.onTaxRevisionStatusChange(TaxRevisionStatus.PENDING_LOCAL);

      // disable search functionality
      this.searchResult = undefined;

      // in case of edit current revision, status will be TRUE
      // in case of create new revision, status will be FALSE
      if (response.checkbox === EditOption.EDIT) {
        this._create = false;
      } else if (response.checkbox === EditOption.CREATE) {
        this._create = true;
        this.taxCloneForEditing.effectiveFrom = response.effectiveFrom;
        this.onTaxRevisionEffectiveFromChange(this.taxCloneForEditing.effectiveFrom);
      } else if (response.checkbox === EditOption.CONFIRM) {
        // save without changes
        this._create = false;
        this.save(true);
      }

      this.isApprovingMode = false;
      this._isEditingMode = true;
      this.editStarted.next();
    }
  }

  public save(status: boolean) {
    if (status) {
      this.isLoading = true;

      // save json if necessary
      this.saveTmpJson(this._id);
      this.setTax(this.taxCloneForEditing);

      this._isEditingMode = false;

      // create request payload
      const request = this._create
        ? // create new
          new TaxCreateRequest(this.getTax())
        : // edit current
          new TaxEditRequest(true, this.getTax().id, this.getTax());

      // send request
      this._create
        ? // create new
          this.api
            .tax()
            // @ts-ignore
            .createTaxRevision(this.getTax().id, request)
            .subscribe((response) => {
              this._isEditingMode = false;
              this.router.navigate(['']);
            })
        : // edit current
          this.api
            .tax()
            // @ts-ignore
            .editTaxRevision(this.getTax().id, this.getTax().revisionNumber, request)
            .subscribe((response: TaxEditResponse) => {
              this._isEditingMode = false;
              this.router.navigate(['']);
            });
    }
  }

  public close() {
    this.isLoading = true;
    const taxData = this.getTax();
    taxData.status = TaxRevisionStatus.CLOSED;

    const request = new TaxEditRequest(true, taxData.id, taxData);

    this.api
      .tax()
      .editTaxRevision(taxData.id, taxData.revisionNumber, request)
      .subscribe(() => {
        // update tax locally
        // this.setTax(TaxEditResponse.transformResponse(taxData, response));
        // this.isLoading = false;
        this.router.navigate(['/']);
      });
  }

  public approve(notes: string) {
    this.isLoading = true;
    const taxData = this.getTax();
    const user = this.userSessionService.userDataValue;

    const isSuperAdminOrAdmin =
      user.isSuperAdmin ||
      (user.userTaxPermissions && user.userTaxPermissions.some((utp) => utp.role === Role.ADMIN && utp.taxType === taxData.type));

    taxData.isLocalApprove = !isSuperAdminOrAdmin;
    taxData.isApproved = isSuperAdminOrAdmin;
    taxData.approvalNotes = notes;

    // req 6
    let filterTaxType = this.taxTypesList.filter((t) => t.id === taxData.type);
    let approvalLevel: number = 0;
    if (filterTaxType.length > 0) {
      approvalLevel = filterTaxType[0].approvalLevel;
    }
    let taxStatus: number = taxData.status;

    if (approvalLevel == 2 && taxStatus == TaxRevisionStatus.PENDING_LOCAL) {
      taxData.status = TaxRevisionStatus.APPROVED;
      taxData.isApproved = true;
    } else {
      taxData.status = isSuperAdminOrAdmin ? TaxRevisionStatus.APPROVED : TaxRevisionStatus.PENDING_GLOBAL;
    }

    const request = new TaxEditRequest(false, taxData.id, taxData);

    this.api
      .tax()
      .editTaxRevision(taxData.id, taxData.revisionNumber, request)
      .subscribe((response) => {
        // update tax locally
        // this.setTax(TaxEditResponse.transformResponse(taxData, response));
        this.isApprovingMode = false;
        this.isLoading = false;
      });
  }

  public reject(reason: string) {
    this.isLoading = true;
    const taxData = this.getTax();
    taxData.isApproved = false;
    taxData.isLocalApprove = false;
    taxData.rejectionReason = reason;
    taxData.status = TaxRevisionStatus.REJECTED;

    const request = new TaxEditRequest(false, taxData.id, taxData);

    this.api
      .tax()
      .editTaxRevision(taxData.id, taxData.revisionNumber, request)
      .subscribe((response) => {
        // update tax locally
        // this.setTax(TaxEditResponse.transformResponse(taxData, response));
        this.isApprovingMode = false;
        this.isLoading = false;
        // req 2
        this.close();
      });
  }

  private search(topic: TaxTopic, parent: TaxTopic) {
    // if topic has rules
    if (topic.rules) {
      // search between topic rules
      topic.rules.forEach((rule: TaxRule) => {
        if (
          rule.title.toLocaleLowerCase().search(this.searchResult.searchCriteria) !== -1 ||
          rule.article.toLocaleLowerCase().search(this.searchResult.searchCriteria) !== -1
        ) {
          this.searchResult.addResult(topic, parent);
        }
      });
    }

    // if topic has rates
    if (topic.rates) {
      topic.rates.forEach((rate: TaxRate) => {
        if (rate.name.toLocaleLowerCase().search(this.searchResult.searchCriteria) !== -1) {
          this.searchResult.addResult(topic, parent);
        }
      });
    }
  }

  public lock() {
    this.api.tax().lockTaxRevision(this.getTax().id, this.getTax().revisionNumber).subscribe();
  }

  public onClickSearch(navigate = false) {
    // initialize search object
    this.searchResult = new TaxSearchResult(this.searchCriteria?.toLocaleLowerCase());
    if (this.searchCriteria !== '') {
      this.getTax().taxTopics.forEach((topic: TaxTopic) => {
        this.search(topic, undefined);
        if (topic.subTopics) {
          topic.subTopics.forEach((subTopic: TaxTopic) => {
            this.search(subTopic, topic);
          });
        }
      });
    }

    if (navigate) {
      this.router.navigate(['/tax', this.getTax().id, 'revision', this.getTax().revisionNumber, 'search']);
    }
  }

  get tax(): Observable<TaxRevision> {
    return this._tax.asObservable();
  }

  public setTax(value: TaxRevision) {
    this._tax.next(value);

    // fetch change notes
    this.api
      .changeNotes()
      .getRevisionChangeLog(this.getTax().revisionId, new ChangeNotesParam(true))
      .subscribe((changeNotes: Array<TaxChangeNotes>) => {
        this._changeNotes = changeNotes;
      });
  }
  public setId(id: number) {
    this._id = id;
  }

  public getTax(): TaxRevision {
    return this._tax.getValue();
  }

  get parsedChangeNotes(): Array<any> {
    return this._parsedChangeNotes;
  }

  set parsedChangeNotes(value: Array<any>) {
    this._parsedChangeNotes = value;
  }

  get taxCloneForEditing(): TaxRevision {
    return this._taxCloneForEditing;
  }

  set taxCloneForEditing(value: TaxRevision) {
    this._taxCloneForEditing = value;
  }

  get isEditingMode(): boolean {
    return this._isEditingMode;
  }

  get isApprovingMode(): boolean {
    return this._isApprovingMode;
  }

  set isApprovingMode(value: boolean) {
    this._isApprovingMode = value;
  }

  get create(): boolean {
    return this._create;
  }

  get taxLockInfo(): TaxLock {
    return this._taxLockInfo;
  }

  set taxLockInfo(value: TaxLock) {
    this._taxLockInfo = value;
  }

  get columns(): Array<string> {
    return this._columns;
  }

  get editColumns(): Array<string> {
    return this._editColumns;
  }

  public setTableArticle(value: TableArticle) {
    this._tableArticle.next(value);
  }

  public getTableArticle(): TableArticle {
    return this._tableArticle.getValue();
  }

  get changeNotes(): Array<TaxChangeNotes> {
    return this._changeNotes;
  }

  set changeNotes(value: Array<TaxChangeNotes>) {
    this._changeNotes = value;
  }

  get isTaxRevisionLocked() {
    return this.getTax().status === TaxRevisionStatus.CLOSED;
  }

  get isTaxRejected() {
    return this.getTax().status === TaxRevisionStatus.REJECTED;
  }

  public onClickEdit() {
    const status = this.getTax().status;
    if (status === TaxRevisionStatus.APPROVED) {
      this.api
        .tax()
        .getTaxEffectiveRange(this.getTax().id)
        .subscribe(
          (response: TaxEffectiveRangeResponse) => {
            this.taxModalService.openTaxEditModal(this.getTax(), response.effectiveFrom);
          },
          () => {},
        );
    }

    if (
      status === TaxRevisionStatus.REJECTED ||
      status === TaxRevisionStatus.PENDING_LOCAL ||
      status === TaxRevisionStatus.PENDING_GLOBAL
    ) {
      this.api
        .tax()
        .getTaxMultiEditors(this.getTax().revisionId)
        .subscribe((response: Array<User>) => {
          this.taxModalService.openTaxMultiEditModal(this.getTax(), response);
        });
    }
  }

  public onClickExport() {
    const status = this.getTax().status;
    if (
      status === TaxRevisionStatus.APPROVED ||
      status === TaxRevisionStatus.PENDING_LOCAL ||
      status === TaxRevisionStatus.PENDING_GLOBAL
    ) {
      this.api
        .tax()
        .exportTax(this.getTax().id, this.getTax().revisionNumber)
        .pipe(catchError(() => of(null)))
        .subscribe((file: File | null) => {
          if (file) {
            saveAs(file, file.name);
          } else {
            this.taxModalService.openExportFailedModal();
          }
        });
    }
  }

  public onClickStartAssessment() {
    this.taxModalService.openTaxStartAssessmentModal(this.getTax());
  }

  public onClickDoneEditing() {
    if (
      this.isEditingMode &&
      UserHelper.isUserSuperAdminOrAdminForTaxType(this.userSessionService.userDataValue, this.getTax().type) &&
      !this.checkIfAdminChangesValid()
    ) {
      return;
    }

    // Req 1
    const autoApprovalFlow = UserHelper.hasAcceptableRole(
      this.userSessionService.userTaxPermissionsValue,
      false,
      false,
      false,
      [Role.APPROVER, Role.ADMIN, Role.SUPER_ADMIN],
      this.getTax(),
    );

    if (autoApprovalFlow) {
      this.taxModalService.openTaxConfirmModal(this.taxCloneForEditing, [], true);
    } else {
      this.api
        .permissions()
        .getAssignedUsers(this.getTax().id, Role.APPROVER)
        .subscribe((response) => {
          this.taxModalService.openTaxConfirmModal(this.taxCloneForEditing, response, false);
        });
    }
  }

  // set column names instead of indexes
  updateTableArticles(articlesList: Array<Record<string, string>>, columnsList: string[]) {
    if (articlesList && columnsList) {
      const columns = cloneDeep(columnsList);
      const articles = cloneDeep(articlesList);

      for (let i = 0; i < columns.length; i++) {
        for (let article of articles) {
          article[columns[i]] = article[i.toString()] || '';
          delete article[i.toString()];
        }
      }
      return articles;
    }
  }

  // set columns indexes instead of names
  // due to problems with empty column names
  setArticlesIndexes(articles: Array<Record<string, string>>): Array<Record<string, string>> {
    if (articles?.length) {
      const columns = this.editColumns;
      if (columns.length) {
        for (let i = 0; i < columns.length; i++) {
          for (let article of articles) {
            article[i.toString()] = article[columns[i]] || '';
            delete article[columns[i]];
          }
        }
        return articles;
      } else {
        return [];
      }
    }
  }

  public onClickCancelEditing() {
    this._isEditingMode = false;

    this.taxCloneForEditing = undefined;
    this.discard.emit();

    this.onTaxRevisionStatusChange(this.getTax().status);
    this.onTaxRevisionEffectiveFromChange(this.getTax().effectiveFrom);
  }

  public onClickDiscardModal(): Observable<boolean> {
    return this.taxModalService.openTaxDiscardModal();
  }

  public onClickRejectChanges() {
    this.taxModalService.openTaxRejectModal();
  }

  public onClickApprove() {
    this.api
      .tax()
      .getTaxMultiEditors(this.getTax().revisionId)
      .subscribe((response: Array<User>) => {
        this.taxModalService.openTaxApproveModal(this.getTax(), response);
      });
  }

  public onClickClose() {
    this.taxModalService.openTaxCloseModal();
  }

  public onClickAddTopicComment(shortCode: string) {
    this.taxModalService.openAddTopicCommentModal(shortCode);
  }

  public onClickShowTopicComments(shortCode: string, comments: Array<TopicComment>, approvedComments: Array<number>) {
    this.taxModalService.openTopicCommentsHistoryModal(shortCode, comments, approvedComments);
  }

  public onTaxRevisionStatusChange(status: TaxRevisionStatus) {
    // emit changes
    this.taxRevisionStatus.emit(status);
  }

  public onTaxRevisionEffectiveFromChange(effectiveFrom: Date) {
    this.taxRevisionEffectiveFrom.emit(effectiveFrom);
  }

  public getTaxId(): number {
    return this._tax.getValue().id;
  }

  public getTaxTopic(tax: TaxRevision, topicId: number): TaxTopic {
    const t = this.getTopicBasedOnId(topicId, tax.taxTopics);
    return t
      ? t
      : ({
          rules: [],
          rates: [],
          subTopics: [],
        } as TaxTopic);
  }

  public isObjectChanged(shortCode: string, key: ChangeNoteOccurrence): boolean {
    if (this.getChangeNoteObject(shortCode, key).length) {
      return true;
    } else {
      return false;
    }
  }

  public getChangeNoteObject(shortCode, key: ChangeNoteOccurrence): any {
    return this.parsedChangeNotes.filter((changes) => changes.hasOwnProperty(key) && changes[key].shortCode === shortCode);
  }

  public getTopicBasedOnId(id: number, tree: Array<TaxTopic>) {
    for (const t of tree) {
      if (t.id === id) {
        return t;
      }

      if (t.subTopics) {
        const x = this.getTopicBasedOnId(id, t.subTopics);
        if (x) {
          return x;
        }
      }
    }

    return null;
  }

  public initTableArticle(topicId: number): string {
    // if columns array is undefined or another topic is created
    if (!this._taxCloneForEditing || !this.columns || this._id !== topicId) {
      // get columns
      const rulesList = this.getTaxTopic(this.getTax(), topicId).rules;
      this._columns = [];
      if (rulesList && rulesList.length && rulesList[0].article && rulesList[0].articleTypeId === ArticleType.JSON) {
        const tableArticle = this.parseRulesAsTableArticle(rulesList)[0];
        if (tableArticle) {
          this._columns = Object.keys(tableArticle);
        }
      }
    }
    if (this._id !== topicId || !this._editColumns?.length) {
      this._editColumns = [...this._columns];
    }

    const rules = this._taxCloneForEditing
      ? this.getTaxTopic(this._taxCloneForEditing, topicId).rules
      : this.getTaxTopic(this.getTax(), topicId).rules;

    if (!rules || !rules.length || rules[0].articleTypeId !== ArticleType.JSON) {
      return;
    }

    // parse original rule string to table article
    const articles = this.parseRulesAsTableArticle(rules);

    if (this._taxCloneForEditing && rules[0].articleTypeId === ArticleType.JSON) {
      if (this._id !== topicId) {
        this.setTableArticle(this.setArticlesIndexes(articles));
      }
    } else {
      this.setTableArticle(articles);
    }
    this._id = topicId;

    return rules[0].shortCode;
  }

  public saveTmpJson(topicId: number) {
    if (this.isEditingMode && this._id) {
      this.taxCloneForEditing.taxTopics.forEach((topic) => {
        const topicIndex = this.taxCloneForEditing.taxTopics.indexOf(topic);

        if (topic.id === topicId) {
          const currTopic = this.taxCloneForEditing.taxTopics[topicIndex];
          if (currTopic.rules && currTopic.rules.length > 0) {
            currTopic.rules[0].article = this.convertTableArticleToJsonString(
              this.updateTableArticles(this.getTableArticle(), this.editColumns),
            );
          }
          return;
        }

        if (topic.subTopics) {
          topic.subTopics.forEach((subTopic) => {
            if (subTopic.id === topicId) {
              const index = this.taxCloneForEditing.taxTopics[topicIndex].subTopics.indexOf(subTopic);
              const currTopic = this.taxCloneForEditing.taxTopics[topicIndex].subTopics[index];

              if (currTopic.rules && currTopic.rules.length > 0) {
                currTopic.rules[0].article = this.convertTableArticleToJsonString(
                  this.updateTableArticles(this.getTableArticle(), this.editColumns),
                );
              }
              return;
            }
          });
        }
      });

      this._id = undefined;
    }
  }

  private parseRulesAsTableArticle(rules: Array<TaxRule>): TableArticle {
    return rules && rules.length > 0 && rules[0].article ? JSON.parse(rules[0].article) : null;
  }

  private convertTableArticleToJsonString(tableArticle: TableArticle): string {
    return JSON.stringify(tableArticle);
  }

  public formatTaxRevisionStatus(status: TaxRevisionStatus): string {
    switch (+status) {
      case TaxRevisionStatus.PENDING_LOCAL:
        return 'pending_local';
      case TaxRevisionStatus.PENDING_GLOBAL:
        return 'pending_global';
      case TaxRevisionStatus.APPROVED:
        return 'approved';
      case TaxRevisionStatus.REJECTED:
        return 'rejected';
      case TaxRevisionStatus.CLOSED:
        return 'closed';
      default:
        break;
    }
  }

  private checkIfAdminChangesValid(): boolean {
    const categoriesValid = this.checkIfCategoriesValid();
    const topicsValid = this.checkIfTopicsValid();
    const rulesValid = this.checkIfRulesValid();
    const ratesValid = this.checkIfRatesValid();

    const errorMessage = ([categoriesValid, topicsValid, rulesValid, ratesValid].filter((isValid) => isValid !== true) as string[])
      .reduce((error, nextError) => error + nextError + '\n', '')
      .trim();
    if (errorMessage) {
      this.modalService
        .showInformation({
          title: this.translateService.instant('commons.validation.error-title'),
          content: errorMessage.split('\n'),
        })
        .then();
    }

    return !errorMessage;
  }

  private checkIfCategoriesValid(): string | true {
    const categoriesWithoutName = this.taxCloneForEditing.taxTopics.filter((category) => !category.name?.trim()).length;
    let errors =
      categoriesWithoutName > 0
        ? categoriesWithoutName === 1
          ? this.translateService.instant('tax.edit-validation.empty-category')
          : this.translateService.instant('tax.edit-validation.empty-categories', { number: `${categoriesWithoutName}` })
        : '';

    this.taxCloneForEditing.taxTopics
      .filter((category) => category.name && category.name.trim().length < ValidationConstants.categoryMinLength)
      .forEach((category) => {
        errors +=
          '\n' +
          this.translateService.instant('tax.edit-validation.min-length-category-name', {
            category: category.name,
            minLength: ValidationConstants.categoryMinLength,
          });
        errors = errors.trim();
      });

    this.taxCloneForEditing.taxTopics
      .filter(
        (category) =>
          category.name &&
          category.name.trim().length >= ValidationConstants.categoryMinLength &&
          !ValidationConstants.categoryTopicNamePattern.test(category.name),
      )
      .forEach((category) => {
        errors += '\n' + this.translateService.instant('tax.edit-validation.category-name-pattern', { category: category.name });
        errors = errors.trim();
      });

    return !errors ? true : errors;
  }

  private checkIfTopicsValid(): string | true {
    const topicErrors = <string[]>this.taxCloneForEditing.taxTopics
      .map((category) => {
        const topicsWithoutName = (category.subTopics || []).filter((topic) => !topic.name?.trim()).length;
        let errors =
          topicsWithoutName > 0
            ? topicsWithoutName === 1
              ? this.translateService.instant('tax.edit-validation.empty-topic', { category: category.name })
              : this.translateService.instant('tax.edit-validation.empty-topics', {
                  category: category.name,
                  number: `${topicsWithoutName}`,
                })
            : '';

        (category.subTopics || [])
          .filter((topic) => topic.name && topic.name.trim().length < ValidationConstants.topicMinLength)
          .forEach((topic) => {
            errors +=
              '\n' +
              this.translateService.instant('tax.edit-validation.min-length-topic-name', {
                category: category.name,
                topic: topic.name,
                minLength: ValidationConstants.topicMinLength,
              });
            errors = errors.trim();
          });

        (category.subTopics || [])
          .filter(
            (topic) =>
              topic.name &&
              topic.name.trim().length >= ValidationConstants.topicMinLength &&
              !ValidationConstants.categoryTopicNamePattern.test(topic.name),
          )
          .forEach((topic) => {
            errors +=
              '\n' +
              this.translateService.instant('tax.edit-validation.topic-name-pattern', { category: category.name, topic: topic.name });
            errors = errors.trim();
          });

        return !errors ? true : <string>errors;
      })
      .filter((error) => error !== true);

    if (!topicErrors || !topicErrors.length) {
      return true;
    }

    return topicErrors.join('\n');
  }

  private checkIfRulesValid(): string {
    const topicRuleNamesValidator = (topics: TaxTopic[]) => {
      return !topics
        ? ''
        : topics.reduce((errors, topic) => {
            const issues = topic.rules
              .map((rule) => {
                const numberOfEmptyRules = topic.rules.filter((r) => !r.title).length;
                if (rule.articleTypeId !== ArticleType.JSON) {
                  if (!rule.title && numberOfEmptyRules === 1) {
                    return this.translateService.instant('tax.edit-validation.empty-rule-name', { topicName: topic.name });
                  } else if (!rule.title && numberOfEmptyRules > 1) {
                    return this.translateService.instant('tax.edit-validation.empty-rules-name', {
                      numberOfRules: numberOfEmptyRules,
                      topicName: topic.name,
                    });
                  }
                } else {
                  rule.title = !rule.title ? topic.name : rule.title;
                }

                if (rule.title.length < ValidationConstants.ruleMinLength) {
                  return this.translateService.instant('tax.edit-validation.min-length-rule-name', {
                    topicName: topic.name,
                    rule: rule.title,
                    minLength: ValidationConstants.ruleMinLength,
                  });
                }

                if (rule.articleTypeId === ArticleType.JSON && rule.article?.length) {
                  const articles = JSON.parse(rule.article)[0];
                  if (articles) {
                    const columnsList = Object.keys(articles);
                    if (!columnsList.length || columnsList.find((col) => col === '' || col.trim() === '') != null) {
                      return this.translateService.instant('tax.edit-validation.empty-column-name', { topicName: topic.name });
                    }
                  }

                  const rows = this.getTableArticle();
                  if (rows) {
                    const rowList = rows.map((obj) => Object.values(obj)).flat();
                    if (!rowList.length || rowList.find((row) => row === '' || row.trim() === '') != null) {
                      return this.translateService.instant('tax.edit-validation.empty-row-name', { topicName: topic.name });
                    }
                  }
                }

                return '';
              })
              .filter((v) => !!v);

            const uniqIssues = uniq(issues).join('\n');

            return (errors += '\n' + uniqIssues + '\n' + topicRuleNamesValidator(topic.subTopics));
          }, '');
    };

    return topicRuleNamesValidator(this.taxCloneForEditing.taxTopics);
  }

  private checkIfRatesValid(): string {
    const topicRateNamesValidator = (topics: TaxTopic[]) => {
      return !topics
        ? ''
        : topics.reduce((errors, topic) => {
            const issues = topic.rates
              .map((rate) => {
                const numberOfEmptyRules = topic.rates.filter((r) => !r.name).length;
                if (!rate.name && numberOfEmptyRules === 1) {
                  return this.translateService.instant('tax.edit-validation.empty-rule-name', { topicName: topic.name });
                } else if (!rate.name && numberOfEmptyRules > 1) {
                  return this.translateService.instant('tax.edit-validation.empty-rules-name', {
                    numberOfRules: numberOfEmptyRules,
                    topicName: topic.name,
                  });
                }

                if (rate.name.length < ValidationConstants.ruleMinLength) {
                  return this.translateService.instant('tax.edit-validation.min-length-rule-name', {
                    topicName: topic.name,
                    rule: rate.name,
                    minLength: ValidationConstants.ruleMinLength,
                  });
                }

                return '';
              })
              .filter((v) => !!v);

            const uniqIssues = uniq(issues).join('\n');

            return (errors += '\n' + uniqIssues + '\n' + topicRateNamesValidator(topic.subTopics));
          }, '');
    };

    return topicRateNamesValidator(this.taxCloneForEditing.taxTopics);
  }

  updateTable() {
    this.updateTableState.next();
  }

  public getTaxStructureDifferences(taxId: number, revisionNumber: number): Observable<TaxStructureDifferences> {
    return this.api.tax().getTaxStructureDifferences(taxId, revisionNumber);
  }

  public addTopicComment(content: string, taxId: number, shortCode: string) {
    this.isLoading = true;
    const request = new TopicCommentCreateRequest(content, taxId, shortCode);

    return this.api
      .topicComment()
      .createTopicComment(request)
      .pipe(
        tap(() => (this.isLoading = false)),
        tap(() => {
          if (!UserHelper.canEditTax(this.authUser, this.getTax())) {
            this.taxModalService.openCommentSuccessModal();
          }
        }),
      );
  }

  public getTopicComments(taxId: number): Observable<Array<TopicComment>> {
    return this.api.topicComment().getTopicComment(taxId);
  }

  public approveTopicComments(taxId: number, commentsIds: Array<number>) {
    this.isLoading = true;

    const request = new ApproveTopicCommentsRequest(taxId, commentsIds);

    this.api
      .topicComment()
      .approveTopicComments(request)
      .subscribe((response) => {
        this.isLoading = false;
      });
  }
}
