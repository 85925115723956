export class TaxParams {
	type: number[];
	territory?: number[];
	date?: string[];
	topicFilters?: string[];
  all?: boolean;

	static transform(taxParams: TaxParams) {
		const x: any = {
			type: JSON.stringify(taxParams.type),
		};

		if (taxParams.territory) {
			x.territory = JSON.stringify(taxParams.territory);
		}

		if (taxParams.date) {
			x.date = JSON.stringify(taxParams.date);
		}

		if (taxParams.topicFilters) {
			x.topicFilters = JSON.stringify(taxParams.topicFilters);
		}
    if (taxParams.all) {
      x.all = taxParams.all;
    }

		return x;

	}

}
