import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { UserSessionService } from '../services/user-session/user-session.service';

@Injectable()
export class NoAuthGuardService implements CanActivate {
  constructor(@Inject(UserSessionService) private userSessionService: UserSessionService, private router: Router) {}

  // functionality only when Not logged in or unauthorized
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    // user logged in - we can check their profile if it does exist in DB
    if (this.userSessionService.isUserLoggedIn()) {
      if (!this.userSessionService.isUserDataCached()) {
        await this.userSessionService.resolveUserProfile();
      }
    }

    const userHasAccess = this.userSessionService.isUserLoggedInAndAuthorized();
    if (!userHasAccess) {
      return true;
    }

    // fall back to home
    return this.router.createUrlTree([route.queryParams['returnUrl'] || '/']);
  }
}
