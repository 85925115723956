import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {Tax} from "../../../../model/data/tax/Tax";
import {ApiCommunicationService} from "../../../../model/services/api-communication/api-communication.service";
import {TaxEvent} from "../../../../model/data/event/TaxEvent";
import {TaxModalService} from "../../../services/tax-modal/tax-modal.service";
import {TaxDataService} from "../../../services/tax-data/tax-data.service";

@Component({
	selector: "app-tax-events",
	templateUrl: "./tax-events.component.html",
	styleUrls: ["./tax-events.component.scss"]
})
export class TaxEventsComponent implements OnInit, OnDestroy {

	// merged tax and revision object
	@Input("taxRevision")
	public taxRevision: Tax;

	// is the component loading
	public loading = true;
	public events: Array<TaxEvent>;

	constructor(private api: ApiCommunicationService,
				public taxService: TaxDataService,
				public taxModalService: TaxModalService) {
	}

	ngOnInit() {
		this.fetchEvents();
		// re-fetch events on modal save
		this.taxModalService.eventsChanged.subscribe(() => this.fetchEvents());
	}

	ngOnDestroy(): void {}


	/**
	 * Get review period from api
	 */
	private fetchEvents() {

		this.loading = true;

		this.api.event().getEventsByTaxId(this.taxRevision.id).subscribe((response: Array<TaxEvent>) => {
			this.events = response;

			this.loading = false;
		});
	}

	public createEvent() {
		this.taxModalService.openTaxEventModal(this.taxRevision.id);
	}

	public editEvent(event: TaxEvent) {
		this.taxModalService.openTaxEventModal(this.taxRevision.id, event);
	}


}
