<div id="tax-table-layout" *ngIf="taxTypes$ | async as taxTypes">
  <!--Header of editor-->
  <div *ngIf="userRoleMode === role.EDITOR" class="header">
    <div class="button-group">
      <p>{{ 'dashboard.your-updates' | translate | uppercase }}:</p>

      <button
        [class.active]="dashboardParam.status === getStatusPendingLocal()"
        (click)="onChange(getStatusPendingLocal())"
        class="ui button">
        {{ 'dashboard.pending_local' | translate }}
      </button>
      <button
        [class.active]="dashboardParam.status === getStatusPendingGlobal()"
        (click)="onChange(getStatusPendingGlobal())"
        class="ui button">
        {{ 'dashboard.pending_global' | translate }}
      </button>
      <button [class.active]="dashboardParam.status === getStatusApproved()" (click)="onChange(getStatusApproved())" class="ui button">
        {{ 'dashboard.approved' | translate }}
      </button>
      <button [class.active]="dashboardParam.status === getStatusRejected()" (click)="onChange(getStatusRejected())" class="ui button">
        {{ 'dashboard.rejected' | translate }}
      </button>
    </div>

    <a [routerLink]="['/tax', 'changes']" [queryParams]="{ status: dashboardParam.status }">{{ 'dashboard.see-all' | translate }}</a>
  </div>

  <!--Header of approver-->
  <div *ngIf="userRoleMode === role.APPROVER" class="header">
    <p>{{ 'dashboard.tax-changes' | translate | uppercase }}</p>
    <a [routerLink]="['/tax', 'approval']">{{ 'dashboard.see-all' | translate }}</a>
  </div>

  <!--Loading indicator-->
  <div class="ui active inline centered loader" *ngIf="!taxes"></div>

  <!--Table-->
  <table
    class="ui table custom-table selectable"
    *ngIf="taxes"
    [class.table-fixed]="userRoleMode === role.EDITOR && dashboardParam.status === getStatusApproved()">
    <thead>
      <tr>
        <th>{{ 'commons.table.tax-listing.tax' | translate | uppercase }}</th>
        <th>{{ 'commons.table.tax-listing.jurisdiction' | translate | uppercase }}</th>
        <th>{{ 'commons.table.tax-listing.subdivision' | translate | uppercase }}</th>
        <th>{{ 'commons.table.tax-listing.validity' | translate | uppercase }}</th>

        <th
          *ngIf="
            userRoleMode === role.APPROVER ||
            dashboardParam.status === getStatusPendingLocal() ||
            dashboardParam.status === getStatusPendingGlobal() ||
            dashboardParam.status === getStatusApproved() ||
            dashboardParam.status === getStatusRejected()
          ">
          {{ 'commons.table.tax-listing.updated' | translate | uppercase }}
        </th>

        <th *ngIf="dashboardParam.status == getStatusPendingLocal()">
          {{ 'commons.table.tax-listing.editor' | translate | uppercase }}
        </th>
        <th *ngIf="dashboardParam.status === getStatusApproved()">{{ 'commons.table.tax-listing.approver' | translate | uppercase }}</th>
        <th *ngIf="dashboardParam.status === getStatusRejected()">{{ 'commons.table.tax-listing.rejecter' | translate | uppercase }}</th>

        <th *ngIf="userRoleMode === role.EDITOR && dashboardParam.status === getStatusRejected()">
          {{ 'commons.table.tax-listing.reason' | translate | uppercase }}
        </th>

        <th *ngIf="userRoleMode === role.EDITOR && dashboardParam.status === getStatusApproved()">
          {{ 'commons.table.tax-listing.notes' | translate | uppercase }}
        </th>
      </tr>
    </thead>

    <tbody *ngIf="taxes.length">
      <tr *ngFor="let tax of taxes" class="cur-pointer" (click)="onClick()" [routerLink]="['/tax', tax.id, 'revision', tax.revisionNumber]">
        <td class="cell-wrap" [title]="tax.type | taxType: taxTypes">{{ tax.type | taxType: taxTypes }}</td>

        <td
          class="cell-wrap"
          *ngIf="tax.location.parent ? tax.location.parent.name : tax.location.name as jurisdictionName"
          [title]="jurisdictionName">
          {{ jurisdictionName }}
        </td>
        <td class="cell-wrap" *ngIf="tax.location.parent ? tax.location.name : '–' as subdivisionName" [title]="subdivisionName">
          {{ subdivisionName }}
        </td>

        <td
          [title]="
            tax.effectiveFrom | date: 'MMM dd, yyyy' + ' - ' + (tax.effectiveTo !== null ? (tax.effectiveTo | date: 'MMM dd, yyyy') : '')
          ">
          {{ tax.effectiveFrom | date: 'MMM dd, yyyy' }} - {{ tax.effectiveTo | date: 'MMM dd, yyyy' }}
        </td>

        <td
          [title]="tax.lastUpdate ? (tax.lastUpdate | date: 'MMM dd, yyyy') : 'N/A'"
          *ngIf="
            userRoleMode === role.APPROVER ||
            dashboardParam.status === getStatusPendingLocal() ||
            dashboardParam.status === getStatusPendingGlobal() ||
            dashboardParam.status === getStatusApproved() ||
            dashboardParam.status === getStatusRejected()
          ">
          {{ tax.lastUpdate ? (tax.lastUpdate | date: 'MMM dd, yyyy') : 'N/A' }}
        </td>

        <td [title]="tax.editor.name" *ngIf="dashboardParam.status === 1">
          {{ tax.editor.name }}
        </td>

        <td
          [title]="tax.approver ? tax.approver.name : 'N/A'"
          *ngIf="dashboardParam.status === getStatusApproved() || dashboardParam.status === getStatusRejected()">
          {{ tax.approver ? tax.approver.name : 'N/A' }}
        </td>

        <td
          class="notes"
          [title]="tax.rejectionReason"
          *ngIf="userRoleMode === role.EDITOR && dashboardParam.status === getStatusRejected()">
          {{ tax.rejectionReason }}
        </td>

        <td class="notes" [title]="tax.approvalNotes" *ngIf="userRoleMode === role.EDITOR && dashboardParam.status === getStatusApproved()">
          {{ tax.approvalNotes ? tax.approvalNotes : '-' }}
        </td>
      </tr>
    </tbody>

    <tbody *ngIf="!taxes.length">
      <tr>
        <td
          *ngIf="dashboardParam.status === getStatusRejected() || dashboardParam.status === getStatusApproved()"
          class="empty align-center"
          colspan="6">
          There are no entries at this moment.
        </td>

        <td
          *ngIf="dashboardParam.status === getStatusPendingLocal() || dashboardParam.status === getStatusPendingGlobal()"
          class="empty align-center"
          colspan="5">
          There are no entries at this moment.
        </td>
      </tr>
    </tbody>
  </table>
</div>
