import Quill, { Delta } from 'quill';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { LocationChildType } from '@rar/model/data/enums/LocationChildType';
import { TaxStructureDifferences } from '@rar/model/data/tax/TaxStructureDifferences';
import { TaxTypeLocations } from '@rar/model/data/tax/TaxTypeLocations';

import { Tax } from '../data/tax/Tax';
import { TaxLock } from '../data/tax/TaxLock';
import { TaxRevision } from '../data/tax/TaxRevision';
import { TaxRule } from '../data/tax/TaxRule';
import { TaxTopic } from '../data/tax/TaxTopic';
import { TaxType } from '../data/tax/TaxType';
import { TaxTypeStructure } from '../data/tax/TaxTypeStructure';
import { User } from '../data/user/User';
import { ValidationError } from '../exception/ValidationError';
import { TaxCreateRequest } from '../request/tax/TaxCreateRequest';
import { TaxEditRequest } from '../request/tax/TaxEditRequest';
import { TaxParams } from '../request/tax/TaxParams';
import { TaxTypeStructureEditRequest } from '../request/tax/TaxTypeStructureEditRequest';
import { AuditTrailListResponse } from '../response/AuditTrailListResponse';
import { FileUploadResponse } from '../response/FileUploadResponse';
import { TaxEditResponse } from '../response/TaxEditResponse';
import { TaxEffectiveRangeResponse } from '../response/TaxEffectiveRangeResponse';
import { TaxListResponse } from '../response/TaxListResponse';
import { AbstractApiConnector } from './AbstractApiConnector';

export class TaxApiConnector extends AbstractApiConnector {
  private static DeltaConstructor: typeof Delta = Quill.import('delta');

  // Api base url
  protected readonly apiRoute = '/api/v1/tax';

  public getTaxPage(pageNumber: number, params: TaxParams): Observable<TaxListResponse> {
    if (typeof pageNumber !== 'number') {
      throw new ValidationError();
    } else {
      return this.apiClient.get(this.apiRoute + '/pages/' + pageNumber, TaxParams.transform(params));
    }
  }

  public getTaxHistory(taxId: number, pageNumber: number, filter: any = {}): Observable<AuditTrailListResponse> {
    if (typeof taxId !== 'number' || typeof pageNumber !== 'number') {
      throw new ValidationError();
    } else {
      return this.apiClient.get(this.apiRoute + '/' + taxId + '/history/' + pageNumber, { filter: JSON.stringify(filter) });
    }
  }

  public getTaxRevision(taxId: number, revisionNumber: number): Observable<Tax & TaxRevision> {
    if (typeof taxId !== 'number' || typeof revisionNumber !== 'number') {
      throw new ValidationError();
    } else {
      return this.apiClient.get<Tax & TaxRevision>(this.apiRoute + '/' + taxId + '/revision/' + revisionNumber).pipe(
        tap((taxRevision) => {
          if (taxRevision && taxRevision.taxTopics && taxRevision.taxTopics.length) {
            this.ensureRichTextArticlesExist(taxRevision.taxTopics);
          }
        }),
      );
    }
  }

  public createTaxRevision(taxId: number, data: TaxCreateRequest): Observable<any> {
    if (typeof taxId !== 'number') {
      throw new ValidationError();
    } else {
      return this.apiClient.post(this.apiRoute + '/' + taxId + '/revision/', data);
    }
  }

  public editTaxRevision(taxId: number, revisionNumber: number, data: TaxEditRequest): Observable<TaxEditResponse> {
    if (typeof taxId !== 'number' || typeof revisionNumber !== 'number') {
      throw new ValidationError();
    } else {
      return this.apiClient.put(this.apiRoute + '/' + taxId + '/revision/' + revisionNumber, data);
    }
  }

  public lockTaxRevision(taxId: number, revisionNumber: number): Observable<TaxLock> {
    if (typeof taxId !== 'number' || typeof revisionNumber !== 'number') {
      throw new ValidationError();
    } else {
      return this.apiClient.patch(this.apiRoute + '/' + taxId + '/revision/' + revisionNumber + '/lock', null);
    }
  }

  public getTaxRevisionLockInfo(taxId: number, revisionNumber: number): Observable<TaxLock> {
    if (typeof taxId !== 'number' || typeof revisionNumber !== 'number') {
      throw new ValidationError();
    } else {
      return this.apiClient.get(this.apiRoute + '/' + taxId + '/revision/' + revisionNumber + '/lock/info', null);
    }
  }

  public lockTaxType(taxTypeId: number): Observable<TaxLock> {
    if (typeof taxTypeId !== 'number') {
      throw new ValidationError();
    } else {
      return this.apiClient.patch(`${this.apiRoute}/type/${taxTypeId}/lock`);
    }
  }

  public getTaxTypeLockInfo(taxTypeId: number): Observable<TaxLock> {
    if (typeof taxTypeId !== 'number') {
      throw new ValidationError();
    } else {
      return this.apiClient.get(`${this.apiRoute}/type/${taxTypeId}/lock`);
    }
  }

  public getTaxTypes(): Observable<Array<TaxType>> {
    return this.apiClient.get(`${this.apiRoute}/types`, null);
  }

  public getAllTaxTypes(): Observable<Array<TaxType>> {
    return this.apiClient.get(`${this.apiRoute}/types/all`, null);
  }

  public getTaxMultiEditors(revisionId: number): Observable<Array<User>> {
    if (typeof revisionId !== 'number') {
      throw new ValidationError();
    } else {
      return this.apiClient.get(this.apiRoute + '/' + revisionId + '/revision');
    }
  }

  public getTaxEffectiveRange(taxId: number): Observable<TaxEffectiveRangeResponse> {
    if (typeof taxId !== 'number') {
      throw new ValidationError();
    } else {
      return this.apiClient.get(this.apiRoute + '/' + taxId + '/range');
    }
  }

  public getTaxEligibility(taxId: number): Observable<any> {
    if (typeof taxId !== 'number') {
      throw new ValidationError();
    } else {
      return this.apiClient.get(this.apiRoute + `/${taxId}/revision/eligibility`);
    }
  }

  /**
   * Uploads attachments and returns its ID, so it can be later downloaded from the tax rule.
   * @param attachment Attachment to upload.
   * @returns ID (guid) od the uploaded attachment.
   */
  public uploadTaxAttachment(attachment: File): Observable<string> {
    const data = new FormData();
    data.append('name', attachment.name);
    data.append('size', `${attachment.size}`);
    data.append('type', attachment.type);
    data.append('file', attachment);
    return this.apiClient.post<string>(`${this.apiRoute}/attachment`, data);
  }

  /**
   * Downloads an attachment. Checks if the logged user has permissions to any tax where the attachment is used.
   * @param attachmentId ID of the attachment.
   * @returns Attachment as a file (blob with filename).
   */
  public downloadAttachment(attachmentId: string): Observable<File> {
    return this.apiClient.getFile(`${this.apiRoute}/attachment/${attachmentId}`);
  }

  private ensureRichTextArticlesExist(taxTopics: Array<TaxTopic>) {
    taxTopics.forEach((taxTopic) => {
      if (taxTopic.rules && taxTopic.rules.length) {
        taxTopic.rules.forEach((rule) => {
          if (rule) {
            this.ensureRichTextArticleExist(rule);
          }
        });
      }
      if (taxTopic.subTopics && taxTopic.subTopics.length) {
        this.ensureRichTextArticlesExist(taxTopic.subTopics);
      }
    });
  }

  /**
   * Creates a basic rich text article version if there is none, and plain version exists.
   * @param rule
   * @private
   */
  private ensureRichTextArticleExist(rule: TaxRule) {
    if (rule.richArticle) {
      if (typeof rule.richArticle === 'string') {
        rule.richArticle = new TaxApiConnector.DeltaConstructor(JSON.parse(rule.richArticle));
      }
      return;
    }
    const article = rule.article || '';
    const richArticle = new TaxApiConnector.DeltaConstructor({
      ops: [{ insert: article }],
    });
    rule.richArticle = richArticle;
  }

  public getTaxTypeStructure(taxTypeId: number): Observable<TaxTypeStructure> {
    return this.apiClient.get<TaxTypeStructure>(`${this.apiRoute}/type/${taxTypeId}/structure`);
  }

  public editTaxTypeStructure(taxTypeId: number, data: TaxTypeStructureEditRequest): Observable<TaxTypeStructure> {
    return this.apiClient.put<TaxTypeStructure>(`${this.apiRoute}/type/${taxTypeId}/structure`, data);
  }

  // Req 6
  public createTaxType(taxTypeName: string, taxTypeApprovalLevel: number): Observable<TaxType> {
    if (typeof taxTypeName !== 'string') {
      throw new ValidationError();
    } else {
      // Req 6
      return this.apiClient.post(`${this.apiRoute}/type`, { name: taxTypeName, approvalLevel: taxTypeApprovalLevel });
    }
  }

  public getTaxTypeLocations(taxTypeId: number): Observable<TaxTypeLocations> {
    return this.apiClient.get<TaxTypeLocations>(`${this.apiRoute}/type/${taxTypeId}/locations`);
  }

  public editTaxTypeLocations(taxTypeId: number, data: TaxTypeLocations): Observable<TaxTypeLocations> {
    return this.apiClient.put<TaxTypeLocations>(`${this.apiRoute}/type/${taxTypeId}/locations`, data);
  }

  public importFile(taxId: number, revisionNumber: number, data: FormData): Observable<FileUploadResponse> {
    return this.apiClient.post(`${this.apiRoute}/${taxId}/revision/${revisionNumber}/import`, data);
  }

  /**
   * Export a tax revision data to the Excel workbook.
   * @param taxId ID of the attachment.
   * @param revisionNumber Number of the tax revision.
   * @returns Excel worksheet as a file (blob with filename).
   */
  public exportTax(taxId: number, revisionNumber: number): Observable<File> {
    return this.apiClient.getFile(`${this.apiRoute}/${taxId}/revision/${revisionNumber}/export`);
  }

  public getTaxStructureDifferences(taxId: number, revisionNumber: number): Observable<TaxStructureDifferences> {
    return this.apiClient.get<TaxStructureDifferences>(`${this.apiRoute}/${taxId}/revision/${revisionNumber}/structure/diff`);
  }

  public propagateTaxTypeStructure(taxId: number, data: TaxStructureDifferences): Observable<any> {
    return this.apiClient.post(`${this.apiRoute}/${taxId}/structure/propagate`, data);
  }

  public clearPropagationStatus(taxTypeId: number): Observable<TaxType[]> {
    return this.apiClient.post(`${this.apiRoute}/type/${taxTypeId}/propagation/status/clear`);
  }
}
