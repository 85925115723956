<div class="modal-header">
  <h4 class="modal-title">
    <ng-container *ngIf="isEditor">Cannot Edit Tax</ng-container>
    <ng-container *ngIf="isApprover">Cannot Approve Tax</ng-container>
  </h4>
</div>

<div class="modal-body">
  <div [ngSwitch]="context.lock.action">
    <div *ngSwitchCase="'structure'">
      {{ 'tax-design.messages.cannot-edit-tax-structure-is-edited' | translate }}
    </div>

    <div *ngSwitchCase="'editing'">This tax has been locked for editing. Edits are being done by:</div>

    <div *ngSwitchCase="'approval'">This tax has been locked for approval. Approves are being done by:</div>

    <!--Pending revision-->
    <div *ngSwitchDefault>
      {{ context.lock.action }}
    </div>
  </div>

  <br />
  <br />

  <a [href]="'mailto:' + context.lock.user.email"
    ><strong>{{ context.lock.user.name }}</strong>
    <i class="fas fa-envelope"></i>
  </a>
</div>

<div class="modal-footer">
  <div></div>

  <div class="button-group">
    <!--Deny-->
    <button class="ui basic button silver" (click)="deny()">
      {{ 'user.user-edit-modal.cancel' | translate }}
    </button>

    <!--Approve-->
    <button class="ui basic button rose" (click)="approve()">
      {{ 'user.user-edit-modal.ok' | translate | uppercase }}
    </button>
  </div>
</div>
