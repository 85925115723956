import { EventEmitter, Inject, Injectable } from '@angular/core';

import { Observable, Subscriber } from 'rxjs';

import { ModalService } from '@rar/commons/services/modal.service';
import { TaxTypeStructure } from '@rar/model/data/tax/TaxTypeStructure';

import { ApiCommunicationService } from '../../model/services/api-communication/api-communication.service';
import { TaxTypeConfirmModalComponent } from '../modals/tax-type-confirm-modal/tax-type-confirm-modal.component';
import { TaxTypeDiscardModalComponent } from '../modals/tax-type-discard-modal/tax-type-discard-modal.component';

@Injectable()
export class TaxTypeModalService {
  public save = new EventEmitter<boolean>();

  constructor(private modalService: ModalService, @Inject(ApiCommunicationService) private api: ApiCommunicationService) {}

  public openTaxTypeSaveModal(taxTypeStructure: TaxTypeStructure) {
    const modalRef = this.modalService.open(TaxTypeConfirmModalComponent);
    modalRef.componentInstance.context = { taxTypeStructure };
    modalRef.result.then(
      () => this.save.emit(true),
      () => this.save.emit(false),
    );
  }

  public openTaxDiscardModal(): Observable<boolean> {
    return new Observable((observer: Subscriber<boolean>) => {
      const modalRef = this.modalService.open(TaxTypeDiscardModalComponent);
      modalRef.result.then(
        () => observer.next(true),
        () => observer.next(false),
      );
    });
  }
}
