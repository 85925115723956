import { Component, OnDestroy, OnInit } from '@angular/core';

import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import { AuditFilterService } from '../../../audit/services/audit-filter.service';
import { User } from '../../../model/data/user/User';
import { ApiCommunicationService } from '../../../model/services/api-communication/api-communication.service';
import { TaxDataService } from '../../services/tax-data/tax-data.service';

@Component({
  selector: 'app-tax-history',
  templateUrl: './tax-history.component.html',
  styleUrls: ['./tax-history.component.scss'],
})
export class TaxHistoryComponent implements OnInit, OnDestroy {
  public users: Observable<Array<User>>;

  constructor(private filterService: AuditFilterService, private api: ApiCommunicationService, private taxDataService: TaxDataService) {
    this.filterService.fixedTaxId = taxDataService.getTaxId();
  }

  ngOnInit() {
    this.taxDataService.tax.pipe(untilDestroyed(this)).subscribe((tax) => {
      this.filterService.fixedTaxId = tax.id;
      this.filterService.getPage();
    });

    this.users = this.taxDataService.tax.pipe(switchMap((tax) => this.api.audit().getAuditUsers(tax.id)));
  }

  ngOnDestroy(): void {}
}
