import { Component, Input, OnInit } from "@angular/core";
import {
	NgbActiveModal,
	NgbDateAdapter,
	NgbDateStruct,
} from "@ng-bootstrap/ng-bootstrap";
import { TaxEvent } from "@rar/model/data/event/TaxEvent";
import { CreateEventRequest } from "@rar/model/request/event/CreateEventRequest";
import { ApiCommunicationService } from "@rar/model/services/api-communication/api-communication.service";

interface ITaxEventModalContext {
	taxId: number;
	event?: TaxEvent;
}

@Component({
	selector: "app-tax-notification-updated-modal",
	templateUrl: "./tax-event-modal.component.html",
	styleUrls: ["./tax-event-modal.component.scss"],
})
export class TaxEventModalComponent implements OnInit {
	@Input()
	context: ITaxEventModalContext;

	// values for notification prior value
	public priorNotification = [
		{
			title: "One week",
			value: 7,
		},
		{
			title: "Two weeks",
			value: 14,
		},
		{
			title: "Three weeks",
			value: 21,
		},
		{
			title: "Four weeks",
			value: 28,
		},
	];
	// the model for selector
	public actualPriorNotification: { title: string; value: number };

	// the model for event date
	public actualEventDate: Date;

	// the model for event name
	public actualEventName: string;

	// minimum date
	public minDate: Date;
	public get minDateBs(): NgbDateStruct {
		return this.dateAdapter.fromModel(this.minDate);
	}

	public invalidDate = false;

	// retrieved data
	public event: TaxEvent;
	public createMode = false;
	public canSave = false;
	public alreadySent = false;

	constructor(
		private activeModal: NgbActiveModal,
		private api: ApiCommunicationService,
		private dateAdapter: NgbDateAdapter<Date>
	) { }

	ngOnInit() {
		this.event = this.context.event;

		// set minimum date
		this.minDate = new Date();
		this.minDate.setHours(
			this.minDate.getHours() +
			(this.actualPriorNotification &&
				this.actualPriorNotification.value
				? this.actualPriorNotification.value
				: 7) *
			24
		);

		// create or update mode
		if (!this.event) {
			this.event = <TaxEvent>{};
			this.createMode = true;
		} else {
			// check if already sent
			this.alreadySent = !!this.event.sent;

			// event date
			this.actualEventDate = new Date(this.event.eventDate);

			// event name
			this.actualEventName = this.event.name;

			// set prior notification
			this.actualPriorNotification =
				// get the right value from selection
				this.priorNotification.find(
					(f) => f.value === this.event.reminderPriorInDays
				);
		}
	}

	public updateValues() {
		// check values
		if (
			this.actualEventDate &&
			this.actualPriorNotification &&
			this.actualPriorNotification.value
		) {
			// set minimum date
			this.minDate = new Date();
			this.minDate.setHours(
				this.minDate.getHours() +
				this.actualPriorNotification.value * 24
			);

			// update activation
			this.event.activation = new Date(this.actualEventDate);
			this.event.activation.setHours(
				this.actualEventDate.getHours() -
				this.actualPriorNotification.value * 24
			);

			// reminder date is in the past
			this.invalidDate = this.event.activation < new Date();

			// check save button state
			this.canSave = !!(
				this.actualEventName &&
				this.actualEventName.length &&
				!this.invalidDate &&
				!this.alreadySent
			);
		} else {
			this.event.activation = undefined;
			this.canSave = false;
		}
	}

	public saveOrUpdate() {
		if (!this.canSave) {
			return;
		}

		// create or update mode
		if (!this.event.id) {
			// update event object
			const request: CreateEventRequest = {
				reminderPriorInDays: this.actualPriorNotification.value,
				name: this.actualEventName,
				eventDate: this.actualEventDate,
			};

			// create
			this.api
				.event()
				.createEvent(this.context.taxId, request)
				.subscribe((res) => {
					this.activeModal.close(undefined);
				});
		} else {
			// update event object
			const request: CreateEventRequest = {
				reminderPriorInDays: this.actualPriorNotification.value,
				name: this.actualEventName,
				eventDate: this.actualEventDate,
			};

			// update
			this.api
				.event()
				.updateEvent(this.event.id, request)
				.subscribe((res) => {
					this.activeModal.close(undefined);
				});
		}
	}

	deny(): void {
		this.activeModal.dismiss(null);
	}
}
