import { Component, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-validation-message',
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.scss'],
})
export class ValidationMessageComponent {
  @Input()
  public inputControl: AbstractControl;

  constructor(private translateService: TranslateService) { }

  get errorMessage(): string | null {
    if (this.inputControl) {
      for (const propertyName in this.inputControl.errors) {
        if (this.inputControl.errors[propertyName] && this.inputControl.touched) {
          return this.getValidatorErrorMessage(propertyName, this.inputControl.errors[propertyName]);
        }
      }
    }
    return null;
  }

  private getValidatorErrorMessage(validatorName: string, error: any) {
    switch (validatorName) {
      case 'required':
        return this.translateService.instant('commons.validation.required');
      case 'email':
        return this.translateService.instant('commons.validation.email');
      case 'emailAlreadyExists':
        return this.translateService.instant('commons.validation.email-exists');
      case 'serverError':
        return error;
    }

    return validatorName + ': ' + JSON.stringify(error);
  }
}
