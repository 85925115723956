<div id="tax-listing-layout">

	<!--Page title-->
	<div id="header" class="padding-bottom">

		<div class="title-group">
			<h1 *ngIf="taxName || territoryName || date; else other">Search Result For
				<span class="black" *ngIf="taxName">{{ ' / ' + taxName }}</span>
				<span class="black" *ngIf="territoryName">{{ ' / ' + territoryName }}</span>
				<span class="black" *ngIf="date"> / {{ date | date:'MMM dd, yyyy' }}</span></h1>

			<ng-template #other>
				<h1>{{ "tax.tax-listing-admin.title" | translate }}</h1>
			</ng-template>
		</div>

		<div class="button-group">

<!--
			<p>{{ "tax.tax-listing-admin.sort" | translate }}:</p>

			<button class="ui basic button" style="margin-right: 1rem">
				{{ "tax.tax-listing-admin.recently-viewed" | translate }}
				<img class="left" [src]="'../../../../assets/images/icons/arrow_down.svg'">
			</button>
-->

			<button (click)="taxFilter.setShowFilter(!taxFilter.showFilter)" class="ui basic button">
				<img [src]="'../../../../assets/images/icons/filter.svg'">
				{{ (taxFilter.showFilter ? "tax.tax-listing-admin.close" : "tax.tax-listing-admin.filter") | translate }}
			</button>

		</div>

	</div>

	<!--Page content-->
	<div>

		<!--Tax table-->
		<app-tax-table></app-tax-table>

	</div>

</div>
