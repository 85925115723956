import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { ValidationConstants } from '@rar/commons/constants';
import { ShortCodeConstants } from '@rar/commons/constants';
import { ModalService } from '@rar/commons/services/modal.service';
import { Role } from '@rar/model/data/enums/Role';
import { TaxTypeTopic } from '@rar/model/data/tax/TaxTypeTopic';
import { TaxTypeDataService } from '@rar/tax-design/services/tax-type-data.service';

@Component({
  selector: 'app-tax-type-rules-key-value',
  templateUrl: './tax-type-rules-key-value.component.html',
  styleUrls: ['./tax-type-rules-key-value.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaxTypeRulesKeyValueComponent implements OnInit, OnDestroy {
  public readonly role = Role;
  public readonly validationConstants = ValidationConstants;

  public topicId: number;

  constructor(
    private route: ActivatedRoute,
    public taxTypeDataService: TaxTypeDataService,
    private cdr: ChangeDetectorRef,
    private modalService: ModalService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.route.params.pipe(untilDestroyed(this)).subscribe((p) => {
      this.topicId = +p['id'];
    });

    this.markForCheck();
  }

  ngOnDestroy(): void {}

  public addNewRuleRow(): void {
    const topic = this.taxTypeDataService.getTaxTopic(this.taxTypeDataService.getTax(), this.topicId);
    topic.rules = this.sortTopics(topic.rules || []);
    topic.subTopics = this.sortTopics(topic.subTopics || []);
    const newRule = {
      taxType: topic.taxType,
      parentId: topic.id,
      type: 'rule',
      shortCode: ShortCodeConstants.newPlaceholder, // will be calculated on API
      order: topic.rules.length ? topic.rules[topic.rules.length - 1].order + 1 : 1,
    } as TaxTypeTopic;
    topic.rules.push(newRule);
    topic.subTopics.push(newRule);
    this.cdr.markForCheck();
  }

  private markForCheck(): void {
    this.cdr.markForCheck();
    setTimeout(() => this.cdr.markForCheck());
  }

  public deleteRuleRow(rule: TaxTypeTopic) {
    this.modalService
      .openConfirmation({
        title: this.translateService.instant('tax.tax-topics.rule-delete-title'),
        yesButtonText: this.translateService.instant('tax.tax-topics.rule-delete-confirm'),
        noButtonText: this.translateService.instant('commons.modal.cancel'),
        content: this.translateService.instant('tax-design.messages.tax-rule-delete-content'),
        isDestructiveWarning: true,
      })
      .then(
        () => {
          const topic = this.taxTypeDataService.getTaxTopic(this.taxTypeDataService.getTax(), this.topicId);
          topic.rules.splice(
            topic.rules.findIndex((r) => (!!rule.id && r.id == rule.id) || (!rule.id && r.name == rule.name && r.order == rule.order)),
            1,
          );
          topic.subTopics.splice(
            topic.subTopics.findIndex((r) => (!!rule.id && r.id == rule.id) || (!rule.id && r.name == rule.name && r.order == rule.order)),
            1,
          );
          this.cdr.markForCheck();
        },
        () => {},
      );
  }

  public sortTopics(topics: TaxTypeTopic[]): TaxTypeTopic[] {
    return topics ? topics.sort((a, b) => a.order - b.order) : [];
  }

  public changeTopicOrder(event: Event, index: number, moveUp: boolean) {
    event.stopPropagation();
    const topic = this.taxTypeDataService.getTaxTopic(this.taxTypeDataService.getTax(), this.topicId);
    const rules = this.sortTopics(topic.rules);
    const currentTopicOrder = rules[index].order;
    const moveToIndex = moveUp ? index - 1 : index + 1;
    rules[index].order = rules[moveToIndex].order;
    rules[moveToIndex].order = currentTopicOrder;

    this.cdr.markForCheck();
  }
}
