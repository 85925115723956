import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { FileNameReader } from '@rar/commons/helpers';

@Injectable()
export class BaseHttpService {
  private baseApiUrl = environment.apiUrl;

  constructor(private httpClient: HttpClient) {}

  get<T>(url: string, params?: HttpParams | { [param: string]: string | string[] } | any): Observable<T> {
    return this.httpClient.get<T>(this.baseApiUrl + url, { params }).pipe(
      tap(
        () => {},
        (error: unknown) => this.handleError(error),
      ),
    );
  }

  getFile(url: string, params?: HttpParams | { [param: string]: string | string[] } | any): Observable<File> {
    return this.httpClient.get(this.baseApiUrl + url, { params, observe: 'response', responseType: 'blob' }).pipe(
      map((response) => {
        const fileName = FileNameReader.getFileName(response.headers);

        // Req 9 -eracode & SRT scan fixes - Angular 13 migration
        const file: File = Object.assign(<Blob>response.body, { name: fileName, lastModified: 0, webkitRelativePath: url });
        return file;
      }),
      tap(
        () => {},
        (error: unknown) => this.handleError(error),
      ),
    );
  }

  post<T>(url: string, body?: unknown, params?: HttpParams | { [param: string]: string | string[] } | any): Observable<T> {
    return this.httpClient.post<T>(this.baseApiUrl + url, body, { params }).pipe(
      tap(
        () => {},
        (error: unknown) => this.handleError(error),
      ),
    );
  }

  patch<T>(url: string, body?: unknown, params?: HttpParams | { [param: string]: string | string[] } | any): Observable<T> {
    return this.httpClient.patch<T>(this.baseApiUrl + url, body, { params }).pipe(
      tap(
        () => {},
        (error: unknown) => this.handleError(error),
      ),
    );
  }

  put<T>(url: string, body?: unknown, params?: HttpParams | { [param: string]: string | string[] } | any): Observable<T> {
    return this.httpClient.put<T>(this.baseApiUrl + url, body, { params }).pipe(
      tap(
        () => {},
        (error: unknown) => this.handleError(error),
      ),
    );
  }

  delete<T>(url: string, params?: HttpParams | { [param: string]: string | string[] } | any): Observable<T> {
    return this.httpClient.delete<T>(this.baseApiUrl + url, { params }).pipe(
      tap(
        () => {},
        (error: unknown) => this.handleError(error),
      ),
    );
  }

  private handleError(error: HttpErrorResponse | unknown): void {
    if (error instanceof HttpErrorResponse) {
      if (error.status === 0) {
        // A client-side or network error occurred. Handle it accordingly.
        // eslint-disable-next-line no-console
        console.error('An error occurred:', error.error);
      } else {
        // The backend returned an unsuccessful response code.
        // The response body may contain clues as to what went wrong.
        // eslint-disable-next-line no-console
        console.error(`Backend returned code ${error.status}, body was: `, error.error);
      }
    }
  }
}
