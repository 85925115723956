import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter } from 'rxjs/operators';

import { TaxRule } from '../../../model/data/tax/TaxRule';

@Component({
  selector: 'app-tax-type-rules',
  templateUrl: './tax-type-rules.component.html',
  styleUrls: ['./tax-type-rules.component.scss'],
})
export class TaxTypeRulesComponent implements OnInit, OnDestroy {
  public rules: TaxRule[];

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    // resolve data
    this.route.data
      .pipe(
        filter((data) => data.topic),
        untilDestroyed(this),
      )
      .subscribe((data) => {
        this.rules = data.topic.rules;
      });
  }

  ngOnDestroy(): void {}
}
