import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { TaxTypeStructurePageComponent } from '../pages/tax-type-structure-page/tax-type-structure-page.component';

@Injectable({
  providedIn: 'root',
})
export class TaxTypeDiscardChangesGuard implements CanDeactivate<TaxTypeStructurePageComponent> {
  canDeactivate(
    component: TaxTypeStructurePageComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return component.canDeactivate();
  }
}
