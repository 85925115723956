<div id="tax-rules-json-layout">
  <div class="table-responsive">
    <table
      class="ui table custom-table"
      *ngIf="
        (!taxDataService.isEditingMode && !(taxDataService.isApprovingMode && isRuleChanged())) ||
        (taxDataService.isApprovingMode && isRuleChanged())
      ">
      <thead>
        <tr>
          <th *ngFor="let column of taxDataService.columns">
            <div appDiffAndSearch [content]="column.toString().toUpperCase()" [searchString]="taxDataService.searchCriteria"></div>
          </th>
        </tr>
      </thead>

      <tbody *ngIf="!taxDataService.isEditingMode && !(taxDataService.isApprovingMode && isRuleChanged())">
        <tr *ngFor="let element of taxDataService.getTableArticle()">
          <td *ngFor="let column of taxDataService.columns">
            <div appDiffAndSearch [content]="element[column]?.toString()" [searchString]="taxDataService.searchCriteria"></div>
          </td>
        </tr>
      </tbody>

      <tbody *ngIf="taxDataService.isApprovingMode && isRuleChanged()">
        <tr *ngFor="let element of newJson; let i = index">
          <td *ngFor="let column of taxDataService.columns">
            <div class="change align"><span [outerHTML]="element[column] | diffPipe: oldJson[i][column]"></span></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <!--Edit-->
  <ngb-accordion [closeOthers]="true" *ngIf="taxDataService.isEditingMode">
    <ngb-panel *ngFor="let element of taxDataService.getTableArticle(); index as currentIndex; trackBy: trackByFn">
      <ng-template ngbPanelTitle>
        <div class="header-container">
          <div class="header">{{ 'tax.tax-topics.row-label' | translate }} {{ currentIndex + 1 }}</div>
          <button type="button" class="btn btn-sm btn-icon btn-primary btn-delete mr-2"
                  [disabled]="taxDataService.getTableArticle().length === 1"
                  (click)="deleteRowHandler(currentIndex)" >
            <svg-icon [src]="'/assets/images/icons/trash.svg'"></svg-icon>
          </button>
          <svg-icon class="icon indicator" src="/assets/images/icons/arrow-fill.svg"></svg-icon>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="item-edit" *ngFor="let column of taxDataService.editColumns; index as i; last as last">
          <label>{{ column }}</label>
          <div class="v2 edit">
            <input #row="ngModel" type="text" class="inline-editor form-control" name="content" [(ngModel)]="element[i]" required/>
          </div>
            <div class="invalid-feedback d-flex flex-start" *ngIf="(!element[i] || !element[i].trim()) && row.touched">
              <div class="invalid-feedback__message">
                <i class="fa fa-exclamation-triangle mr-1"></i>
                {{ 'commons.validation.required' | translate }}
              </div>
          </div>
          <div class="hr" *ngIf="!last"></div>
        </div>
      </ng-template>

    </ngb-panel>
  </ngb-accordion>
</div>
