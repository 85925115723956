import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { UserSessionService } from '@rar/user/services/user-session/user-session.service';

import { environment } from '../../../environments/environment';
import { UserEncryptionService } from '@rar/user/services/user-encryption/user-encryption.service';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';

@Injectable()
export class BearerInterceptor implements HttpInterceptor {
  constructor(@Inject(UserSessionService) private userSessionService: UserSessionService,
  private userEncryption: UserEncryptionService,
  @Inject(OAuthService) private oauthService: OAuthService
  ) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.startsWith(environment.apiUrl)) {
      // const token = this.userSessionService.getToken();
      // RAR SRT encryption Start
      let token = this.userSessionService.getToken();

      if (token.length>11 && !token.includes("EnCrYpTeD")){
        let token1: string=''; 
        let token2: string ='';
        let token3: string='';
        let token4: string='';

        token1=token;
        token2 = token1.replace('Bearer','').trim();
        token3= 'EnCrYpTeD' + this.userEncryption.encrypt(token2);
        sessionStorage.setItem('access_token', token3);

        let id_token = sessionStorage.getItem('id_token');
        if (id_token) {
          id_token = this.userEncryption.encrypt(id_token);
          sessionStorage.setItem('id_token', id_token);
        }

        token4='Bearer '+ token3;
        token =token4;
      }
      console.log('sessionStorage',sessionStorage);
      // RAR SRT encryption End

      request = request.clone({
        setHeaders: {
          Authorization: token,
        },
      });
    }

    return next.handle(request);
  }
}
