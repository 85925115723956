import { Component } from "@angular/core";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: "app-tax-reject-modal",
	templateUrl: "./tax-reject-modal.component.html",
	styleUrls: ["./tax-reject-modal.component.scss"]
})
export class TaxRejectModalComponent {

	constructor(private activeModal: NgbActiveModal) { }

	public reason: string;

	approve(reason: string): void {
		this.activeModal.close(reason);
	}

	deny(): void {
		this.activeModal.dismiss(null);
	}

}
