<div id="tax-recent-views-table">
  <div class="header">
    <p>{{ 'Recently viewed' | uppercase }}</p>
    <a [routerLink]="['/tax', 'views']">{{ 'dashboard.see-all' | translate }}</a>
  </div>

  <!--Loading indicator-->
  <div class="ui active inline centered loader" *ngIf="!taxes"></div>

  <!--Table-->
  <table class="ui table custom-table selectable" *ngIf="taxes">
    <thead>
      <tr>
        <th>{{ 'commons.table.tax-listing.tax' | translate | uppercase }}</th>
        <th>{{ 'commons.table.tax-listing.jurisdiction' | translate | uppercase }}</th>
        <th>{{ 'commons.table.tax-listing.subdivision' | translate | uppercase }}</th>
        <th>{{ 'commons.table.tax-listing.validity' | translate | uppercase }}</th>
        <th>{{ 'commons.table.tax-listing.last-viewed' | translate | uppercase }}</th>
      </tr>
    </thead>

    <tbody *ngIf="taxTypes$ | async as taxTypes">
      <tr
        *ngFor="let recent of taxes"
        class="cur-pointer"
        (click)="onClick()"
        [routerLink]="['/tax', recent.tax.id, 'revision', recent.tax.revisionNumber]">
        <td class="cell-wrap">{{ recent.tax.type | taxType: taxTypes }}</td>
        <td
          class="cell-wrap"
          *ngIf="recent.tax.location.parent ? recent.tax.location.parent.name : recent.tax.location.name as jurisdictionName"
          [title]="jurisdictionName">
          {{ jurisdictionName }}
        </td>
        <td
          class="cell-wrap"
          *ngIf="recent.tax.location.parent ? recent.tax.location.name : '–' as subdivisionName"
          [title]="subdivisionName">
          {{ subdivisionName }}
        </td>
        <td>{{ recent.tax.effectiveFrom | date: 'MMM dd, yyyy' }} - {{ recent.tax.effectiveTo | date: 'MMM dd, yyyy' }}</td>
        <td>{{ recent.date | date: 'MMM dd, yyyy hh:mm a' }}</td>
      </tr>
    </tbody>

    <tbody *ngIf="!taxes.length">
      <tr>
        <td colspan="4" class="empty" style="text-align: center">There are no entries at this moment.</td>
      </tr>
    </tbody>
  </table>
</div>
