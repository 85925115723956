<div id="tax-info-layout">
  <div class="header">
    <div class="form-group">
      <input
        id="new-tax-type-name"
        name="newTaxTypeName"
        #newTaxTypeNameModel="ngModel"
        type="text"
        class="title form-control"
        [(ngModel)]="tax.name"
        required
        [minlength]="ValidationConstants.taxTypeMinLength"
        [maxlength]="ValidationConstants.taxTypeMaxLength"
        [pattern]="ValidationConstants.categoryTopicNamePattern"
        (focusout)="tax.name = tax.name.trim()"
        appUniqueName
        [taxTypeName]="tax.name"
        data-test="new tax type name" 
      />
        &nbsp;&nbsp;&nbsp;&nbsp;
        <!-- // Req 6 -->
        <ng-select
          id="new-tax-type-approvalLevel"
          #newTaxTypeApprovalModel="ngModel"
          style="width:80px"
          name="newTaxTypeApprovalLevel"
          [items]="newTaxTypeApprovalLevelList" 
           bindLabel="text" 
           bindValue="id" 
           [(ngModel)]="tax.approvalLevel"
           class="selection"
           [searchable]="false"
           required
        >
        </ng-select>
      <div class="invalid-feedback" *ngIf="newTaxTypeNameModel.invalid">
        <div class="invalid-feedback__message">
          <i class="fa fa-exclamation-triangle mr-1"></i>
          <ng-container *ngIf="newTaxTypeNameModel.errors.pattern && !newTaxTypeNameModel.errors.minlength">
            <span>{{ 'commons.validation.category-topic-pattern' | translate }}</span>
          </ng-container>
          <ng-container *ngIf="newTaxTypeNameModel.errors.minlength">
            <span>{{
              'commons.validation.minlength' | translate: { minlength: newTaxTypeNameModel.errors.minlength.requiredLength }
            }}</span>
          </ng-container>
          <ng-container *ngIf="newTaxTypeNameModel.errors.required">
            <span>{{ 'commons.validation.required' | translate }}</span>
          </ng-container>
          <ng-container *ngIf="newTaxTypeNameModel.errors.unique">
            <span>{{ 'commons.validation.unique-tax-type-name' | translate }}</span>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="button-group d-flex">
      <!--Cancel editing button-->
      <button class="btn btn-outline-secondary btn-icon mr-2" (click)="taxDataService.onClickCancelEditing()">
        <svg-icon class="cancel" [src]="'../../../../assets/images/icons/add.svg'"></svg-icon>
        <span>{{ 'tax.tax-rates.cancel' | translate }}</span>
      </button>

      <!--Submit changes button-->
      <button class="btn btn-primary" (click)="taxDataService.onClickDoneEditing()">
        {{ 'tax.tax-rates.submit' | translate }}
      </button>
    </div>
  </div>

  <hr />
</div>
