import { Injectable } from '@angular/core';

import { ngSelectConstants } from '@rar/commons/constants';
import { LocationChildType } from '@rar/model/data/enums/LocationChildType';
import { Role } from '@rar/model/data/enums/Role';
import { Location } from '@rar/model/data/location/Location';
import { User } from '@rar/model/data/user/User';
import { UserTaxPermission } from '@rar/model/data/user/UserTaxPermission';

@Injectable()
export class UserFormMapperService {
  mapFormToModel(userFormValue: any): Partial<User> {
    const isSuperAdmin = userFormValue.rolesAndPermissions.rolesAndPermissionsTable.some((row) => row.role === Role.SUPER_ADMIN);

    const model: Partial<User> = {
      email: userFormValue.personalInformation.email,
      name: userFormValue.personalInformation.fullName,
      isSuperAdmin: isSuperAdmin,
      userTaxPermissions: isSuperAdmin ? [] : this.mapFormToUserTaxPermissions(userFormValue.rolesAndPermissions.rolesAndPermissionsTable),
    };

    return model;
  }

  mapFormToUserTaxPermissions(
    formArray: { id?: number; role: number; taxType?: number; jurisdiction?: number; subdivisions?: number[] }[],
  ): UserTaxPermission[] {
    return formArray.flatMap((row): UserTaxPermission[] => {
      const permissions: UserTaxPermission[] = [];
      const isSuperAdmin = row.role === Role.SUPER_ADMIN;
      const includesAllSubdivisions =
        row.subdivisions?.includes(ngSelectConstants.allOptionValue) || row.role === Role.ADMIN ? true : false;
      const includesCountryLevel = !isSuperAdmin && (row.subdivisions?.includes(ngSelectConstants.countryLevelValue) ?? false);
      const isAllJurisdictions = isSuperAdmin || row.jurisdiction === ngSelectConstants.allOptionValue || !row.jurisdiction;
      if (isAllJurisdictions || includesAllSubdivisions || includesCountryLevel) {
        permissions.push({
          id: row.id ?? null,
          role: row.role,
          taxType: row.taxType === ngSelectConstants.allOptionValue ? null : row.taxType,
          location: isAllJurisdictions ? null : ({ id: row.jurisdiction } as Location),
          isCountryLevel: includesCountryLevel,
        });
      }
      if (!includesAllSubdivisions && row.subdivisions && row.subdivisions.length) {
        permissions.push(
          ...row.subdivisions
            .filter((subdivision) => subdivision !== ngSelectConstants.countryLevelValue)
            .map((subdivision) => ({
              id: null,
              role: row.role,
              taxType: row.taxType,
              location: { id: subdivision } as Location,
              isCountryLevel: false,
            })),
        );
      }
      return permissions;
    });
  }

  mapTaxTypeToForm(utp?: UserTaxPermission): number {
    if (!utp) {
      return undefined; // none selected
    }

    if (utp.taxType === null) {
      return ngSelectConstants.allOptionValue; // all selected
    }

    return utp.taxType;
  }

  mapJurisdictionToForm(utp?: UserTaxPermission): number {
    if (!utp) {
      return undefined; // none selected
    }

    if (!utp.location) {
      return ngSelectConstants.allOptionValue; // all selected
    }

    return utp.location.parent?.id || utp.location.id;
  }

  mapSubdivisionsToForm(utps?: UserTaxPermission[]): number[] {
    if (!utps || !utps.length) {
      return undefined; // none selected
    }

    const countryUtp = utps.find((utp) => !utp.location || !utp.location.parent);
    if (countryUtp && (!countryUtp.location || countryUtp.isCountryLevel === false)) {
      // all subdivisions selected
      return [ngSelectConstants.allOptionValue];
    }

    const subdivisions: number[] = [];
    if (utps.some((utp) => utp.isCountryLevel === true)) {
      subdivisions.push(ngSelectConstants.countryLevelValue); // country level selected
    }

    subdivisions.push(
      ...utps
        //  subdivisions excluding country level item
        .filter((utp) => utp.isCountryLevel === false && utp.location && !!utp.location.parent)
        // get subdivision location id
        .map((utp) => utp.location.id),
    );

    return subdivisions;
  }
}
