import { Component, OnInit } from "@angular/core";
import { Placement } from "@ng-bootstrap/ng-bootstrap/util/positioning";
import { TaxListing } from "../../../model/data/tax/TaxListing";
import { TaxFilterService } from "../../services/tax-filter/tax-filter.service";

/*Unused component*/
@Component({
	selector: "app-tax-filter",
	templateUrl: "./tax-filter.component.html",
	styleUrls: ["./tax-filter.component.scss"],
})
export class TaxFilterComponent implements OnInit {
	public filter: TaxListing = <any>{};

	public placement: Placement = "bottom";

	constructor(private filterService: TaxFilterService) {
		this.filter.location = <any>{};
		this.filter.approver = <any>{};
		this.filter.editor = <any>{};
	}

	ngOnInit() {}

	public onChange() {
		// this.filterService.updateFilter(this.filter);
	}
}
