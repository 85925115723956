import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { ValidationConstants } from '@rar/commons/constants';
import { ModalService } from '@rar/commons/services/modal.service';
import { ApiCommunicationService } from '@rar/model/services/api-communication/api-communication.service';

import { TaxTypesTableComponent } from '../../components/tax-types-table/tax-types-table.component';

@Component({
  selector: 'app-tax-type-listing-page',
  templateUrl: './tax-type-listing-page.component.html',
  styleUrls: ['./tax-type-listing-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaxTypeListingPageComponent implements OnInit, OnDestroy {
  @ViewChild(TaxTypesTableComponent, { static: true })
  public taxTypesTableComponent: TaxTypesTableComponent;

  @ViewChild('newTaxTypeForm', { static: false })
  public newTaxTypeForm: NgForm;

  public readonly ValidationConstants = ValidationConstants;

  public newTaxTypeName = '';
  // Req 6
  public newTaxTypeApprovalLevel = '';
  public newTaxTypeApprovalLevelList: { id: number; text: number }[];

  constructor(
    private modalService: ModalService,
    private translateService: TranslateService,
    private api: ApiCommunicationService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  public addNewTaxType(newTaxTypeDialogTemplate: TemplateRef<any>) {
    this.newTaxTypeName = '';
    // Req 6
    this.newTaxTypeApprovalLevel = '';
    // Req 6
    this.newTaxTypeApprovalLevelList = [
      {
        id: 2,
        text: 2,
      },
      {
        id: 3,
        text: 3,
      },
    ];
    this.modalService
      .openConfirmation({
        title: this.translateService.instant('tax-design.add-new-form.title'),
        yesButtonText: this.translateService.instant('tax-design.actions.save'),
        noButtonText: this.translateService.instant('commons.modal.cancel'),
        contentTemplate: newTaxTypeDialogTemplate,
        // req 6
        // validator: () => {
        //   this.newTaxTypeForm.form.markAllAsTouched();
        //   return this.newTaxTypeForm.valid;
        // },
      })
      .then(
        () => {
          // req 6
          if (this.newTaxTypeName.length < 5) {
            this.modalService.showInformation({
              title: 'Error creating tax type',
              content: 'Min length for Tax type is 5',
            });
            return of();
          }
          // req 6
          if (this.newTaxTypeApprovalLevel.length === 0) {
            this.modalService.showInformation({
              title: 'Error creating tax type',
              content: 'Approval level is mandatory',
            });
            return of();
          }
          this.api
            .tax()
            // Req 6
            .createTaxType(this.newTaxTypeName, Number(this.newTaxTypeApprovalLevel))
            .pipe(
              untilDestroyed(this),
              tap(() => {
                this.taxTypesTableComponent.updateTable();
              }),
              catchError(() => {
                this.modalService
                  .showInformation({
                    title: this.translateService.instant('tax-design.add-new-form.error-exists-title'),
                    content: this.translateService.instant('tax-design.add-new-form.error-exists-content').split('\n'),
                  })
                  .then();
                return of();
              }),
            )
            .subscribe();
        },
        () => {},
      );
  }
}
