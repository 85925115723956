<div id="tax-change-notes-layout">

	<div id="header">
		<h2>{{ "tax.tax-change-notes.title" | translate }}</h2>
	</div>

	<app-tax-change-notes-table [changeNotes]="changeNotes" [topics]="topics" *ngIf="changeNotes"></app-tax-change-notes-table>

	<app-empty-placeholder class="footer" *ngIf="!changeNotes"></app-empty-placeholder>

</div>
