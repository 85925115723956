<div id="main-search">
  <div id="hamburger">
    <button class="ui button" (click)="onClickHamburger()">
      <img [src]="'../../../../assets/images/icons/hamburger.svg'" />
    </button>
  </div>

  <div id="main" class="container">
    <form (ngSubmit)="onSubmitSearchForm()" #form="ngForm" id="search-form" class="row flex-nowrap justify-content-md-center">
      <div class="typehead-container col col-tax-type p-0">
        <input
          #tax
          id="taxSearch"
          name="taxSearch"
          type="text"
          class="form-control"
          [class.invalid]="!selectedTax"
          [(ngModel)]="selectedTax"
          [ngbTypeahead]="searchTax"
          [placeholder]="'commons.search.tax' | translate"
          [inputFormatter]="nameSelector"
          [resultFormatter]="nameSelector" />
        <!--remove-->
        <i [ngClass]="{ active: selectedTax }" class="fa fa-window-close remove" (click)="tax.value = ''; selectedTax = null"></i>
      </div>

      <div class="typehead-container col col-jurisdiction p-0">
        <input
          #jurisdiction
          id="jurisdictionSearch"
          name="jurisdictionSearch"
          type="text"
          class="form-control"
          [class.invalid]="!selectedJurisdiction"
          [(ngModel)]="selectedJurisdiction"
          [ngbTypeahead]="searchJurisdiction"
          [placeholder]="'commons.search.jurisdiction' | translate"
          [inputFormatter]="nameSelector"
          [resultFormatter]="nameSelector" />
        <!--remove-->
        <i
          [ngClass]="{ active: selectedJurisdiction }"
          class="fa fa-window-close remove"
          (click)="jurisdiction.value = ''; selectedJurisdiction = null">
        </i>
      </div>

      <div class="col col-date p-0">
        <div class="input-group">
          <input
            name="dateSearch"
            #dp="ngbDatepicker"
            ngbDatepicker
            autocomplete="off"
            class="form-control flex-grow-1 flex-shrink-1"
            [class.invalid]="!isValidDate()"
            [(ngModel)]="selectedDate"
            [placeholder]="'commons.search.today' | translate"
            [minDate]="minSearchDate"
            [maxDate]="maxSearchDate"
            placement="bottom" />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary btn-icon" (click)="dp.toggle()" [attr.aria-expanded]="dp.isOpen" type="button">
              <svg-icon svgClass="icon" src="/assets/images/icons/calendar.svg"></svg-icon>
            </button>
          </div>
        </div>
      </div>

      <button class="ui button col-auto px-4" type="submit">
        {{ 'commons.search.find' | translate }}
      </button>
    </form>
  </div>

  <div id="profile">
    <div ngbDropdown class="d-inline-block" placement="bottom-right">
      <button class="btn" id="userMenuDropdown" ngbDropdownToggle>
        <p class="d-inline" id="user-name">{{ user ? user.name : 'User' }}</p>
        <img class="icon caret" [src]="'../../../../assets/images/icons/arrow_down.svg'" />
      </button>
      <div ngbDropdownMenu aria-labelledby="userMenuDropdown">
        <a ngbDropdownItem [routerLink]="['user/profile']">{{ 'commons.search.profile.account' | translate }}</a>

        <a ngbDropdownItem (click)="onLogout()">{{ 'commons.search.profile.logout' | translate }}</a>
      </div>
    </div>
  </div>
</div>
