import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ValidationConstants } from '@rar/commons/constants';
import { Role } from '@rar/model/data/enums/Role';

import { ChangeNoteOccurrence } from '../../../model/data/enums/ChangeNoteOccurrence';
import { TaxDataService } from '../../services/tax-data/tax-data.service';

@Component({
  selector: 'app-tax-rates',
  templateUrl: './tax-rates.component.html',
  styleUrls: ['./tax-rates.component.scss'],
})
export class TaxRatesComponent implements OnInit {
  public topicId: number;

  constructor(private route: ActivatedRoute, public taxDataService: TaxDataService) {}

  role = Role;

  validationConstants = ValidationConstants;

  ngOnInit() {
    this.topicId = +this.route.snapshot.params['id'];
  }

  public isRateChanged(shortCode: string): boolean {
    return this.taxDataService.isObjectChanged(shortCode, ChangeNoteOccurrence.VALUE);
  }

  public getRateDiff(shortCode: string): any {
    return this.taxDataService.getChangeNoteObject(shortCode, ChangeNoteOccurrence.VALUE)[0][ChangeNoteOccurrence.VALUE];
  }
}
