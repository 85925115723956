import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { TaxRevision } from '../../../model/data/tax/TaxRevision';
import { User } from '../../../model/data/user/User';

interface ITaxMultiEditModalContext {
	tax: TaxRevision;
	editors: Array<User>;
}

@Component({
	selector: 'app-tax-multi-edit-modal',
	templateUrl: './tax-multi-edit-modal.component.html',
	styleUrls: ['./tax-multi-edit-modal.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaxMultiEditModalComponent {
	constructor(private activeModal: NgbActiveModal) { }

	@Input()
	context: ITaxMultiEditModalContext;

	approve(): void {
		this.activeModal.close(null);
	}

	deny(): void {
		this.activeModal.dismiss(null);
	}

	public get editors(): string {
		return this.context.editors.map((e) => e.name).join(', ');
	}
}
