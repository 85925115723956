import { Inject, Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { uniq } from 'lodash-es';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, shareReplay, startWith, switchMap } from 'rxjs/operators';

import { ngSelectConstants } from '@rar/commons/constants';
import { Role } from '@rar/model/data/enums/Role';
import { Location } from '@rar/model/data/location/Location';
import { LocationWithTaxTypes } from '@rar/model/data/location/LocationWithTaxTypes';
import { TaxType } from '@rar/model/data/tax/TaxType';
import { User } from '@rar/model/data/user/User';
import { ApiCommunicationService } from '@rar/model/services/api-communication/api-communication.service';

import { UserSessionService } from '../user-session/user-session.service';

@Injectable()
export class UserFormDataService {
  constructor(
    @Inject(ApiCommunicationService) private api: ApiCommunicationService,
    private userSessionService: UserSessionService,
    private translateService: TranslateService,
  ) {
    this.initObservables();
  }

  taxTypes$: Observable<TaxType[]>;
  roles$: Observable<{ id: Role; name: string }[]>;
  jurisdictions$: Observable<LocationWithTaxTypes[]>;
  isCommonDataLoading$: Observable<boolean>;

  private initObservables(): void {
    this.taxTypes$ = this.getTaxTypes();
    this.roles$ = this.getRoles();
    this.jurisdictions$ = this.getJurisdictions();

    this.isCommonDataLoading$ = combineLatest([this.taxTypes$, this.roles$, this.jurisdictions$]).pipe(
      filter(([taxTypes, roles, jurisdictions]) => !!taxTypes && !!roles && !!jurisdictions),
      map(() => false),
      startWith(true),
    );
  }

  calculateReadOnlyMode(user: User): boolean {
    let userTaxTypes = uniq(
      user.userTaxPermissions.map((utp) => {
        return { taxType: utp.taxType, locationId: utp.location ? utp.location?.id : null, canEdit: false };
      }),
    );

    const currentUserAdminTaxTypes = uniq(
      this.userSessionService.userDataValue.userTaxPermissions
        .filter((utp) => utp.role === Role.ADMIN)
        .map((utp) => {
          return { taxType: utp.taxType, locationId: utp.location ? utp.location?.id : null };
        }),
    );

    userTaxTypes = userTaxTypes.map((utt) => {
      return {
        ...utt,
        canEdit: !!currentUserAdminTaxTypes.find(
          (p) => (p.taxType === utt.taxType && !p.locationId) || (p.taxType === utt.taxType && p.locationId === utt.locationId),
        ),
      };
    });

    return this.userSessionService.userDataValue.isSuperAdmin === false && userTaxTypes.every((utt) => utt.canEdit) === false;
  }

  getTaxTypeForForm(roleSelected$: Observable<Role>, readOnly: boolean = false) {
    return combineLatest([roleSelected$, this.userSessionService.userData]).pipe(
      switchMap(([roleSelected, userData]) =>
        this.taxTypes$.pipe(
          map((taxTypes) => {
            const taxTypesForUser =
              userData.isSuperAdmin || readOnly
                ? taxTypes
                : taxTypes.filter((tt) => userData.userTaxPermissions.some((utp) => utp.taxType === tt.id && utp.role === Role.ADMIN));

            if (roleSelected === Role.SUPER_ADMIN) {
              return [
                {
                  id: ngSelectConstants.allOptionValue,
                  name: this.translateService.instant('user.form.all-option'),
                  // Req 6
                  approvalLevel: this.translateService.instant('user.form.all-option'),
                },
                ...taxTypesForUser,
              ];
            }

            return taxTypesForUser;
          }),
        ),
      ),
    );
  }

  getJurisdictionsForForm(taxType$: Observable<number>, readOnly: boolean = false): Observable<Location[]> {
    return combineLatest([taxType$, this.userSessionService.userData]).pipe(
      switchMap(([taxType, userData]) =>
        this.jurisdictions$.pipe(
          map((jurisdictions) => {
            const adminPermittedLocationsForTaxType = [
              ...userData.userTaxPermissions
                .filter((utp) => utp.role === Role.ADMIN && utp.taxType === taxType)
                .map((utp) => (utp.location ? utp.location.id : null)),
            ];

            if (readOnly || userData.isSuperAdmin || adminPermittedLocationsForTaxType.includes(null)) {
              return jurisdictions.filter((j) => j.taxTypes?.includes(taxType) || j.id === ngSelectConstants.allOptionValue);
            } else {
              return jurisdictions.filter((j) => j.taxTypes?.includes(taxType) && adminPermittedLocationsForTaxType.includes(j.id));
            }
          }),
        ),
      ),
    );
  }

  getSubdivisionsForForm(
    jurisdictions$: Observable<Location[]>,
    selectedJurisdiction$: Observable<number>,
    selectedTaxType$: Observable<number>,
  ) {
    return combineLatest([jurisdictions$, selectedJurisdiction$, selectedTaxType$]).pipe(
      map(([jurisdictions, selectedJurisdiction, selectedTaxType]) => {
        if (!jurisdictions || jurisdictions.length === 0) {
          return [];
        }
        const allSubdivisionsLocation = {
          id: ngSelectConstants.allOptionValue,
          name: this.translateService.instant('user.form.all-subdivisions'),
        } as Location;

        if (selectedJurisdiction === ngSelectConstants.allOptionValue) {
          return [allSubdivisionsLocation];
        }

        const locations = [
          ...(jurisdictions.find((j) => j.id === selectedJurisdiction)?.children ?? []).filter((location) =>
            location['taxTypes'].includes(selectedTaxType),
          ),
        ];
        locations.unshift(allSubdivisionsLocation, {
          id: ngSelectConstants.countryLevelValue,
          name: this.translateService.instant('user.form.country-level'),
        } as Location);

        return locations;
      }),
    );
  }

  private getTaxTypes(): Observable<TaxType[]> {
    return this.api.tax().getAllTaxTypes().pipe(shareReplay(1));
  }

  private getRoles() {
    return this.userSessionService.userData.pipe(
      filter((userData) => !!userData),
      map((userData) => {
        const roles = [
          { id: Role.ADMIN, name: this.translateService.instant('commons.roles.admin') },
          { id: Role.APPROVER, name: this.translateService.instant('commons.roles.approver') },
          { id: Role.EDITOR, name: this.translateService.instant('commons.roles.editor') },
          { id: Role.VIEWER, name: this.translateService.instant('commons.roles.viewer') },
        ];

        if (userData.isSuperAdmin) {
          roles.unshift({ id: Role.SUPER_ADMIN, name: this.translateService.instant('commons.roles.superadmin') });
        }
        return roles;
      }),
    );
  }

  private getJurisdictions(): Observable<LocationWithTaxTypes[]> {
    return this.api
      .location()
      .getCountriesTree()
      .pipe(
        map((locations) => {
          locations.unshift({
            id: ngSelectConstants.allOptionValue,
            name: this.translateService.instant('user.form.all-option'),
          } as LocationWithTaxTypes);
          return locations;
        }),
        shareReplay(1),
      );
  }
}
