import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';

import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';

import { TaxType } from '@rar/model/data/tax/TaxType';
import { ApiCommunicationService } from '@rar/model/services/api-communication/api-communication.service';

import { TaxRecentViews } from '../../../model/data/dashboard/TaxRecentViews';
import { DashboardLoaderService } from '../../services/dashboard-loader.service';

@Component({
  selector: 'app-dashboard-recently-viewed-table',
  templateUrl: './dashboard-recently-viewed-table.component.html',
  styleUrls: ['./dashboard-recently-viewed-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardRecentlyViewedTableComponent implements OnDestroy {
  @Input('recentViews')
  public taxes: Array<TaxRecentViews>;

  public readonly taxTypes$: Observable<TaxType[]>;

  constructor(private dashboardService: DashboardLoaderService, private api: ApiCommunicationService) {
    this.taxTypes$ = this.api.tax().getTaxTypes().pipe(untilDestroyed(this));
  }

  public ngOnDestroy() {}

  public onClick() {
    this.dashboardService.load.emit();
  }
}
