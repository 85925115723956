import { Observable } from "rxjs";
import { TopicComment } from "../data/tax/TopicComment";
import { ValidationError } from "../exception/ValidationError";
import { ApproveTopicCommentsRequest } from "../request/TopicComment/ApproveTopicCommentsRequest";
import { TopicCommentCreateRequest } from "../request/TopicComment/TopicCommentCreateRequest";
import { AbstractApiConnector } from "./AbstractApiConnector";

export class TopicCommentApiConnector extends AbstractApiConnector {
    protected apiRoute: string = '/api/v1/topicComment';

    public createTopicComment(data: TopicCommentCreateRequest): Observable<any> {
        return this.apiClient.post(this.apiRoute, data);
    }

    public getTopicComment(taxId: number): Observable<Array<TopicComment>> {
        if (typeof taxId !== 'number') {
            throw new ValidationError();
          } else {
            return this.apiClient.get(this.apiRoute + '/' + taxId);
          }
    }

    public approveTopicComments(data: ApproveTopicCommentsRequest): Observable<any> {
      return this.apiClient.post(this.apiRoute + "/approve", data);
  }
}