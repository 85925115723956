<div class="modal-header">
	<h4 class="modal-title">Are you sure you want to close this revision?</h4>
</div>

<div class="modal-body">This action will discard all changes.</div>

<div class="modal-footer">
	<div></div>

	<div class="button-group">
		<!--Deny-->
		<button class="ui basic button silver" (click)="deny()">
			{{ "user.user-edit-modal.cancel" | translate }}
		</button>

		<!--Approve-->
		<button class="ui basic button rose" (click)="approve()">Yes</button>
	</div>
</div>
