<div id="tax-topic-layout">
  <ng-container *ngIf="topic && topic.parent ? topic.parent : topic as categoryTopic">
    <div id="header">
      <ng-container *ifHasRole="[Role.ADMIN, Role.SUPER_ADMIN]; requestedTax: taxDataService.getTax(); else: staticCategoryName">
        <ng-container *ngIf="taxDataService.isEditingMode; else staticCategoryName">
          <ng-container *ngIf="taxDataService.getTaxTopic(taxDataService.taxCloneForEditing, categoryTopic.id) as categoryForEdit">
            <input
              #categoryName="ngModel"
              type="text"
              class="title form-control"
              [(ngModel)]="categoryForEdit.name"
              required
              [minlength]="ValidationConstants.categoryMinLength"
              [maxlength]="ValidationConstants.categoryMaxLength"
              [pattern]="ValidationConstants.categoryTopicNamePattern"
              data-test="category heading" />
            <div class="short-code">({{ categoryForEdit.shortCode }})</div>
            <div class="invalid-feedback" *ngIf="categoryName.invalid">
              <div class="invalid-feedback__message">
                <i class="fa fa-exclamation-triangle mr-1"></i>
                <ng-container *ngIf="categoryName.errors.pattern && !categoryName.errors.minlength">
                  <span>{{ 'commons.validation.category-topic-pattern' | translate }}</span>
                </ng-container>
                <ng-container *ngIf="categoryName.errors.minlength">
                  <span>{{ 'commons.validation.minlength' | translate: { minlength: categoryName.errors.minlength.requiredLength } }}</span>
                </ng-container>
                <ng-container *ngIf="categoryName.errors.required">
                  <span>{{ 'commons.validation.required' | translate }}</span>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-template #staticCategoryName>
        <h2 class="title flex-flow" [title]="categoryTopic.name">
          <span class="text-truncate">{{ categoryTopic.name }}</span>
          <span *ngIf="taxDataService.isEditingMode || taxDataService.isApprovingMode">({{ categoryTopic.shortCode }})</span>
        </h2>
      </ng-template>
      <ng-container *ifHasRole="[Role.ADMIN, Role.SUPER_ADMIN]; requestedTax: taxDataService.getTax()">
        <button *ngIf="taxDataService.isEditingMode" class="btn btn-primary btn-icon" (click)="deleteCategory(categoryTopic.id)">
          <svg-icon class="icon mr-2" src="../../../../assets/images/icons/trash.svg"></svg-icon>
          {{ 'tax.tax-topics.category-delete' | translate }}
        </button>
      </ng-container>
    </div>

    <ngb-accordion
      *ngIf="categoryTopic.subTopics && categoryTopic.subTopics.length"
      #accordion="ngbAccordion"
      class="tax-view"
      [closeOthers]="true"
      [activeIds]="activeTopicPanelId"
      (panelChange)="beforeChange($event)">
      <ngb-panel
        [cardClass]="accordion.isExpanded('topic-' + currentTopic.id) ? 'active' : ''"
        *ngFor="let currentTopic of sortTopics(categoryTopic.subTopics); let i = index; last as last; first as first"
        id="topic-{{ currentTopic.id }}">
        <ng-template ngbPanelTitle>
          <div class="header-container" (click)="selectTopic(currentTopic)">
            <div class="header">
              <ng-container *ifHasRole="[Role.ADMIN, Role.SUPER_ADMIN]; requestedTax: taxDataService.getTax(); else: staticTopicName">
                <ng-container *ngIf="taxDataService.isEditingMode; else staticTopicName">
                  <ng-container *ngIf="taxDataService.getTaxTopic(taxDataService.taxCloneForEditing, currentTopic.id) as topicForEdit">
                    <input
                      #topicName="ngModel"
                      type="text"
                      class="title form-control"
                      [class.show-feedback]="topicName.invalid && (topicForEdit.id > 0 || topicName.touched)"
                      [(ngModel)]="topicForEdit.name"
                      required
                      [minlength]="ValidationConstants.topicMinLength"
                      [maxlength]="ValidationConstants.topicMaxLength"
                      [pattern]="ValidationConstants.categoryTopicNamePattern"
                      data-test="topic heading" />
                    <input type="hidden" name="topicOrder" [value]="currentTopic?.order" />
                    <div class="short-code">({{ topicForEdit.shortCode }})</div>
                    <button
                      class="btn btn-sm btn-outline-primary btn-icon arrow-button mr-2"
                      (click)="changeTopicOrder($event, i, true)"
                      *ngIf="!first">
                      <svg-icon class="icon arrow-up" src="../../../../assets/images/icons/arrow-down.svg"></svg-icon>
                    </button>
                    <button
                      class="btn btn-sm btn-outline-primary btn-icon arrow-button mr-2"
                      (click)="changeTopicOrder($event, i, false)"
                      *ngIf="!last">
                      <svg-icon class="icon" src="../../../../assets/images/icons/arrow-down.svg"></svg-icon>
                    </button>
                    <button class="btn btn-primary btn-icon" (click)="deleteTopic(i)">
                      <svg-icon class="icon" src="../../../../assets/images/icons/trash.svg"></svg-icon>
                      <span>{{ 'tax.tax-topics.topic-delete' | translate }}</span>
                    </button>
                    <div class="invalid-feedback" *ngIf="topicName.invalid && (topicForEdit.id > 0 || topicName.touched)">
                      <div class="invalid-feedback__message">
                        <i class="fa fa-exclamation-triangle mr-1"></i>
                        <ng-container *ngIf="topicName.errors.pattern && !topicName.errors.minlength">
                          <span>{{ 'commons.validation.category-topic-pattern' | translate }}</span>
                        </ng-container>
                        <ng-container *ngIf="topicName.errors.minlength">
                          <span>{{
                            'commons.validation.minlength' | translate: { minlength: topicName.errors.minlength.requiredLength }
                          }}</span>
                        </ng-container>
                        <ng-container *ngIf="topicName.errors.required">
                          <span>{{ 'commons.validation.required' | translate }}</span>
                        </ng-container>
                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </ng-container>
              <ng-template #staticTopicName>
                <div class="topic-title flex-flow" [title]="currentTopic.name">
                  <span class="text-truncate">{{ currentTopic.name }}</span>
                  <span *ngIf="taxDataService.isEditingMode || taxDataService.isApprovingMode">({{ currentTopic.shortCode }})</span>
                </div>
              </ng-template>
            </div>
            <app-tax-topic-comment-bar
              *ngIf="
                taxDataService.getTax().status !== getStatusPendingLocal() && taxDataService.getTax().status !== getStatusPendingGlobal()
              "
              [taxId]="taxId"
              [topic]="currentTopic"
              [topicComments]="getCommentsForTopic(currentTopic.shortCode)"></app-tax-topic-comment-bar>

            <div class="counter" *ngIf="!hasJsonRuleType(currentTopic)">
              <span i18n>
                { getTopicCounter(currentTopic), plural, =1 { 1 {{ 'tax.tax-topics.rule-singular' | translate }} } other {
                {{ getTopicCounter(currentTopic) }} {{ 'tax.tax-topics.rule-plural' | translate }} } }
              </span>
            </div>

            <svg-icon class="icon indicator" src="/assets/images/icons/arrow-fill.svg"></svg-icon>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-container *ngIf="topic.rules && topic.rules.length > 0 ? topic.rules[0].articleTypeId : 1 as articleType">
            <ng-container *ngIf="taxDataService.isEditingMode">
              <ng-container *ifHasRole="[Role.ADMIN, Role.SUPER_ADMIN]; requestedTax: taxDataService.getTax()">
                <!-- Req 9 -eracode & SRT scan fixes - Angular 12 migration -  [articleType]="articleType"  -->
                <app-tax-topic-type-selector
                  [articleType]="articleType" 
                  [isDisabled]="(topic.rules && topic.rules.length > 0) || (topic.rates && topic.rates.length > 0)"
                  (articleTypeChange)="onArticleTypeChange($event)"></app-tax-topic-type-selector>

                <app-tax-table-column-container
                  *ngIf="taxDataService.isEditingMode && articleType === ArticleType.JSON"
                  [topic]="topic"
                  (updateArticlesList)="onUpdateArticlesList($event)"></app-tax-table-column-container>
              </ng-container>
            </ng-container>
            <app-tax-rates></app-tax-rates>
            <app-tax-rules [articleType]="articleType"></app-tax-rules>

            <ng-container *ngIf="taxDataService.isEditingMode && articleType === ArticleType.JSON">
              <div
                class="d-flex justify-content-end"
                *ifHasRole="[Role.EDITOR, Role.APPROVER, Role.ADMIN, Role.SUPER_ADMIN]; requestedTax: taxDataService.getTax()">
                <button class="btn btn-sm btn-primary btn-icon" [disabled]="!taxDataService.editColumns?.length" (click)="onAddNewRow()">
                  <svg-icon class="icon" src="../../../../assets/images/icons/add2.svg"></svg-icon>
                  <span>{{ 'tax.tax-topics.add-new-row' | translate }}</span>
                </button>
              </div>
            </ng-container>
          </ng-container>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </ng-container>

  <ng-container *ifHasRole="[Role.ADMIN, Role.SUPER_ADMIN]; requestedTax: taxDataService.getTax()">
    <div class="flex-flow justify-content-end" *ngIf="taxDataService.isEditingMode">
      <!--Add new topic button-->
      <button class="btn btn-primary btn-icon" (click)="addNewTopic()">
        <svg-icon class="icon" src="../../../../assets/images/icons/add2.svg"></svg-icon>
        <span>{{ 'tax.tax-topics.topic-add' | translate }}</span>
      </button>
    </div>
  </ng-container>
</div>
