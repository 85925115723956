import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TopicComment } from '@rar/model/data/tax/TopicComment';

interface ITaxTopicCommentConfirmChangesModalContext {
	shortCode: string;
	approvedComments: Array<number>;
  }

@Component({
  selector: 'app-tax-topic-comment-confirm-changes-modal',
  templateUrl: './tax-topic-comment-confirm-changes-modal.component.html',
  styleUrls: ['./tax-topic-comment-confirm-changes-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaxTopicCommentConfirmChangesModalComponent {

	constructor(private activeModal: NgbActiveModal) { }

	@Input()
	context: ITaxTopicCommentConfirmChangesModalContext;
	
  	approve(): void {
		const event = {
			shortCode: this.context.shortCode,
			commentIds: this.context.approvedComments,
			isConfirmed: true
		};
	  
		this.activeModal.close(event);
	}

	cancel(): void {
		const event = {
			shortCode: this.context.shortCode,
			commentIds: this.context.approvedComments,
			isConfirmed: false
		};

		this.activeModal.close(event);
	}
}
