<div class="d-flex flex-column">
  <div class="d-flex">
    <input
      type="text"
      class="form-control"
      [(ngModel)]="columnName"
      (change)="onValueChange($event)"
     />

    <button class="btn btn-sm btn-icon btn-outline-primary btn-delete"
            (click)="onDeleteColumn()">
      <svg-icon [src]="'/assets/images/icons/trash.svg'"></svg-icon>
    </button>
  </div>

  <div class="invalid-feedback d-flex flex-start" *ngIf="!columnName || !columnName.trim()">
    <div class="invalid-feedback__message">
      <i class="fa fa-exclamation-triangle mr-1"></i>
      {{ 'tax.tax-topics.column-required' | translate }}
    </div>
  </div>
</div>
