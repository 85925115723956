import { Component, Input } from "@angular/core";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TaxRevision } from '@rar/model/data/tax/TaxRevision';

interface ITaxAssessmentModalContext {
	tax: TaxRevision;
}

@Component({
	selector: "app-assessment-approve-modal",
	templateUrl: "./tax-assessment-modal.component.html",
	styleUrls: ["./tax-assessment-modal.component.scss"]
})
export class TaxAssessmentModalComponent {

	constructor(private activeModal: NgbActiveModal) { }

	@Input()
	context: ITaxAssessmentModalContext;

	approve(): void {
		this.activeModal.close(null);
	}

	deny(): void {
		this.activeModal.dismiss(null);
	}

}
