<div id="audit-trail-page-layout">

	<div id="header" class="padding-bottom">
		<h1>{{ "audit.title" | translate }}</h1>

		<!--Filter-->
		<app-audit-filter [users]="users | async" [taxes]="taxes | async"></app-audit-filter>

	</div>

	<!--Display-->
	<app-audit-display></app-audit-display>

</div>
