import {Pipe, PipeTransform} from '@angular/core';
import {TaxType} from '@rar/model/data/tax/TaxType';

@Pipe({
  name: 'taxType',
})
export class TaxTypePipe implements PipeTransform {

  transform(value: number, taxes?: TaxType[]): string {
    return taxes && taxes.length ? taxes.find((type) => type.id === value).name : '';
  }
}
