export const ValidationConstants = {
  taxTypeMinLength: 5,
  taxTypeMaxLength: 50,
  categoryMinLength: 3,
  categoryMaxLength: 1200,
  topicMinLength: 3,
  topicMaxLength: 1200,
  ruleMinLength: 3,
  ruleMaxLength: 4000,
  categoryTopicNamePattern: /^[A-Za-z\u00C0-\u00F6\u00F8-\u02AF]/,
} as const;
