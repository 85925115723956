import { ChangeDetectorRef, Injectable, OnDestroy } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { pairwise, startWith } from 'rxjs/operators';

import { ngSelectConstants } from '@rar/commons/constants';
import { UserManagementApiConnector } from '@rar/model/connectors/UserManagementApiConnector';
import { Role } from '@rar/model/data/enums/Role';
import { User } from '@rar/model/data/user/User';
import { UserTaxPermission } from '@rar/model/data/user/UserTaxPermission';
import { DataSourcesRowForm } from '@rar/user/models/data-sources-row.interface';
import { UserValidators } from '@rar/user/validators/UserValidators';

import { UserSessionService } from '../user-session/user-session.service';
import { UserFormDataService } from './user-form-data.service';
import { UserFormMapperService } from './user-form-mapper.service';

@Injectable()
export class UserFormFieldsService implements OnDestroy {
  constructor(
    private userFormMapperService: UserFormMapperService,
    private userFormDataService: UserFormDataService,
    private formBuilder: UntypedFormBuilder,
    private userSessionService: UserSessionService,
  ) {}

  private userForm: UntypedFormGroup;
  private userFormDisabled = false;

  private rolesAndPermissionsTable: UntypedFormArray;

  private dataSourcesPerRow: DataSourcesRowForm[] = [];

  ngOnDestroy() {}

  get rolesAndPermissionsFormArray(): UntypedFormArray {
    return this.rolesAndPermissionsTable;
  }

  get personalInformation(): UntypedFormGroup {
    return this.userForm.get('personalInformation') as UntypedFormGroup;
  }

  get userFormGroup(): UntypedFormGroup {
    return this.userForm;
  }

  get isUserFormDisabled(): boolean {
    return this.userFormDisabled;
  }

  get emailValueChanged$(): Observable<string> {
    return this.personalInformation.get('email').valueChanges;
  }

  getDataSourcesForRow(index: number): DataSourcesRowForm {
    return this.dataSourcesPerRow && this.dataSourcesPerRow[index] ? this.dataSourcesPerRow[index] : null;
  }

  initForm(disabled: boolean): void {
    if (disabled) {
      this.userFormDisabled = disabled;
    }

    const personalInformation = new UntypedFormGroup({
      fullName: new UntypedFormControl('', [Validators.required, Validators.maxLength(100)]),
      email: new UntypedFormControl('', [Validators.required, Validators.maxLength(254), Validators.email]),
    });

    this.rolesAndPermissionsTable = this.formBuilder.array([]);

    const rolesAndPermissions = new UntypedFormGroup({
      rolesAndPermissionsTable: this.rolesAndPermissionsTable,
    });

    this.userForm = new UntypedFormGroup({
      personalInformation: personalInformation,
      rolesAndPermissions: rolesAndPermissions,
    });

    if (this.userFormDisabled) {
      this.personalInformation.disable();
    }
  }

  addUniqueEmailValidator(api: UserManagementApiConnector, changeDetector: ChangeDetectorRef) {
    this.personalInformation.get('email').setAsyncValidators([UserValidators.uniqueEmail(api, changeDetector)]);
    this.personalInformation.get('email').updateValueAndValidity();
  }

  removeUniqueEmailValidator() {
    this.personalInformation.get('email').clearAsyncValidators();
    this.personalInformation.get('email').updateValueAndValidity();
  }

  initializeFormValue(user: User): void {
    if (user) {
      this.personalInformation.patchValue(
        {
          fullName: user.name,
          email: user.email,
        },
        { emitEvent: false, onlySelf: true },
      );
    }

    if (user && user.isSuperAdmin) {
      this.addPermissionRow([{ role: Role.SUPER_ADMIN } as UserTaxPermission]);
    } else if (user && user.userTaxPermissions && user.userTaxPermissions.length) {
      this.addGroupedPermissionRows(user.userTaxPermissions);
    } else {
      this.addPermissionRow();
    }
  }

  private addGroupedPermissionRows(userTaxPermissions: UserTaxPermission[]) {
    const groupedUtps: UserTaxPermission[][] = [];
    userTaxPermissions.forEach((utp) => {
      const locationId: number | undefined = utp.location?.id;
      if (!utp.taxType || !locationId) {
        // no tax type or location - row with "All tax types" or "All jurisdictions"
        groupedUtps.push([utp]);
        return;
      }
      // find the country ID for this utp
      const jurisdictionId = utp.location.parent?.id ?? utp.location.id;
      // check if it's already in the group list
      const groupedUtp = groupedUtps.find(
        (g) =>
          g[0].role === utp.role &&
          g[0].taxType === utp.taxType &&
          (g[0].location?.id === jurisdictionId || g[0].location?.parent?.id === jurisdictionId),
      );
      if (!groupedUtp) {
        groupedUtps.push([utp]);
      } else {
        if (utp.location.id !== jurisdictionId) {
          // we found a subdivision
          groupedUtp.push(utp);
        } else if (utp.isCountryLevel) {
          // we found country level selection
          groupedUtp.unshift(utp);
        }
      }
    });
    groupedUtps.forEach((utps) => {
      this.addPermissionRow(utps);
    });
  }

  addPermissionRow(utps: UserTaxPermission[] = []): void {
    const utp = utps && utps.length ? utps[0] : undefined;
    const roleField = new UntypedFormControl(utp?.role, [Validators.required]);
    const taxTypeField = new UntypedFormControl(this.userFormMapperService.mapTaxTypeToForm(utp), [Validators.required]);
    const jurisdictionField = new UntypedFormControl({ value: this.userFormMapperService.mapJurisdictionToForm(utp), disabled: !utp }, [
      Validators.required,
    ]);
    const subdivisionField = new UntypedFormControl({ value: this.userFormMapperService.mapSubdivisionsToForm(utps), disabled: !utp }, [
      Validators.required,
    ]);

    const rowForm = new UntypedFormGroup({
      id: new UntypedFormControl(utp?.id),
      role: roleField,
      taxType: taxTypeField,
      jurisdiction: jurisdictionField,
      subdivisions: subdivisionField,
    });

    subdivisionField.valueChanges
      .pipe(startWith(subdivisionField.value), pairwise(), untilDestroyed(this))
      .subscribe(([previousSubdivisions, currentSubdivisions]: [number[], number[]]) => {
        // if selected "all", selecting other item will unselect "all"
        if (previousSubdivisions?.includes(ngSelectConstants.allOptionValue) && currentSubdivisions?.length > 1) {
          subdivisionField.patchValue(currentSubdivisions.filter((subdivision) => subdivision !== ngSelectConstants.allOptionValue));
        }

        // when selecting "all", other items will be unselected
        if (
          previousSubdivisions?.includes(ngSelectConstants.allOptionValue) === false &&
          currentSubdivisions?.includes(ngSelectConstants.allOptionValue)
        ) {
          subdivisionField.patchValue([ngSelectConstants.allOptionValue]);
        }
      });

    jurisdictionField.valueChanges
      .pipe(startWith(jurisdictionField.value), pairwise(), untilDestroyed(this))
      .subscribe(([previousJurisdiction, currentJurisdiction]: [number, number]) => {
        if (previousJurisdiction === currentJurisdiction) {
          return;
        }

        // if jurisdiction is set to all, subdivision must also be set to all
        if (!currentJurisdiction || currentJurisdiction === ngSelectConstants.allOptionValue) {
          subdivisionField.patchValue([ngSelectConstants.allOptionValue]);
          subdivisionField.disable({ emitEvent: false });
        } else if (roleField.value === Role.ADMIN) {
          subdivisionField.patchValue([ngSelectConstants.allOptionValue]);
          subdivisionField.disable({ emitEvent: false });
        } else {
          subdivisionField.enable({ emitEvent: false });
        }

        // if jurisdiction field is changed, the default subdivision value is Country level
        if (!!currentJurisdiction && currentJurisdiction !== ngSelectConstants.allOptionValue && roleField.value !== Role.ADMIN) {
          subdivisionField.patchValue([ngSelectConstants.countryLevelValue]);
        }
      });

    taxTypeField.valueChanges.pipe(untilDestroyed(this)).subscribe((taxType) => {
      if (!taxType || roleField.value === Role.SUPER_ADMIN) {
        jurisdictionField.disable({ emitEvent: false });
      } else if (taxType !== ngSelectConstants.allOptionValue) {
        if (roleField.value === Role.ADMIN) {
          jurisdictionField.patchValue(ngSelectConstants.allOptionValue);
        }
        jurisdictionField.enable({ emitEvent: false });
      }
      if (jurisdictionField.value !== ngSelectConstants.allOptionValue) {
        jurisdictionField.reset();
        subdivisionField.reset();
      }
    });

    roleField.valueChanges.pipe(startWith(roleField.value), untilDestroyed(this)).subscribe((role) => {
      // Admin and Super Admin will have jurisdiction and subdivisions selected to All and disabled
      if (role === Role.SUPER_ADMIN) {
        jurisdictionField.patchValue(ngSelectConstants.allOptionValue);
        jurisdictionField.disable({ emitEvent: false });
        subdivisionField.disable({ emitEvent: false });
      } else if (role === Role.ADMIN) {
        if (jurisdictionField.value !== ngSelectConstants.allOptionValue) {
          jurisdictionField.patchValue(jurisdictionField.value);
        }
        jurisdictionField.enable({ emitEvent: false });
        subdivisionField.disable({ emitEvent: false });
      } else {
        jurisdictionField.enable({ emitEvent: false });
        if (jurisdictionField.value === ngSelectConstants.allOptionValue) {
          subdivisionField.disable({ emitEvent: false });
        } else {
          subdivisionField.enable({ emitEvent: false });
        }
      }

      // Super admin will have also selected Tax Type and disabled
      if (role === Role.SUPER_ADMIN) {
        taxTypeField.patchValue(ngSelectConstants.allOptionValue);
        taxTypeField.disable({ emitEvent: false });
      } else {
        taxTypeField.enable({ emitEvent: false });
        if (taxTypeField.value === ngSelectConstants.allOptionValue) {
          taxTypeField.reset();
          jurisdictionField.disable({ emitEvent: false });
        }
      }
    });

    const taxTypes$ = this.userFormDataService.getTaxTypeForForm(
      roleField.valueChanges.pipe(startWith(roleField.value)),
      this.isUserFormDisabled,
    );
    const jurisdictions$ = this.userFormDataService.getJurisdictionsForForm(
      taxTypeField.valueChanges.pipe(startWith(taxTypeField.value)),
      this.isUserFormDisabled,
    );
    const subdivisions$ = this.userFormDataService.getSubdivisionsForForm(
      jurisdictions$,
      jurisdictionField.valueChanges.pipe(startWith(jurisdictionField.value)),
      taxTypeField.valueChanges.pipe(startWith(taxTypeField.value)),
    );

    this.dataSourcesPerRow.push({
      taxTypes$,
      jurisdictions$,
      subdivisions$,
    });

    if (this.userFormDisabled) {
      rowForm.disable({ emitEvent: false });
    }

    this.rolesAndPermissionsTable.push(rowForm);
  }

  deletePermissionRow(index: number): void {
    this.rolesAndPermissionsFormArray.removeAt(index);
    this.dataSourcesPerRow.splice(index, 1);
  }
}
