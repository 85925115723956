<div class="modal-header">
	<h4 class="modal-title">{{ "tax.tax-events.modal.title" | translate }}</h4>
</div>

<div class="modal-body">
	<!--settings panel-->
	<div class="row nopad-top">
		<div class="column flex-row">
			<!--event date-->
			<div class="ui input">
				<input
					[(ngModel)]="actualEventName"
					(ngModelChange)="updateValues()"
					required
					minlength="3"
					maxlength="255"
					placeholder="Event name"
				/>
			</div>

			<!--starting from-->
			<p class="disabled-info">
				{{ "tax.tax-schedule.confirm-set-date" | translate }}
			</p>

			<!--event date-->
			<div class="ui input">
				<div class="input-group">
					<input
						#dp="ngbDatepicker"
						ngbDatepicker
						autocomplete="off"
						class="form-control"
						[ngClass]="{ warning: this.invalidDate }"
						[(ngModel)]="actualEventDate"
						(ngModelChange)="updateValues()"
						placeholder="Choose event date"
						[minDate]="minDateBs"
					/>
					<div class="input-group-append">
						<button
							class="btn btn-outline-secondary btn-icon"
							(click)="dp.toggle()"
							[attr.aria-expanded]="dp.isOpen"
							type="button"
						>
							<svg-icon
								svgClass="icon"
								src="/assets/images/icons/calendar.svg"
							></svg-icon>
						</button>
					</div>
				</div>
			</div>

			<!--with reminder-->
			<p class="disabled-info">
				{{ "tax.tax-schedule.confirm-reminder" | translate }}
			</p>

			<!--prior value-->
			<div class="ui input">
				<ng-select
					class="selection"
					[(ngModel)]="actualPriorNotification"
					(ngModelChange)="updateValues()"
					bindLabel="title"
					[items]="priorNotification"
					[searchable]="false"
					[placeholder]="
						actualPriorNotification
							? actualPriorNotification.title
							: 'Choose one'
					"
				></ng-select>
			</div>

			<!--with reminder-->
			<p class="disabled-info">
				{{ "tax.tax-schedule.confirm-before" | translate }}
			</p>
		</div>
	</div>
</div>

<div class="modal-footer">
	<div class="pad-left">
		<p data-ngIf="alreadySent">
			{{ "tax.tax-events.modal.already-sent" | translate }}
		</p>
		<p data-ngIf="event.activation && !alreadySent">
			<span
				[ngClass]="{ warning: this.invalidDate }"
				[ngbPopover]="
					'Reminder date' +
					(this.invalidDate ? ' is in the past' : '')
				"
				placement="top"
			>
				Notification on: {{ event.activation | date: "MMM dd, yyyy" }}
			</span>
		</p>
	</div>

	<div class="button-group">
		<!--Deny-->
		<button class="ui basic button silver" (click)="deny()">
			{{ "user.user-edit-modal.cancel" | translate }}
		</button>

		<!--Approve-->
		<button
			data-ngIf="!alreadySent"
			class="ui basic button rose"
			[disabled]="!canSave"
			(click)="saveOrUpdate()"
		>
			{{ "tax.tax-events.modal.save" | translate }}
		</button>
	</div>
</div>
