<div class="tax-settings-user">
	<div id="header">
		<p><img class="ui avatar image"
				[src]="'../../../../assets/images/icons/user_'+(role == 2 ? 'editor': 'approver')+'.svg'">{{
			"tax.tax-settings.users." + (role == 2 ? 'editor': 'approver') | translate }}</p>
	</div>
	<div id="content">

		<!--Loading indicator-->
		<div class="ui active inline centered loader" *ngIf="!assignedUsers"></div>

		<div class="user assigned" *ngFor="let user of assignedUsers">
			<p>
				<a [href]="'mailto:' + user.email" ngbPopover="Send email" placement="right">
					<b>{{ user.name }}</b>
					&lt;{{ user.email }}&gt;
					<i class="fas fa-envelope"></i>
				</a>
			</p>
			</div>
	</div>
</div>
