/**
 * Enumeration of [[User]] Roles.
 * Should be the same as the contents of the [Role] table in the database.
 * If those do not line up than this enum should be the representative.
 */
export enum Role {

	/**
	 * Default - Has read access for everything, but no write access.
	 */
	VIEWER = 1,

	/**
	 * Can create new revisions for taxes. Revisions require approval in order to become visible to everyone.
	 */
	EDITOR = 2,

	/**
	 * Can approve or reject tax revisions. Supervisor of editors.
	 */
	APPROVER = 3,

	/**
	 * Can manage users in the system except for other Admins and Super-Admins.
	 */
	ADMIN = 4,

	/**
	 * Can edit all users in the system.
	 */
	SUPER_ADMIN = 5
}
