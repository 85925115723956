import { Observable } from 'rxjs';

import { User } from '../data/user/User';
import { AuthConfigTypeDef } from '../utility/AuthConfigTypeDef';
import { AbstractApiConnector } from './AbstractApiConnector';

export class AuthApiConnector extends AbstractApiConnector {
  // Api base url
  protected readonly apiRoute = '/api/v1/auth';

  public getAuthConfiguration(): Observable<AuthConfigTypeDef> {
    return this.http.get<AuthConfigTypeDef>(this.apiBaseUrl + this.apiRoute + '/configuration');
  }

  public clearUserCache(): Observable<void> {
    return this.http.get<void>(this.apiBaseUrl + this.apiRoute + '/user/cache/clear');
  }

  public getInternalUserProfile(): Observable<User> {
    return this.apiClient.get(this.apiRoute + '/internalprofile');
  }
}
