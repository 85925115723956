import { UserStatus } from '../../data/enums/UserStatus';
import { User } from '../../data/user/User';

export class UserParam {
  name: string;
  email: string;
  status: UserStatus;
  lastLoggedIn: Date;
  createdAt: Date;

  static transform(userParam: User) {
    if (!userParam) {
      return;
    }

    const x: any = {};

    if (userParam.name) {
      x.name = userParam.name;
    }

    if (userParam.email) {
      x.email = userParam.email;
    }

    if (userParam.status) {
      x.status = userParam.status;
    }

    if (userParam.lastLoggedIn) {
      x.lastLoggedIn = userParam.lastLoggedIn;
    }

    if (userParam.createdAt) {
      x.createdAt = userParam.createdAt;
    }

    return x;
  }
}
