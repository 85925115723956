import { Component, Input, OnInit } from '@angular/core';

import { UserHelper } from '@rar/commons/helpers';

import { Role } from '../../../../model/data/enums/Role';
import { User } from '../../../../model/data/user/User';
import { ApiCommunicationService } from '../../../../model/services/api-communication/api-communication.service';
import { UserSessionService } from '../../../../user/services/user-session/user-session.service';

@Component({
  selector: 'app-tax-settings-users',
  templateUrl: './tax-settings-users.component.html',
  styleUrls: ['./tax-settings-users.component.scss'],
})
export class TaxSettingsUsersComponent implements OnInit {
  public assignedUsers: User[];
  public enabled = false;

  @Input('role')
  public role: Role;

  @Input('taxId')
  taxId: number;

  @Input()
  taxType: number;

  constructor(private api: ApiCommunicationService, private userSessionService: UserSessionService) {}

  ngOnInit() {
    this.enabled = UserHelper.isUserSuperAdminOrAdminForTaxType(this.userSessionService.userDataValue, this.taxType);

    this.api
      .permissions()
      .getAssignedUsers(this.taxId, this.role)
      .subscribe((users) => {
        this.assignedUsers = users;
      });
  }
}
