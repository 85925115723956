import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { TaxStructureDifferences, TopicDifference } from '@rar/model/data/tax/TaxStructureDifferences';
import { SelectTreeElementService } from '@rar/tax/modals/tax-restore-modal/select-tree-element.service';

@Component({
  selector: 'app-tax-restore-modal',
  templateUrl: './tax-restore-modal.component.html',
  styleUrls: ['./tax-restore-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SelectTreeElementService],
})
export class TaxRestoreModalComponent implements OnInit, OnDestroy {
  constructor(private activeModal: NgbActiveModal, private selectService: SelectTreeElementService) {}

  @Input()
  categories: TopicDifference[];

  ngOnInit(): void {
    this.selectService.selectedElement$.pipe(untilDestroyed(this)).subscribe((category) => {
      this.propagateSelection(category);
    });
  }

  saveTaxesToPropagate() {
    this.activeModal.close({ categories: this.categories } as TaxStructureDifferences);
  }

  private propagateSelection(category: TopicDifference) {
    if (category.toPropagate) {
      const parents = this.getParents(category.shortCode, this.categories, []);
      parents.map((parent) => {
        if (!parent.isPropagated) {
          parent.toPropagate = true;
        }
      });
    } else {
      // if category is not selected -> unselect its children
      if (category.subTopics?.length) {
        category.subTopics.forEach((topic) => {
          topic.toPropagate = false;

          if (topic.subTopics?.length) {
            topic.subTopics.forEach((rule) => {
              rule.toPropagate = false;
            });
          }
        });
      }
    }
  }

  private getParents(target: string, categories: TopicDifference[], tree: TopicDifference[]) {
    for (let category of categories) {
      if (category.shortCode === target) {
        return tree.concat(category);
      }
      if (category.subTopics) {
        const parents = this.getParents(target, category.subTopics, tree.concat(category));
        if (parents) {
          return parents;
        }
      }
    }
    return null;
  }

  close(): void {
    this.activeModal.dismiss(null);
  }

  ngOnDestroy() {}
}
