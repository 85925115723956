import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { untilDestroyed } from 'ngx-take-until-destroy';

import { RoutePaths } from '@rar/commons/constants/route-paths';

import { User } from '../../../model/data/user/User';
import { ApiCommunicationService } from '../../../model/services/api-communication/api-communication.service';

@Component({
  selector: 'app-user-page',
  templateUrl: './user-page.component.html',
  styleUrls: ['./user-page.component.scss'],
})
export class UserPageComponent implements OnInit, OnDestroy {
  public id!: number;
  public isEditMode = false;

  public user!: User;

  constructor(private route: ActivatedRoute, private api: ApiCommunicationService) {
    this.id = +this.route.snapshot.params[RoutePaths.users.editedUserIdParam];
    this.isEditMode = !!this.id;
  }

  ngOnInit() {
    if (this.id && this.isEditMode) {
      this.fetchUser();
    }
  }

  ngOnDestroy(): void {}

  private fetchUser() {
    this.api
      .user()
      .getUser(this.id)
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.user = response;
      });
  }
}
