import { AbstractApiConnector } from "./AbstractApiConnector";
import { Observable } from "rxjs";
import { UserListResponse } from "../response/UserListResponse";
import { UserStatus } from "../data/enums/UserStatus";
import { UserParam } from "../request/user/UserParam";
import { User } from "../data/user/User";
import { ValidationError } from "../exception/ValidationError";
import { Util } from "../../commons/util/Util";

export class UserApiConnector extends AbstractApiConnector {

	// Api base url
	protected readonly apiRoute = "/api/v1/user";

	public getUser(userId: number): Observable<User> {
		if (typeof userId !== "number") {
			throw new ValidationError();
		} else {
			return this.apiClient.get(this.apiRoute + `/${userId}`);
		}
	}

	public getUsers(pageNumber: number, params?: User): Observable<UserListResponse> {
		if (typeof pageNumber !== "number") {
			throw new ValidationError();
		} else {
			return this.apiClient.get(this.apiRoute + `/${pageNumber}/list`, UserParam.transform(params));
		}
	}

	public setUserStatus(userId: number, status: UserStatus): Observable<UserListResponse> {
		if (!Util.existValueInEnum(UserStatus, status) || typeof userId !== "number") {
			throw new ValidationError();
		} else {
			return this.apiClient.patch(this.apiRoute + `/${userId}`, { status: status });
		}
	}

}
