<div id="dashboard-page-layout">
  <!--Loading indicator-->
  <div class="ui active inline centered loader" *ngIf="!upcomingChangeLocations || loading"></div>

  <div *ngIf="upcomingChangeLocations && !loading" class="dashboard-layout" [ngClass]="getRoleClass()">
    <div id="title" class="padding-bottom">
      <h1>{{ 'dashboard.title' | translate }}</h1>
    </div>

    <!--Audit trail button-->
    <app-audit-button *ifHasRole="[role.ADMIN, role.SUPER_ADMIN]" class="padding-top-bottom" id="audit"></app-audit-button>

    <!--Upcoming changes list-->
    <app-dashboard-upcoming-changes
      id="upcoming-changes"
      [upcomingChanges]="upcomingChanges"
      [username]="user.name"></app-dashboard-upcoming-changes>

    <!--Latest changes list-->
    <app-dashboard-latest-changes id="latest-changes" [latestChanges]="latestChanges" [username]="user.name"></app-dashboard-latest-changes>

    <!--Map-->
    <app-dashboard-map id="map" [locations]="upcomingChangeLocations"></app-dashboard-map>

    <div id="tables">
      <!--Your recently viewd table (only Viewer)-->
      <app-dashboard-recently-viewed-table
        *ifHasRoleExact="[role.VIEWER]"
        class="padding-top"
        [recentViews]="recentViews"></app-dashboard-recently-viewed-table>

      <!--Your updates table-->
      <app-dashboard-tax-table
        *ifHasRole="[role.EDITOR, role.ADMIN]; excludeGlobalAdmin: true"
        class="padding-top"
        data-test="tax-table-editor"
        [taxes]="taxesToEdit$ | async"
        [userRoleMode]="role.EDITOR"></app-dashboard-tax-table>

      <!--Tax changes for local approval-->
      <app-dashboard-tax-table
        class="padding-top"
        *ifHasRole="[role.APPROVER]"
        data-test="tax-table-approver"
        [taxes]="taxesToLocalApprove$ | async"
        [userRoleMode]="role.APPROVER"></app-dashboard-tax-table>

      <!--Tax changes for global approval-->
      <app-dashboard-tax-table
        *ifHasRole="[role.ADMIN, role.SUPER_ADMIN]; excludeLocalAdmin: true"
        class="padding-top"
        data-test="tax-table-approver"
        [taxes]="taxesToGlobalApprove$ | async"
        [userRoleMode]="role.APPROVER"></app-dashboard-tax-table>
    </div>

    <!--Recently viewed list-->
    <app-dashboard-recently-viewed
      *ifHasRole="[role.EDITOR, role.APPROVER, role.ADMIN, role.SUPER_ADMIN]"
      class="padding-top"
      id="recently-viewed"
      [recentViews]="recentViews"
      [username]="user.name"></app-dashboard-recently-viewed>
  </div>
</div>
