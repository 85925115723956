<div id="tax-history-layout">

	<div id="header">
		<h2>{{ "tax.tax-history.title" | translate }}</h2>

		<!--Filter-->
		<app-audit-filter class="filter" [users]="users | async"></app-audit-filter>
	</div>

	<app-audit-display></app-audit-display>

</div>
