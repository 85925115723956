import { Pipe, PipeTransform } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

import { Role } from '@rar/model/data/enums/Role';

@Pipe({
  name: 'userRole',
})
export class UserRolePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(value: any, args?: any): any {
    switch (+value) {
      case Role.VIEWER:
        return this.translateService.instant('commons.roles.viewer');
      case Role.EDITOR:
        return this.translateService.instant('commons.roles.editor');
      case Role.APPROVER:
        return this.translateService.instant('commons.roles.approver');
      case Role.ADMIN:
        return this.translateService.instant('commons.roles.admin');
      case Role.SUPER_ADMIN:
        return this.translateService.instant('commons.roles.superadmin');
      default:
        return 'Invalid role';
    }
  }
}
