import {PagedResponse} from "./wrappers/PagedResponse";
import {TaxChanges} from "../data/dashboard/TaxChanges";
import {Tax} from "../data/tax/Tax";

export class TaxChangeListResponse extends PagedResponse<{taxes: Array<TaxChanges>}> {

	private static transform(taxChanges: Array<TaxChanges>): Array<Tax> {
		const taxes = [];

		taxChanges.forEach((changes: TaxChanges) => {
			taxes.push(TaxChanges.transform(changes));
		});

		return taxes;
	}

	public static transformResponse(response: TaxChangeListResponse) {
		const responseTransformed = <any>{};
		responseTransformed._info = response._info;
		responseTransformed._links = response._links;
		responseTransformed._embedded = {};
		responseTransformed._embedded.taxes = TaxChangeListResponse.transform(response._embedded.taxes);
		return responseTransformed;
	}

}
