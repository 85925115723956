import {AbstractApiConnector} from "./AbstractApiConnector";
import {Observable} from "rxjs";
import {TaxChangeListResponse} from "../response/TaxChangeListResponse";
import {UserListResponse} from "../response/UserListResponse";
import {DashboardRequest} from "../request/dashboard/DashboardRequest";
import {TaxRecentViewListResponse} from "../response/TaxRecentViewListResponse";
import {DashboardParam} from "../request/dashboard/DashboardParam";
import {ValidationError} from "../exception/ValidationError";
import {Util} from "../../commons/util/Util";

export class DashboardApiConnector extends AbstractApiConnector {

	protected readonly apiRoute = "/api/v1/dashboard";

	/**
	 * Returns the upcoming tax changes
	 * @param type
	 * @param pageNumber
	 */
	public getUpcomingChanges(type: DashboardRequest, pageNumber: number): Observable<TaxChangeListResponse> {
		if (!Util.existValueInEnum(DashboardRequest, type) || typeof pageNumber !== "number") {
			throw new ValidationError();
		} else {
			return this.apiClient.get(this.apiRoute + "/upcoming/" + `${type}/${pageNumber}`);
		}
	}

	/**
	 * Returns the latest tax changes
	 * @param type
	 * @param pageNumber
	 */
	public getLatestChanges(type: DashboardRequest, pageNumber: number): Observable<TaxChangeListResponse> {
		if (!Util.existValueInEnum(DashboardRequest, type) || typeof pageNumber !== "number") {
			throw new ValidationError();
		} else {
			return this.apiClient.get(this.apiRoute + "/latest/" + `${type}/${pageNumber}`);
		}
	}

	/**
	 * Returns the list of recently viewed taxes for a user
	 * @param type
	 * @param pageNumber
	 */
	public getRecentViews(type: DashboardRequest, pageNumber: number): Observable<TaxRecentViewListResponse> {
		if (!Util.existValueInEnum(DashboardRequest, type) || typeof pageNumber !== "number") {
			throw new ValidationError();
		} else {
			return this.apiClient.get(this.apiRoute + "/views/" + `${type}/${pageNumber}`);
		}
	}

	/**
	 * Returns the list of new users
	 * @param type
	 * @param pageNumber
	 */
	public getUsers(type: DashboardRequest, pageNumber: number): Observable<UserListResponse> {
		if (!Util.existValueInEnum(DashboardRequest, type) || typeof pageNumber !== "number") {
			throw new ValidationError();
		} else {
			return this.apiClient.get(this.apiRoute + "/users/" + `${type}/${pageNumber}`);
		}
	}

	/**
	 * Returns the list of taxes waiting for approval
	 * @param type
	 * @param pageNumber
	 * @param param
	 */
	public getAwaitingTaxesForApproval(type: DashboardRequest, pageNumber: number, param: DashboardParam): Observable<TaxChangeListResponse> {
		if (!Util.existValueInEnum(DashboardRequest, type) || typeof pageNumber !== "number") {
			throw new ValidationError();
		} else {
			return this.apiClient.get(this.apiRoute + "/approver/" + `${type}/${pageNumber}`, param);
		}
	}

	/**
	 * Returns the list of pending, rejected and approved taxes for the editor
	 * @param type
	 * @param pageNumber
	 * @param param
	 */
	public getAwaitingTaxesForEditor(type: DashboardRequest, pageNumber: number, param: DashboardParam): Observable<TaxChangeListResponse> {
		if (!Util.existValueInEnum(DashboardRequest, type) || typeof pageNumber !== "number") {
			throw new ValidationError();
		} else {
			return this.apiClient.get(this.apiRoute + "/editor/" + `${type}/${pageNumber}`, param);
		}
	}

}
