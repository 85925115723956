/**
 * Indicates the change note's occurrence type
 */
export enum ChangeNoteOccurrence {
  // revision number changed
  REVISION_NUMBER = 'revisionNumber',

  // description changed
  DESCRIPTION = 'description',

  // status changed
  STATUS = 'status',

  // rule related change
  ARTICLE = 'article',

  // rule title
  RULE_TITLE = 'title',

  // rule short code
  RULE_SHORTCODE = 'shortCode',

  // rate title
  RATE_TITLE = 'name',

  // rate related change
  VALUE = 'value',

  // rule order
  RULE_ORDER = 'rule order',

  // effective to changed
  EFFECTIVE_TO = 'effectiveTo',

  // effective from changed
  EFFECTIVE_FROM = 'effectiveFrom',

  // topic name
  TOPIC_NAME = 'topic name',

  // topic short code
  TOPIC_SHORTCODE = 'topic shortcode',

  // category name
  CATEGORY_NAME = 'category name',

  // category short code
  CATEGORY_SHORTCODE = 'category shortcode',
}
