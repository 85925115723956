import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UserSessionService } from 'src/app/user/services/user-session/user-session.service';

import { UserHelper } from '@rar/commons/helpers';
import { Role } from '@rar/model/data/enums/Role';
import { TaxLock } from '@rar/model/data/tax/TaxLock';
import { User } from '@rar/model/data/user/User';

interface ITaxLockModalContext {
  lock: TaxLock;
  forRole: Role;
}

@Component({
  selector: 'app-tax-lock-modal',
  templateUrl: './tax-lock-modal.component.html',
  styleUrls: ['./tax-lock-modal.component.scss'],
})
export class TaxLockModalComponent implements OnInit {
  constructor(private activeModal: NgbActiveModal, private userSessionService: UserSessionService) {}

  @Input()
  context: ITaxLockModalContext;

  public authUser: User;

  public get isApprover() {
    return this.context.forRole === Role.APPROVER;
  }

  public get isEditor() {
    return this.context.forRole === Role.EDITOR;
  }

  ngOnInit() {
    this.userSessionService.userData.subscribe((u: User) => {
      this.authUser = u;
    });
  }

  approve(): void {
    this.activeModal.close(null);
  }

  deny(): void {
    this.activeModal.dismiss(null);
  }
}
