import {EventEmitter, Injectable} from "@angular/core";

@Injectable({
	providedIn: "root"
})
export class DashboardLoaderService {

	public load = new EventEmitter<void>();
	public mapClickEvent = new EventEmitter<number>();

	constructor() {
	}

}
