/**
 * Actual representation of a tax's details. Revisions are responsible for version control and storing historical data.
 */
import { DatabaseEntity } from '../DatabaseEntity';
import { TaxRevisionStatus } from '../enums/TaxRevisionStatus';
import { Location } from '../location/Location';
import { User } from '../user/User';
import { Tax } from './Tax';
import { TaxTopic } from './TaxTopic';

export class TaxRevision extends DatabaseEntity {
  /**
   * Unique identifier for a revision
   */
  public id: number;

  /**
   * Parent [[Tax]] object unique identifier.
   */
  public tax: Tax;

  public location: Location;

  /**
   * Tax revision unique identifier.
   */
  public revisionNumber: number;

  /**
   * Array of all related topics to the [[TaxRevision]]
   */
  public taxTopics: Array<TaxTopic>;

  /**
   * Description of the tax revision.
   */
  public description: string;

  public name: string;

  // Req 6
  public approvalLevel: string;

  public type: number;

  /**
   * The effective start date of the tax revision.
   */
  public effectiveFrom: Date;

  /**
   * The effective end date of the tax revision.
   */
  public effectiveTo: Date;

  /**
   * The editor's user ID, who created the revision.
   */
  public editor: User;

  /**
   * The last approver's user ID, who rejected or accepted the revision.
   */
  public approver: User;

  /**
   * The status of approval of the tax revison.
   */
  public isApproved: boolean;

  /**
   * The edit notes, which is the communication form on the editor's side.
   */
  public changeNotes: string;

  /**
   * The rejectionReason, which is the communication form on the approver's side.
   */
  public rejectionReason: string;

  /**
   * The status of the tax revision, selected from [[TaxRevisionStatus]] enumeration.
   */
  public status: TaxRevisionStatus;

  public approvalNotes: string;

  public lastUpdate: Date;

  public revisionId: number;

  public isLocalApprove: boolean;
}
