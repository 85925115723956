import { Inject, Injectable, OnDestroy } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { uniq } from 'lodash-es';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Observable } from 'rxjs';

import { RoutePaths, ValidationConstants } from '@rar/commons/constants';
import { ModalService } from '@rar/commons/services/modal.service';
import { TaxLock } from '@rar/model/data/tax/TaxLock';
import { TaxType } from '@rar/model/data/tax/TaxType';
import { TaxTypeStructure } from '@rar/model/data/tax/TaxTypeStructure';
import { TaxTypeTopic } from '@rar/model/data/tax/TaxTypeTopic';
import { TaxTypeStructureEditRequest } from '@rar/model/request/tax/TaxTypeStructureEditRequest';
import { ApiCommunicationService } from '@rar/model/services/api-communication/api-communication.service';

import { TaxTypeModalService } from './tax-type-modal.service';

@Injectable()
export class TaxTypeDataService implements OnDestroy {
  public isLoading = false;

  private _tax = new BehaviorSubject<TaxTypeStructure>(undefined);

  private _taxTypes: TaxType[];
  private _taxLockInfo: TaxLock;
  private _askForDeactivation = false;

  constructor(
    private taxModalService: TaxTypeModalService,
    private router: Router,
    private modalService: ModalService,
    private translateService: TranslateService,
    @Inject(ApiCommunicationService) private api: ApiCommunicationService,
  ) {
    this.api
      .tax()
      .getAllTaxTypes()
      .pipe(untilDestroyed(this))
      .subscribe((taxTypes: TaxType[]) => {
        this._taxTypes = taxTypes;
      });
  }

  public ngOnDestroy() {}

  public save(status: boolean) {
    if (status) {
      this.isLoading = true;

      // create request payload
      const request = new TaxTypeStructureEditRequest(this.getTax());

      const navigationExtras: NavigationExtras = {
        state: {
          refreshSearch: true,
        },
      };

      this.api
        .tax()
        .editTaxTypeStructure(this.getTax().id, request)
        .subscribe(() => {
          this._askForDeactivation = false;
          this.router.navigate([RoutePaths.taxDesign.list], navigationExtras);
        });
    }
  }

  public lock() {
    this.api.tax().lockTaxType(this.getTax().id).subscribe();
  }

  get tax(): Observable<TaxTypeStructure> {
    return this._tax.asObservable();
  }

  public setTax(value: TaxTypeStructure) {
    this._tax.next(value);
  }

  public getTax(): TaxTypeStructure {
    return this._tax.getValue();
  }

  get taxLockInfo(): TaxLock {
    return this._taxLockInfo;
  }

  get askForDeactivation(): boolean {
    return this._askForDeactivation;
  }

  set askForDeactivation(value: boolean) {
    this._askForDeactivation = value;
  }

  set taxLockInfo(value: TaxLock) {
    this._taxLockInfo = value;
  }

  public onClickDoneEditing() {
    if (!this.checkIfAdminChangesValid()) {
      return;
    }

    this.taxModalService.openTaxTypeSaveModal(this.getTax());
  }

  public onClickCancelEditing() {
    this.router.navigate([RoutePaths.taxDesign.list]);
  }

  public onClickDiscardModal(): Observable<boolean> {
    return this.taxModalService.openTaxDiscardModal();
  }

  public getTaxTopic(tax: TaxTypeStructure, topicId: number): TaxTypeTopic {
    const t = this.getTopicBasedOnId(topicId, tax.topics);
    return t ? t : new TaxTypeTopic();
  }

  public getTopicBasedOnId(id: number, tree: Array<TaxTypeTopic>) {
    for (const t of tree) {
      if (t.id === id) {
        return t;
      }

      if (t.subTopics) {
        const x = this.getTopicBasedOnId(id, t.subTopics);
        if (x) {
          return x;
        }
      }
    }

    return null;
  }

  private checkIfAdminChangesValid(): boolean {
    const nameValid = this.checkIfNameValid();
    const categoriesValid = this.checkIfCategoriesValid();
    const topicsValid = this.checkIfTopicsValid();
    const rulesValid = this.checkIfRulesValid();
    const ratesValid = this.checkIfRatesValid();

    const errorMessage = (
      [nameValid, categoriesValid, topicsValid, rulesValid, ratesValid].filter((isValid) => isValid !== true) as string[]
    )
      .reduce((error, nextError) => error + nextError + '\n', '')
      .trim();
    if (errorMessage) {
      this.modalService
        .showInformation({
          title: this.translateService.instant('commons.validation.error-title'),
          content: errorMessage.split('\n'),
        })
        .then();
    }

    return !errorMessage;
  }

  private checkIfNameValid(): string | true {
    const taxName = this.getTax().name?.trim() || '';
    if (!taxName) {
      return this.translateService.instant('tax-design.add-new-form.error-required');
    }
    if (taxName.length < ValidationConstants.taxTypeMinLength) {
      return this.translateService.instant('tax-design.add-new-form.error-min-length', {
        name: taxName,
        minLength: ValidationConstants.taxTypeMinLength,
      });
    }
    if (!ValidationConstants.categoryTopicNamePattern.test(taxName)) {
      return this.translateService.instant('tax-design.add-new-form.error-pattern', { name: taxName });
    }
    if (this._taxTypes.filter((tt) => tt.id !== this.getTax().id).some((tt) => tt.name === taxName)) {
      return this.translateService.instant('tax-design.add-new-form.error-exists-content');
    }
    return true;
  }

  private checkIfCategoriesValid(): string | true {
    const categoriesWithoutName = this.getTax().topics.filter((category) => !category.name?.trim()).length;
    let errors =
      categoriesWithoutName > 0
        ? categoriesWithoutName === 1
          ? this.translateService.instant('tax.edit-validation.empty-category')
          : this.translateService.instant('tax.edit-validation.empty-categories', { number: `${categoriesWithoutName}` })
        : '';

    this.getTax()
      .topics.filter((category) => category.name && category.name.trim().length < ValidationConstants.categoryMinLength)
      .forEach((category) => {
        errors +=
          '\n' +
          this.translateService.instant('tax.edit-validation.min-length-category-name', {
            category: category.name,
            minLength: ValidationConstants.categoryMinLength,
          });
        errors = errors.trim();
      });

    this.getTax()
      .topics.filter(
        (category) =>
          category.name &&
          category.name.trim().length >= ValidationConstants.categoryMinLength &&
          !ValidationConstants.categoryTopicNamePattern.test(category.name),
      )
      .forEach((category) => {
        errors += '\n' + this.translateService.instant('tax.edit-validation.category-name-pattern', { category: category.name });
        errors = errors.trim();
      });

    return !errors ? true : errors;
  }

  private checkIfTopicsValid(): string | true {
    const topicErrors = <string[]>this.getTax()
      .topics.map((category) => {
        const topicsWithoutName = (category.subTopics || []).filter((topic) => !topic.name?.trim()).length;
        let errors =
          topicsWithoutName > 0
            ? topicsWithoutName === 1
              ? this.translateService.instant('tax.edit-validation.empty-topic', { category: category.name })
              : this.translateService.instant('tax.edit-validation.empty-topics', {
                  category: category.name,
                  number: `${topicsWithoutName}`,
                })
            : '';

        (category.subTopics || [])
          .filter((topic) => topic.name && topic.name.trim().length < ValidationConstants.topicMinLength)
          .forEach((topic) => {
            errors +=
              '\n' +
              this.translateService.instant('tax.edit-validation.min-length-topic-name', {
                category: category.name,
                topic: topic.name,
                minLength: ValidationConstants.topicMinLength,
              });
            errors = errors.trim();
          });

        (category.subTopics || [])
          .filter(
            (topic) =>
              topic.name &&
              topic.name.trim().length >= ValidationConstants.topicMinLength &&
              !ValidationConstants.categoryTopicNamePattern.test(topic.name),
          )
          .forEach((topic) => {
            errors +=
              '\n' +
              this.translateService.instant('tax.edit-validation.topic-name-pattern', { category: category.name, topic: topic.name });
            errors = errors.trim();
          });

        return !errors ? true : <string>errors;
      })
      .filter((error) => error !== true);

    if (!topicErrors || !topicErrors.length) {
      return true;
    }

    return topicErrors.join('\n');
  }

  private checkIfRulesValid(): string {
    const topicRuleNamesValidator = (topics: TaxTypeTopic[]) => {
      return !topics
        ? ''
        : topics.reduce((errors, topic) => {
            const issues = (topic.rules ?? [])
              .map((rule) => {
                const numberOfEmptyRules = topic.rules.filter((r) => !r.name).length;
                if (!rule.name && numberOfEmptyRules === 1) {
                  return this.translateService.instant('tax.edit-validation.empty-rule-name', { topicName: topic.name });
                } else if (!rule.name && numberOfEmptyRules > 1) {
                  return this.translateService.instant('tax.edit-validation.empty-rules-name', {
                    numberOfRules: numberOfEmptyRules,
                    topicName: topic.name,
                  });
                }

                if (rule.name.length < ValidationConstants.ruleMinLength) {
                  return this.translateService.instant('tax.edit-validation.min-length-rule-name', {
                    topicName: topic.name,
                    rule: rule.name,
                    minLength: ValidationConstants.ruleMinLength,
                  });
                }

                return '';
              })
              .filter((v) => !!v);

            const uniqIssues = uniq(issues).join('\n');

            return (errors += '\n' + uniqIssues + '\n' + topicRuleNamesValidator(topic.subTopics));
          }, '');
    };

    return topicRuleNamesValidator(this.getTax().topics);
  }

  private checkIfRatesValid(): string {
    const topicRateNamesValidator = (topics: TaxTypeTopic[]) => {
      return !topics
        ? ''
        : topics.reduce((errors, topic) => {
            const issues = (topic.rates ?? [])
              .map((rate) => {
                const numberOfEmptyRules = topic.rates.filter((r) => !r.name).length;
                if (!rate.name && numberOfEmptyRules === 1) {
                  return this.translateService.instant('tax.edit-validation.empty-rule-name', { topicName: topic.name });
                } else if (!rate.name && numberOfEmptyRules > 1) {
                  return this.translateService.instant('tax.edit-validation.empty-rules-name', {
                    numberOfRules: numberOfEmptyRules,
                    topicName: topic.name,
                  });
                }

                if (rate.name.length < ValidationConstants.ruleMinLength) {
                  return this.translateService.instant('tax.edit-validation.min-length-rule-name', {
                    topicName: topic.name,
                    rule: rate.name,
                    minLength: ValidationConstants.ruleMinLength,
                  });
                }

                return '';
              })
              .filter((v) => !!v);

            const uniqIssues = uniq(issues).join('\n');

            return (errors += '\n' + uniqIssues + '\n' + topicRateNamesValidator(topic.subTopics));
          }, '');
    };

    return topicRateNamesValidator(this.getTax().topics);
  }
}
