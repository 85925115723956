<div class="modal-header">
	<h4 class="modal-title">Approve changes?</h4>
</div>

<div class="modal-body">
	<div>
		You are about to approve changes to
		<strong
			>{{ context.tax.name }} - {{ context.tax.location.name }}.</strong
		>
	</div>

	<br />

	<div>
		This form will be valid from:
		<strong
			>{{ context.tax.effectiveFrom | date: "MMM dd, yyyy" }} -
			{{ context.tax.effectiveTo | date: "MMM dd, yyyy" }}</strong
		>
	</div>

	<br />

	<div>
		<textarea
			[(ngModel)]="notes"
			placeholder="Approval notes..."
		></textarea>
	</div>

	<br />

	The alterations made by the following editor(s):
	<br />

	<strong *ngFor="let editor of context.editors; let isLast = last">
		{{ editor.name }}{{ isLast ? "" : ", " }}
	</strong>

	<br />
	<br />

	Do you want to approve the changes made by these editors?
</div>

<div class="modal-footer">
	<div></div>

	<div class="button-group">
		<!--Deny-->
		<button class="ui basic button silver" (click)="deny()">
			{{ "user.user-edit-modal.cancel" | translate }}
		</button>

		<!--Approve-->
		<button class="ui basic button rose" (click)="approve(notes)">
			Yes
		</button>
	</div>
</div>
