import { EventEmitter, Injectable } from "@angular/core";

@Injectable()
export class CollapseService {

	public isCollapsed = new EventEmitter<boolean>();

	private localStorage;
	private status: boolean;
	private key = "navbar.is.collapsed";

	constructor() {
		this.localStorage = window.localStorage;
		this.status = this.getStatus();
	}

	public changeStatus() {
		this.status = !this.status;
		this.localStorage.setItem(this.key, String(this.status));

		this.isCollapsed.emit(this.status);
	}
	
	public getStatus() {
		return (this.localStorage.getItem(this.key) === "true");
	}

}
