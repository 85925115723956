import { UserTaxPermission } from '@rar/model/data/user/UserTaxPermission';

import { UserStatus } from '../../data/enums/UserStatus';

export class UserPayload {
  constructor(name: string, email: string, status: UserStatus, isSuperAdmin: boolean, userTaxPermissions: UserTaxPermission[]) {
    this.name = name;
    this.email = email;
    this.status = status;
    this.isSuperAdmin = isSuperAdmin;
    this.userTaxPermissions = userTaxPermissions;
  }

  name: string;
  email: string;
  isSuperAdmin: boolean;
  status: UserStatus;
  userTaxPermissions: UserTaxPermission[];
}
