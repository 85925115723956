<div class="modal-header">
	<h4 class="modal-title">
		{{ "tax.tax-info.approver-list-title" | translate }}
	</h4>
</div>

<div class="modal-body">
	<div *ngIf="context.approvers.length === 0; else approvers">
		There is no assigned approver for this tax.
	</div>

	<ng-template #approvers>
		<div *ngFor="let approver of context.approvers">
			<p>
				<a
					[href]="'mailto:' + approver.email"
					ngbPopover="Send email"
					placement="right"
				>
					<b>{{ approver.name }}</b>
					&lt;{{ approver.email }}&gt;
					<i class="fas fa-envelope"></i>
				</a>
			</p>
		</div>
	</ng-template>
</div>

<div class="modal-footer">
	<div></div>

	<div class="button-group">
		<button class="ui basic button silver" (click)="approve()">
			Close
		</button>
	</div>
</div>
