import { Component } from "@angular/core";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: "app-tax-notification-updated-modal",
	templateUrl: "./tax-notification-updated-modal.component.html",
	styleUrls: ["./tax-notification-updated-modal.component.scss"]
})
export class TaxNotificationUpdatedModalComponent {

	constructor(private activeModal: NgbActiveModal) { }

	deny(): void {
		this.activeModal.dismiss(null);
	}
}
