<div id="tax-rates-layout">
  <!--Display-->
  <div *ngIf="!taxDataService.isEditingMode && !taxDataService.isApprovingMode">
    <div class="ui relaxed divided list">
      <div class="item" *ngFor="let rate of taxDataService.getTaxTopic(taxDataService.getTax(), topicId).rates">
        <div class="name">
          <div appDiffAndSearch [content]="rate.name" [searchString]="taxDataService.searchCriteria"></div>
        </div>
        <div class="v">
          <div>
            <p>{{ rate.value ? rate.value : rate.description }} <span *ngIf="rate.value && rate.valueTypeId === 2">%</span></p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--Edit-->
  <div class="list-edit" *ngIf="taxDataService.isEditingMode">
    <div
      class="item edit"
      *ngFor="let rate of taxDataService.getTaxTopic(taxDataService.taxCloneForEditing, topicId).rates; last as last; index as i">
      <div class="rule-counter">{{ 'tax.tax-topics.rule-label' | translate }} {{ i + 1 }}</div>
      <div class="name">
        <ng-container *ifHasRole="[role.ADMIN, role.SUPER_ADMIN]; requestedTax: taxDataService.getTax(); else: staticLabelName">
          <label>{{ 'tax.tax-topics.rule-label' | translate }}</label>
          <input
            #rateName="ngModel"
            type="text"
            class="title form-control show-feedback"
            [(ngModel)]="rate.name"
            required
            [maxLength]="validationConstants.ruleMaxLength" />
          <div class="invalid-feedback" *ngIf="rateName.invalid">
            <div class="invalid-feedback__message">
              <i class="fa fa-exclamation-triangle mr-1"></i>
              {{ 'commons.validation.required' | translate }}
            </div>
          </div>
        </ng-container>
        <ng-template #staticLabelName>
          <p>{{ rate.name }}</p>
        </ng-template>
      </div>
      <label>{{ 'tax.tax-topics.rule-value' | translate }}</label>
      <div class="v2 edit">
        <input type="number" class="form-control" [(ngModel)]="rate.value" />
      </div>

      <div class="hr" *ngIf="!last"></div>
    </div>
  </div>

  <!--Approve-->
  <div *ngIf="taxDataService.isApprovingMode">
    <div class="ui relaxed divided list">
      <div class="item" *ngFor="let rate of taxDataService.getTaxTopic(taxDataService.getTax(), topicId).rates">
        <div class="name">
          <p>{{ rate.name }} {{ rate.shortCode }}</p>
        </div>

        <div class="v">
          <div *ngIf="!isRateChanged(rate.shortCode); else changed">
            <p>{{ rate.value ? rate.value : rate.description }} <span *ngIf="rate.value && rate.valueTypeId === 2">%</span></p>
          </div>

          <ng-template #changed>
            <div class="changes">
              <div class="change">
                <span class="oldValue">{{ getRateDiff(rate.shortCode).old.toString() | titlecase }}</span>
                <i class="fa fa-arrow-right"></i>
                <span class="newValue">{{ getRateDiff(rate.shortCode).new.toString() | titlecase }}</span>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
