import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy } from '@angular/core';

import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';

import { TaxType } from '@rar/model/data/tax/TaxType';

import { TaxChanges } from '../../../model/data/dashboard/TaxChanges';
import { Role } from '../../../model/data/enums/Role';
import { TaxRevisionStatus } from '../../../model/data/enums/TaxRevisionStatus';
import { DashboardParam } from '../../../model/request/dashboard/DashboardParam';
import { DashboardRequest } from '../../../model/request/dashboard/DashboardRequest';
import { ApiCommunicationService } from '../../../model/services/api-communication/api-communication.service';
import { DashboardLoaderService } from '../../services/dashboard-loader.service';

@Component({
  selector: 'app-dashboard-tax-table',
  templateUrl: './dashboard-tax-table.component.html',
  styleUrls: ['./dashboard-tax-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardTaxTableComponent implements OnDestroy {
  @Input('taxes')
  public taxes: Array<TaxChanges>;

  @Input()
  public userRoleMode: Role;

  public readonly role = Role;
  public readonly dashboardParam: DashboardParam;
  public readonly taxTypes$: Observable<TaxType[]>;

  constructor(
    @Inject(ApiCommunicationService) private api: ApiCommunicationService,
    private dashboardService: DashboardLoaderService,
    private cdr: ChangeDetectorRef,
  ) {
    this.taxTypes$ = this.api.tax().getTaxTypes().pipe(untilDestroyed(this));
    this.dashboardParam = new DashboardParam(this.getStatusPendingLocal());
  }

  ngOnDestroy(): void {}

  public onChange(status: number) {
    this.dashboardParam.status = status;
    this.taxes = undefined;
    this.api
      .dashboard()
      .getAwaitingTaxesForEditor(DashboardRequest.SHORT, 1, this.dashboardParam)
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        switch (this.userRoleMode) {
          case this.role.APPROVER:
            this.taxes = response._embedded.taxes.filter((t) => t.status === TaxRevisionStatus.PENDING_LOCAL);
            break;
          case this.role.SUPER_ADMIN:
            this.taxes = response._embedded.taxes.filter((t) => t.status === TaxRevisionStatus.PENDING_GLOBAL);
            break;
          default:
            this.taxes = response._embedded.taxes;
            break;
        }

        this.cdr.markForCheck();
      });
  }

  public onClick() {
    this.dashboardService.load.emit();
  }

  public getStatusPendingLocal(): TaxRevisionStatus {
    return TaxRevisionStatus.PENDING_LOCAL;
  }

  public getStatusPendingGlobal(): TaxRevisionStatus {
    return TaxRevisionStatus.PENDING_GLOBAL;
  }

  public getStatusApproved(): TaxRevisionStatus {
    return TaxRevisionStatus.APPROVED;
  }

  public getStatusRejected(): TaxRevisionStatus {
    return TaxRevisionStatus.REJECTED;
  }
}
