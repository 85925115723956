import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import {
  NgbDateAdapter,
  NgbDateNativeAdapter,
  NgbDateParserFormatter,
  NgbModalConfig,
  NgbModule,
  NgbPopoverConfig,
} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule, SvgLoader } from 'angular-svg-icon';
import { NgxWebstorageModule } from 'ngx-webstorage';

import { LongDateParserFormatter } from '@rar/commons/formatters';

import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { EmptyPlaceholderComponent } from './components/empty-placeholder/empty-placeholder.component';
import { ErrorDisplayComponent } from './components/error-display/error-display.component';
import { LoginDimmerComponent } from './components/login-dimmer/login-dimmer.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { SearchComponent } from './components/search/search.component';
import { TaxChangeNotesTableComponent } from './components/tax-change-notes-table/tax-change-notes-table.component';
import { ValidationMessageComponent } from './components/validation-message/validation-message.component';
import { DiffAndSearchDirective } from './directives/diff-and-search.directive';
import { HasRoleDirective } from './directives/has-role.directive';
import { bootstrapModalConfigurationInit, bootstrapPopoverConfigurationInit } from './initializers';
import { TaxDetailedChangeNotesComponent } from './modals/tax-detailed-change-notes/tax-detailed-change-notes.component';
import { DownloadAttachmentPageComponent } from './pages/download-attachment-page/download-attachment-page.component';
import { NoAccessPageComponent } from './pages/no-access-page/no-access-page.component';
import { DiffPipe } from './pipes/diff.pipe';
import { TaxTypePipe } from './pipes/tax-type.pipe';
import { RarSvgLoader } from './services/svg-loader.service';

const routes: Routes = [];

/**
 * The commons module.
 *
 * Contains common parts parts that are used almost everywhere.
 * E.g. navigation bar belongs here.
 */
@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forChild(),
        AngularSvgIconModule.forRoot({
            loader: {
                provide: SvgLoader,
                useClass: RarSvgLoader,
                deps: [HttpClient],
            },
        }),
        RouterModule.forChild(routes),
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NgSelectModule,
        NgxWebstorageModule.forRoot(),
    ],
    declarations: [
        NavbarComponent,
        SearchComponent,
        LoginDimmerComponent,
        ErrorDisplayComponent,
        EmptyPlaceholderComponent,
        DiffPipe,
        TaxTypePipe,
        TaxChangeNotesTableComponent,
        TaxDetailedChangeNotesComponent,
        DiffAndSearchDirective,
        ConfirmDialogComponent,
        ValidationMessageComponent,
        NoAccessPageComponent,
        MultiSelectComponent,
        HasRoleDirective,
        DownloadAttachmentPageComponent,
    ],
    exports: [
        NavbarComponent,
        SearchComponent,
        LoginDimmerComponent,
        ErrorDisplayComponent,
        EmptyPlaceholderComponent,
        TaxChangeNotesTableComponent,
        DiffAndSearchDirective,
        DiffPipe,
        TaxTypePipe,
        ValidationMessageComponent,
        NoAccessPageComponent,
        MultiSelectComponent,
        HasRoleDirective,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: bootstrapPopoverConfigurationInit,
            multi: true,
            deps: [NgbPopoverConfig],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: bootstrapModalConfigurationInit,
            multi: true,
            deps: [NgbModalConfig],
        },
        { provide: NgbDateAdapter, useClass: NgbDateNativeAdapter },
        {
            provide: NgbDateParserFormatter,
            useClass: LongDateParserFormatter,
            deps: [NgbDateAdapter],
        },
    ]
})
export class CommonsModule {}
