<ng-container *ngIf="isUserAuthorized$ | async; else notAuthorized">
  <div id="page-wrapper" *ngIf="translationsLoaded$ | async">
    <!--progressbar-->
    <!--<ng-progress [color]="'#DA3D6A'" [showSpinner]="false"></ng-progress>-->

    <div id="nav-layout">
      <app-navbar></app-navbar>
    </div>

    <div id="search-layout">
      <app-search></app-search>
    </div>

    <main id="main-layout">
      <router-outlet></router-outlet>
    </main>

    <app-login-dimmer></app-login-dimmer>
  </div>
</ng-container>

<ng-template #notAuthorized>
  <router-outlet></router-outlet>
</ng-template>
