import { Component, HostListener, Inject, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectConfig } from '@ng-select/ng-select';
import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ModalService } from '@rar/commons/services/modal.service';

import { MonitoringService } from './commons/services/monitoring.service';
import { UserSessionService } from './user/services/user-session/user-session.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  public isScrolled = false;
  public translationsLoaded$: Observable<boolean>;
  public readonly isUserAuthorized$ = this.userSessionService.userData.pipe(
    map(() => this.userSessionService.isUserLoggedInAndAuthorized()),
    untilDestroyed(this),
  );

  constructor(
    private modalService: ModalService,
    // injectable fields
    private translate: TranslateService,
    private router: Router,
    @Inject(UserSessionService) private userSessionService: UserSessionService,
    private monitoringService: MonitoringService,
    private typeaheadConfig: NgbTypeaheadConfig,
    private selectConfig: NgSelectConfig,
  ) {
    // initialize stuff
    this.monitoringService.init();
    this.initBootstrap();
    this.initTranslations();
  }

  public ngOnDestroy(): void {}

  /*Scroll event handler*/
  @HostListener('window:scroll', ['$event'])
  private onWindowScroll(): void {
    this.isScrolled = window.pageYOffset !== 0;
  }

  private initBootstrap(): void {
    this.typeaheadConfig.editable = false;
    this.selectConfig.bindLabel = 'name';
  }

  private initTranslations(): void {
    // translation service defaults
    this.translate.setDefaultLang('en');
    this.translationsLoaded$ = this.translate.use('en').pipe(map(() => true));
  }
}
