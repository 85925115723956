<!-- main navigation header -->
<nav id="main-nav" [@navbar]="isCollapsed ? 'closed' : 'open'" [class.nav-open]="!isCollapsed" [class.nav-closed]="isCollapsed">
  <div class="nav-brand" [routerLink]="['/']">
    <img class="logo" [src]="'../../../../assets/images/pwc-logo.svg'" alt="PwC" />
    <div class="app-name">Rules and Rates</div>
  </div>

  <div class="nav-item" [ngClass]="{ wide: !isCollapsed }">
    <a (click)="onSelectTab()" [routerLink]="['/']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
      <svg-icon class="icon" src="../../../../assets/images/icons/dashboard.svg"></svg-icon>
      <div [hidden]="isCollapsed">
        {{ 'commons.navbar.dashboard' | translate | uppercase }}
      </div>
    </a>
    <a (click)="onSelectTab()" [routerLink]="['/tax']" [routerLinkActive]="'active'">
      <svg-icon class="icon" src="../../../../assets/images/icons/taxes.svg"></svg-icon>
      <div [hidden]="isCollapsed">
        {{ 'commons.navbar.tax' | translate | uppercase }}
      </div>
    </a>
    <a (click)="onSelectTab()" *ngIf="isUserAdmin" [routerLink]="['/' + RoutePaths.users.list]" [routerLinkActive]="'active'">
      <svg-icon class="icon" src="../../../../assets/images/icons/users.svg"></svg-icon>
      <div [hidden]="isCollapsed">
        {{ 'commons.navbar.users' | translate | uppercase }}
      </div>
    </a>
    <a (click)="onSelectTab()" *ngIf="isUserSuperAdmin" [routerLink]="['/' + RoutePaths.taxDesign.list]" [routerLinkActive]="'active'">
      <svg-icon class="icon" src="../../../../assets/images/icons/edit2.svg"></svg-icon>
      <div [hidden]="isCollapsed">
        {{ 'commons.navbar.tax-design' | translate | uppercase }}
      </div>
    </a>
  </div>
</nav>
