<div id="tax-rule-key-value-layout">
  <!--Display-->
  <div *ngIf="!taxDataService.isEditingMode && !taxDataService.isApprovingMode">
    <div class="ui relaxed divided list">
      <div class="item" *ngFor="let rule of filterKeyValueRules(taxDataService.getTaxTopic(taxDataService.getTax(), topicId).rules)">
        <div class="name">
          <div appDiffAndSearch [content]="rule.title" [searchString]="taxDataService.searchCriteria"></div>
        </div>
        <div class="v">
          <ng-container *ngIf="rule.article && !rule.htmlArticle">
            <div appDiffAndSearch [content]="rule.article" [searchString]="taxDataService.searchCriteria"></div>
          </ng-container>
          <ng-container *ngIf="rule.htmlArticle">
            <!-- req 8 - Veracode & SRT scan fixes - Angular 14 upgradation -[content]="rule.htmlArticle" -->
            <div
              class="ql-editor"
              [content]="rule.htmlArticle"
              [innerHtml]="rule.htmlArticle"
              appDiffAndSearch
              [searchString]="taxDataService.searchCriteria"
              ></div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>

  <!--Edit-->
  <div *ngIf="taxDataService.isEditingMode">
    <ng-container *ngIf="taxDataService.getTaxTopic(taxDataService.taxCloneForEditing, topicId) as taxTopic">
      <div class="hr" *ngIf="taxTopic.rates.length > 0"></div>

      <div class="item edit" *ngFor="let rule of filterKeyValueRules(taxTopic.rules); index as i; last as last; first as first">
        <div class="d-flex justify-content-between align-items-center">
          <div class="rule-counter">
            {{ 'tax.tax-topics.rule-label' | translate }} {{ taxTopic.rates.length + i + 1 }} ({{ rule.shortCode }})
          </div>
          <div class="d-flex">
            <ng-container *ifHasRole="[role.EDITOR, role.APPROVER, role.ADMIN, role.SUPER_ADMIN]; requestedTax: taxDataService.getTax()">
              <button class="btn btn-sm btn-outline-primary btn-icon arrow-button mr-2"
                      (click)="changeRuleOrder(rule, true)" *ngIf="!first">
                <svg-icon class="icon arrow-up" src="../../../../assets/images/icons/arrow-down.svg"></svg-icon>
              </button>
              <button class="btn btn-sm btn-outline-primary btn-icon arrow-button mr-2"
                      (click)="changeRuleOrder(rule, false)" *ngIf="!last">
                <svg-icon class="icon" src="../../../../assets/images/icons/arrow-down.svg"></svg-icon>
              </button>
            </ng-container>
            <ng-container *ifHasRole="[role.ADMIN, role.SUPER_ADMIN]; requestedTax: taxDataService.getTax()">
              <button class="btn btn-sm btn-primary btn-icon btn-delete-rule" (click)="deleteRuleRow(rule, i)">
                <svg-icon class="icon" src="../../../../assets/images/icons/trash.svg"></svg-icon>
                <span>{{ 'tax.tax-topics.rule-delete' | translate }}</span>
              </button>
            </ng-container>
          </div>
        </div>
        <div class="name">
          <ng-container *ifHasRole="[role.ADMIN, role.SUPER_ADMIN]; requestedTax: taxDataService.getTax(); else: staticLabelName">
            <label>{{ 'tax.tax-topics.rule-label' | translate }}</label>
            <input
              #ruleName="ngModel"
              type="text"
              class="title form-control"
              [class.show-feedback]="ruleName.invalid && (rule.id > 0 || ruleName.touched)"
              [(ngModel)]="rule.title"
              required
              [minlength]="validationConstants.ruleMinLength"
              [maxlength]="validationConstants.ruleMaxLength"
              data-test="rule name" />

            <div class="invalid-feedback" *ngIf="ruleName.invalid && (rule.id > 0 || ruleName.touched)">
              <div class="invalid-feedback__message" *ngIf="ruleName.errors.minlength">
                <i class="fa fa-exclamation-triangle mr-1"></i>
                {{ 'commons.validation.minlength' | translate: { minlength: ruleName.errors.minlength.requiredLength } }}
              </div>
              <div class="invalid-feedback__message" *ngIf="ruleName.errors.required">
                <i class="fa fa-exclamation-triangle mr-1"></i>
                {{ 'commons.validation.required' | translate }}
              </div>
            </div>
          </ng-container>
          <ng-template #staticLabelName>
            <p>{{ rule.title }}</p>
          </ng-template>
        </div>
        <label>{{ 'tax.tax-topics.rule-value' | translate }}</label>
        <div class="v2 edit">
          <app-rich-text-editor name="rich-text-editor-{{ i }}" [(ngModel)]="rule.richArticle"></app-rich-text-editor>
        </div>

        <div class="hr" *ngIf="!last"></div>
      </div>

      <ng-container *ngIf="enableAdd">
        <div class="btn-container" *ifHasRole="[role.ADMIN, role.SUPER_ADMIN]; requestedTax: taxDataService.getTax()">
          <button class="btn btn-sm btn-primary btn-icon" (click)="addNewRuleRow()">
            <svg-icon class="icon" src="../../../../assets/images/icons/add2.svg"></svg-icon>
            <span>{{ 'tax.tax-topics.rule-add' | translate }}</span>
          </button>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <!--Approve-->
  <div *ngIf="taxDataService.isApprovingMode">
    <div class="ui relaxed divided list">
      <div class="item" *ngFor="let rule of filterKeyValueRules(taxDataService.getTaxTopic(taxDataService.getTax(), topicId).rules)">
        <div class="name">
          <p>{{ rule.title }} ({{ rule.shortCode }})</p>
        </div>

        <div class="v">
          <div *ngIf="!isRuleChanged(rule.shortCode); else changed">
            <ng-container *ngIf="rule.article && !rule.htmlArticle">
              {{ rule.article }}
            </ng-container>
            <ng-container *ngIf="rule.htmlArticle">
              <div class="ql-editor" [innerHtml]="rule.htmlArticle"></div>
            </ng-container>
          </div>

          <ng-template #changed>
            <div class="change align">
              <span [outerHTML]="getRuleDiff(rule.shortCode).new | diffPipe: getRuleDiff(rule.shortCode).old"></span>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
