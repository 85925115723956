<ng-container *ngIf="!taxTypeLocations; then loading; else locationsForm"></ng-container>
<ng-container *ngIf="locationsSaving; then loading"></ng-container>

<ng-template #loading>
  <div class="ui active inline centered loader"></div>
</ng-template>

<ng-template #locationsForm>
  <app-tax-type-locations-form
    [class.d-none]="locationsSaving"
    [taxTypeLocations]="taxTypeLocations"
    (locationsSavingChange)="locationsSaving = $event"></app-tax-type-locations-form>
</ng-template>
