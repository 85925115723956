import { ArticleType } from '@rar/model/data/enums/ArticleType';

import { TaxRevision } from '../../data/tax/TaxRevision';
import { TaxTopic } from '../../data/tax/TaxTopic';

export class TaxCreateRequest {
  constructor(revisionData: TaxRevision) {
    this.description = revisionData.description;

    const tmp = revisionData.effectiveFrom;
    this.effectiveFrom = new Date(Date.UTC(tmp.getFullYear(), tmp.getMonth(), tmp.getDate(), tmp.getHours(), tmp.getMinutes()));

    if (revisionData.effectiveTo) {
      Object.assign(this, { effectiveTo: revisionData.effectiveTo });
    }

    this.taxTopics = this.transformRevisionData(revisionData.taxTopics);
  }

  description: string;
  effectiveFrom: Date;
  effectiveTo: Date;

  taxTopics: Array<any>;

  private transformRevisionData(topics: Array<TaxTopic>) {
    const result: Array<any> = [];

    // map subtopics and rules
    if (topics) {
      for (let i = 0; i < topics.length; i++) {
        const topic = topics[i];
        const rules = topics[i].rules;
        const rates = topics[i].rates;

        const mappedRules: Array<any> = [];
        const mappedRates: Array<any> = [];

        if (rules) {
          // map the rules to payload format
          for (let j = 0; j < rules.length; j++) {
            const rule = rules[j];
            const isKeyValueType = rule.articleTypeId === ArticleType.KEY_VALUE;

            mappedRules.push({
              title: rule.title,
              article: isKeyValueType && rule.richArticle ? null : rule.article,
              richArticle: isKeyValueType && rule.richArticle ? JSON.stringify(rule.richArticle) : null,
              articleTypeId: rule.articleTypeId,
              shortCode: rule.shortCode,
              topicShortCode: rule.topicShortCode,
              ruleOrder: rule.ruleOrder,
            });
          }
        }

        if (rates) {
          // map the rates to payload format
          for (let j = 0; j < rates.length; j++) {
            const rate = rates[j];
            const ratePayload = {
              name: rate.name,
              valueTypeId: rate.valueTypeId,
              shortCode: rate.shortCode,
              topicShortCode: rate.topicShortCode,
            };

            if (rate.description !== null) {
              Object.assign(ratePayload, { description: rate.description });
            }

            if (rate.max !== null) {
              Object.assign(ratePayload, { max: rate.max });
            }

            if (rate.min !== null) {
              Object.assign(ratePayload, { min: rate.min });
            }

            if (rate.value !== null) {
              Object.assign(ratePayload, { value: rate.value });
            }

            mappedRates.push(ratePayload);
          }
        }

        const topicPayload = {
          shortCode: topic.shortCode,
          name: topic.name,
          description: topic.description,
          order: topic.order,
        };

        if (mappedRates.length > 0) {
          Object.assign(topicPayload, { rates: mappedRates });
        }

        if (mappedRules.length > 0) {
          Object.assign(topicPayload, { rules: mappedRules });
        }

        if (topic.subTopics) {
          Object.assign(topicPayload, { subTopics: this.transformRevisionData(topic.subTopics) });
        }

        result.push(topicPayload);
      }
    }

    return result;
  }
}
