<div class="modal-header">
  <h4 class="modal-title">{{ 'tax.tax-topics.category-reorder-title' | translate }}</h4>
</div>

<div class="modal-body">
  <div class="mb-1" *ngFor="let topic of sortTopics(topics); let i = index; first as first; last as last" id="topic-{{ topic.id }}">
    <div class="header d-flex align-items-center border px-3 py-2">
      <div class="title text-truncate pr-3" [title]="topic.name">
        {{ topic.name || '(missing name)' }}
      </div>
      <div class="ml-auto d-flex">
        <button
          class="btn btn-sm btn-outline-primary btn-icon arrow-button"
          [ngClass]="last ? 'last' : 'mr-2'"
          (click)="changeTopicOrder(i, true)"
          *ngIf="!first">
          <svg-icon class="icon arrow-up" src="../../../../assets/images/icons/arrow-down.svg"></svg-icon>
        </button>
        <button class="btn btn-sm btn-outline-primary btn-icon arrow-button mr-2" (click)="changeTopicOrder(i, false)" *ngIf="!last">
          <svg-icon class="icon" src="../../../../assets/images/icons/arrow-down.svg"></svg-icon>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="button-group">
    <button class="ui basic button silver" (click)="cancel()">
      {{ 'commons.modal.cancel' | translate }}
    </button>
    <button class="ui basic button rose" (click)="approve()">
      {{ 'tax.tax-topics.category-reorder-confirm' | translate }}
    </button>
  </div>
</div>
