/**
 * Root level Tax object.
 * Has [[TaxRevision]](s) that contain the changes in a differential manner.
 */
import { DatabaseEntity } from '../DatabaseEntity';
import { Location } from '../location/Location';
import { TaxRevision } from './TaxRevision';

export class Tax extends DatabaseEntity {
  /**
   * Tax unique identifier.
   */
  public id: number;

  /**
   * Tax full name.
   */
  public name: string;

  /**
   * Tax's associated location or jurisdiction. Points to [[Location]]'s ID.
   */
  public location: Location;

  /**
   * Tax type ID.
   */
  public type: number;

  /**
   * Array of all related revisions to the [[Tax]]
   */
  public revisions: Array<TaxRevision>;
}
