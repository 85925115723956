import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

import { TaxTypeLocationFormRow } from '../../models/tax-type-location-form-row';
import { TaxTypeLocationsFormDataService } from '../../services/tax-type-locations-form/tax-type-locations-form-data.service';
import { TaxTypeLocationsFormFieldsService } from '../../services/tax-type-locations-form/tax-type-locations-form-fields.service';

@Component({
  selector: 'app-tax-type-locations',
  templateUrl: './tax-type-locations.component.html',
  styleUrls: ['./tax-type-locations.component.scss'],
})
export class TaxTypeLocationsComponent implements OnInit {
  constructor(
    private locationsFormFieldsService: TaxTypeLocationsFormFieldsService,
    private locationsFormDataService: TaxTypeLocationsFormDataService,
  ) {}

  @Input()
  locationsFormGroup!: UntypedFormGroup;

  @Output()
  readonly addLocationClicked = new EventEmitter<void>();

  @Output()
  readonly deleteLocationClicked = new EventEmitter<number>();

  locationsTable: UntypedFormArray;

  isLoaded$ = this.locationsFormDataService.isCommonDataLoaded$;

  ngOnInit() {
    this.locationsTable = this.locationsFormGroup.get('locationsTable') as UntypedFormArray;
  }

  getDataSourcesForRow(index: number): TaxTypeLocationFormRow {
    return this.locationsFormFieldsService.getDataSourcesForRow(index);
  }
}
