import { NgbModalConfig, NgbPopoverConfig } from "@ng-bootstrap/ng-bootstrap";

export function bootstrapPopoverConfigurationInit(
	popverConfig: NgbPopoverConfig
) {
	return () => {
		popverConfig.triggers = "mouseenter:mouseleave";
	};
}

export function bootstrapModalConfigurationInit(modalConfig: NgbModalConfig) {
	return () => {
		modalConfig.centered = true;
		modalConfig.modalDialogClass = "modal-fit";
	};
}
