<div id="tax-sub-menu-layout">
  <ngb-accordion class="tax-tree" [closeOthers]="true" [activeIds]="activeTopicPanelId" (panelChange)="beforeChange($event)">
    <ngb-panel *ngFor="let topic of sortTopics(topics); let i = index" id="topic-{{ topic.id }}">
      <ng-template ngbPanelTitle>
        <div class="header-container" (click)="onSelectTopic(topic)">
          <div class="header">
            <div
              class="title text-truncate"
              [title]="topic.name"
              [ngClass]="{
                highlighted: isSearchResult(topic),
                changed: taxDataService.isApprovingMode && topicChangeHighlight(topic),
                active: activeTopic === topic
              }">
              <ng-container *ngIf="taxDataService.isEditingMode; else staticCategoryName">
                <ng-container *ngIf="taxDataService.getTaxTopic(taxDataService.taxCloneForEditing, topic.id) as editedCategory">
                  <span>{{ editedCategory.name || '(missing name)' }}</span>
                </ng-container>
              </ng-container>
              <ng-template #staticCategoryName>
                <span>{{ topic.name }}</span>
              </ng-template>
            </div>
            <div class="sub-title">
              <!-- Req 8 - Veracode & SRT scan fixes - Angular 14 upgradation -->
              <span i18n>
                { topic.subTopics ? topic.subTopics.length : 0, plural, =0 { {{ 'tax.tax-topics.no-topics' | translate }} } =1 { 1
                  {{ 'tax.tax-topics.topic-singular' | translate }} } other { {{ topic.subTopics ? topic.subTopics.length : 0}}
                  {{ 'tax.tax-topics.topic-plural' | translate }} } }
              </span>
            </div>
          </div>

          <svg-icon class="icon indicator" src="/assets/images/icons/circle-arrow.svg"></svg-icon>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <button
          *ngFor="let subTopic of topic.subTopics"
          [ngClass]="{
            highlighted: isSearchResult(subTopic),
            changed: taxDataService.isApprovingMode && topicChangeHighlight(subTopic),
            active: activeTopic === subTopic
          }"
          (click)="onSelectTopic(subTopic)"
          class="sub-topic">
          <ng-container *ngIf="taxDataService.isEditingMode; else staticTopicName">
            <ng-container *ngIf="taxDataService.getTaxTopic(taxDataService.taxCloneForEditing, subTopic.id) as editedTopic">
              <span>{{ editedTopic.name || '(missing name)' }}</span>
            </ng-container>
          </ng-container>
          <ng-template #staticTopicName>
            <span>{{ subTopic.name }}</span>
          </ng-template>
        </button>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>

  <ng-container *ifHasRole="[Role.ADMIN, Role.SUPER_ADMIN]; requestedTax: taxDataService.getTax()">
    <div *ngIf="taxDataService.isEditingMode">
      <!--Add new category button-->
      <button class="btn btn-primary btn-icon mt-4" (click)="addNewCategory()">
        <svg-icon class="icon" src="../../../../assets/images/icons/add2.svg"></svg-icon>
        <span>{{ 'tax.tax-topics.category-add' | translate }}</span>
      </button>
    </div>
  </ng-container>
</div>
