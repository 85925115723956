import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { finalize, shareReplay } from 'rxjs/operators';

import { RoutePaths } from '@rar/commons/constants/route-paths';
import { ModalService } from '@rar/commons/services/modal.service';
import { TaxLock } from '@rar/model/data/tax/TaxLock';
import { TaxType } from '@rar/model/data/tax/TaxType';
import { ApiCommunicationService } from '@rar/model/services/api-communication/api-communication.service';

@Component({
  selector: 'app-tax-types-table',
  templateUrl: './tax-types-table.component.html',
  styleUrls: ['./tax-types-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaxTypesTableComponent implements OnInit, OnDestroy {
  public taxTypes: Array<TaxType>;

  public routePaths = RoutePaths.taxDesign;

  constructor(
    private router: Router,
    private modalService: ModalService,
    private translateService: TranslateService,
    private api: ApiCommunicationService,
    private cdr: ChangeDetectorRef,
  ) {
    this.loadTaxTypes();
  }

  ngOnInit() {}

  ngOnDestroy() {}

  private loadTaxTypes() {
    this.api
      .tax()
      .getAllTaxTypes()
      .pipe(
        shareReplay(1),
        untilDestroyed(this),
        finalize(() => {
          this.cdr.markForCheck();
        }),
      )
      .subscribe(
        (taxTypes) => {
          this.taxTypes = taxTypes;
        },
        (error) => {
          error.handled = true;
          this.taxTypes = null;
        },
      );
  }

  public updateTable() {
    this.loadTaxTypes();
  }

  public async editTaxType(taxTypeId: number): Promise<void> {
    if (!(await this.isTaxLocked(taxTypeId))) {
      this.router.navigate(['/' + this.routePaths.getEditPath(taxTypeId)]);
    }
  }

  private async isTaxLocked(taxTypeId: number): Promise<boolean> {
    const info: TaxLock = await this.api.tax().getTaxTypeLockInfo(taxTypeId).toPromise();
    const isTaxTypeEdited = !info || (!info.ok && info.action === 'locked by another user');
    const isTaxEdited = info && !info.ok && info.action === 'tax-editing';

    if (isTaxTypeEdited || isTaxEdited) {
      this.modalService
        .showInformation({
          title: this.translateService.instant('tax-design.messages.cannot-edit-tax-type'),
          content: this.translateService
            .instant(isTaxTypeEdited ? 'tax-design.messages.lock-tax-type-is-edited' : 'tax-design.messages.lock-tax-is-edited')
            .split('\n'),
        })
        .then();

      return true;
    }

    return false;
  }

  public showTaxTypeStatus(taxType: TaxType) {
    const failedTaxes = taxType.propagationStatus.failedTax?.map((tax) => tax.name);
    this.modalService
      .openConfirmation({
        title: this.translateService.instant('tax-design.messages.tax-propagation-status'),
        content: this.translateService
          .instant('tax-design.messages.tax-propagation-content', {
            date: new Date(taxType.propagationStatus.lastPropagationDate).toLocaleString(),
            tax: taxType.name,
            locations: failedTaxes,
          })
          .split('\n'),
        yesButtonText: this.translateService.instant('commons.modal.clear-status'),
        noButtonText: this.translateService.instant('commons.modal.close'),
      })
      .then(
        () => this.clearTaxTypeStatus(taxType.id),
        () => {},
      );
  }

  private clearTaxTypeStatus(taxTypeId: number) {
    this.api.tax().clearPropagationStatus(taxTypeId).subscribe((types) => {
      this.taxTypes = types;
      this.cdr.markForCheck();
    });
  }
}
