import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LocationWithTaxTypes } from '@rar/model/data/location/LocationWithTaxTypes';

import { Location } from '../data/location/Location';
import { ValidationError } from '../exception/ValidationError';
import { ResponseWrapper } from '../response/wrappers/ResponseWrapper';
import { AbstractApiConnector } from './AbstractApiConnector';

export class LocationApiConnector extends AbstractApiConnector {
  // Api base url
  protected readonly apiRoute = '/api/v1/location';

  public getLocations(): Observable<Array<Location>> {
    return this.apiClient.get(this.apiRoute);
  }

  public getCountriesTree(): Observable<Array<LocationWithTaxTypes>> {
    return this.apiClient
      .get<LocationWithTaxTypes[]>(this.apiRoute + '/tree')
      .pipe(map((locations: LocationWithTaxTypes[]) => this.assignParentLocations(locations)));
  }

  private assignParentLocations<T extends Location>(locations: T[]): T[] {
    if (!locations) {
      return [];
    }

    // set parent location for subdivisions - it's not sent in JSON because of circular references
    locations.forEach((location) => {
      if (location.children && location.children.length) {
        location.children.forEach((child) => {
          child.parent = location;
        });
      }
    });

    return locations;
  }

  public getAllCountriesTree(): Observable<Array<Location>> {
    return this.apiClient
      .get<Location[]>(this.apiRoute + '/tree/all')
      .pipe(map((locations: Location[]) => this.assignParentLocations(locations)));
  }

  public getCountriesTreeForSearch(): Observable<Array<LocationWithTaxTypes>> {
    return this.apiClient.get<LocationWithTaxTypes[]>(this.apiRoute + '/tree-search').pipe(
      map((locations: LocationWithTaxTypes[]) => {
        return locations ?? [];
      }),
    );
  }

  public getLocation(locationId: number): Observable<ResponseWrapper<Location>> {
    if (typeof locationId !== 'number') {
      throw new ValidationError();
    } else {
      return this.apiClient.get(this.apiRoute + '/' + locationId);
    }
  }

  public searchLocation(query: string): Observable<Array<Location>> {
    return this.apiClient.get(this.apiRoute + '/search', { filter: query });
  }
}
