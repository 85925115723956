<div id="audit-legend-layout">
	<img [src]="'../../../../assets/images/icons/user_approver.svg'"
		ngbPopover="Approver action" placement="bottom">
	<img [src]="'../../../../assets/images/icons/user_admin.svg'"
		ngbPopover="Admin action" placement="bottom">
	<img [src]="'../../../../assets/images/icons/user_editor.svg'"
		ngbPopover="Editor action" placement="bottom">
    <!-- req 3 -->
		<img [src]="'../../../../assets/images/icons/history-delete.svg'"
		ngbPopover="Approver action" placement="bottom">
</div>
