import { Component } from "@angular/core";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: "app-tax-discard-modal",
	templateUrl: "./tax-discard-modal.component.html",
	styleUrls: ["./tax-discard-modal.component.scss"]
})
export class TaxDiscardModalComponent {

	constructor(private activeModal: NgbActiveModal) { }

	approve(): void {
		this.activeModal.close(null);
	}

	deny(): void {
		this.activeModal.dismiss(null);
	}
}
