<div class="list-group ">
  <div *ngFor="let category of categories">
    <div class="custom-control custom-checkbox">
      <input class="custom-control-input mr-2" type="checkbox"
             [disabled]="category.isPropagated && !category.isUpdated"
             [(ngModel)]="category.toPropagate"
             (ngModelChange)="propagateToParents(category)"
             [id]="category.shortCode+'Checkbox'">

      <label [for]="category.shortCode+'Checkbox'" class="d-block font-weight-normal custom-control-label">
        <div class="text-truncate">{{category.shortCode}} &ndash; {{category.name}}</div>
      </label>
    </div>
    <app-tax-restore-modal-tree class="mt-2 ml-4" [categories]="category.subTopics"></app-tax-restore-modal-tree>
  </div>
</div>
