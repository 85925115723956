import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Input, Output, EventEmitter, OnDestroy
} from '@angular/core';
import {TaxDataService} from '@rar/tax/services/tax-data/tax-data.service';
import {ArticleType} from '@rar/model/data/enums/ArticleType';
import {ShortCodeConstants} from '@rar/commons/constants';
import {Role} from '@rar/model/data/enums/Role';
import {TranslateService} from '@ngx-translate/core';
import {ModalService} from '@rar/commons/services/modal.service';

type Articles = Array<Record<string, string>>;

@Component({
  selector: 'app-tax-table-column-container',
  templateUrl: './tax-table-column-container.component.html',
  styleUrls: ['./tax-table-column-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaxTableColumnContainerComponent implements OnInit, OnDestroy {

  constructor(private cdr: ChangeDetectorRef,
              private translateService: TranslateService,
              private modalService: ModalService,
              public taxService: TaxDataService) { }

  @Input() topic;

  @Output()
  updateArticlesList = new EventEmitter<Articles>();

  rules = [];
  public readonly role = Role;

  ngOnInit(): void {
    if (!this.taxService.editColumns || !this.taxService.editColumns.length && this.taxService.getTableArticle()) {
      this.taxService.initTableArticle(this.topic.id);
    }
    this.rules = this.taxService.getTaxTopic(this.taxService.taxCloneForEditing, this.topic.id).rules;
    const articles = this.taxService.getTaxTopic(this.taxService.taxCloneForEditing, this.topic.id).rules[0]?.article;

    this.taxService.setTableArticle(articles ? this.taxService.setArticlesIndexes(JSON.parse(articles)) : []);
  }


  public deleteColumnHandler(columnIndex: number): void {
    this.modalService
      .openConfirmation({
        title: this.translateService.instant('tax.tax-topics.confirm-delete'),
        yesButtonText: this.translateService.instant('tax.tax-topics.confirm-delete'),
        noButtonText: this.translateService.instant('user.user-edit-modal.cancel'),
        content: this.translateService.instant('tax.tax-topics.delete-column-modal-content')
      })
      .then(() => {
        const deleteProperty = this.taxService.editColumns[columnIndex];
        let articles = this.taxService.updateTableArticles(this.taxService.getTableArticle(), this.taxService.editColumns);
        this.taxService.editColumns.splice(columnIndex, 1);
        if (articles && articles.length) {
          articles.forEach((article) => {
            // delete column from each row
            if (article[deleteProperty] != null) {
              delete article[deleteProperty];
            }
          });
          // empty list if the last column is deleted
          if (!this.taxService.editColumns.length) {
            articles = [];
          }
          this.updateArticles(this.taxService.setArticlesIndexes(articles));
        }
      }, () => {},
    );
  }

  public addNewColumnHandler(): void {
    const newColumn = 'Column ' + (this.taxService.editColumns.length + 1);
    this.taxService.editColumns.push(newColumn);
    const articles = this.taxService.getTableArticle() || [];

    if (!articles || !articles.length) {
      if (!this.rules.length) {
        // create new rule
        this.rules.push({
          article: '[{}]',
          articleTypeId: ArticleType.JSON,
          topicShortCode: this.topic.shortCode,
          shortCode: ShortCodeConstants.newPlaceholder,
          title: this.topic.name,
          ruleOrder: 1
        });

      } else if (!this.rules[0].article) {
        this.rules[0].article = '[{}]';
      }

      this.taxService.setTableArticle(JSON.parse(this.rules[0].article));
    }
    if (articles) {
      if (articles.length) {
        // add new column to each row
        articles.forEach(article => {
          article[this.taxService.editColumns.indexOf(newColumn).toString()] = '';
        });
      } else {
        // add new row if there is just one column without rows
        const newRow = {};
        newRow[this.taxService.editColumns.indexOf(newColumn).toString()] = '';
        this.taxService.setId(this.topic.id);
        articles.push(newRow);

      }
      this.updateArticles(articles);
    }
  }


  public onUpdateColumnName(column): void {
    this.taxService.editColumns[column.index] = column.columnName;
    const articles = this.taxService.getTableArticle();

    if (articles && articles.length) {
     this.updateArticles(articles);
    }
  }

  private updateArticles(articles: Articles): void {
    this.updateArticlesList.emit(articles);

    const a = this.taxService.updateTableArticles(articles, this.taxService.editColumns);

    this.taxService.getTaxTopic(this.taxService.taxCloneForEditing, this.topic.id).rules[0].article = JSON.stringify(a);

    this.rules[0].article = JSON.stringify(a);
    this.taxService.setTableArticle(articles);

    this.cdr.markForCheck();
    this.taxService.updateTable();
  }

  trackByFn(index, item) {
    return index;
  }

  deleteRule(topic) {
    this.modalService
      .openConfirmation({
        title: this.translateService.instant('tax.tax-topics.rule-delete-title'),
        yesButtonText: this.translateService.instant('tax.tax-topics.rule-delete-confirm'),
        noButtonText: this.translateService.instant('commons.modal.cancel'),
        content: this.translateService.instant('tax.tax-topics.rule-delete-content'),
        isDestructiveWarning: true
      })
      .then(
        () => {
          this.topic.rules = [];
          this.taxService.getTaxTopic(this.taxService.taxCloneForEditing, topic.id).rules = [];
          this.taxService.setTableArticle([]);
          this.taxService.editColumns.length = 0;
          this.cdr.markForCheck();
        },
        () => {},
      );
  }

  ngOnDestroy() {
  }
}
