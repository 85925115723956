<div id="tax-info-layout">
  <div class="header">
    <h1>{{ tax.name }}</h1>
    <div class="d-flex align-items-center">
      <div class="button-group d-flex">
        <div class="button-group" *ngIf="taxDataService.isPermitted">
          <!--Close revision, available only if revision's status is rejected or pending-->
          <button
            class="btn btn-outline-secondary mr-2"
            [disabled]="!taxDataService.taxLockInfo.ok"
            *ngIf="
              isEditor &&
              (taxDataService.getTax().status === getStatusPendingLocal() ||
                (taxDataService.getTax().status === getStatusPendingGlobal() && getRole() != role.APPROVER) ||
                taxDataService.getTax().status === getStatusRejected()) &&
              !taxDataService.isEditingMode
            "
            (click)="taxDataService.onClickClose()">
            {{ 'tax.tax-rates.discard' | translate }}
          </button>
        </div>

        <!--Start assesment button-->
        <ng-container
          *ifHasRole="[Role.APPROVER, Role.ADMIN, Role.SUPER_ADMIN]; requestedTax: taxDataService.getTax(); excludeLocalAdmin: true">
          <button
            class="btn btn-outline-secondary btn-icon mr-2 ml-2"
            [disabled]="!taxDataService.taxLockInfo.ok"
            *ngIf="
              (taxDataService.getTax().status === getStatusPendingLocal() ||
                (taxDataService.getTax().status === getStatusPendingGlobal() && getRole() != role.APPROVER)) &&
              !taxDataService.isApprovingMode &&
              taxDataService.isPermitted &&
              !taxDataService.isEditingMode
            "
            (click)="taxDataService.onClickStartAssessment()">
            <svg-icon [src]="'../../../../assets/images/icons/edit.svg'"></svg-icon>
            <span>{{ 'tax.tax-rates.approve' | translate }}</span>
          </button>

          <!--Discard approving button-->
          <button
            class="btn btn-outline-secondary btn-icon"
            *ngIf="
              (taxDataService.getTax().status === getStatusPendingLocal() ||
                (taxDataService.getTax().status === getStatusPendingGlobal() && getRole() != role.APPROVER)) &&
              taxDataService.isApprovingMode &&
              !taxDataService.isEditingMode
            "
            (click)="taxDataService.isApprovingMode = false">
            <svg-icon class="cancel" [src]="'../../../../assets/images/icons/add.svg'"></svg-icon>
            <span>{{ 'tax.tax-rates.discard-approve' | translate }}</span>
          </button>
        </ng-container>
      </div>
      <div class="button-group d-flex" *ngIf="taxDataService.isEditingMode">
        <!--Cancel editing button-->
        <button class="btn btn-outline-secondary btn-icon mr-2" (click)="taxDataService.onClickCancelEditing()">
          <svg-icon class="cancel" [src]="'../../../../assets/images/icons/add.svg'"></svg-icon>
          <span>{{ 'tax.tax-rates.cancel' | translate }}</span>
        </button>

        <!--Submit changes button-->
        <button class="btn btn-primary" (click)="taxDataService.onClickDoneEditing()" [disabled]="isDoneEditingDisabled$ | async">
          {{ 'tax.tax-rates.submit' | translate }}
        </button>
      </div>

      <div #actionDropdown="ngbDropdown" ngbDropdown class="action-menu d-inline-block" *ngIf="!taxDataService.isEditingMode">
        <button class="btn btn-sm btn-outline-primary" id="action-dropdown-1" ngbDropdownToggle>
          {{ 'tax.tax-actions.actions' | translate }}
          <svg-icon class="icon" [class.open]="actionDropdown.isOpen()" src="/assets/images/icons/circle-arrow.svg"></svg-icon>
        </button>

        <div ngbDropdownMenu aria-labelledby="action-dropdown-1">
          <button
            ngbDropdownItem
            *ifHasRole="[role.EDITOR, role.APPROVER, role.ADMIN, role.SUPER_ADMIN]; requestedTax: tax"
            [disabled]="
              taxDataService.isEditingMode ||
              !taxDataService.taxLockInfo.ok ||
              taxDataService.isTaxRevisionLocked ||
              taxDataService.isTaxRejected
            "
            (click)="taxDataService.onClickEdit()">
            <svg-icon class="icon" [src]="'/assets/images/icons/edit2.svg'"></svg-icon>
            {{ 'tax.tax-actions.edit-tax' | translate }}
          </button>
          <!-- Req 4 -->
          <button ngbDropdownItem
            *ifHasRole="[role.ADMIN, role.SUPER_ADMIN,role.EDITOR, role.VIEWER, role.APPROVER]; requestedTax: tax"
           [routerLink]="['history']">
            <svg-icon class="icon" [src]="'/assets/images/icons/library.svg'"></svg-icon>
            {{ 'tax.tax-actions.history' | translate }}
          </button>

          <label
            for="file-upload"
            class="file-upload-button mb-0"
            ngbDropdownItem
            *ifHasRole="[role.EDITOR, role.APPROVER, role.ADMIN, role.SUPER_ADMIN]; requestedTax: tax"
            [disabled]="
              taxDataService.isEditingMode ||
              !taxDataService.taxLockInfo.ok ||
              taxDataService.isTaxRevisionLocked ||
              taxDataService.isTaxRejected
            ">
            <svg-icon class="icon" [src]="'/assets/images/icons/import.svg'"></svg-icon>
            {{ 'tax.tax-actions.import' | translate }}
          </label>
          <input
            #inputElement
            id="file-upload"
            class="file-upload-input"
            type="file"
            accept=".xlsx"
            (change)="onImportClick($event.target, inputElement)" />
          <!-- Req 4 -->
          <button
            ngbDropdownItem
            *ifHasRole="[role.EDITOR, role.APPROVER, role.ADMIN, role.SUPER_ADMIN, role.VIEWER]; requestedTax: tax"
            [disabled]="
              taxDataService.isEditingMode ||
              !taxDataService.taxLockInfo.ok ||
              taxDataService.isTaxRevisionLocked ||
              !(taxDataService.taxExportAvailable$ | async)
            "
            (click)="taxDataService.onClickExport()">
            <svg-icon class="icon" [src]="'/assets/images/icons/export.svg'"></svg-icon>
            {{ 'tax.tax-actions.export' | translate }}
          </button>
          <button ngbDropdownItem *ifHasRole="[role.ADMIN, role.SUPER_ADMIN]; requestedTax: tax" (click)="restoreGlobalTemplate()">
            <svg-icon class="icon" [src]="'/assets/images/icons/first-aid.svg'"></svg-icon>
            {{ 'tax.restore-global-template.action' | translate }}
          </button>

          <ng-container *ifHasRole="[role.ADMIN, role.SUPER_ADMIN]; requestedTax: tax">
            <div class="dropdown-divider"></div>
            <button ngbDropdownItem [routerLink]="['settings']">
              <svg-icon class="icon" [src]="'/assets/images/icons/settings.svg'"></svg-icon>
              {{ 'tax.tax-actions.settings' | translate }}
            </button>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="content">
    <div class="info">
      <p>{{ 'tax.tax-info.jurisdiction' | translate }}:</p>
      <p class="value">{{ tax.location.name }}</p>
      <p>{{ 'tax.tax-info.validity' | translate }}:</p>
      <p class="value">{{ tax.effectiveFrom | date: 'yyyy' }} - {{ tax.effectiveTo | date: 'yyyy' }}</p>
      <p>{{ 'tax.tax-info.status' | translate }}:</p>
      <span [appRevisionStatus]="tax.status" class="dot"></span>
      <p class="value">{{ 'tax.tax-info.' + taxDataService.formatTaxRevisionStatus(tax.status) | translate }}</p>
      <p>{{ 'tax.tax-info.approver' | translate }}:</p>
      <a (click)="onApproverList()" href="javascript:void(0)" class="approver">Show list of approvers</a>

      <div class="lock" *ngIf="taxDataService.taxLockInfo && !taxDataService.taxLockInfo.ok && (isEditor || isApprover)">
        <i class="fas fa-lock" *ngIf="!(isApprover && taxDataService.taxLockInfo.action === null)"></i>
        <p *ngIf="taxDataService.taxLockInfo.action === 'structure'">Locked for editing:</p>
        <p *ngIf="taxDataService.taxLockInfo.action === 'editing'">Locked for editing:</p>
        <p *ngIf="taxDataService.taxLockInfo.action === 'approval'">Locked for approval:</p>
        <p
          *ngIf="taxDataService.taxLockInfo.action === null && isEditor"
          ngbPopover="Must be closed or approved before updating or creating a new one."
          placement="bottom">
          There is a pending or rejected revision for this tax.
        </p>
        <p *ngIf="!!taxDataService.taxLockInfo.action && taxDataService.taxLockInfo.action !== 'open'" class="approver">
          {{ taxDataService.taxLockInfo.user.name }}
        </p>
      </div>
    </div>

    <div id="search">
      <div class="search-input">
        <div *ngIf="isSearchMode && !taxDataService.isEditingMode" class="ui input">
          <input
            (keyup)="taxDataService.onClickSearch(triggerNavigationPredicate())"
            type="text"
            [(ngModel)]="taxDataService.searchCriteria"
            placeholder="Search..." />
        </div>

        <button
          class="ui basic button icon"
          *ngIf="!isSearchMode"
          ngbPopover="Search"
          placement="top"
          [disabled]="taxDataService.isEditingMode"
          (click)="onClickSearch()">
          <svg-icon [src]="'./assets/images/icons/search.svg'"></svg-icon>
        </button>

        <button
          class="ui basic button icon close-search"
          *ngIf="isSearchMode"
          ngbPopover="Quit Search"
          placement="top"
          [disabled]="taxDataService.isEditingMode"
          (click)="onClickSearch()">
          <svg-icon [src]="'/assets/images/icons/add.svg'"></svg-icon>
        </button>
      </div>
      <div class="results" *ngIf="taxDataService.searchResult && !taxDataService.isEditingMode">
        <a class="ui basic" [routerLink]="['search']">{{ 'tax.tax-topics.search-results' | translate }}</a>
      </div>
    </div>
  </div>

  <hr />
</div>
