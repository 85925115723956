import { Observable } from 'rxjs';

import { Util } from '../../commons/util/Util';
import { Role } from '../data/enums/Role';
import { User } from '../data/user/User';
import { ValidationError } from '../exception/ValidationError';
import { AbstractApiConnector } from './AbstractApiConnector';

export class PermissionsApiConnector extends AbstractApiConnector {
  // Api base url
  protected readonly apiRoute = '/api/v1/user';
  protected readonly apiRouteTax = '/api/v1/tax';

  public getAssignedUsers(taxId: number, role: Role): Observable<Array<User>> {
    if (!Util.existValueInEnum(Role, role) || typeof taxId !== 'number') {
      throw new ValidationError();
    } else {
      return this.apiClient.get(this.apiRoute + '/' + taxId + '/permissions/tax/users', { role: role });
    }
  }

  public get(taxId: number): Observable<any> {
    if (typeof taxId !== 'number') {
      throw new ValidationError();
    } else {
      return this.apiClient.get(this.apiRouteTax + `/${taxId}` + '/permissions');
    }
  }
}
