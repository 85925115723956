<div id="audit-filter-layout" *ngIf="auditFilterService.allAuditTrails">
  <app-audit-legend></app-audit-legend>

  <p *ngIf="users || taxes">{{ "audit.filter" | translate }}:</p>

  <div class="ui segments">
    <!--User filter-->
    <div class="ui segment" *ngIf="users">
      <ng-select
        class="selection"
        [(ngModel)]="selectedUser"
        (ngModelChange)="onSelectFilter()"
        [items]="users"
        [searchable]="false"
        [placeholder]="'audit.filter.user' | translate"
      ></ng-select>
    </div>

    <!--Tax filter-->
    <div class="ui segment" *ngIf="taxes">
      <ng-select
        class="selection"
        [(ngModel)]="selectedTax"
        (ngModelChange)="onSelectFilter()"
        [items]="taxes"
        [searchable]="false"
        [placeholder]="'audit.filter.tax' | translate"
      ></ng-select>
    </div>
  </div>

  <!--Clear filter-->
  <button
    *ngIf="users || taxes"
    class="ui basic button noa-padding"
    [disabled]="!(selectedUser || selectedTax)"
    (click)="onClearFilter()"
    ngbPopover="Clear Filters"
    placement="bottom"
  >
    <img [src]="'../../../../assets/images/icons/filter.svg'" />
  </button>

  <p class="info">
    {{ auditFilterService.currentPage }} of {{ auditFilterService.totalPages }}
  </p>

  <button
    class="ui basic button pager"
    style="margin-right: 0.5rem"
    (click)="auditFilterService.fetchPreviousPage()"
    [disabled]="!auditFilterService.isPreviousPage"
  >
    <img
      class="pager-icon"
      [src]="'../../../../assets/images/icons/arrow_left.svg'"
    />
  </button>
  <button
    class="ui basic button pager"
    (click)="auditFilterService.fetchNextPage()"
    [disabled]="!auditFilterService.isNextPage"
  >
    <img
      class="pager-icon"
      [src]="'../../../../assets/images/icons/arrow_right.svg'"
    />
  </button>
</div>
