<ng-container *ifHasRole="[role.APPROVER, role.ADMIN, role.SUPER_ADMIN]">
  <div
    id="tax-approve-layout"
    *ngIf="taxDataService.isApprovingMode && (tax.status === getStatusPendingLocal() || tax.status === getStatusPendingGlobal())">
    <p>Approve all changes?</p>

    <div class="button-group">
      <button class="ui basic button" (click)="taxDataService.onClickRejectChanges()">Reject</button>
      <button class="ui basic button green" (click)="taxDataService.onClickApprove()">Approve</button>
    </div>
  </div>
</ng-container>
