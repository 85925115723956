<div id="tax-listing-filter-layout">
	<div>
		<div class="label">
			{{ "commons.table.tax-listing.tax" | translate | uppercase }}
		</div>
		<div class="ui input">
			<input
				type="text"
				[(ngModel)]="filter.name"
				(ngModelChange)="onChange()"
				placeholder="Tax"
			/>
		</div>
	</div>

	<div>
		<div class="label">
			{{ "commons.table.tax-listing.country" | translate | uppercase }}
		</div>
		<div class="ui input">
			<input
				type="text"
				[(ngModel)]="filter.location.name"
				(ngModelChange)="onChange()"
				placeholder="Country"
			/>
		</div>
	</div>

	<div>
		<div class="label">
			{{ "commons.table.tax-listing.editor" | translate | uppercase }}
		</div>
		<div class="ui input">
			<input
				type="text"
				[(ngModel)]="filter.editor.name"
				(ngModelChange)="onChange()"
				placeholder="Editor"
			/>
		</div>
	</div>

	<div>
		<div class="label">
			{{ "commons.table.tax-listing.approver" | translate | uppercase }}
		</div>
		<div class="ui input">
			<input
				type="text"
				[(ngModel)]="filter.approver.name"
				(ngModelChange)="onChange()"
				placeholder="Approver"
			/>
		</div>
	</div>

	<div>
		<div class="label">
			{{ "commons.table.tax-listing.updated" | translate | uppercase }}
		</div>
		<div class="input-group">
			<input
				#dp="ngbDatepicker"
				ngbDatepicker
				autocomplete="off"
				class="form-control"
				[(ngModel)]="filter.updatedAt"
				(ngModelChange)="onChange()"
				placement="bottom"
			/>
			<div class="input-group-append">
				<button
					class="btn btn-outline-secondary btn-icon"
					(click)="dp.toggle()"
					[attr.aria-expanded]="dp.isOpen"
					type="button"
				>
					<svg-icon
						svgClass="icon"
						src="/assets/images/icons/calendar.svg"
					></svg-icon>
				</button>
			</div>
		</div>
	</div>
</div>
