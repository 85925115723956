import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { untilDestroyed } from 'ngx-take-until-destroy';

import { UserHelper } from '@rar/commons/helpers';
import { Role } from '@rar/model/data/enums/Role';

import { TopicService } from '../../../commons/services/topic.service';
import { TaxRevisionStatus } from '../../../model/data/enums/TaxRevisionStatus';
import { TaxRevision } from '../../../model/data/tax/TaxRevision';
import { TaxDataService } from '../../services/tax-data/tax-data.service';
import { TaxModalService } from '../../services/tax-modal/tax-modal.service';

@Component({
  selector: 'app-tax-approve',
  templateUrl: './tax-approve.component.html',
  styleUrls: ['./tax-approve.component.scss'],
})
export class TaxApproveComponent implements OnInit, OnDestroy {
  public tax: TaxRevision;

  public readonly role = Role;

  constructor(
    public taxDataService: TaxDataService,
    private taxModalService: TaxModalService,
    private router: Router,
    private topicService: TopicService,
  ) {}

  ngOnInit() {
    this.taxDataService.tax.pipe(untilDestroyed(this)).subscribe((value: TaxRevision) => {
      this.tax = value;
    });

    this.taxModalService.assessment.pipe(untilDestroyed(this)).subscribe(() => {
      this.taxDataService.isApprovingMode = true;
      this.topicService.selectTopic.emit(undefined);
      this.router.navigate(['/tax', this.taxDataService.getTaxId(), 'revision', this.taxDataService.getTax().revisionNumber, 'notes']);
    });

    this.taxModalService.reject.pipe(untilDestroyed(this)).subscribe((reason: string) => {
      this.taxDataService.reject(reason);
    });

    this.taxModalService.approve.pipe(untilDestroyed(this)).subscribe((notes: string) => this.taxDataService.approve(notes));
  }

  ngOnDestroy(): void {}

  public getStatusPendingLocal() {
    return TaxRevisionStatus.PENDING_LOCAL;
  }

  public getStatusPendingGlobal() {
    return TaxRevisionStatus.PENDING_GLOBAL;
  }
}
