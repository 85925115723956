import {Component, OnInit} from "@angular/core";

@Component({
	selector: "app-audit-legend",
	templateUrl: "./audit-legend.component.html",
	styleUrls: ["./audit-legend.component.scss"]
})
export class AuditLegendComponent implements OnInit {

	constructor() {
	}

	ngOnInit() {
	}

}
