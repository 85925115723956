import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { TaxDataService } from '@rar/tax/services/tax-data/tax-data.service';

import { TaxRevision } from '../../model/data/tax/TaxRevision';
import { TaxTopic } from '../../model/data/tax/TaxTopic';

@Injectable()
export class TaxTopicResolverService implements Resolve<TaxTopic> {
  // "injected manually" because resolver is instantiated before this service
  public static taxDataService: TaxDataService;

  constructor() {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TaxTopic> | Promise<TaxTopic> | TaxTopic {
    const topicId = +route.params['id'];
    let categoryOrTopic: TaxTopic = null;

    const taxRevision =
      TaxTopicResolverService.taxDataService && TaxTopicResolverService.taxDataService.isEditingMode
        ? TaxTopicResolverService.taxDataService.taxCloneForEditing
        : <TaxRevision>route.parent.data['taxRevision'];

    taxRevision.taxTopics.forEach((category) => {
      if (category.id === topicId) {
        categoryOrTopic = category;
      } else {
        if (category.subTopics) {
          category.subTopics.forEach((topic) => {
            topic.parent = category;

            if (topic.id === topicId) {
              categoryOrTopic = topic;
            }
          });
        }
      }
    });

    return categoryOrTopic;
  }
}
