import { Component, Inject, OnDestroy, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { untilDestroyed } from 'ngx-take-until-destroy';

import { ValidationConstants } from '@rar/commons/constants';
import { TaxTypeTopicService } from '@rar/commons/services/tax-type-topic.service';
import { Role } from '@rar/model/data/enums/Role';
import { TaxTypeStructure } from '@rar/model/data/tax/TaxTypeStructure';
import { ApiCommunicationService } from '@rar/model/services/api-communication/api-communication.service';

import { TaxTypeDataService } from '../../services/tax-type-data.service';

@Component({
  selector: 'app-tax-type-info',
  templateUrl: './tax-type-info.component.html',
  styleUrls: ['./tax-type-info.component.scss'],
})
export class TaxTypeInfoComponent implements OnInit, OnDestroy {
  public readonly ValidationConstants = ValidationConstants;

  public tax: TaxTypeStructure;
  public isSearchMode: boolean;

  // Req 6
  public newTaxTypeApprovalLevelList: { id: number; text: number }[];

  constructor(
    public taxDataService: TaxTypeDataService,
    public route: ActivatedRoute,
    private topicService: TaxTypeTopicService,
    @Inject(ApiCommunicationService) private api: ApiCommunicationService,
  ) {
    this.isSearchMode = false;
  }

  role = Role;

  ngOnInit() {
    // Req 6
    this.newTaxTypeApprovalLevelList = [
      {
        id: 2,
        text: 2,
      },
      {
        id: 3,
        text: 3,
      },
    ];
    // subscribe to tax change
    this.taxDataService.tax.pipe(untilDestroyed(this)).subscribe((tax: TaxTypeStructure) => {
      this.tax = tax;
    });
  }

  ngOnDestroy(): void {}
}
