import { Component, Input, OnDestroy, OnInit } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable } from 'rxjs';

import { ChangeNoteOccurrence } from '../../../model/data/enums/ChangeNoteOccurrence';
import { TaxChangeNotes } from '../../../model/data/tax/TaxChangeNotes';
import { TaxTopic } from '../../../model/data/tax/TaxTopic';

enum Property {
  OLD = 'old',
  NEW = 'new',
  ORIGINAL = 'original',
  TOPIC_SHORT_CODE = 'topicShortCode',
}

interface ITaxDetailedChangeNotesModalContext {
  changes: Observable<Array<TaxChangeNotes>>;
  topics: Array<TaxTopic>;
}

@Component({
  selector: 'app-tax-detailed-change-notes',
  templateUrl: './tax-detailed-change-notes.component.html',
  styleUrls: ['./tax-detailed-change-notes.component.scss'],
})
export class TaxDetailedChangeNotesComponent implements OnInit, OnDestroy {
  private readonly EFFECTIVE_TO = 'effective to';
  private readonly EFFECTIVE_FROM = 'effective from';

  constructor(private activeModal: NgbActiveModal) {}

  @Input()
  context: ITaxDetailedChangeNotesModalContext;

  public changes;

  ngOnInit() {
    this.context.changes.pipe(untilDestroyed(this)).subscribe((changes) => {
      this.processChanges(changes);
    });
  }

  ngOnDestroy(): void {}

  approve(): void {
    this.activeModal.close(null);
  }

  private processChanges(notes) {
    if (!notes || !notes.length) {
      return;
    }

    this.changes = [];

    // process changes
    for (const changes of notes) {
      const json = JSON.parse(changes.changeNotes);
      const editor = changes.user;

      this.changes = [{ editor: editor, data: this.processTaxChanges(json, changes.message === 'alternative') }, ...this.changes];
    }
  }

  private processTaxChanges(json: any, alternative?: boolean) {
    let result = [];

    for (const key of Object.keys(json)) {
      const object = json[key];

      // if description changed
      if (object.hasOwnProperty(ChangeNoteOccurrence.DESCRIPTION)) {
        result = [...result, this.registerTaxChanges(object, ChangeNoteOccurrence.DESCRIPTION, false)];
      }

      // if status changed
      if (object.hasOwnProperty(ChangeNoteOccurrence.STATUS)) {
        result = [...result, this.registerTaxChanges(object, ChangeNoteOccurrence.STATUS, true)];
      }

      // if effective to changed
      if (object.hasOwnProperty(ChangeNoteOccurrence.EFFECTIVE_TO)) {
        result = [...result, this.registerTaxChanges(object, ChangeNoteOccurrence.EFFECTIVE_TO, true, this.EFFECTIVE_TO)];
      }

      // if effective from changed
      if (object.hasOwnProperty(ChangeNoteOccurrence.EFFECTIVE_FROM)) {
        result = [...result, this.registerTaxChanges(object, ChangeNoteOccurrence.EFFECTIVE_FROM, true, this.EFFECTIVE_FROM)];
      }

      // if something change in rule name
      if (object.hasOwnProperty(ChangeNoteOccurrence.RULE_TITLE)) {
        result = [
          ...result,
          this.registerTaxChanges(
            object,
            ChangeNoteOccurrence.RULE_TITLE,
            false,
            alternative ? ChangeNoteOccurrence.RULE_TITLE : this.produceOccurrence(object[ChangeNoteOccurrence.RULE_TITLE]),
          ),
        ];
      }

      // if something change in rate name
      if (object.hasOwnProperty(ChangeNoteOccurrence.RATE_TITLE)) {
        result = [
          ...result,
          this.registerTaxChanges(
            object,
            ChangeNoteOccurrence.RATE_TITLE,
            false,
            alternative ? ChangeNoteOccurrence.RATE_TITLE : this.produceOccurrence(object[ChangeNoteOccurrence.RATE_TITLE]),
          ),
        ];
      }

      // if something change inside a rule
      if (object.hasOwnProperty(ChangeNoteOccurrence.ARTICLE)) {
        result = [
          ...result,
          this.registerTaxChanges(
            object,
            ChangeNoteOccurrence.ARTICLE,
            false,
            alternative ? ChangeNoteOccurrence.ARTICLE : this.produceOccurrence(object[ChangeNoteOccurrence.ARTICLE]),
          ),
        ];
      }

      if (object.hasOwnProperty(ChangeNoteOccurrence.RULE_SHORTCODE)) {
        result = [
          ...result,
          this.registerTaxChanges(
            object,
            ChangeNoteOccurrence.RULE_SHORTCODE,
            false,
            alternative ? ChangeNoteOccurrence.RULE_SHORTCODE : this.produceOccurrence(object[ChangeNoteOccurrence.RULE_SHORTCODE]),
          ),
        ];
      }

      // if something change inside a rate
      if (object.hasOwnProperty(ChangeNoteOccurrence.VALUE)) {
        result = [
          ...result,
          this.registerTaxChanges(
            object,
            ChangeNoteOccurrence.VALUE,
            true,
            alternative ? ChangeNoteOccurrence.VALUE : this.produceOccurrence(object[ChangeNoteOccurrence.VALUE]),
          ),
        ];
      }

      // if something change in topic name
      if (object.hasOwnProperty(ChangeNoteOccurrence.TOPIC_NAME)) {
        result = [...result, this.registerTaxChanges(object, ChangeNoteOccurrence.TOPIC_NAME, false)];
      }

      // if something change in topic short code
      if (object.hasOwnProperty(ChangeNoteOccurrence.TOPIC_SHORTCODE)) {
        result = [...result, this.registerTaxChanges(object, ChangeNoteOccurrence.TOPIC_SHORTCODE, false)];
      }

      // if something change in category name
      if (object.hasOwnProperty(ChangeNoteOccurrence.CATEGORY_NAME)) {
        result = [...result, this.registerTaxChanges(object, ChangeNoteOccurrence.CATEGORY_NAME, false)];
      }

      // if something change in category short code
      if (object.hasOwnProperty(ChangeNoteOccurrence.CATEGORY_SHORTCODE)) {
        result = [...result, this.registerTaxChanges(object, ChangeNoteOccurrence.CATEGORY_SHORTCODE, false)];
      }

      // if something change in rule order
      if (object.hasOwnProperty(ChangeNoteOccurrence.RULE_ORDER)) {
        result = [...result, this.registerTaxChanges(object, ChangeNoteOccurrence.RULE_ORDER, true)];
      }
    }

    return result;
  }

  private registerTaxChanges(object: any, occurrence: ChangeNoteOccurrence, alternativeDisplay: boolean, alternativeOccurrence?: string) {
    return {
      occurrence: alternativeOccurrence ? alternativeOccurrence : occurrence,
      new: object[occurrence][Property.NEW] ? object[occurrence][Property.NEW] : undefined,
      original: object[occurrence][Property.OLD] ? object[occurrence][Property.OLD] : undefined,
      topicShortCode: object[occurrence][Property.TOPIC_SHORT_CODE],
      alternativeDisplay: !object[occurrence][Property.OLD] || !object[occurrence][Property.NEW] ? true : alternativeDisplay,
    };
  }

  private produceOccurrence(object: any): string {
    const shortCode = object[Property.TOPIC_SHORT_CODE];
    let occurrence = '';

    if (!this.context.topics) {
      return occurrence;
    }

    this.context.topics.forEach((topic: TaxTopic) => {
      if (topic.shortCode === shortCode) {
        occurrence = occurrence.concat(topic.name);
      }

      if (topic.subTopics) {
        topic.subTopics.forEach((subTopic: TaxTopic) => {
          if (subTopic.shortCode === shortCode) {
            occurrence = occurrence.concat(topic.name, ' >> ', subTopic.name);
          }
        });
      }
    });

    return occurrence;
  }
}
