import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Role } from '@rar/model/data/enums/Role';
import { TaxRevision } from '@rar/model/data/tax/TaxRevision';
import { User } from '@rar/model/data/user/User';

interface ITaxConfirmModalContext {
  tax: TaxRevision;
  approvers: Array<User>;
  autoApprove: boolean;
}

@Component({
  selector: 'app-tax-confirm-modal',
  templateUrl: './tax-confirm-modal.component.html',
  styleUrls: ['./tax-confirm-modal.component.scss'],
})
export class TaxConfirmModalComponent {
  constructor(private activeModal: NgbActiveModal) {}

  @Input()
  context: ITaxConfirmModalContext;
  public readonly Role = Role;
  role = Role;

  approve(): void {
    this.activeModal.close(null);
  }

  deny(): void {
    this.activeModal.dismiss(null);
  }
}
