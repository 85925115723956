import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { untilDestroyed } from 'ngx-take-until-destroy';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';

import { TaxRevisionStatus } from '../../../model/data/enums/TaxRevisionStatus';
import { TaxLock } from '../../../model/data/tax/TaxLock';
import { TaxRevision } from '../../../model/data/tax/TaxRevision';
import { User } from '../../../model/data/user/User';
import { ApiCommunicationService } from '../../../model/services/api-communication/api-communication.service';
import { UserSessionService } from '../../../user/services/user-session/user-session.service';
import { TaxDataService } from '../../services/tax-data/tax-data.service';
import { TaxModalService } from '../../services/tax-modal/tax-modal.service';

@Component({
  selector: 'app-tax-revision-page',
  templateUrl: './tax-revision-page.component.html',
  styleUrls: ['./tax-revision-page.component.scss'],
  providers: [TaxDataService, TaxModalService],
})
export class TaxRevisionPageComponent implements OnInit, OnDestroy {
  public tax: TaxRevision = undefined;

  private lockTimeoutId: any;
  private lockInfoIntervalId: any;
  private lockRequestIntervalId: any;

  constructor(
    private modalService: TaxModalService,
    private route: ActivatedRoute,
    public taxDataService: TaxDataService,
    private userSessionService: UserSessionService,
    @Inject(ApiCommunicationService) private api: ApiCommunicationService,
  ) {}

  ngOnInit() {
    // resolve route data
    this.route.data.pipe(untilDestroyed(this)).subscribe((data) => {
      this.tax = data.taxRevision;
      this.taxDataService.setTax(this.tax);

      // permission check
      this.api
        .permissions()
        .get(this.tax.id)
        .subscribe((response) => {
          this.taxDataService.isPermitted = response.permitted;
        });
    });

    // get authenticated user
    this.userSessionService.userData.pipe(untilDestroyed(this)).subscribe((u: User) => {
      this.taxDataService.authUser = u;

      // get tax lock info
      this.getTaxRevisionLockInfo();
    });

    // send lock request every 4 min if is editing or approving mode
    this.lockRequestIntervalId = setInterval(() => {
      if (this.taxDataService.isEditingMode || this.taxDataService.isApprovingMode) {
        this.taxDataService.lock();
      }
    }, 240000);

    this.lockTimeoutId = setTimeout(() => {
      // poll lock info every half min
      this.lockInfoIntervalId = setInterval(() => {
        this.getTaxRevisionLockInfo();
      }, 30000);
    }, 30000);
  }

  ngOnDestroy(): void {
    // clear timeouts
    if (this.lockTimeoutId) {
      clearTimeout(this.lockTimeoutId);
    }

    // clear intervals
    if (this.lockRequestIntervalId) {
      clearInterval(this.lockRequestIntervalId);
    }

    if (this.lockInfoIntervalId) {
      clearInterval(this.lockInfoIntervalId);
    }
  }

  public canDeactivate(): Observable<boolean> {
    if (!this.taxDataService.isEditingMode && !this.taxDataService.isApprovingMode) {
      return of(true);
    } else {
      return this.taxDataService.onClickDiscardModal();
    }
  }

  private getTaxRevisionLockInfo() {
    forkJoin([
      this.api
        .tax()
        .getTaxEligibility(this.tax.id)
        .pipe(
          catchError(() => of([])),
          untilDestroyed(this),
        ),
      this.api.tax().getTaxRevisionLockInfo(this.taxDataService.getTax().id, this.taxDataService.getTax().revisionNumber),
    ]).subscribe((data: Object[]) => {
      const info = <TaxLock>data[1];

      // if current revision is not pending or rejected and it is not locked
      if (
        // @ts-ignore
        data[0].length === 0 &&
        info.ok &&
        this.tax.status !== TaxRevisionStatus.PENDING_LOCAL &&
        this.tax.status !== TaxRevisionStatus.REJECTED
      ) {
        info.ok = false;
        info.action = null;
        info.user = null;
      }

      // re-fetch tax if ...
      if (
        this.taxDataService.taxLockInfo &&
        !this.taxDataService.taxLockInfo.ok &&
        info.ok &&
        this.taxDataService.getTax().status !== TaxRevisionStatus.APPROVED
      ) {
        this.api
          .tax()
          .getTaxRevision(this.taxDataService.getTax().id, this.taxDataService.getTax().revisionNumber)
          .pipe(untilDestroyed(this))
          .subscribe((tax: TaxRevision) => {
            this.taxDataService.setTax(tax);
          });
      }

      this.taxDataService.taxLockInfo = info;
    });
  }

  handleImportFile(file: File) {
    const data = new FormData();
    data.append('name', file.name);
    data.append('size', `${file.size}`);
    data.append('type', file.type);
    data.append('file', file);

    this.api
      .tax()
      .lockTaxRevision(this.tax.id, this.tax.revisionNumber)
      .pipe(
        untilDestroyed(this),
        switchMap(() => {
          return this.api.tax().importFile(this.tax.id, this.tax.revisionNumber, data);
        }),
        finalize(() => (this.taxDataService.isLoading = false)),
      )
      .subscribe({
        next: (value) => {
          this.modalService.openFileUploadModal(value);
        },
        error: () => {
          this.modalService.openFileUploadModal(undefined);
        },
      });
  }
}
