import {Component, OnInit} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
	selector: "app-tax-loading",
	templateUrl: "./tax-loading.component.html",
	styleUrls: ["./tax-loading.component.scss"]
})
export class TaxLoadingComponent implements OnInit {

	constructor(private router: Router, private route: ActivatedRoute) {
	}

	ngOnInit() {
		this.router.navigate(["/tax", this.route.snapshot.params["id"], "revision", this.route.snapshot.params["revisionId"]]);
	}

}
