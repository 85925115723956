import { Component, Input, OnInit } from "@angular/core";
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TaxRevision } from "../../../model/data/tax/TaxRevision";
import { User } from "../../../model/data/user/User";

interface ITaxApproveModalContext {
	tax: TaxRevision;
	editors: Array<User>;
}

@Component({
	selector: "app-tax-approve-modal",
	templateUrl: "./tax-approve-modal.component.html",
	styleUrls: ["./tax-approve-modal.component.scss"]
})
export class TaxApproveModalComponent implements OnInit {

	public notes: string;

	constructor(private activeModal: NgbActiveModal) { }

	@Input()
	context: ITaxApproveModalContext;

	ngOnInit() {
		if (this.context.editors.length === 0) {
			this.context.editors.push(this.context.tax.editor);
		}
	}

	approve(notes: string): void {
		this.activeModal.close(notes);
	}

	deny(): void {
		this.activeModal.dismiss();
	}

}
