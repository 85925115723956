<div id="tax-rule-key-value-layout">
  <div>
    <ng-container *ngIf="taxTypeDataService.getTaxTopic(taxTypeDataService.getTax(), topicId) as taxTopic">
      <div class="hr" *ngIf="taxTopic.rates.length > 0"></div>

      <div class="item edit" *ngFor="let rule of sortTopics(taxTopic.rules); index as i; last as last; first as first">
        <div class="d-flex justify-content-between align-items-center">
          <div class="rule-counter mr-auto">
            {{ 'tax.tax-topics.rule-label' | translate }} {{ taxTopic.rates.length + i + 1 }} ({{ rule.shortCode }})
          </div>
          <input type="hidden" name="ruleOrder" [value]="rule?.order" />
          <button
            class="btn btn-sm btn-outline-primary btn-icon arrow-button mr-2"
            (click)="changeTopicOrder($event, i, true)"
            *ngIf="!first">
            <svg-icon class="icon arrow-up" src="../../../../assets/images/icons/arrow-down.svg"></svg-icon>
          </button>
          <button
            class="btn btn-sm btn-outline-primary btn-icon arrow-button mr-2"
            (click)="changeTopicOrder($event, i, false)"
            *ngIf="!last">
            <svg-icon class="icon" src="../../../../assets/images/icons/arrow-down.svg"></svg-icon>
          </button>
          <button class="btn btn-sm btn-primary btn-icon" (click)="deleteRuleRow(rule)">
            <svg-icon class="icon" src="../../../../assets/images/icons/trash.svg"></svg-icon>
            <span>{{ 'tax.tax-topics.rule-delete' | translate }}</span>
          </button>
        </div>
        <div class="name">
          <label>{{ 'tax.tax-topics.rule-label' | translate }}</label>
          <input
            #ruleName="ngModel"
            type="text"
            class="title form-control"
            [class.show-feedback]="ruleName.invalid && (rule.id > 0 || ruleName.touched)"
            [(ngModel)]="rule.name"
            required
            [minlength]="validationConstants.ruleMinLength"
            [maxlength]="validationConstants.ruleMaxLength"
            data-test="rule name" />

          <div class="invalid-feedback" *ngIf="ruleName.invalid && (rule.id > 0 || ruleName.touched)">
            <div class="invalid-feedback__message" *ngIf="ruleName.errors.minlength">
              <i class="fa fa-exclamation-triangle mr-1"></i>
              {{ 'commons.validation.minlength' | translate: { minlength: ruleName.errors.minlength.requiredLength } }}
            </div>
            <div class="invalid-feedback__message" *ngIf="ruleName.errors.required">
              <i class="fa fa-exclamation-triangle mr-1"></i>
              {{ 'commons.validation.required' | translate }}
            </div>
          </div>
        </div>

        <div class="hr" *ngIf="!last"></div>
      </div>

      <div class="btn-container">
        <div *ngIf="$any(taxTopic).locationsWithTable as locationsWithTable" class="invalid-feedback conflict-message">
          {{ 'tax-design.messages.topic-table-rule-conflict' | translate: { locations: locationsWithTable } }}
        </div>

        <button class="btn btn-sm btn-primary btn-icon" (click)="addNewRuleRow()" [disabled]="!!$any(taxTopic).locationsWithTable">
          <svg-icon class="icon" src="../../../../assets/images/icons/add2.svg"></svg-icon>
          <span>{{ 'tax.tax-topics.rule-add' | translate }}</span>
        </button>
      </div>
    </ng-container>
  </div>
</div>
