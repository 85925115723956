import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";

@Component({
	selector: "app-tax-listing-page",
	templateUrl: "./tax-listing-page.component.html",
	styleUrls: ["./tax-listing-page.component.scss"]
})
export class TaxListingPageComponent implements OnInit {

	constructor(public route: ActivatedRoute) { }

	ngOnInit() {
	}

}
