import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';

import { NgbPanelChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';

import { ShortCodeConstants } from '@rar/commons/constants';
import { UserHelper } from '@rar/commons/helpers';
import { TaxTypeTopic } from '@rar/model/data/tax/TaxTypeTopic';

import { TopicService } from '../../../commons/services/topic.service';
import { ChangeNoteOccurrence } from '../../../model/data/enums/ChangeNoteOccurrence';
import { Role } from '../../../model/data/enums/Role';
import { TaxChangeNotes } from '../../../model/data/tax/TaxChangeNotes';
import { TaxRevision } from '../../../model/data/tax/TaxRevision';
import { TaxTopic } from '../../../model/data/tax/TaxTopic';
import { TaxDataService } from '../../services/tax-data/tax-data.service';
import { TaxModalService } from '../../services/tax-modal/tax-modal.service';

@Component({
  selector: 'app-tax-sub-menu',
  templateUrl: './tax-sub-menu.component.html',
  styleUrls: ['./tax-sub-menu.component.scss'],
})
export class TaxSubMenuComponent implements OnInit, OnDestroy {
  public readonly Role = Role;
  private readonly TOPIC_SHORT_CODE = 'topicShortCode';

  public topics: TaxTopic[] = [];

  // to able to show subtopics
  public selectedTopic: TaxTopic;
  // to able bind active class to buttons
  public activeTopic: TaxTopic;
  public activeTopicPanelId: string = '';

  public changedTopics = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public taxDataService: TaxDataService,
    private taxModalService: TaxModalService,
    private topicService: TopicService,
    private cdr: ChangeDetectorRef,
  ) {
    // MUST BE HERE
    // subscribe to tax edit flow, enable editing
    this.taxModalService.edit.pipe(untilDestroyed(this)).subscribe((response) => {
      this.taxDataService.enableEditingMode(response);
    });

    // MUST BE HERE
    // subscribe to tax edit flow, save changes
    this.taxModalService.save.pipe(untilDestroyed(this)).subscribe((status: boolean) => {
      this.taxDataService.save(status);
    });

    // MUST BE HERE
    // subscribe to tax edit flow, save changes
    this.taxModalService.import.pipe(untilDestroyed(this)).subscribe(() => {
      this.router.navigate(['']);
    });

    // MUST BE HERE
    // subscribe to tax close action
    this.taxModalService.close.pipe(untilDestroyed(this)).subscribe(() => {
      this.taxDataService.close();
    });

    this.taxDataService.tax.pipe(untilDestroyed(this)).subscribe((tax: TaxRevision) => {
      this.topics = tax.taxTopics || [];

      this.processChangesToDisplay();

      if (this.route.children[0]) {
        this.route.children[0].params
          .pipe(
            map((params) => +params['id']),
            distinctUntilChanged(),
            untilDestroyed(this),
          )
          .subscribe((topicId) => {
            this.setTopicState(topicId);
            this.setActiveTopicPanelId();
          });
      }
    });

    this.taxDataService.editStarted
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.topics = this.taxDataService.taxCloneForEditing.taxTopics;
      });

    this.taxDataService.discard
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.topics = this.taxDataService.getTax().taxTopics;
      });
  }

  ngOnInit() {
    if (!this.route.children.length && this.topics.length) {
      const topicToNavigate =
        this.topics[0].subTopics && this.topics[0].subTopics.length > 0 ? this.topics[0].subTopics[0] : this.topics[0];

      this.navigateToTopic(topicToNavigate);
      this.selectedTopic = this.topics[0];
      this.setActiveTopicPanelId();
    }

    this.topicService.selectTopic.pipe(untilDestroyed(this)).subscribe((selectedTopic: TaxTopic) => {
      if (selectedTopic === null) {
        if (!+this.route.children[0].snapshot.params['id']) {
          this.onSelectTopic(this.topics[0]);
        }
      } else {
        if (selectedTopic) {
          if (selectedTopic.parent) {
            this.onSelectTopic(selectedTopic.parent);
          }
          this.onSelectTopic(selectedTopic);
        } else {
          this.selectedTopic = undefined;
          this.clearActiveTopic();
        }
      }
    });

    this.router.events
      .pipe(
        filter((ev) => ev instanceof NavigationStart),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.clearActiveTopic();
      });
  }

  ngOnDestroy(): void {}

  public topicChangeHighlight(topic: TaxTopic) {
    if (!this.taxDataService.changeNotes && !UserHelper.canApproveTax(this.taxDataService.authUser, topic.taxRevision)) {
      return false;
    }

    return this.isChangedTopic(topic.shortCode);
  }

  private isChangedTopic(shortCode: string) {
    return this.changedTopics.some((code) => code === shortCode);
  }

  public onSelectTopic(selectedTopic: TaxTopic) {
    if (this.activeTopic === selectedTopic) {
      return;
    }

    const t = this.topics.find((topic) => topic.name === selectedTopic.name);
    if (t !== undefined && t.subTopics !== undefined && t.subTopics.length !== 0) {
      this.navigateToTopic(t.subTopics[0]);
      this.selectedTopic = t.subTopics[0];
    } else {
      if (t !== undefined) {
        this.navigateToTopic(t);
        this.selectedTopic = undefined;
      } else {
        this.navigateToTopic(selectedTopic);
      }
    }
  }

  public isSearchResult(currentTopic: TaxTopic): boolean {
    if (this.taxDataService.searchResult && this.taxDataService.searchResult.topics) {
      return Array.from(this.taxDataService.searchResult.topics.values()).some(
        (topic) => topic.id === currentTopic.id || (topic.parent ? topic.parent.id === currentTopic.id : false),
      );
    } else {
      return false;
    }
  }

  private navigateToTopic(selectedTopic: TaxTopic) {
    this.router.navigate(['topic', selectedTopic.id], { relativeTo: this.route });
    this.activeTopic = selectedTopic;
  }

  public clearActiveTopic() {
    this.activeTopic = undefined;
  }

  public beforeChange($event: NgbPanelChangeEvent) {
    if (!$event.nextState) {
      $event.preventDefault();
    }
  }

  private setTopicState(currentTopicState: number) {
    this.topics.forEach((topic: TaxTopic) => {
      if (topic.id === currentTopicState) {
        this.activeTopic = topic;
        this.selectedTopic = topic;
      }

      if (topic.subTopics) {
        topic.subTopics.forEach((subTopic: TaxTopic) => {
          if (subTopic.id === currentTopicState) {
            this.selectedTopic = topic;
            this.activeTopic = subTopic;
          }
        });
      }
    });
  }

  private processChangesToDisplay() {
    this.taxDataService.parsedChangeNotes = [];

    if (this.taxDataService.changeNotes && UserHelper.canApproveTax(this.taxDataService.authUser, this.taxDataService.getTax())) {
      this.taxDataService.changeNotes.forEach((changes: TaxChangeNotes) => {
        const json = JSON.parse(changes.changeNotes);

        for (const key of Object.keys(json)) {
          const object = json[key];

          // catch if something changed in rules
          if (object.hasOwnProperty(ChangeNoteOccurrence.ARTICLE)) {
            this.taxDataService.parsedChangeNotes.push(object);
            this.changedTopics.push(object[ChangeNoteOccurrence.ARTICLE][this.TOPIC_SHORT_CODE]);
          }

          // catch if something changed in rates
          if (object.hasOwnProperty(ChangeNoteOccurrence.VALUE)) {
            this.taxDataService.parsedChangeNotes.push(object);
            this.changedTopics.push(object[ChangeNoteOccurrence.VALUE][this.TOPIC_SHORT_CODE]);
          }
        }
      });

      // get parent topics to highlight them
      this.topics.forEach((topic: TaxTopic) => {
        const subTopics = topic.subTopics;
        if (subTopics) {
          subTopics.forEach((subTopic: TaxTopic) => {
            if (
              this.changedTopics.some((code) => code === subTopic.shortCode) &&
              !this.changedTopics.some((code) => code === topic.shortCode)
            ) {
              this.changedTopics.push(topic.shortCode);
            }
          });
        }
      });
    }
  }

  private setActiveTopicPanelId() {
    this.activeTopicPanelId = this.selectedTopic ? `topic-${this.selectedTopic.id}` : '';
  }

  public addNewCategory() {
    // as a new id we take highest (abs) id - 1 (as all negatives are temporary ids)
    const tax = this.taxDataService.taxCloneForEditing;
    const newCategory = {
      id: Math.min(0, ...tax.taxTopics.map((category) => -Math.abs(category.id))) - 1,
      shortCode: ShortCodeConstants.newPlaceholder, // will be calculated on API
      rates: [],
      rules: [],
      subTopics: [],
      parent: null,
      parentId: null,
      name: '',
      taxType: tax.type,
      taxRevisionId: tax.revisionId,
      order: !this.topics.length ? 1 : this.topics[this.topics.length - 1].order + 1,
    } as unknown as TaxTopic;
    this.topics.push(newCategory);
    this.onSelectTopic(newCategory);
    this.markForCheck();
  }

  private markForCheck(): void {
    this.cdr.markForCheck();
    setTimeout(() => this.cdr.markForCheck());
  }

  public sortTopics(topics: TaxTopic[]): TaxTopic[] {
    return topics ? topics.sort((a, b) => a.order - b.order) : [];
  }
}
