import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { SessionStorageService } from 'ngx-webstorage';

import { LocalStorageKeys } from '@rar/commons/constants';
import { RoutePaths } from '@rar/commons/constants/route-paths';
import { UserHelper } from '@rar/commons/helpers';
import { Role } from '@rar/model/data/enums/Role';
import { UserSessionService } from '@rar/user/services/user-session/user-session.service';
import { UserEncryptionService } from '../services/user-encryption/user-encryption.service';

/**
 * Auth guard
 * This service prevents unauthorized users from using 'login-required' pages
 * It is used in routing as provider for "CanActivate"
 */
@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(
    @Inject(UserSessionService) private userSessionService: UserSessionService,
    private router: Router,
    private sessionStorageService: SessionStorageService,
    private userEncryption: UserEncryptionService,
  ) {}

  // functionality only accessible when user is logged in
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    // RAR SRT encryption Start
    let idTokenClaims = sessionStorage.getItem('id_token_claims_obj')
    let idTokenClaimArray: string[];
    if (idTokenClaims) {
      idTokenClaimArray = JSON.parse(idTokenClaims);
      // @ts-ignore
      idTokenClaimArray.iss=this.userEncryption.encrypt(idTokenClaimArray.iss);
      // @ts-ignore
      idTokenClaimArray.idp=this.userEncryption.encrypt(idTokenClaimArray.idp);
      // @ts-ignore
      idTokenClaimArray.email=this.userEncryption.encrypt(idTokenClaimArray.email);
      // @ts-ignore
      idTokenClaimArray.given_name=this.userEncryption.encrypt(idTokenClaimArray.given_name);
      // @ts-ignore
      idTokenClaimArray.aud=this.userEncryption.encrypt(idTokenClaimArray.aud);
      // @ts-ignore
      idTokenClaimArray.family_name=this.userEncryption.encrypt(idTokenClaimArray.family_name);
      // @ts-ignore
      idTokenClaimArray.GlobalGradeCode=this.userEncryption.encrypt(idTokenClaimArray.GlobalGradeCode);
      // @ts-ignore
      idTokenClaimArray.businessunit=this.userEncryption.encrypt(idTokenClaimArray.businessunit);
      // @ts-ignore
      idTokenClaimArray.country=this.userEncryption.encrypt(idTokenClaimArray.country);
      console.log('JSON.stringify(idTokenClaimArray)',JSON.stringify(idTokenClaimArray));
      sessionStorage.setItem('id_token_claims_obj', JSON.stringify(idTokenClaimArray));
    }
    // RAR SRT encryption End
    if (window.location.search.startsWith('?code=')) {
      await this.userSessionService.parseTokenFromUrl();
      if (!this.userSessionService.isUserLoggedIn()) {
        // has an invalid token
        this.userSessionService.tryLogIn();
        return false;
      } else {
        return this.router.createUrlTree(['/']);
      }
    }

    // user not logged in yet or token lost its validity
    if (!this.userSessionService.isUserLoggedIn()) {
      this.storeReturnUrl(`/${state.url}`);
      this.userSessionService.tryLogIn();
      return false;
    }

    // user logged in - we can check their profile if it does exist in DB
    let returnUrlTree: UrlTree | boolean = true;
    if (this.userSessionService.isUserLoggedIn()) {
      returnUrlTree = this.restoreReturnUrl();
      if (!this.userSessionService.isUserDataCached()) {
        await this.userSessionService.resolveUserProfile();
      }
    }

    const noAccessUrlTree = this.router.createUrlTree(['/' + RoutePaths.noAccess]);
    if (!this.userSessionService.isUserLoggedInAndAuthorized()) {
      return noAccessUrlTree;
    }

    const expectedRoles = <Array<Role>>route.data['roles'];
    if (!expectedRoles || !Array.isArray(expectedRoles) || !expectedRoles.length) {
      // pass the user - there are no expected roles required
      return returnUrlTree;
    }

    // this is not really async because user profile has already been cached a few lines before
    const userProfile = await this.userSessionService.resolveUserProfile();
    if (expectedRoles.some((expectedRole) => UserHelper.isUserInRoleForAnyTax(userProfile, expectedRole))) {
      // expected role found
      return returnUrlTree;
    }

    // no expected roles found
    return noAccessUrlTree;
  }

  private storeReturnUrl(returnUrl: string): void {
    this.sessionStorageService.store(LocalStorageKeys.returnUrl, returnUrl);
  }

  private restoreReturnUrl(): UrlTree | boolean {
    const returnUrl = this.sessionStorageService.retrieve(LocalStorageKeys.returnUrl);

    if (returnUrl) {
      this.sessionStorageService.clear(LocalStorageKeys.returnUrl);
      return this.router.createUrlTree([returnUrl]);
    }

    return true;
  }
}
