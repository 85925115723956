import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

import { untilDestroyed } from 'ngx-take-until-destroy';

import { RoutePaths } from '@rar/commons/constants/route-paths';
import { UserHelper } from '@rar/commons/helpers';

import { UserSessionService } from '../../../user/services/user-session/user-session.service';
import { CollapseService } from '../../services/collapse.service';
import { SearchService } from '../../services/search.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  animations: [
    trigger('navbar', [
      state('closed', style({ width: '*' })),
      state('open', style({ width: '270px' })),
      transition('open <=> closed', animate('.5s ease-out')),
    ]),
  ],
})
export class NavbarComponent implements OnInit, OnDestroy, AfterViewInit {
  public readonly RoutePaths = RoutePaths;

  public isCollapsed: boolean;
  public isUserAdmin: boolean;
  public isUserSuperAdmin: boolean;

  constructor(
    private collapseService: CollapseService,
    private userSessionService: UserSessionService,
    private searchService: SearchService,
  ) {
    this.isCollapsed = this.collapseService.getStatus();
    this.collapseService.isCollapsed.pipe(untilDestroyed(this)).subscribe((status: boolean) => (this.isCollapsed = status));
    this.userSessionService.userData.pipe(untilDestroyed(this)).subscribe((u) => {
      this.isUserAdmin = u && UserHelper.isUserSuperAdminOrAdminForAnyTax(u);
      this.isUserSuperAdmin = u && u.isSuperAdmin;
    });
  }

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngAfterViewInit() {}

  onSelectTab() {
    this.searchService.clearSearchFilters();
  }
}
