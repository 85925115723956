<div id="users-layout">
  <!--Page title-->
  <div id="header" class="padding-bottom">
    <div>
      <h1 *ngIf="queryParam">New {{ 'user.users-admin.title' | translate }}</h1>
      <h1 *ngIf="!queryParam">{{ 'user.users-admin.title' | translate }}</h1>
    </div>

    <div class="flex-flow">
      <a class="btn btn-sm btn-primary btn-icon" [routerLink]="userRoute" data-test="add new user">
        <svg-icon [src]="'/assets/images/icons/add2.svg'"></svg-icon>
        <span>
          {{ 'user.users-admin.add' | translate }}
        </span>
      </a>

      <button class="btn btn-sm btn-outline-secondary btn-icon" (click)="resetFilters(); userFilter.setShowFilter(!userFilter.showFilter)">
        <svg-icon [src]="'/assets/images/icons/filter.svg'"></svg-icon>
        <span>
          {{ (userFilter.showFilter ? 'user.users-admin.close' : 'user.users-admin.filter') | translate }}
        </span>
      </button>
    </div>
  </div>

  <!--Page content-->
  <div>
    <!--User table-->
    <app-user-table></app-user-table>
  </div>
</div>
