<div class="modal-header">
  <h4 class="modal-title">{{ 'tax.tax-import.import' | translate }}</h4>
</div>

<div class="modal-body">
  <ng-container *ngIf="fileResponse; else error">
    <ng-container *ngIf="fileResponse?.rowsImported + fileResponse?.rowsNotModified === fileResponse?.totalRowsToImport; else partial">
      <div>
        <span *ngIf="fileResponse.rowsAdded">
          {{
            'tax.tax-import.import-success'
              | translate
                : {
                    rowsAdded: fileResponse.rowsAdded,
                    rowsUpdated: fileResponse.rowsImported - fileResponse.rowsAdded,
                    totalRowsToImport: fileResponse.totalRowsToImport
                  }
          }}
        </span>
        <span *ngIf="!fileResponse.rowsAdded">
          {{
            'tax.tax-import.update-success'
              | translate
                : {
                    rowsImported: fileResponse.rowsImported,
                    totalRowsToImport: fileResponse.totalRowsToImport
                  }
          }}
        </span>
      </div>
    </ng-container>
    <ng-template #partial>
      <div *ngIf="!fileResponse.notImportedRows">
        <p class="mb-1">
          {{
            'tax.tax-import.update-partial'
              | translate
                : {
                    rowsImported: fileResponse.rowsImported,
                    totalRowsToImport: fileResponse.totalRowsToImport
                  }
          }}
        </p>
        <p>
          {{ 'tax.tax-import.import-records' | translate }}
          <br />
          {{ fileResponse.notImportedShortCode.join(', ') }}
        </p>
      </div>
      <div *ngIf="fileResponse.notImportedRows">
        <p class="mb-1" *ngIf="!fileResponse.rowsAdded">
          {{
            'tax.tax-import.update-partial'
              | translate: { rowsImported: fileResponse.rowsImported, totalRowsToImport: fileResponse.totalRowsToImport }
          }}
        </p>
        <p class="mb-1" *ngIf="fileResponse.rowsAdded">
          {{
            'tax.tax-import.import-partial'
              | translate
                : {
                    rowsAdded: fileResponse.rowsAdded,
                    rowsUpdated: fileResponse.rowsImported - fileResponse.rowsAdded,
                    totalRowsToImport: fileResponse.totalRowsToImport
                  }
          }}
        </p>
        <p>
          {{ 'tax.tax-import.import-wrong-records' | translate }}
          <br />
          {{ getWrongRowsInfo() }}
        </p>
      </div>
    </ng-template>
  </ng-container>
  <ng-template #error>
    <div>{{ 'tax.tax-import.import-error' | translate }}</div>
  </ng-template>
</div>

<div class="modal-footer">
  <div class="button-group">
    <button class="ui basic button rose" (click)="approve()">
      {{ 'user.user-edit-modal.ok' | translate | uppercase }}
    </button>
  </div>
</div>
