export class PagedResponse<T> {
	_embedded: T;
	_info: {
		"current": number;
		"total": number;
	};
	_links: {
		next: string;
		previous: string;
	};
}
