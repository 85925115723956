import { DatabaseEntity } from '../DatabaseEntity';
import { TaxRevisionStatus } from '../enums/TaxRevisionStatus';
import { Location } from '../location/Location';
import { Tax } from '../tax/Tax';
import { TaxRevision } from '../tax/TaxRevision';
import { User } from '../user/User';

export class TaxChanges extends DatabaseEntity {
  public approver: User;
  public changeNotes: string;
  public description: string;
  public editor: User;
  public effectiveFrom: Date;
  public effectiveTo: Date;
  public isApproved: boolean;
  public location: Location;
  public name: string;
  public rejectionReason: string;
  public approvalNotes: string;
  public revisionNumber: number;
  public status: TaxRevisionStatus;
  public type: number;

  public lastUpdate: Date;

  public static transform(taxChanges: TaxChanges): Tax {
    const tax = new Tax();
    const revision = new TaxRevision();

    tax.id = taxChanges.id;
    tax.name = taxChanges.name;
    tax.type = taxChanges.type;

    // revision
    revision.revisionNumber = taxChanges.revisionNumber;
    revision.description = taxChanges.description;
    revision.effectiveFrom = taxChanges.effectiveFrom;
    revision.effectiveTo = taxChanges.effectiveTo;
    revision.status = taxChanges.status;
    revision.approver = taxChanges.approver;
    revision.editor = taxChanges.editor;
    revision.lastUpdate = taxChanges.lastUpdate;
    revision.isApproved = taxChanges.isApproved;
    revision.approvalNotes = taxChanges.approvalNotes;
    revision.rejectionReason = taxChanges.rejectionReason;
    tax.revisions = [];
    tax.revisions.push(revision);

    tax.location = taxChanges.location;

    return tax;
  }
}
