import { Directive, ElementRef, Input } from '@angular/core';

import { DiffPipe } from '../pipes/diff.pipe';
import { InstantSearch } from '../util/InstantSearch';

@Directive({
  selector: '[appDiffAndSearch]',
})
export class DiffAndSearchDirective {
  @Input()
  public content: string;

  private _diff: string;
  private _searchString: string;

  constructor(private el: ElementRef) {}

  @Input()
  set searchString(searchString: string) {
    this._searchString = searchString;
    this.updateHtml();
  }

  get searchString() {
    return this._searchString;
  }

  get diff(): string {
    return this._diff;
  }

  @Input()
  set diff(value: string) {
    this._diff = value;
    this.updateHtml();
  }

  private updateHtml() {
    this.setContent(this.content);

    this.diffContent();

    this.highlightSearch();
  }

  private highlightSearch() {
    // skip highlight if no criteria
    if (!this._searchString || !this._searchString.trim().length) {
      return;
    }

    new InstantSearch(this.el.nativeElement, [
      {
        token: this._searchString, // searchText.value,
        className: 'search--highlight', // this is the individual highlight class
        sensitiveSearch: false,
      },
    ]).highlight();
  }

  private diffContent() {
    if (!this._diff || !this._diff.trim().length) {
      return;
    }

    (<HTMLElement>this.el.nativeElement).innerHTML = new DiffPipe().transform(this.content, this.diff);
  }

  private setContent(content: any) {
    (<HTMLElement>this.el.nativeElement).innerHTML = content;
  }
}
