<div id="user-profile-layout" *ngIf="user">
  <div class="padding-bottom">
    <h1>{{ 'user.user-profile.title' | translate }}</h1>
  </div>

  <!--Personal-->
  <p class="block-title">{{ 'user.user-profile.personal' | translate | uppercase }}</p>
  <div class="info">
    <div class="block">
      <p>{{ 'user.user-profile.name' | translate }}</p>
      <div class="ui input" ngbPopover="This data cannot be changed" placement="right">
        <input disabled type="text" [(ngModel)]="user.name" />
      </div>
    </div>

    <div class="block">
      <p>{{ 'user.user-profile.email' | translate }}</p>
      <div class="ui input" ngbPopover="This data cannot be changed" placement="right">
        <input disabled type="text" [value]="user.email" />
      </div>
    </div>
  </div>

  <!--Rules and permissions-->
  <p class="block-title">{{ 'user.user-profile.roles-permissions' | translate | uppercase }}</p>
  <!-- req 5 -->
  <!-- <div class="info">
    <div class="block">
      <p>{{ 'user.user-profile.role' | translate }}</p>
      <div class="ui input" ngbPopover="This data cannot be changed" placement="right">
        <input disabled type="text" [value]="rolesValue" />
      </div>
    </div>

    <div class="block" *ngIf="permittedLocations && permittedLocations.length">
      <p>{{ 'user.user-profile.countries' | translate }}</p>
      <div class="countries">
        <div class="country" *ngFor="let location of permittedLocations">{{ location }}</div>
      </div>
    </div>

    <div class="block" *ngIf="permittedTaxes && permittedTaxes.length">
      <p>{{ 'user.user-profile.taxes' | translate }}</p>
      <div class="countries">
        <div class="country" *ngFor="let tax of permittedTaxes">{{ tax }}</div>
      </div>
    </div>
  </div> -->

  <!-- req 5 -->
    <table class="table table-bordered-parts">
      <thead>
        <th >{{ 'user.roles-permissions.col-roles' | translate }}</th>
        <th >{{ 'user.roles-permissions.col-taxtype' | translate }}</th>
        <th >{{ 'user.roles-permissions.col-jurisdiction' | translate }}</th>
        <th >{{ 'user.roles-permissions.col-subdivisions' | translate }}</th>
      </thead>
      <tbody>
        <ng-container *ngFor="let up of finalUserProfiles; let i = index">
          <tr>
            <td>
                {{up.role}}
            </td>
            <td>
              {{up.taxType}}
            </td>
            <td>
              {{up.jurisdisction}}
            </td>
            <td>
              {{up.subdivision}}
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>




</div>
