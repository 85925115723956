import { Component, Input, OnInit } from '@angular/core';

import { TaxRevisionStatus } from '../../../model/data/enums/TaxRevisionStatus';
import { DashboardParam } from '../../../model/request/dashboard/DashboardParam';
import { TaxFilterService } from '../../services/tax-filter/tax-filter.service';

@Component({
  selector: 'app-tax-listing-changes',
  templateUrl: './tax-listing-changes.component.html',
  styleUrls: ['./tax-listing-changes.component.scss'],
  providers: [TaxFilterService],
})
export class TaxListingChangesComponent implements OnInit {
  @Input('type')
  public param: string;

  @Input('status')
  public status: number;

  constructor(public taxFilter: TaxFilterService) {}

  ngOnInit() {
    this.taxFilter.getTaxChanges(this.param, 1, new DashboardParam(this.status));
  }

  public getStatusPendingLocal() {
    return TaxRevisionStatus.PENDING_LOCAL;
  }

  public getStatusPendingGlobal() {
    return TaxRevisionStatus.PENDING_GLOBAL;
  }

  public getStatusApproved() {
    return TaxRevisionStatus.APPROVED;
  }

  public getStatusRejected() {
    return TaxRevisionStatus.REJECTED;
  }
}
