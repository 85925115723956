import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { ArticleType } from '@rar/model/data/enums/ArticleType';
import { TaxTopic } from '@rar/model/data/tax/TaxTopic';

@Component({
  selector: 'app-tax-topic-type-selector',
  templateUrl: './tax-topic-type-selector.component.html',
  styleUrls: ['./tax-topic-type-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaxTopicTypeSelectorComponent implements OnInit {
  public readonly ArticleType = ArticleType;

  @Input()
  articleType: ArticleType = ArticleType.KEY_VALUE;

  @Input()
  isDisabled = true;

  @Output()
  readonly articleTypeChange = new EventEmitter<ArticleType>();

  ngOnInit(): void {}

  changeArticleType(articleType: ArticleType): void {
    if (this.articleType === articleType) {
      return;
    }

    this.articleType = articleType;
    this.articleTypeChange.emit(this.articleType);
  }
}
