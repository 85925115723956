import {TaxTopic} from "./TaxTopic";

export class TaxSearchResult {

	constructor (searchCriteria: string) {
		this.searchCriteria = searchCriteria;
	}

	searchCriteria: string;
	topics: Set<TaxTopic>;

	public addResult(topic: TaxTopic, parent: TaxTopic) {
		if (!this.topics) {
			this.topics = new Set<TaxTopic>();
		}

		if (parent) {
			topic.parent = parent;
		}

		this.topics.add(topic);
	}

}
