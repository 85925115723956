import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { FileUploadResponse } from '@rar/model/response/FileUploadResponse';

@Component({
  selector: 'app-tax-file-upload-modal',
  templateUrl: './tax-file-upload-modal.component.html',
  styleUrls: ['./tax-file-upload-modal.component.scss'],
})
export class TaxFileUploadModalComponent {
  constructor(private activeModal: NgbActiveModal) {}

  @Input()
  fileResponse: FileUploadResponse;

  approve() {
    this.activeModal.close();
  }

  getWrongRowsInfo(): string {
    return this.fileResponse.notImportedRows.map((x) => `${x.categoryShortCode} (row ${x.row + 2})`).join(', ');
  }
}
