<div [class.table-responsive]="taxes">
  <!--Loading indicator-->
  <div class="ui active inline centered loader" *ngIf="!taxes"></div>

  <!--Tax table-->
  <table class="ui table custom-table padded-table selectable" *ngIf="taxes">
    <thead>
      <tr>
        <th>
          {{ 'commons.table.tax-listing.tax' | translate | uppercase }}
        </th>
        <th>
          {{ 'commons.table.tax-listing.jurisdiction' | translate | uppercase }}
        </th>
        <th>
          {{ 'commons.table.tax-listing.subdivision' | translate | uppercase }}
        </th>
        <th>
          {{ 'commons.table.tax-listing.validity' | translate | uppercase }}
        </th>

        <th *ngIf="statusFilter && +statusFilter === 2">
          {{ 'commons.table.tax-listing.rejecter' | translate | uppercase }}
        </th>
        <th *ngIf="!statusFilter || +statusFilter !== 2">
          {{ 'commons.table.tax-listing.approver' | translate | uppercase }}
        </th>

        <th>
          {{ 'commons.table.tax-listing.updated' | translate | uppercase }}
        </th>
      </tr>
    </thead>

    <!--Filter-->
    <tr class="noahover" *ngIf="taxFilterService.showFilter">
      <td>
        <div class="ui input">
          <input
            type="text"
            [(ngModel)]="filter.typeName"
            (ngModelChange)="onChange()"
            [placeholder]="'commons.table.tax-listing.tax' | translate" />
        </div>
      </td>

      <td>
        <div class="ui input">
          <input
            type="text"
            [(ngModel)]="filter.jurisdiction"
            (ngModelChange)="onChange()"
            [placeholder]="'commons.table.tax-listing.jurisdiction' | translate" />
        </div>
      </td>

      <td>
        <div class="ui input">
          <input
            type="text"
            [(ngModel)]="filter.subdivision"
            (ngModelChange)="onChange()"
            [placeholder]="'commons.table.tax-listing.subdivision' | translate" />
        </div>
      </td>

      <td>
        <!--					<div class="ui input">
						<input suiDatepicker class="narrow"
							[(ngModel)]="filter.effectiveFrom"
							(ngModelChange)="onChange()"
							[pickerMode]="'date'"
							[pickerUseNativeOnMobile]="true"
							placeholder="From">
					</div>
					<div class="ui input">
						<input suiDatepicker class="narrow"
							[(ngModel)]="filter.effectiveTo"
							(ngModelChange)="onChange()"
							[pickerMode]="'date'"
							[pickerUseNativeOnMobile]="true"
							placeholder="To">
					</div>-->
      </td>

      <td>
        <div class="ui input">
          <input
            type="text"
            [(ngModel)]="filter.approverName"
            (ngModelChange)="onChange()"
            [placeholder]="'commons.table.tax-listing.approver' | translate" />
        </div>
      </td>

      <td class="date-cell">
        <div class="ui input">
          <div class="input-group">
            <input
              #dp="ngbDatepicker"
              ngbDatepicker
              autocomplete="off"
              class="form-control"
              [(ngModel)]="filter.lastUpdate"
              (ngModelChange)="onChange()"
              placeholder="Last updated"
              container="body"
              placement="bottom-right" />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary btn-icon" (click)="dp.toggle()" [attr.aria-expanded]="dp.isOpen" type="button">
                <svg-icon svgClass="icon" src="/assets/images/icons/calendar.svg"></svg-icon>
              </button>
            </div>
          </div>
        </div>
      </td>
    </tr>

    <!--Content-->
    <tbody>
      <tr
        class="table-row cur-pointer"
        *ngFor="let tax of taxes"
        (click)="onClickTax()"
        [routerLink]="['/tax', tax.id, 'revision', tax.revisions[0].revisionNumber]">
        <td class="cell-wrap" [title]="tax.typeName">{{ tax.typeName }}</td>
        <td
          class="cell-wrap"
          *ngIf="tax.location.parent ? tax.location.parent.name : tax.location.name as jurisdictionName"
          [title]="jurisdictionName">
          {{ jurisdictionName }}
        </td>
        <td class="cell-wrap" *ngIf="tax.location.parent ? tax.location.name : '–' as subdivisionName" [title]="subdivisionName">
          {{ subdivisionName }}
        </td>
        <td
          [title]="
            (tax.revisions[0].effectiveFrom | date: 'MMM dd, yyyy') +
            ' - ' +
            (tax.revisions[0].effectiveTo !== null ? (tax.revisions[0].effectiveTo | date: 'MMM dd, yyyy') : '')
          ">
          {{ tax.revisions[0].effectiveFrom | date: 'MMM dd, yyyy' }}
          - {{ tax.revisions[0].effectiveTo | date: 'MMM dd, yyyy' }}
        </td>
        <td [title]="tax.revisions[0].approver ? tax.revisions[0].approver.name : 'N/A'">
          {{ tax.revisions[0].approver ? tax.revisions[0].approver.name : 'N/A' }}
        </td>
        <td [title]="tax.revisions[0].lastUpdate | date: 'MMM dd, yyyy hh:mm a'">
          {{ tax.revisions[0].lastUpdate | date: 'MMM dd, yyyy hh:mm a' }}
        </td>
      </tr>
    </tbody>

    <tfoot *ngIf="taxes.length">
      <tr>
        <th colspan="6">
          <div class="ui right floated pagination menu">
            <button class="ui basic button" [disabled]="!taxFilterService.isNextPage" (click)="taxFilterService.fetchNextPage()">
              <img class="icon" [src]="'../../../../assets/images/icons/arrow_right.svg'" />
            </button>
          </div>

          <div class="ui right floated pagination menu">
            <button class="ui basic button" [disabled]="!taxFilterService.isPreviousPage" (click)="taxFilterService.fetchPreviousPage()">
              <img class="icon" [src]="'../../../../assets/images/icons/arrow_left.svg'" />
            </button>
          </div>
          <div class="ui right floated pagination menu description">
            <p>
              {{ taxFilterService.currentPage }} of
              {{ taxFilterService.totalPages }}
            </p>
          </div>
        </th>
      </tr>
    </tfoot>
  </table>

  <!--empty message-->
  <app-empty-placeholder class="footer" *ngIf="taxes && !taxes.length"></app-empty-placeholder>
</div>
