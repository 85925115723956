import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { untilDestroyed } from 'ngx-take-until-destroy';

import { RoutePaths } from '@rar/commons/constants';
import { TaxTypeLocations } from '@rar/model/data/tax/TaxTypeLocations';
import { ApiCommunicationService } from '@rar/model/services/api-communication/api-communication.service';

@Component({
  selector: 'app-tax-type-locations-page',
  templateUrl: './tax-type-locations-page.component.html',
  styleUrls: ['./tax-type-locations-page.component.scss'],
})
export class TaxTypeLocationsPageComponent implements OnInit, OnDestroy {
  public id!: number;

  public taxTypeLocations!: TaxTypeLocations;
  public locationsSaving: boolean;

  constructor(private router: Router, private route: ActivatedRoute, private api: ApiCommunicationService) {
    this.id = +this.route.snapshot.params[RoutePaths.taxDesign.editedLocationsIdParam];
  }

  ngOnInit() {
    if (this.id) {
      this.fetchLocations();
    } else {
      this.router.navigate([RoutePaths.taxDesign.list]);
    }
  }

  ngOnDestroy(): void {}

  private fetchLocations() {
    this.api
      .tax()
      .getTaxTypeLocations(this.id)
      .pipe(untilDestroyed(this))
      .subscribe((response) => {
        this.taxTypeLocations = response;
      });
  }
}
