import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Tax } from '@rar/model/data/tax/Tax';
import { TaxRevision } from '@rar/model/data/tax/TaxRevision';

@Component({
  selector: 'app-tax-settings',
  templateUrl: './tax-settings.component.html',
  styleUrls: ['./tax-settings.component.scss'],
})
export class TaxSettingsComponent implements OnInit {
  public revision: Tax & TaxRevision;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    // resolve route data
    this.route.parent.data.subscribe((data) => {
      this.revision = data.taxRevision;
    });
  }
}
