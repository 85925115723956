<div class="modal-header">
  <h4 class="modal-title">
    {{ 'tax.tax-rates.edit-rate-modal.title' | translate }}
  </h4>
</div>

<div class="modal-body">
  <p>
    {{ 'tax.tax-rates.edit-rate-modal.content-start' | translate }}
    <strong>{{ context.tax.name }} / {{ context.tax.location.name }}</strong>
    {{ 'tax.tax-rates.edit-rate-modal.content-end' | translate }}:
  </p>

  <p>
    <strong>{{ context.tax.effectiveFrom | date: 'MMM dd, yyyy' }} - {{ context.tax.effectiveTo | date: 'MMM dd, yyyy' }}</strong>
  </p>

  <p>
    {{ 'tax.edit-modal.note' | translate }}
  </p>

  <div class="ui form">
    <div class="grouped fields">
      <!--Confirm revision checkbox-->
      <div class="field form-check">
        <input class="form-check-input" type="radio" id="response01" [value]="1" [(ngModel)]="response.checkbox" />
        <label for="response01" class="form-check-label">
          <strong>{{ 'tax.edit-modal.confirm-without-changes.title' | translate }}</strong>
          <br />
          {{ 'tax.edit-modal.confirm-without-changes.description' | translate }}
        </label>
      </div>

      <!--Edit existing checkbox-->
      <div class="field form-check">
        <input class="form-check-input" type="radio" id="response02" [value]="2" [(ngModel)]="response.checkbox" />
        <label for="response02" class="form-check-label">
          <strong>{{ 'tax.edit-modal.edit-current-revision.title' | translate }}</strong>
          <br />
          {{ 'tax.edit-modal.edit-current-revision.description' | translate }}
        </label>
      </div>

      <!--Create new checkbox -->
      <!-- req 10 -->
      <div class="field form-check"
      *ifHasRole="[role.ADMIN, role.SUPER_ADMIN]; requestedTax: context.tax">
        <input class="form-check-input" type="radio" id="response03" [value]="3" [(ngModel)]="response.checkbox" />
        <label for="response03" class="form-check-label">
          <strong>{{ 'tax.edit-modal.create-new-revision.title' | translate }}</strong>
          <br />
          {{ 'tax.edit-modal.create-new-revision.description' | translate }}
        </label>

        <!--Effective from-->
        <label class="effective-from-label">{{ 'tax.edit-modal.create-new-revision.effective-from' | translate }}</label>
        <div class="ui right icon input">
          <div class="datepicker-field input-group">
            <input
              #dp="ngbDatepicker"
              ngbDatepicker
              autocomplete="off"
              class="form-control"
              [(ngModel)]="response.effectiveFrom"
              placeholder="Select Date"
              placement="bottom-right"
              [minDate]="getMinDate()" />
            <div class="input-group-append">
              <button class="btn btn-outline-secondary btn-icon" (click)="dp.toggle()" [attr.aria-expanded]="dp.isOpen" type="button">
                <svg-icon svgClass="icon" src="/assets/images/icons/calendar.svg"></svg-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div></div>

  <div class="button-group">
    <!--Deny-->
    <button class="ui basic button silver" (click)="deny()">
      {{ 'user.user-edit-modal.cancel' | translate }}
    </button>

    <!--Approve-->
    <button
      class="ui basic button rose"
      [disabled]="response.checkbox === 3 && response.effectiveFrom === undefined"
      (click)="approve(response)">
      {{ 'user.user-edit-modal.ok' | translate | uppercase }}
    </button>
  </div>
</div>
