import {PagedResponse} from "./wrappers/PagedResponse";
import {TaxRecentViews} from "../data/dashboard/TaxRecentViews";
import {Tax} from "../data/tax/Tax";
import {TaxChanges} from "../data/dashboard/TaxChanges";

export class TaxRecentViewListResponse extends PagedResponse<{taxes: Array<TaxRecentViews>}> {

	private static transform(taxRecentViewListResponse: TaxRecentViewListResponse): Array<Tax> {
		const taxChanges: Array<Tax> = [];
		taxRecentViewListResponse._embedded.taxes.forEach(t => taxChanges.push(TaxChanges.transform(t.tax)));
		return taxChanges;
	}

	public static transformResponse(response: TaxRecentViewListResponse) {
		const responseTransformed = <any>{};
		responseTransformed._info = response._info;
		responseTransformed._links = response._links;
		responseTransformed._embedded = {};
		responseTransformed._embedded.taxes = {};
		responseTransformed._embedded.taxes.tax = TaxRecentViewListResponse.transform(response);
		return responseTransformed;
	}

}
