import { Inject, Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TaxType } from '../../..//model/data/tax/TaxType';
import { TaxRevisionStatus } from '../../../model/data/enums/TaxRevisionStatus';
import { Tax } from '../../../model/data/tax/Tax';
import { DashboardParam } from '../../../model/request/dashboard/DashboardParam';
import { DashboardRequest } from '../../../model/request/dashboard/DashboardRequest';
import { TaxParams } from '../../../model/request/tax/TaxParams';
import { TaxChangeListResponse } from '../../../model/response/TaxChangeListResponse';
import { TaxRecentViewListResponse } from '../../../model/response/TaxRecentViewListResponse';
import { ApiCommunicationService } from '../../../model/services/api-communication/api-communication.service';

type TaxTable = Tax & { typeName: string };
export interface TaxFilter {
  typeName?: string;
  jurisdiction?: string;
  subdivision?: string;
  approverName?: string;
  effectiveFrom?: Date;
  effectiveTo?: Date;
  lastUpdate?: Date;
}

@Injectable()
export class TaxFilterService {
  private allTaxes: any;
  private _taxes: BehaviorSubject<Array<TaxTable>> = new BehaviorSubject<Array<TaxTable>>(undefined);

  // url param
  private param: string;
  private taxParams: TaxParams;
  private dashboardParam: DashboardParam;

  private filter: TaxFilter = {};
  private _showFilter = false;

  // are the next and prev pages available
  private _isNextPage = false;
  private _isPreviousPage = false;

  private taxTypesList: TaxType[];

  constructor(@Inject(ApiCommunicationService) private api: ApiCommunicationService) {
    this.api
      .tax()
      .getTaxTypes()
      .pipe(
        tap((types) => {
          this.taxTypesList = types;
          this.update();
        }),
      )
      .subscribe();
  }

  public getPage(page: number, params: TaxParams) {
    this.taxParams = params;

    // fetch data
    this.api
      .tax()
      .getTaxPage(page, params)
      .subscribe((taxes) => {
        this.allTaxes = taxes;

        // update paging
        this._isNextPage = !!taxes._links.next;
        this._isPreviousPage = !!taxes._links.previous;

        // update filter
        this.update();
      });
  }

  public getTaxChanges(type: string, page: number, status: DashboardParam) {
    this.param = type;

    if (this.param === 'approval') {
      this.api
        .dashboard()
        .getAwaitingTaxesForApproval(DashboardRequest.ALL, page, new DashboardParam(TaxRevisionStatus.PENDING_LOCAL))
        .subscribe((response) => {
          this.allTaxes = TaxChangeListResponse.transformResponse(response);

          // update paging
          this._isNextPage = !!response._links.next;
          this._isPreviousPage = !!response._links.previous;

          // update filter
          this.update();
        });
    }

    if (this.param === 'changes') {
      this.dashboardParam = status;
      this.api
        .dashboard()
        .getAwaitingTaxesForEditor(DashboardRequest.ALL, 1, this.dashboardParam)
        .subscribe((response) => {
          this.allTaxes = TaxChangeListResponse.transformResponse(response);

          // update paging
          this._isNextPage = !!response._links.next;
          this._isPreviousPage = !!response._links.previous;

          // update filter
          this.update();
        });
    }

    if (this.param === 'upcoming') {
      this.api
        .dashboard()
        .getUpcomingChanges(DashboardRequest.ALL, page)
        .subscribe((response) => {
          this.allTaxes = TaxChangeListResponse.transformResponse(response);

          // update paging
          this._isNextPage = !!response._links.next;
          this._isPreviousPage = !!response._links.previous;

          // update filter
          this.update();
        });
    }

    if (this.param === 'views') {
      this.api
        .dashboard()
        .getRecentViews(DashboardRequest.ALL, page)
        .subscribe((response) => {
          this.allTaxes = TaxRecentViewListResponse.transformResponse(response);

          // update paging
          this._isNextPage = !!response._links.next;
          this._isPreviousPage = !!response._links.previous;

          // update filter
          this.update();
        });
    }

    if (this.param === 'latest') {
      this.api
        .dashboard()
        .getLatestChanges(DashboardRequest.ALL, page)
        .subscribe((response) => {
          this.allTaxes = TaxChangeListResponse.transformResponse(response);

          // update paging
          this._isNextPage = !!response._links.next;
          this._isPreviousPage = !!response._links.previous;

          // update filter
          this.update();
        });
    }
  }

  public updateFilter(filter: TaxFilter) {
    this.filter = filter;
    this.update();
  }

  private update() {
    const data = this.param === 'views' ? this.allTaxes._embedded.taxes.tax : this.allTaxes._embedded.taxes;

    this._taxes.next(
      data
        .map((tax) => {
          if (this.taxTypesList) {
            tax.typeName = this.taxTypesList.find((t) => t.id === tax.type).name;
          }
          return tax;
        })
        .filter((t) => this.filterTax(t)),
    );
  }

  private filterTax(t: TaxTable): boolean {
    const approverName = t.revisions[0].approver ? t.revisions[0].approver.name : '';

    return (
      this.searchString(this.filter.typeName, t.typeName) &&
      // jurisdiction will be in location, or in its parent when the location is subdivision
      this.searchString(this.filter.jurisdiction, t.location.parent?.name ?? t.location.name) &&
      // when filtering by subdivision the location must have a parent
      (!this.filter.subdivision || (t.location.parent && this.searchString(this.filter.subdivision, t.location.name))) &&
      this.searchString(this.filter.approverName, approverName) &&
      (this.filter.effectiveFrom ? this.filter.effectiveFrom >= t.revisions[0].effectiveFrom : true) &&
      (this.filter.effectiveTo ? this.filter.effectiveTo <= t.revisions[0].effectiveTo : true) &&
      (this.filter.lastUpdate ? this.isSameDay(t.revisions[0].lastUpdate, this.filter.lastUpdate) : true)
    );
  }

  private searchString(filter: string, text: string): boolean {
    let regexSearch = false;
    try {
      regexSearch = !!filter && (text || '').toLocaleLowerCase().search(filter.toLowerCase()) !== -1;
    } catch {
      // filter is probably not a valid regex string and will break the search method
    }
    return filter ? regexSearch || (text || '').toLocaleLowerCase().indexOf(filter.toLowerCase()) !== -1 : true;
  }

  /**
   * Is the same day?
   * @param d1 string date.
   * @param d2
   */
  private isSameDay(d1: any, d2: Date): boolean {
    // cast from string
    const ud1 = new Date(d1);
    return (
      ud1 &&
      d2 &&
      new Date(d2).getDate() &&
      ud1.getFullYear() === d2.getFullYear() &&
      ud1.getMonth() === d2.getMonth() &&
      ud1.getDate() === d2.getDate()
    );
  }

  get taxes(): Observable<Array<TaxTable>> {
    return this._taxes.asObservable();
  }

  public fetchNextPage() {
    if (this.isNextPage) {
      if (this.taxParams) {
        this.getPage(this.allTaxes._info.current + 1, this.taxParams);
      }

      if (this.param) {
        this.getTaxChanges(this.param, this.allTaxes._info.current + 1, this.dashboardParam);
      }
    } else {
      console.error('Fetching next page is not allowed.');
    }
  }

  public fetchPreviousPage() {
    if (this.isPreviousPage) {
      if (this.taxParams) {
        this.getPage(this.allTaxes._info.current - 1, this.taxParams);
      }

      if (this.param) {
        this.getTaxChanges(this.param, this.allTaxes._info.current - 1, this.dashboardParam);
      }
    } else {
      console.error('Fetching previous page is not allowed.');
    }
  }

  get isNextPage(): boolean {
    return this._isNextPage;
  }

  get isPreviousPage(): boolean {
    return this._isPreviousPage;
  }

  get totalPages(): number {
    return this.allTaxes._info.total;
  }

  get currentPage(): number {
    return this.allTaxes._info.current;
  }

  get showFilter(): boolean {
    return this._showFilter;
  }

  public setShowFilter(value: boolean) {
    this._showFilter = value;
  }
}
