import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SvgLoader } from 'angular-svg-icon';
import { Observable } from 'rxjs';

@Injectable()
export class RarSvgLoader extends SvgLoader {
  constructor(private http: HttpClient) {
    super();
  }

  getSvg(url: string): Observable<string> {
    const headers = new HttpHeaders().set('Accept', 'image/svg+xml');
    return this.http.get(url, { responseType: 'text', headers: headers });
  }
}
