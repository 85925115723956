<div class="modal-header">
  <h4 class="modal-title">Multiple editing</h4>
</div>

<div class="modal-body">
  The pending revision edit was made by:
  <br />
  <strong>{{ editors }}</strong>

  <br />
  <br />

  Making any further changes will lock the approval process until the edit is finished.
  <br />
  Do you want to continue?
</div>

<div class="modal-footer">
  <div></div>

  <div class="button-group">
    <!--Deny-->
    <button class="ui basic button silver" (click)="deny()">
      {{ 'user.user-edit-modal.cancel' | translate }}
    </button>

    <!--Approve-->
    <button class="ui basic button rose" (click)="approve()">Yes</button>
  </div>
</div>
