<div class="modal-header">
	<h4 class="modal-title">
		Do you want to save changes?
	</h4>
</div>

<div class="modal-body"></div>

<div class="modal-footer">
	<div></div>

	<div class="button-group">
		<button class="ui basic button silver" (click)="cancel()">
			{{ "user.user-edit-modal.cancel" | translate }}
		</button>

		<button 
			class="ui basic button rose"
			(click)="approve()">
			Yes
		</button>
	</div>
</div>