export const RoutePaths = {
  users: {
    list: 'users',
    addNew: 'users/new',
    edit: 'users/:id',
    getEditPath(userId: number) {
      return `users/${userId}`;
    },
    editedUserIdParam: 'id',
  },
  download: {
    page: 'download-attachment',
    attachmentIdParameter: 'attachmentId',
    closeAfterDownload: 'close',
  },
  taxDesign: {
    list: 'tax-design',
    edit: 'tax-design/:id',
    getEditPath(taxTypeId: number) {
      return `tax-design/${taxTypeId}`;
    },
    editedTaxTypeIdParam: 'id',
    locations: 'tax-design/:id/locations',
    getLocationsPath(taxTypeId: number) {
      return `tax-design/${taxTypeId}/locations`;
    },
    editedLocationsIdParam: 'id',
  },
  noAccess: 'no-access',
  notFound: '/',
} as const;
