import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, NG_VALIDATORS, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { NgbAccordionModule, NgbDatepickerModule, NgbDropdownModule, NgbModalModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { CommonsModule } from '@rar/commons/commons.module';
import { RoutePaths } from '@rar/commons/constants/route-paths';
import { Role } from '@rar/model/data/enums/Role';
import { TaxModule } from '@rar/tax/tax.module';
import { AuthGuardService } from '@rar/user/guard/auth-guard.service';

import { TaxTypeInfoComponent } from './components/tax-type-info/tax-type-info.component';
import { TaxTypeLocationsFormComponent } from './components/tax-type-locations-form/tax-type-locations-form.component';
import { TaxTypeLocationsComponent } from './components/tax-type-locations/tax-type-locations.component';
import { TaxTypeRatesComponent } from './components/tax-type-rates/tax-type-rates.component';
import { TaxTypeRulesKeyValueComponent } from './components/tax-type-rules/tax-type-rules-key-value/tax-type-rules-key-value.component';
import { TaxTypeRulesComponent } from './components/tax-type-rules/tax-type-rules.component';
import { TaxTypeSubMenuComponent } from './components/tax-type-sub-menu/tax-type-sub-menu.component';
import { TaxTypeTopicTypeSelectorComponent } from './components/tax-type-topic-type-selector/tax-type-topic-type-selector.component';
import { TaxTypeTopicComponent } from './components/tax-type-topic/tax-type-topic.component';
import { TaxTypesTableComponent } from './components/tax-types-table/tax-types-table.component';
import { UniqueNameDirective } from './directives/unique-name.directive';
import { TaxTypeDiscardChangesGuard } from './guards/tax-type-discard-changes-guard';
import { TaxTypeConfirmModalComponent } from './modals/tax-type-confirm-modal/tax-type-confirm-modal.component';
import { TaxTypeDiscardModalComponent } from './modals/tax-type-discard-modal/tax-type-discard-modal.component';
import { TaxTypeReorderCategoryModalComponent } from './modals/tax-type-reorder-category-modal/tax-type-reorder-category-modal.component';
import { TaxTypeListingPageComponent } from './pages/tax-type-listing-page/tax-type-listing-page.component';
import { TaxTypeLocationsPageComponent } from './pages/tax-type-locations-page/tax-type-locations-page.component';
import { TaxTypeStructurePageComponent } from './pages/tax-type-structure-page/tax-type-structure-page.component';
import { TaxTypeStructureResolver } from './resolvers/tax-type-structure.resolver';
import { TaxTypeTopicResolver } from './resolvers/tax-type-topic.resolver';
import { TaxTypeLocationsFormMapperService } from './services/tax-type-locations-form/tax-type-locations-form-mapper.service';

const routes: Routes = [
  {
    path: RoutePaths.taxDesign.list,
    component: TaxTypeListingPageComponent,
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    data: {
      roles: [Role.SUPER_ADMIN],
    },
  },
  {
    path: RoutePaths.taxDesign.locations,
    component: TaxTypeLocationsPageComponent,
    canActivate: [AuthGuardService],
    data: {
      roles: [Role.SUPER_ADMIN],
    },
    resolve: {
      taxTypeStructure: TaxTypeStructureResolver,
    },
  },
  {
    path: RoutePaths.taxDesign.edit,
    component: TaxTypeStructurePageComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [TaxTypeDiscardChangesGuard],
    data: {
      roles: [Role.SUPER_ADMIN],
    },
    resolve: {
      taxTypeStructure: TaxTypeStructureResolver,
    },
    children: [
      {
        path: 'topic/:id',
        component: TaxTypeTopicComponent,
        resolve: {
          topic: TaxTypeTopicResolver,
        },
        canActivate: [AuthGuardService],
        runGuardsAndResolvers: 'always',
      },
    ],
  },
];

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        TranslateModule.forChild(),
        RouterModule.forChild(routes),
        FormsModule,
        ReactiveFormsModule,
        NgbDatepickerModule,
        NgbModalModule,
        NgbPopoverModule,
        NgSelectModule,
        NgbDropdownModule,
        NgbAccordionModule,
        AngularSvgIconModule,
        CommonsModule,
        TaxModule,
    ],
    declarations: [
        TaxTypeListingPageComponent,
        TaxTypesTableComponent,
        TaxTypeStructurePageComponent,
        TaxTypeSubMenuComponent,
        TaxTypeInfoComponent,
        TaxTypeTopicComponent,
        TaxTypeRatesComponent,
        TaxTypeRulesComponent,
        TaxTypeRulesKeyValueComponent,
        TaxTypeConfirmModalComponent,
        TaxTypeDiscardModalComponent,
        TaxTypeReorderCategoryModalComponent,
        TaxTypeTopicTypeSelectorComponent,
        TaxTypeLocationsPageComponent,
        TaxTypeLocationsFormComponent,
        TaxTypeLocationsComponent,
        UniqueNameDirective,
    ],
    providers: [
        TaxTypeStructureResolver,
        TaxTypeTopicResolver,
        TaxTypeLocationsFormMapperService,
        { provide: NG_VALIDATORS, useExisting: UniqueNameDirective, multi: true },
    ]
})
export class TaxDesignModule {}
