import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';
import {Directive, Input, OnDestroy, OnInit} from '@angular/core';
import {ApiCommunicationService} from '@rar/model/services/api-communication/api-communication.service';
import {TaxType} from '@rar/model/data/tax/TaxType';
import {untilDestroyed} from 'ngx-take-until-destroy';

@Directive({
  selector: '[appUniqueName]',
  providers: [{provide: NG_VALIDATORS, useExisting: UniqueNameDirective, multi: true}]
})
export class UniqueNameDirective implements Validator, OnDestroy, OnInit {

  constructor(private api: ApiCommunicationService) {
  }

  private taxTypes: TaxType[];
  private initName: string;

  @Input()
  taxTypeName: string;

  ngOnInit() {
    this.api.tax().getAllTaxTypes().pipe(untilDestroyed(this)).subscribe((taxTypes: TaxType[]) => {
      this.taxTypes = taxTypes;
    });
    this.initName = this.taxTypeName;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.taxTypes) {
      const taxType = this.taxTypes.find(tax => tax.name === control.value.trim());
      if (taxType && this.initName && this.initName !== taxType.name) {
        control.setErrors({ unique: true });
        return { unique: true };
      } else {
        return null;
      }
    }
  }

  ngOnDestroy() {
  }
}
