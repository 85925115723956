import {Component, Input, OnInit} from "@angular/core";
import {AuditFilterService} from "../../services/audit-filter.service";

import {User} from "../../../model/data/user/User";
import {AuditTax} from "../../../model/data/audit/AuditTax";

@Component({
	selector: "app-audit-filter",
	templateUrl: "./audit-filter.component.html",
	styleUrls: ["./audit-filter.component.scss"]
})
export class AuditFilterComponent implements OnInit {

	// filter
	@Input("users")
	public users: Array<User>;

	@Input("taxes")
	public taxes: Array<AuditTax>;

	@Input("taxId")
	public taxId: number;

	public selectedUser: User;
	public selectedTax: AuditTax;

	constructor(public auditFilterService: AuditFilterService) {
		this.onClearFilter();
	}

	ngOnInit() {
    this.auditFilterService.getInitData();
	}

	public onSelectFilter() {
		this.auditFilterService.selectedTaxId = this.selectedTax ? this.selectedTax.id : undefined;

		const filter = this.selectedUser ? {userId: [this.selectedUser.id]} : {};
		if (this.selectedTax) {
			Object.assign(filter, {taxId: [this.selectedTax.id]});
		}

		this.auditFilterService.onFilter(filter);
	}

	public onClearFilter() {
		this.selectedTax = undefined;
		this.selectedUser = undefined;
		this.auditFilterService.onClear();
	}
}
