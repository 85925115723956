import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { saveAs } from 'file-saver';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of, timer } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { RoutePaths } from '@rar/commons/constants';
import { ApiCommunicationService } from '@rar/model/services/api-communication/api-communication.service';

@Component({
  selector: 'app-download-attachment-page',
  templateUrl: './download-attachment-page.component.html',
  styleUrls: ['./download-attachment-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadAttachmentPageComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    @Inject(ApiCommunicationService) private api: ApiCommunicationService,
  ) { }

  isDownloading = true;

  ngOnInit(): void {
    this.activatedRoute.params
      .pipe(
        switchMap((params) => {
          const attachmentId = `${params[RoutePaths.download.attachmentIdParameter]}`;

          return this.api.tax().downloadAttachment(attachmentId);
        }),
        catchError(() => of(null)),
        untilDestroyed(this),
      )
      .subscribe((file: File | null) => {
        if (file) {
          saveAs(file, file.name);
        }

        this.isDownloading = false;
        this.cdr.markForCheck();

        if (this.activatedRoute.snapshot.queryParamMap.has(RoutePaths.download.closeAfterDownload)) {
          setTimeout(() => {
            this.router.navigate(['/']);
          });
        }
      });
  }

  ngOnDestroy(): void { }
}
