import { EventEmitter, Injectable } from '@angular/core';

import { TaxTypeTopic } from '../../model/data/tax/TaxTypeTopic';

@Injectable({ providedIn: 'root' })
export class TaxTypeTopicService {
  public selectTopic = new EventEmitter<TaxTypeTopic>();

  constructor() {}
}
