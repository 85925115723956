import { Injectable, TemplateRef, Type } from '@angular/core';

import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmDialogComponent } from '../components/confirm-dialog/confirm-dialog.component';

export type NgbModalType<T> = Omit<NgbModalRef, 'componentInstance'> & {
  componentInstance: T;
};

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(public ngbModal: NgbModal) {}

  open<T>(content: Type<T>, options?: NgbModalOptions): NgbModalType<T> {
    return this.ngbModal.open(content, options);
  }

  openConfirmation(options: {
    title: string;
    content?: string | string[];
    contentTemplate?: TemplateRef<any>;
    yesButtonText?: string | null;
    noButtonText?: string | null;
    modalClass?: string;
    validator?: () => boolean;
    isDestructiveWarning?: boolean;
  }): Promise<boolean> {
    const modalRef = this.open(ConfirmDialogComponent, {
      animation: false,
      backdrop: 'static',
      modalDialogClass: options.modalClass ?? 'modal-dialog-centered modal-fit',
      keyboard: true,
    });

    const dialog = modalRef.componentInstance;
    dialog.title = options.title;
    dialog.contentTemplate = options.contentTemplate;
    dialog.content = typeof options.content === 'string' ? [options.content] : options.content;
    dialog.yesButtonText = options.yesButtonText || 'Yes';
    dialog.noButtonText = options.noButtonText || 'No';
    dialog.validator = options.validator || (() => true);
    dialog.isDestructiveWarning = options.isDestructiveWarning;

    return modalRef.result as Promise<boolean>;
  }

  showInformation(options: {
    title: string;
    content?: string | string[];
    contentTemplate?: TemplateRef<any>;
    confirmButtonText?: string | null;
    modalClass?: string;
  }): Promise<void> {
    const modalRef = this.open(ConfirmDialogComponent, {
      animation: false,
      backdrop: 'static',
      modalDialogClass: options.modalClass ?? 'modal-dialog-centered modal-fit',
      keyboard: true,
    });

    const dialogComponent = modalRef.componentInstance;
    dialogComponent.title = options.title;
    dialogComponent.contentTemplate = options.contentTemplate;
    dialogComponent.content = typeof options.content === 'string' ? [options.content] : options.content;
    dialogComponent.yesButtonText = options.confirmButtonText || 'Close';
    dialogComponent.isInformationDialog = true;

    return modalRef.result.then(
      () => {},
      () => {},
    );
  }
}
