export interface TopicDifference {
  isPropagated: boolean;
  name: string;
  type: null | 'rule';
  shortCode: string;
  subTopics: TopicDifference[] | null;
  toPropagate: boolean;
  order: number;
  isUpdated?: boolean;
}

/**
 * Response object that includes the differences between global structure and tax revision .
 */
export interface TaxStructureDifferences {
  categories: TopicDifference[];
}
