import { HttpClient } from '@angular/common/http';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import * as d3 from 'd3';
import { untilDestroyed } from 'ngx-take-until-destroy';

import { Location } from '../../../model/data/location/Location';
import { DashboardLoaderService } from '../../services/dashboard-loader.service';

@Component({
  selector: 'app-dashboard-map',
  templateUrl: './dashboard-map.component.html',
  styleUrls: ['./dashboard-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardMapComponent implements OnInit, OnDestroy, AfterContentInit {
  @Input('locations')
  public locations: Array<Location>;

  @ViewChild('mapContainer', { static: true }) mapContainer: ElementRef;

  private svg;
  private width;
  private height;
  private g;

  private zoom = d3.zoom().on('zoom', this.zoomed.bind(this));

  constructor(private http: HttpClient, private dashboardService: DashboardLoaderService) {}

  ngOnInit(): void {
    this.locations = this.locations.filter(function (elem, index, self) {
      return index === self.indexOf(elem);
    });
  }

  ngOnDestroy(): void {}

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.width = this.mapContainer.nativeElement.clientWidth;
    this.height = this.mapContainer.nativeElement.clientHeight;

    this.svg.transition().attr('width', this.width).attr('height', this.height);

    this.initiateZoom();
  }

  ngAfterContentInit(): void {
    const markerDimension = 20;

    this.width = this.mapContainer.nativeElement.clientWidth;
    this.height = this.mapContainer.nativeElement.clientHeight;

    this.svg = d3
      .select('div#map-container')
      .append('svg')
      .attr('width', this.width)
      .attr('height', this.height)
      .call(this.zoom.bind(this));

    this.g = this.svg.append('g');

    // const albersProjection = d3.geoMercator()
    // 	.scale(90)
    // 	.translate([this.width / 2.1, this.height / 1.5]);

    const albersProjection = d3
      .geoEquirectangular()
      .center([0, 15])
      .scale([this.width / (2 * Math.PI)])
      .translate([this.width / 2, this.height / 2]);

    const geoPath = d3.geoPath().projection(albersProjection);

    const tooltip = this.svg.append('text').attr('class', 'd3-tooltip');

    this.http
      .get('assets/world_countries.json')
      .pipe(untilDestroyed(this))
      .subscribe(
        (data: any) => {
          this.g
            .attr('class', 'countries')
            .selectAll('path')
            .data(data.features)
            .enter()
            .append('path')
            .attr('d', geoPath)
            // .style("fill", function(d) { return color(populationById[d.id]); })
            .style('stroke', 'white')
            .style('stroke-width', 0.5)
            .style('opacity', 0.1);
          // adding markers
          this.svg
            .selectAll('.mark')
            .data(this.locations)
            .enter()
            .append('image')
            .attr('class', 'mark')
            .attr('width', markerDimension)
            .attr('height', markerDimension)
            .attr('xlink:href', function (d, i) {
              return '/assets/images/icons/location.svg';
            })
            .attr('transform', function (data) {
              if (data.lng && data.lat) {
                const coords = albersProjection([data.lng, data.lat]);
                return 'translate(' + (coords[0] - markerDimension / 2) + ', ' + (coords[1] - markerDimension) + ')';
              }
            })
            .on('mouseover', function (event) {
              const data = event.srcElement.__data__;
              tooltip.text(data.name);
              const size = tooltip.node().getBBox();
              tooltip.attr('transform', function () {
                if (data.lng && data.lat) {
                  const coords = albersProjection([data.lng, data.lat]);
                  return 'translate(' + (coords[0] - size.width / 2) + ', ' + (coords[1] - (size.height + 8)) + ')';
                }
              });
              tooltip.style('visibility', 'visible');
            })
            .on('mouseout', function () {
              tooltip.style('visibility', 'hidden');
            })
            .on('click', (event) => {
              this.dashboardService.mapClickEvent.emit(event.srcElement.__data__.id);
            });
          this.initiateZoom();
        },
        (error) => console.log(error),
      );
  }

  initiateZoom() {
    const minZoom = Math.max(
      this.mapContainer.nativeElement.clientWidth / this.width,
      this.mapContainer.nativeElement.clientHeight / this.height,
    );
    const maxZoom = 10 * minZoom;
    this.zoom.scaleExtent([minZoom, maxZoom]).translateExtent([
      [0, 0],
      [this.width, this.height],
    ]);

    const midX = (this.mapContainer.nativeElement.clientWidth - minZoom * this.width) / 2;
    const midY = (this.mapContainer.nativeElement.clientHeight - minZoom * this.height) / 2;

    this.svg.call(this.zoom.transform, d3.zoomIdentity.translate(midX, midY).scale(minZoom));
  }

  zoomed(event) {
    const t = event.transform;

    if (this.svg.attr('transform')) {
      const currentScale = +this.svg.attr('transform').match(/scale\((\d.*)\)/)[1];

      if (currentScale !== t.k) {
        if (t.k === 1) {
          this.zoom.translateExtent([
            [0, 0],
            [this.width, this.height],
          ]);
        } else {
          const d = t.k > currentScale ? 1 : -1;
          t.x = t.x + 50 * d;
          t.y = t.y + 10 * d;
          this.zoom.translateExtent([
            [-this.width * (t.k / 7), -this.height * (t.k / 10)],
            [this.width, this.height],
          ]);
        }
      }
    }

    this.svg.attr('transform', 'translate(' + [t.x, t.y] + ')scale(' + t.k + ')');
  }
}
