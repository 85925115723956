import { Component, Inject, Injector, OnDestroy, OnInit, SkipSelf } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter, map } from 'rxjs/operators';

import { RoutePaths } from '@rar/commons/constants/route-paths';
import { UserHelper } from '@rar/commons/helpers';

import { Role } from '../../../model/data/enums/Role';
import { UserStatus } from '../../../model/data/enums/UserStatus';
import { User } from '../../../model/data/user/User';
import { ApiCommunicationService } from '../../../model/services/api-communication/api-communication.service';
import { UserFilterService } from '../../services/user-filter/user-filter.service';
import { UserSessionService } from '../../services/user-session/user-session.service';

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.scss'],
})
export class UserTableComponent implements OnInit, OnDestroy {
  public users: Array<User>;

  public filter: User = <any>{};

  public roles: { id: 0 | Role; name: string }[] = [
    { id: 0, name: this.translateService.instant('commons.roles.all') },
    { id: Role.VIEWER, name: this.translateService.instant('commons.roles.viewer') },
    { id: Role.EDITOR, name: this.translateService.instant('commons.roles.editor') },
    { id: Role.APPROVER, name: this.translateService.instant('commons.roles.approver') },
    { id: Role.ADMIN, name: this.translateService.instant('commons.roles.admin') },
    { id: Role.SUPER_ADMIN, name: this.translateService.instant('commons.roles.superadmin') },
  ];

  public statuses: { id: 0 | UserStatus; name: string }[] = [
    { id: 0, name: this.translateService.instant('commons.user-statuses.all') },
    { id: UserStatus.ACTIVE, name: this.translateService.instant('commons.user-statuses.active') },
    { id: UserStatus.ARCHIVED, name: this.translateService.instant('commons.user-statuses.archived') },
  ];

  public routePaths = RoutePaths.users;

  constructor(
    @SkipSelf() private injector: Injector,
    public userFilterService: UserFilterService,
    @Inject(ApiCommunicationService) private api: ApiCommunicationService,
    private router: Router,
    private translateService: TranslateService,
    private userSessionService: UserSessionService,
  ) {}

  ngOnInit() {
    this.userFilterService.users.pipe(untilDestroyed(this)).subscribe((users) => (this.users = users));
  }

  ngOnDestroy() {}

  public onClickEdit(user: User) {
    this.userSessionService.userData
      .pipe(
        filter((userData) => !!userData),
        map((userData) => UserHelper.isUserSuperAdminOrAdminForAnyTax(userData)),
        untilDestroyed(this),
      )
      .subscribe((isAdmin) => {
        if (isAdmin) {
          this.router.navigate(['/' + RoutePaths.users.getEditPath(user.id)]);
        }
      });
  }

  public updateTable() {
    this.userFilterService.refetchPage();
  }

  public toggleUserStatus(user: User) {
    // flip status
    const newStatus = user.status === UserStatus.ARCHIVED ? UserStatus.ACTIVE : UserStatus.ARCHIVED;
    user.status = newStatus;

    this.api
      .user()
      .setUserStatus(user.id, newStatus)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.userFilterService.updateUserLocally(user);
      });
  }

  public onChange() {
    this.userFilterService.updateFilter(this.filter);
  }
}
