import { AbstractApiConnector } from "./AbstractApiConnector";
import { Observable } from "rxjs";
import { ValidationError } from "../exception/ValidationError";
import { TaxReviewPeriod } from "../data/event/TaxReviewPeriod";
import { TaxEvent } from "../data/event/TaxEvent";
import { UpdateEventRequest } from "../request/event/UpdateEventRequest";
import { CreateEventRequest } from "../request/event/CreateEventRequest";
import { CreateUpdateReviewPeriodRequest } from "../request/event/CreateUpdateReviewPeriodRequest";

export class EventApiConnector extends AbstractApiConnector {

	// Api base url
	protected readonly apiRoute = "/api/v1/tax";
	protected readonly apiRouteEvent = "/api/v1/events";
	protected readonly apiRouteReviewPeriod = "/api/v1/reviewperiod/nextActivation";

	/**
	 * Get tax review period
	 * @param taxId
	 */
	public getReviewPeriod(taxId: number): Observable<TaxReviewPeriod> {
		if (typeof taxId !== "number") {
			throw new ValidationError();
		} else {
			return this.apiClient.get(`${this.apiRoute}/${taxId}/reviewperiod`);
		}
	}

	/**
	 * Save tax review period
	 * @param taxId
	 * @param data
	 */
	public saveOrUpdateReviewPeriod(taxId: number, data: CreateUpdateReviewPeriodRequest): Observable<TaxReviewPeriod> {
		if (typeof taxId !== "number") {
			throw new ValidationError();
		} else {
			return this.apiClient.post(`${this.apiRoute}/${taxId}/reviewperiod`, data);
		}
	}

	/**
	 * Calculates next activation for a given fictive tax
	 * @param data
	 */
	public calculateNextActivation(data: CreateUpdateReviewPeriodRequest): Observable<{ nextActivation: Date }> {
		return this.apiClient.post(`${this.apiRouteReviewPeriod}`, data);
	}

	/**
	 * Get event by tax
	 * @param taxId
	 */
	public getEventsByTaxId(taxId: number): Observable<Array<TaxEvent>> {
		if (typeof taxId !== "number") {
			throw new ValidationError();
		} else {
			return this.apiClient.get(`${this.apiRoute}/${taxId}/events`);
		}
	}

	public createEvent(taxId: number, request: CreateEventRequest): Observable<TaxEvent> {
		if (typeof taxId !== "number") {
			throw new ValidationError();
		} else {
			return this.apiClient.post(`${this.apiRoute}/${taxId}/events`, request);
		}
	}

	public updateEvent(eventId: number, request: UpdateEventRequest): Observable<TaxEvent> {
		if (typeof eventId !== "number") {
			throw new ValidationError();
		} else {
			return this.apiClient.patch(this.apiRouteEvent + "/" + eventId, request);
		}
	}

}
