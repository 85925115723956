import { uniq } from 'lodash-es';

import { Role } from '@rar/model/data/enums/Role';
import { Location } from '@rar/model/data/location/Location';
import { TaxRevision } from '@rar/model/data/tax/TaxRevision';
import { User } from '@rar/model/data/user/User';
import { UserTaxPermission } from '@rar/model/data/user/UserTaxPermission';

export const UserHelper = {
  isUserSuperAdminOrAdminForAnyTax(user: User): boolean {
    return user.isSuperAdmin || user.userTaxPermissions?.some((utp) => utp.role === Role.ADMIN);
  },

  isUserInRoleForAnyTax(user: User, role: Role): boolean {
    if (role === Role.SUPER_ADMIN) {
      return user.isSuperAdmin;
    }
    return user.userTaxPermissions && user.userTaxPermissions.some((utp) => utp.role === role);
  },

  isUserOnlyInOneRoleForAllTaxes(user: User, role: Role): boolean {
    if (role === Role.SUPER_ADMIN) {
      return user.isSuperAdmin;
    }
    return user.userTaxPermissions && user.userTaxPermissions.every((utp) => utp.role === role);
  },

  isUserSuperAdminOrAdminForTaxType(user: User, taxTypeId: number) {
    if (user.isSuperAdmin) {
      return true;
    }
    return user.userTaxPermissions && user.userTaxPermissions.some((utp) => utp.role === Role.ADMIN && utp.taxType === taxTypeId);
  },

  // TODO: used in user-profile - to improve that page later and delete this function if unnecessary
  getRolesForProfile(user: User): Role[] {
    if (user.isSuperAdmin) {
      return [Role.SUPER_ADMIN];
    }

    if (
      user.userTaxPermissions &&
      user.userTaxPermissions.some((utp) => utp.role == Role.ADMIN && !!utp.location) &&
      user.userTaxPermissions.some((utp) => utp.role == Role.APPROVER)
    ) {
      return [Role.ADMIN, Role.APPROVER];
    }

    return !user.userTaxPermissions || !user.userTaxPermissions.length ? [] : [Math.max(...user.userTaxPermissions.map((utp) => utp.role))];
  },

  getHighestRole(user: User): Role | null {
    if (user.isSuperAdmin) {
      return Role.SUPER_ADMIN;
    }
    return !user.userTaxPermissions || !user.userTaxPermissions.length ? null : Math.max(...user.userTaxPermissions.map((utp) => utp.role));
  },

  canEditTax(user: User, tax: TaxRevision) {
    const type = tax.type || tax.tax?.type;
    const location = tax.location || tax.tax?.location;
    const role = Role.EDITOR;
    return checkMinimumRoleForTaxType(user, role, type, location);
  },

  canApproveTax(user: User, tax: TaxRevision) {
    const type = tax.type || tax.tax?.type;
    const location = tax.location || tax.tax?.location;
    const role = Role.APPROVER;
    return checkMinimumRoleForTaxType(user, role, type, location);
  },

  hasAcceptableRole(
    userTaxPermissions: UserTaxPermission[],
    exact: boolean,
    excludeLocalAdmin: boolean,
    excludeGlobalAdmin: boolean,
    roles: Role | Role[],
    requestedTax: TaxRevision,
  ): boolean {
    return exact
      ? hasExactValidRole(userTaxPermissions, roles, requestedTax, excludeLocalAdmin, excludeGlobalAdmin)
      : hasValidRole(userTaxPermissions, roles, requestedTax, excludeLocalAdmin, excludeGlobalAdmin);
  },
} as const;

const minimumRoles = {
  [Role.VIEWER]: [Role.VIEWER, Role.EDITOR, Role.APPROVER, Role.ADMIN, Role.SUPER_ADMIN],
  [Role.EDITOR]: [Role.EDITOR, Role.APPROVER, Role.ADMIN, Role.SUPER_ADMIN],
  [Role.APPROVER]: [Role.APPROVER, Role.ADMIN, Role.SUPER_ADMIN],
  [Role.ADMIN]: [Role.ADMIN, Role.SUPER_ADMIN],
  [Role.SUPER_ADMIN]: [Role.SUPER_ADMIN],
};

function checkMinimumRoleForTaxType(user: User, role: Role, taxTypeId: number, location: Location) {
  const roles = minimumRoles[role];
  return user.isSuperAdmin || hasValidRole(user.userTaxPermissions, roles, { type: taxTypeId, location } as TaxRevision, false, false);
}

function hasValidRole(
  userTaxPermissions: UserTaxPermission[],
  roles: Role | Role[],
  requestedTax: TaxRevision,
  excludeLocalAdmin: boolean,
  excludeGlobalAdmin: boolean,
): boolean {
  const acceptedRoles = uniq(Array.isArray(roles) ? roles : [roles]);

  if (excludeLocalAdmin) {
    userTaxPermissions = excludeLocalAdmins(userTaxPermissions);
  }

  if (excludeGlobalAdmin) {
    userTaxPermissions = excludeGlobalAdmins(userTaxPermissions);
  }

  if (requestedTax) {
    // any role with check tax permissions
    return acceptedRoles.some((role) =>
      userTaxPermissions.some(
        (utp) =>
          utp.role === role &&
          (!utp.taxType || utp.taxType === requestedTax.type) &&
          (!utp.location || utp.location.id === requestedTax.location.id),
      ),
    );
  }

  // any requested role without checking tax
  return acceptedRoles.some((role) => userTaxPermissions.some((utp) => utp.role === role));
}

function hasExactValidRole(
  userTaxPermissions: UserTaxPermission[],
  roles: Role | Role[],
  requestedTax: TaxRevision,
  excludeLocalAdmin: boolean,
  excludeGlobalAdmin: boolean,
): boolean {
  const acceptedRoles = uniq(Array.isArray(roles) ? roles : [roles]);

  if (excludeLocalAdmin) {
    userTaxPermissions = excludeLocalAdmins(userTaxPermissions);
  }

  if (excludeGlobalAdmin) {
    userTaxPermissions = excludeGlobalAdmins(userTaxPermissions);
  }

  if (requestedTax) {
    // exact match + check tax permissions
    return (
      userTaxPermissions.every((utp) => acceptedRoles.includes(utp.role)) &&
      acceptedRoles.every((role) =>
        userTaxPermissions.some(
          (utp) =>
            utp.role === role &&
            (!utp.taxType || utp.taxType === requestedTax.type) &&
            (!utp.location || utp.location.id === requestedTax.location.id),
        ),
      )
    );
  }

  // exact match - only role check
  return (
    userTaxPermissions.every((utp) => acceptedRoles.includes(utp.role)) &&
    acceptedRoles.every((role) => userTaxPermissions.some((utp) => utp.role === role))
  );
}

function excludeLocalAdmins(userTaxPermissions: UserTaxPermission[]): UserTaxPermission[] {
  const filteredUserTaxPermissions: UserTaxPermission[] = [];

  for (const utp of userTaxPermissions) {
    if (utp.role === Role.ADMIN && !!utp.location) {
      continue;
    }
    filteredUserTaxPermissions.push(utp);
  }

  return filteredUserTaxPermissions;
}

function excludeGlobalAdmins(userTaxPermissions: UserTaxPermission[]): UserTaxPermission[] {
  const filteredUserTaxPermissions: UserTaxPermission[] = [];

  for (const utp of userTaxPermissions) {
    if (utp.role === Role.ADMIN && !utp.location) {
      continue;
    }
    filteredUserTaxPermissions.push(utp);
  }

  return filteredUserTaxPermissions;
}
