import {Pipe, PipeTransform} from "@angular/core";
import {AuditTrail} from "../../model/data/audit/AuditTrail";

@Pipe({
	name: "auditDateFilterPipe"
})
export class AuditDateFilterPipe implements PipeTransform {

	transform(value: any, year: number, month: number, day: number): any {

		const result: Array<AuditTrail> = [];

		for (const audit of value) {
			if (
				audit.createdAt.getFullYear() === year &&
				audit.createdAt.getMonth() === month &&
				audit.createdAt.getDate() === day
			) {
				result.push(audit);
			}
		}

		return result;
	}

}
