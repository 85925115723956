/**
 * Object that bundles together category, topic, rate and rule.
 */
import { DatabaseEntity } from '../DatabaseEntity';

export class TaxTypeTopic extends DatabaseEntity {
  /**
   * Unique identifier for a topic
   */
  public id: number;

  /**
   * Tax topic text identifier.
   */
  public shortCode: string;

  /**
   * Tax type topic type.
   */
  public type: null | 'rule' | 'rate';

  /**
   * Tax topic full name
   */
  public name: string;

  /**
   * Tax type ID.
   */
  public taxType: number;

  /**
   * The parent  topic id, if there is any
   */
  public parentId: number | null;

  /**
   * The tax topic id collection of the current node's children, if present.
   */
  public subTopics: Array<TaxTypeTopic>;

  // frontend-only properties
  public parent?: TaxTypeTopic;
  public rules?: TaxTypeTopic[];
  public rates?: TaxTypeTopic[];

  /**
   * Topic order on global level
   */
  public order: number;
}

export type TaxTypeTopicWithLocationsWithTable = Omit<TaxTypeTopic, 'subTopics'> & {
  subTopics: Array<TaxTypeTopic & { locationsWithTable?: true }>;
};
