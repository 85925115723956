import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { of } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';

import { RoutePaths, ngSelectConstants } from '@rar/commons/constants';
import { htmlEscape } from '@rar/commons/helpers';
import { ModalService } from '@rar/commons/services/modal.service';
import { TaxTypeLocations } from '@rar/model/data/tax/TaxTypeLocations';
import { ApiCommunicationService } from '@rar/model/services/api-communication/api-communication.service';

import { TaxTypeLocationsFormDataService } from '../../services/tax-type-locations-form/tax-type-locations-form-data.service';
import { TaxTypeLocationsFormFieldsService } from '../../services/tax-type-locations-form/tax-type-locations-form-fields.service';
import { TaxTypeLocationsFormMapperService } from '../../services/tax-type-locations-form/tax-type-locations-form-mapper.service';

@Component({
  selector: 'app-tax-type-locations-form',
  templateUrl: './tax-type-locations-form.component.html',
  styleUrls: ['./tax-type-locations-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TaxTypeLocationsFormFieldsService, TaxTypeLocationsFormDataService],
})
export class TaxTypeLocationsFormComponent implements OnInit, OnDestroy {
  constructor(
    private locationsFormFieldsService: TaxTypeLocationsFormFieldsService,
    private locationsFormDataService: TaxTypeLocationsFormDataService,
    private locationsFormMapperService: TaxTypeLocationsFormMapperService,
    private modalService: ModalService,
    private translateService: TranslateService,
    @Inject(ApiCommunicationService) private api: ApiCommunicationService,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {}
  public readonly htmlEscape = htmlEscape;

  @ViewChild('confirmationDelete', { static: true })
  public confirmationDeleteTemplateRef: TemplateRef<any>;

  @Output()
  readonly locationsSavingChange = new EventEmitter<boolean>();

  @Input()
  public taxTypeLocations!: TaxTypeLocations;

  public locationsToDelete = '';

  public readonly isCommonDataLoaded$ = this.locationsFormDataService.isCommonDataLoaded$;

  ngOnInit() {
    this.locationsFormDataService.initDeleteTemplate(
      this.confirmationDeleteTemplateRef,
      (locationsToDelete) => (this.locationsToDelete = locationsToDelete),
    );
    this.locationsFormFieldsService.initForm();
    this.locationsFormFieldsService.initializeFormValue(this.taxTypeLocations);
  }

  ngOnDestroy(): void {
    this.locationsFormFieldsService.ngOnDestroy();
  }

  public get taxTypeLocationsForm(): UntypedFormGroup {
    return this.locationsFormFieldsService.taxTypeLocationsFormGroup;
  }

  public onAddLocationClicked(): void {
    this.locationsFormFieldsService.addLocationRow();
  }

  public async onDeleteLocationClicked(index: number): Promise<void> {
    const jurisdictions = await this.locationsFormDataService.getJurisdictionsForForm().toPromise();
    const selectedLocations: { jurisdiction: number; subdivisions: number[] } =
      this.locationsFormFieldsService.locationsFormArray.getRawValue()[index];

    if (!selectedLocations.subdivisions || selectedLocations.subdivisions.length === 0) {
      // no selection yet - can be deleted without asking
      this.locationsFormFieldsService.deleteLocationRow(index);
      return;
    }

    const locationsIds = selectedLocations.subdivisions.map((id) =>
      id === ngSelectConstants.countryLevelValue ? selectedLocations.jurisdiction : id,
    );

    const locations = locationsIds
      .map((id) => {
        const location = jurisdictions.find((j) => j.id === id || (j.children && j.children.some((sub) => sub.id === id)));
        if (location.id === id) {
          return location.name;
        }

        return location.children.find((l) => l.id === id).name;
      })
      .join(', ');

    const canDelete = await this.locationsFormDataService.askLocationsDelete(locations);
    if (canDelete) {
      this.locationsFormFieldsService.deleteLocationRow(index);
      this.cdr.markForCheck();
    }
  }

  public onSubmit(confirmationTemplate: TemplateRef<any>): void {
    this.locationsFormFieldsService.taxTypeLocationsFormGroup.markAllAsTouched();

    if (!this.locationsFormFieldsService.taxTypeLocationsFormGroup.valid) {
      return;
    }

    this.modalService
      .openConfirmation({
        title: this.translateService.instant('tax-design.locations.save-changes-title'),
        yesButtonText: this.translateService.instant('commons.modal.confirm'),
        noButtonText: this.translateService.instant('commons.modal.cancel'),
        contentTemplate: confirmationTemplate,
      })
      .then(
        () => this.saveLocations(),
        () => {},
      );
  }

  public onCancel(): void {
    this.router.navigate([RoutePaths.taxDesign.list]);
  }

  private saveLocations() {
    const locations = {
      ...this.taxTypeLocations,
      ...this.locationsFormMapperService.mapFormToModel(this.locationsFormFieldsService.taxTypeLocationsFormGroup.getRawValue()),
    };

    this.locationsSavingChange.next(true);
    this.api
      .tax()
      .editTaxTypeLocations(this.taxTypeLocations.id, locations)
      .pipe(
        untilDestroyed(this),
        tap(() => {
          this.router.navigate([RoutePaths.taxDesign.list], { state: { refreshSearch: true } });
        }),
        catchError((err: HttpErrorResponse) => {
          this.modalService
            .showInformation({
              title: this.translateService.instant('tax-design.locations.error-saving-title'),
              content: this.translateService.instant('tax-design.locations.error-saving-content').split('\n'),
            })
            .then(() => {
              return this.router.navigate([RoutePaths.taxDesign.list], { state: { refreshSearch: true } });
            });
          return of(undefined);
        }),
        finalize(() => {
          this.locationsSavingChange.next(false);
        }),
      )
      .subscribe();
  }
}
