<div class="modal-header">
	<h4 class="modal-title">The notification dates have been updated</h4>
</div>

<div class="modal-body"></div>

<div class="modal-footer">
	<div></div>

	<div class="button-group">
		<!--Close-->
		<button class="ui basic button silver" (click)="deny()">Close</button>

		<!--Approve-->
		<div></div>
	</div>
</div>
