<h2 class="ui center aligned icon header">
	<i class="circular exclamation icon"></i>
	{{"commons.error-display.title" | translate}}
</h2>
<div id="refreshButton">
	<div class="ui animated button" (click)="onClickRefresh()" tabindex="0">
		<div class="visible content">{{"commons.error-display.button" | translate}}</div>
		<div class="hidden content">
			<i class="right refresh icon"></i>
		</div>
	</div>
</div>
