import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

import { TaxRevisionStatus } from '../../model/data/enums/TaxRevisionStatus';
import { TaxRevision } from '../../model/data/tax/TaxRevision';
import { TaxDataService } from '../services/tax-data/tax-data.service';

@Directive({
  selector: '[appRevisionStatus]',
})
export class RevisionStatusDirective implements OnInit {
  @Input('appRevisionStatus')
  public status: TaxRevisionStatus;

  constructor(private elRef: ElementRef, private renderer: Renderer2, private taxDataService: TaxDataService) {}

  ngOnInit() {
    // subscribe to tax revision change
    this.taxDataService.taxRevisionStatus.subscribe((status: TaxRevisionStatus) => {
      this.status = status;
      this.setStatus();
    });

    // subscribe to tax change
    this.taxDataService.tax.subscribe((tax: TaxRevision) => {
      this.status = tax.status;
    });

    this.setStatus();
  }

  private setStatus() {
    switch (+this.status) {
      case TaxRevisionStatus.APPROVED:
        this.setBackgroundColor('green');
        break;
      case TaxRevisionStatus.PENDING_LOCAL:
      case TaxRevisionStatus.PENDING_GLOBAL:
        this.setBackgroundColor('orange');
        break;
      case TaxRevisionStatus.REJECTED:
        this.setBackgroundColor('red');
        break;
      case TaxRevisionStatus.CLOSED:
        this.setBackgroundColor('silver');
        break;
      default:
        this.setBackgroundColor('white');
        break;
    }
  }

  private setBackgroundColor(color: string) {
    this.renderer.setStyle(this.elRef.nativeElement, 'background-color', color);
  }
}
