import { Component, Input, OnInit } from '@angular/core';

import { NgbActiveModal, NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

import { Role } from '../../../model/data/enums/Role';
import { TaxRevision } from '../../../model/data/tax/TaxRevision';

interface ITaxEditModalContext {
  tax: TaxRevision;
  effectiveFromValidator: Date;
}

export enum EditOption {
  CONFIRM = 1,
  EDIT = 2,
  CREATE = 3,
}

export class TaxEditModalData {
  checkbox: EditOption;
  effectiveFrom?: Date;
  effectiveTo?: Date;
}

@Component({
  selector: 'app-tax-edit-modal',
  templateUrl: './tax-edit-modal.component.html',
  styleUrls: ['./tax-edit-modal.component.scss'],
})
export class TaxEditModalComponent implements OnInit {
  public tax: TaxRevision;
  public readonly Role = Role;

  constructor(private activeModal: NgbActiveModal, private dateAdapter: NgbDateAdapter<Date>) {}

  @Input()
  context: ITaxEditModalContext;

  response: TaxEditModalData = new TaxEditModalData();
  // req 10
  role = Role;

  ngOnInit() {
    this.response.checkbox = EditOption.CONFIRM;
  }

  getMinDate(): NgbDateStruct {
    const highestEffectiveFrom = new Date(this.context.effectiveFromValidator);
    // const now = new Date();

    // return highestEffectiveFrom > now ? highestEffectiveFrom : now;
    return this.dateAdapter.fromModel(highestEffectiveFrom);
  }

  approve(response: TaxEditModalData): void {
    this.activeModal.close(response);
  }

  deny(): void {
    this.activeModal.dismiss(null);
  }
}
