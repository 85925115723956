import {User} from "../user/User";
import {TaxChangeNotes} from "./TaxChangeNotes";

export class UserChangeNotes {

	public id: number;
	public description: string;
	public editor: User;
	public user: User;

	public static transform(userChangeNotes: UserChangeNotes) {
		const rv: TaxChangeNotes = new TaxChangeNotes();

		rv.id = userChangeNotes.id;
		rv.changeNotes = userChangeNotes.description;
		rv.user = userChangeNotes.editor;

		rv.isSummary = null;
		rv.taxId = null;
		rv.taxRevision = null;

		return rv;
	}

}
