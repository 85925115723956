<div id="tax-sub-menu-layout">
  <ngb-accordion class="tax-tree" [closeOthers]="true" [activeIds]="activeTopicPanelId" (panelChange)="beforeChange($event)">
    <ngb-panel *ngFor="let topic of sortTopics(topics); let i = index" id="topic-{{ topic.id }}">
      <ng-template ngbPanelTitle>
        <div class="header-container" (click)="onSelectTopic(topic)">
          <div class="header">
            <div
              class="title text-truncate"
              [title]="topic.name"
              [ngClass]="{
                active: activeTopic === topic
              }">
              {{ topic.name || '(missing name)' }}
            </div>
            <div class="sub-title">
              <!-- Req 8 - Veracode & SRT scan fixes - Angular 14 version upgrdation- fixing bug for saving tax -->
              <span i18n>
                { topic.subTopics ? topic.subTopics.length : 0, plural, =0 { {{ 'tax.tax-topics.no-topics' | translate }} } =1 { 1
                  {{ 'tax.tax-topics.topic-singular' | translate }} } other { {{ topic.subTopics ? topic.subTopics.length : 0}}
                  {{ 'tax.tax-topics.topic-plural' | translate }} } }
              </span>
            </div>
          </div>

          <svg-icon class="icon indicator" src="/assets/images/icons/circle-arrow.svg"></svg-icon>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <button
          *ngFor="let subTopic of topic.subTopics"
          [ngClass]="{
            active: activeTopic === subTopic
          }"
          (click)="onSelectTopic(subTopic)"
          class="sub-topic">
          {{ subTopic.name || '(missing name)' }}
        </button>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>

  <div>
    <!--Add new category button-->
    <button class="btn btn-primary btn-icon mt-4" (click)="addNewCategory()">
      <svg-icon class="icon" src="../../../../assets/images/icons/add2.svg"></svg-icon>
      <span>{{ 'tax.tax-topics.category-add' | translate }}</span>
    </button>

    <!--Reorder categories link-->
    <button class="reorder-categories mt-4" (click)="openReorderCategoriesModal()" *ngIf="modalService">
      {{ 'tax.tax-topics.category-reorder' | translate }}
    </button>
  </div>
</div>
