<div class="modal-header">
  <h4 class="modal-title">Detailed list of changes for revision</h4>

  <button type="button" class="close" aria-label="Close" (click)="approve()">&times;</button>
</div>

<div class="modal-body">
  <div class="ui active inverted dimmer" *ngIf="!changes || !changes.length">
    <div class="ui text loader">Retrieving list of changes...</div>
  </div>

  <!--Tax table-->
  <div *ngIf="changes && changes.length">
    <table class="changes-table ui table custom-table padded-table" *ngFor="let change of changes; let i = index">
      <!--header-->
      <thead>
        <tr>
          <th class="editor-col">
            <b>Change #{{ changes.length - i }}</b
            >&nbsp;&nbsp;by&nbsp;&nbsp;
            <a [href]="'mailto:' + change.editor.email" ngbPopover="Send email" placement="left">
              <b>{{ change.editor.name }}</b>
              <i class="fa fa-envelope"></i>
            </a>
          </th>
        </tr>
      </thead>

      <!--changes-->
      <tbody>
        <tr class="table-row" *ngFor="let note of change.data">
          <td class="changes">
            <div class="occurrence">
              {{ note.occurrence }}
              {{ (note.original == null ? 'audit.added' : note.new == null ? 'audit.deleted' : 'audit.edited') | translate }}
            </div>

            <!--normal display-->
            <div class="change align" *ngIf="!note.alternativeDisplay">
              <span [outerHTML]="note.new || '' | diffPipe: note.original || ''"></span>
            </div>
            <!--alternative display-->
            <div class="change" *ngIf="note.alternativeDisplay">
              <span class="oldValue">{{ note.original?.toString() }}</span>
              <i class="fa fa-arrow-right"></i>
              <span class="newValue">{{ note.new?.toString() }}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="modal-footer">
  <div></div>

  <div class="button-group">
    <button class="ui basic button silver" (click)="approve()">Close</button>
  </div>
</div>
