import { Injectable } from '@angular/core';

import { ngSelectConstants } from '@rar/commons/constants';
import { LocationChildType } from '@rar/model/data/enums/LocationChildType';
import { Location } from '@rar/model/data/location/Location';
import { TaxTypeLocations } from '@rar/model/data/tax/TaxTypeLocations';

@Injectable()
export class TaxTypeLocationsFormMapperService {
  mapFormToModel(locationsFormValue: any): Pick<TaxTypeLocations, 'locations'> {
    const model = {
      locations: this.mapFormToLocations(locationsFormValue.locationsTable),
    };

    return model;
  }

  mapFormToLocations(formArray: { jurisdiction: number; subdivisions: number[] }[]): Location[] {
    return formArray.flatMap((row): Location[] => {
      const locations: Location[] = [];
      const includesCountryLevel = row.subdivisions?.includes(ngSelectConstants.countryLevelValue) ?? false;
      if (includesCountryLevel) {
        locations.push({
          id: row.jurisdiction,
        } as Location);
      }
      if (row.subdivisions && row.subdivisions.length) {
        locations.push(
          ...row.subdivisions
            .filter((subdivision) => subdivision !== ngSelectConstants.countryLevelValue)
            .map((subdivision) => ({ id: subdivision } as Location)),
        );
      }
      return locations;
    });
  }

  mapJurisdictionToForm(location?: Location): number {
    if (!location) {
      return undefined; // none selected
    }

    return location.parent?.id || location.id;
  }

  mapSubdivisionsToForm(locations?: Location[]): number[] {
    if (!locations || !locations.length) {
      return undefined; // none selected
    }

    const countryUtp = locations.find((location) => location && !location.parent);

    const subdivisions: number[] = [];
    if (countryUtp) {
      subdivisions.push(ngSelectConstants.countryLevelValue); // country level selected
    }

    subdivisions.push(
      ...locations
        //  subdivisions excluding country level item
        .filter((location) => location && !!location.parent)
        // get subdivision location id
        .map((location) => location.id),
    );

    return subdivisions;
  }
}
