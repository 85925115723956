<h4>{{ 'user.roles-permissions.title' | translate }}</h4>

<ng-container *ngIf="(isLoading$ | async) === false; else loader">
  <div [formGroup]="rolesAndPermissionsFormGroup">
    <table class="table table-bordered-parts">
      <thead>
        <th class="cw-1 label-required">{{ 'user.roles-permissions.col-roles' | translate }}</th>
        <th class="cw-1 label-required">{{ 'user.roles-permissions.col-taxtype' | translate }}</th>
        <th class="cw-2 label-required">{{ 'user.roles-permissions.col-jurisdiction' | translate }}</th>
        <th class="cw-2 label-required">{{ 'user.roles-permissions.col-subdivisions' | translate }}</th>
        <th class="cw-delete"></th>
      </thead>
      <tbody>
        <ng-container formArrayName="rolesAndPermissionsTable" *ngFor="let group of rolesAndPermissionsTable.controls; let i = index">
          <tr [formGroupName]="i" *ngIf="getDataSourcesForRow(i) as data">
            <td>
              <ng-select
                formControlName="role"
                placeholder="{{ 'user.roles-permissions.dropdown-placeholder' | translate }}"
                [items]="roles"
                [searchable]="false"
                bindValue="id"
                class="control-with-feedback"
                data-test="new user role"></ng-select>
              <app-validation-message [inputControl]="group.get('role')"></app-validation-message>
            </td>

            <td>
              <ng-select
                formControlName="taxType"
                placeholder="{{ 'user.roles-permissions.dropdown-placeholder' | translate }}"
                [items]="data.taxTypes$ | async"
                [searchable]="false"
                bindValue="id"
                class="control-with-feedback"
                data-test="new user type"></ng-select>
              <app-validation-message [inputControl]="group.get('taxType')"></app-validation-message>
            </td>

            <td>
              <ng-select
                formControlName="jurisdiction"
                placeholder="{{ 'user.roles-permissions.dropdown-placeholder' | translate }}"
                [items]="data.jurisdictions$ | async"
                [searchable]="false"
                bindValue="id"
                class="control-with-feedback"
                data-test="new user jurisdiction"></ng-select>
              <app-validation-message [inputControl]="group.get('jurisdiction')"></app-validation-message>
            </td>

            <td>
              <app-multi-select
                [group]="$any(group)"
                [controlName]="'subdivisions'"
                placeholder="{{ 'user.roles-permissions.dropdown-placeholder' | translate }}"
                [items]="data.subdivisions$ | async"
                bindValue="id"
                controlLocator="new user subdivision"
                itemLocator="subdivision"></app-multi-select>
            </td>

            <td>
              <input type="hidden" formControlName="id" />
              <button
                class="btn btn-sm btn-icon btn-outline-secondary btn-delete"
                (click)="deletePermissionClicked.emit(i)"
                [disabled]="rolesAndPermissionsTable.controls.length === 1 || isFormDisabled"
                data-test="new user delete permissions">
                <svg-icon [src]="'/assets/images/icons/trash.svg'"></svg-icon>
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <button
    type="button"
    class="btn btn-permission btn-icon btn-outline-secondary"
    [disabled]="isFormDisabled"
    (click)="addPermissionClicked.emit()"
    data-test="new user add permissions">
    <svg-icon [src]="'/assets/images/icons/add2.svg'"></svg-icon>
    <span>
      {{ 'user.form.add-permission' | translate }}
    </span>
  </button>
</ng-container>

<ng-template #loader>
  <div class="ui my-3 active inline centered loader"></div>
</ng-template>
