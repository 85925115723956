import { TaxTypeStructure } from '@rar/model/data/tax/TaxTypeStructure';
import { TaxTypeTopic, TaxTypeTopicWithLocationsWithTable } from '@rar/model/data/tax/TaxTypeTopic';

export class TaxTypeStructureEditRequest {
  constructor(taxTypeStructure: TaxTypeStructure) {
    this.name = taxTypeStructure.name;
    // Req 6
    this.approvalLevel = taxTypeStructure.approvalLevel;
    this.topics = this.mapTopics(taxTypeStructure.topics);
  }

  name: string;
  // Req 6
  approvalLevel: number;
  topics: TaxTypeTopic[];

  private mapTopics(topics: TaxTypeTopicWithLocationsWithTable[]): TaxTypeTopic[] {
    return topics.map((topic) => {
      // remove parent, rules, rates to prevent circular references when converting to JSON
      const { parent, rules, rates, ...topicToSave } = topic;
      if (topicToSave.id < 0) {
        topicToSave.id = null;
      }
      if (topicToSave.parentId < 0) {
        topicToSave.parentId = null;
      }
      if (topicToSave.subTopics) {
        topicToSave.subTopics.forEach((st) => delete st.locationsWithTable);
        topicToSave.subTopics = this.mapTopics(topicToSave.subTopics);
      }

      return topicToSave;
    });
  }
}
