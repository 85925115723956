<div id="latest-changes-layout">
  <div class="header">
    <p>{{ 'dashboard.latest-changes' | translate | uppercase }}</p>
    <a [routerLink]="['/tax', 'latest']">{{ 'dashboard.see-all' | translate }}</a>
  </div>

  <!--Loading indicator-->
  <div class="ui active inline centered loader" *ngIf="!taxes"></div>

  <div class="ui segment" *ngIf="taxes">
    <app-empty-placeholder *ngIf="taxes.length === 0; else data"></app-empty-placeholder>

    <ng-template #data>
      <div class="ui relaxed divided list">
        <div
          class="item cur-pointer"
          *ngFor="let latest of taxes"
          [routerLink]="['/tax', latest.id, 'revision', latest.revisionNumber]"
          (click)="onClick()">
          <div class="content">
            <div class="header">
              <div class="tax narrow">{{ latest.name }}</div>
            </div>
            <div class="location narrow">
              <img [src]="'../../../../assets/images/icons/location.svg'" />
              {{ latest.location.name }}
            </div>
            <div class="date narrow">
              {{ latest.lastUpdate | date: 'MMM dd, yyyy hh:mm a' }} by {{ latest.editor.name === username ? 'You' : latest.editor.name }}
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>
