<form [formGroup]="userForm" (ngSubmit)="onSubmit(confirmation)" class="flex-flow-v flex-flow--md">
  <div class="d-md-flex justify-content-md-between align-items-md-start">
    <h1>{{ (isEditMode ? 'user.form.edit-user' : 'user.form.add-new-user') | translate }}</h1>

    <div class="flex-flow">
      <button type="button" class="btn btn-sm btn-outline-secondary" (click)="onCancel()" data-test="new user cancel">
        {{ 'user.form.cancel' | translate }}
      </button>
      <button type="submit" [disabled]="readOnly" class="btn btn-sm btn-primary" data-test="new user save">
        {{ 'user.form.save' | translate }}
      </button>
    </div>
  </div>

  <p *ngIf="readOnly" class="font-weight-bold text-danger">
    {{ 'user.form.can-not-edit-this-user' | translate }}
  </p>

  <app-user-personal-info [personalInformationFormGroup]="userForm.get('personalInformation')"></app-user-personal-info>

  <app-user-roles-permissions
    *ngIf="roles$ | async as roles"
    (addPermissionClicked)="onAddPermissionClicked()"
    (deletePermissionClicked)="onDeletePermissionClicked($event)"
    [roles]="roles"
    [rolesAndPermissionsFormGroup]="userForm.get('rolesAndPermissions')"></app-user-roles-permissions>
</form>

<ng-template #confirmation>
  <p>
    {{ 'user.form.confirmation-save' | translate }}:
    <strong class="medium">{{ userForm.get('personalInformation').get('fullName').value }}</strong>
  </p>
</ng-template>
