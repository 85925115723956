import { Component, OnDestroy, OnInit } from '@angular/core';

import { untilDestroyed } from 'ngx-take-until-destroy';

import { TaxChangeNotes } from '../../../model/data/tax/TaxChangeNotes';
import { TaxRevision } from '../../../model/data/tax/TaxRevision';
import { TaxTopic } from '../../../model/data/tax/TaxTopic';
import { TaxDataService } from '../../services/tax-data/tax-data.service';

@Component({
  selector: 'app-tax-change-notes',
  templateUrl: './tax-change-notes.component.html',
  styleUrls: ['./tax-change-notes.component.scss'],
})
export class TaxChangeNotesComponent implements OnInit, OnDestroy {
  constructor(public taxDataService: TaxDataService) {}

  public changeNotes: Array<TaxChangeNotes>;
  public topics: Array<TaxTopic>;

  ngOnInit() {
    this.taxDataService.tax.pipe(untilDestroyed(this)).subscribe((tax: TaxRevision) => {
      this.changeNotes = this.taxDataService.changeNotes;
      this.topics = tax.taxTopics;
    });
  }

  ngOnDestroy(): void {}
}
