import {Component, OnInit} from "@angular/core";
import {Router} from "@angular/router";

@Component({
	selector: "app-audit-button",
	templateUrl: "./audit-button.component.html",
	styleUrls: ["./audit-button.component.scss"]
})
export class AuditButtonComponent implements OnInit {

	constructor(private router: Router) { }

	ngOnInit() {
	}

	public onClickAuditTrail() {
		this.router.navigate(["audit"]);
	}

}
