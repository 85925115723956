import Quill, { RangeStatic, StringMap } from 'quill';
import { v4 as uuidv4 } from 'uuid';

import { AttachmentEmbedData } from '@rar/rich-text-editor/attachments/attachment-embed-data';
import { AttachmentHandlerService } from '@rar/rich-text-editor/attachments/attachment-handler.service';
import { QuillWithEditor } from '@rar/rich-text-editor/helpers/quill-extensions';

import { environment } from '../../../environments/environment';

const Module = Quill.import('core/module');

const Icons: StringMap = Quill.import('ui/icons');
Icons.attachment = `<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;">
<path class="ql-stroke" style="fill:currentColor" d="M467.076,68.86c-59.902-59.902-156.846-59.896-216.741,0L34.919,284.276c-46.558,46.557-46.558,122.312,0,168.87
  c46.57,46.571,122.326,46.544,168.87,0L419.205,237.73c33.36-33.36,33.36-87.64,0-121c-33.359-33.361-87.64-33.361-121,0
  L114.478,300.457c-6.975,6.975-6.975,18.285,0,25.259c6.975,6.975,18.285,6.975,25.259,0l183.727-183.727
  c19.432-19.432,51.05-19.432,70.481,0c19.431,19.432,19.431,51.05,0,70.481L178.53,427.887c-32.71,32.71-85.646,32.706-118.352,0
  c-15.806-15.806-24.511-36.821-24.511-59.175s8.706-43.369,24.511-59.176L275.594,94.119c45.94-45.94,120.287-45.934,166.222,0
  c45.827,45.828,45.827,120.395,0,166.222l-95.741,95.741c-6.975,6.975-6.975,18.284,0,25.259s18.285,6.975,25.259,0l95.741-95.741
  C526.978,225.7,526.971,128.754,467.076,68.86z"/></svg>`;

/**
 * Quill module for attachments support. It is not singleton, it's created for every quill editor.
 */
export class AttachmentsQuillModule extends Module {
  private static _attachmentHandlerService: AttachmentHandlerService;
  public static get attachmentHandlerService(): AttachmentHandlerService {
    return AttachmentsQuillModule._attachmentHandlerService;
  }

  private readonly quill: QuillWithEditor;

  private range: RangeStatic | null = null;

  constructor(quill: Quill, private attachmentHandlerService: AttachmentHandlerService) {
    super(quill, attachmentHandlerService);
    this.quill = quill as QuillWithEditor;
    AttachmentsQuillModule._attachmentHandlerService = attachmentHandlerService;

    this.quill.getModule('toolbar').addHandler('attachment', () => this.attachmentClickHandler());
  }

  private attachmentClickHandler(): void {
    const files = this.getFilesFromEditor();
    if (files.length >= environment.tax.attachments.allowedMaxFiles) {
      this.attachmentHandlerService.showMaxFilesMessage();
      return;
    }

    this.range = this.quill.getSelection();

    const input: HTMLInputElement = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', environment.tax.attachments.allowedExtensions);
    input.onchange = () => this.fileChanged(input);
    input.click();
  }

  private async fileChanged(input: HTMLInputElement): Promise<void> {
    const elementId = `attachment_${uuidv4()}`;
    try {
      const files = input.files;
      const file = files ? files[0] : null;
      if (!file) {
        input.remove();
        return;
      }

      if (!this.validateFile(file)) {
        input.remove();
        return;
      }

      const value: AttachmentEmbedData = {
        elementId,
        file,
        attachmentId: null,
        isUploading: true,
      };
      this.insertAttachment(value);

      const attachmentId = await this.attachmentHandlerService.upload(this.quill.editorComponent, elementId, file);
      delete value.elementId;
      delete value.isUploading;
      const element = document.querySelector<HTMLElement>(`[data-element-id="${elementId}"]`);
      if (element) {
        this.attachmentHandlerService.onFileUploaded(element, attachmentId);
      } else {
        throw new Error('Missing element with id: ' + elementId);
      }
    } catch (error) {
      console.error(error);
      input?.remove();
      document.querySelector<HTMLElement>(`[data-element-id="${elementId}"]`)?.remove();
    }
  }

  private insertAttachment(value: AttachmentEmbedData): void {
    this.quill.editorComponent.insertAttachment(this.range?.index ?? 0, value);
  }

  private validateFile(file: File) {
    if (!environment.tax.attachments.allowedExtensions.split(',').some((ext) => file.name.toLowerCase().endsWith(ext))) {
      this.attachmentHandlerService.showInvalidExtensionMessage();
      return false;
    }
    if (file.size > environment.tax.attachments.allowedSize) {
      this.attachmentHandlerService.showInvalidSizeMessage();
      return false;
    }
    return true;
  }

  private getFilesFromEditor(): AttachmentEmbedData[] {
    return this.quill
      .getContents()
      .filter((op) => !!op.insert.attachment)
      .map((op) => op.insert);
  }
}
