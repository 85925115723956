<div
  class="ui active inline centered loader"
  *ngIf="
    !(taxTypeStructure && (taxTypeDataService.taxLockInfo || taxTypeDataService.taxLockInfo === null)) || taxTypeDataService.isLoading
  "></div>

<div
  id="tax-revision-layout"
  *ngIf="taxTypeStructure && (taxTypeDataService.taxLockInfo || taxTypeDataService.taxLockInfo === null) && !taxTypeDataService.isLoading">
  <!--Info component-->
  <div id="info" class="padding-top-bottom">
    <app-tax-type-info></app-tax-type-info>
  </div>

  <!--Topic sub-menu component-->
  <div id="topics">
    <app-tax-type-sub-menu></app-tax-type-sub-menu>
  </div>

  <!--Content-->
  <div id="rules-rates">
    <router-outlet></router-outlet>
  </div>
</div>
