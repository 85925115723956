import {Component, Input, OnInit} from "@angular/core";
import {ApiCommunicationService} from "../../../../model/services/api-communication/api-communication.service";
import {TaxDataService} from "../../../services/tax-data/tax-data.service";
import {TaxModalService} from "../../../services/tax-modal/tax-modal.service";
import {TaxReviewPeriodFrequency} from "../../../../model/data/enums/TaxReviewPeriodFrequency";
import {TaxReviewPeriod} from "../../../../model/data/event/TaxReviewPeriod";
import {Tax} from "../../../../model/data/tax/Tax";
import {CreateUpdateReviewPeriodRequest} from "../../../../model/request/event/CreateUpdateReviewPeriodRequest";

@Component({
	selector: "app-tax-schedule",
	templateUrl: "./tax-schedule.component.html",
	styleUrls: ["./tax-schedule.component.scss"]
})
export class TaxScheduleComponent implements OnInit {

	// merged tax and revision object
	@Input("taxRevision")
	public taxRevision: Tax;

	// is the component loading
	public loading = true;

	// dropdown for frequency
	public confirmRulesFrequencies = [
		{
			title: "Monthly",
			value: TaxReviewPeriodFrequency.MONTHLY
		},
		{
			title: "Bimonthly",
			value: TaxReviewPeriodFrequency.BIMONTHLY
		},
		{
			title: "Quarterly",
			value: TaxReviewPeriodFrequency.QUARTERLY
		},
		{
			title: "Semi-annually",
			value: TaxReviewPeriodFrequency.SEMIANNUALLY
		},
		{
			title: "Annually",
			value: TaxReviewPeriodFrequency.ANNUALLY
		},
		{
			title: "Biannually",
			value: TaxReviewPeriodFrequency.BIANNUALLY
		}
	];
	// the model for selector
	public actualConfirmationFrequency: {title: string, value: number};

	// values for notification prior value
	public priorNotification = [
		{
			title: "One week",
			value: 7
		},
		{
			title: "Two weeks",
			value: 14
		},
		{
			title: "Three weeks",
			value: 21
		},
		{
			title: "Four weeks",
			value: 28
		}
	];
	// the model for selector
	public actualPriorNotification: {title: string, value: number};

	// the model for start date
	public actualStartDate: Date;

	// response data from server
	public actualPeriodData: TaxReviewPeriod;

	public saveButtonEnabled = false;

	constructor(private api: ApiCommunicationService,
				public taxService: TaxDataService,
				public taxModalService: TaxModalService) {
	}

	ngOnInit() {
		this.fetchPeriod();
	}

	/**
	 * Get review period from api
	 */
	private fetchPeriod() {
		this.loading = true;
		this.actualPeriodData = undefined;

		this.api.event().getReviewPeriod(this.taxRevision.id).subscribe((response) => {

			// update values based on response
			this.updateState(response);

			this.loading = false;
		});
	}

	/**
	 * Save period data
	 */
	public saveReviewPeriod() {
		this.loading = true;

		this.api.event().saveOrUpdateReviewPeriod(this.taxRevision.id, this.forgeRequest()).subscribe((response) => {
			this.updateState(response);
			this.loading = false;
			this.taxModalService.openTaxNotificationUpdatedModalComponent();
		});
	}

	/**
	 * Creates request.
	 */
	private forgeRequest(): CreateUpdateReviewPeriodRequest {

		if (this.actualStartDate
			&& this.actualPriorNotification && this.actualPriorNotification.value
			&& this.actualConfirmationFrequency && this.actualConfirmationFrequency.value) {

			return {
				eventStartDate: this.actualStartDate,
				reminderPriorInDays: this.actualPriorNotification.value,
				reviewPeriod: this.actualConfirmationFrequency.value
			};

		} else {
			throw new Error("Request can not be forged");
		}
	}

	/**
	 * Update component state based on server respone.
	 * @param data response from server
	 */
	private updateState(data: TaxReviewPeriod) {
		// check if it has been set
		if (data && data.id) {

			// set main object
			this.actualPeriodData = data;

			// set frequency
			this.actualConfirmationFrequency =
				// get the right value from selection
				this.confirmRulesFrequencies.find(f => f.value === this.actualPeriodData.reviewPeriod);

			// set prior notification
			this.actualPriorNotification =
				// get the right value from selection
				this.priorNotification.find(f => f.value === this.actualPeriodData.reminderPriorInDays);

			// if custom value has been used
			if (!this.actualPriorNotification) {
				this.actualPriorNotification = {
					title: `${this.actualPeriodData.reminderPriorInDays} days`,
					value: this.actualPeriodData.reminderPriorInDays
				};
			}

			// start date
			this.actualStartDate = new Date(this.actualPeriodData.eventStartDate);

		} else {
			// clear values
			this.actualPeriodData = <any>{};
			this.actualConfirmationFrequency = undefined;
			this.actualPriorNotification = undefined;
			this.actualStartDate = undefined;
		}
	}

	public updateNextActivation() {

		// skip if data is not yet loaded
		if (!this.actualPeriodData || this.loading) {
			return;
		}

		try {
			const request = this.forgeRequest();
			if (request.reviewPeriod && request.reminderPriorInDays && request.eventStartDate) {

				this.saveButtonEnabled = true;

				this.api.event().calculateNextActivation(this.forgeRequest()).subscribe((response) => {
					this.actualPeriodData.nextActivation = response.nextActivation;
				});

			} else {
				this.saveButtonEnabled = false;
			}

		} catch (e) {
			this.saveButtonEnabled = false;
			return;
		}

	}

}
