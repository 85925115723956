import { Inject, Injectable } from "@angular/core";
import { ModalService } from '../../commons/services/modal.service';
import { AuditTrail } from "../../model/data/audit/AuditTrail";
import { TaxChangeNotes } from "../../model/data/tax/TaxChangeNotes";
import { UserChangeNotes } from "../../model/data/tax/UserChangeNotes";
import { ApiCommunicationService } from "../../model/services/api-communication/api-communication.service";
import { ShowChangeNotesModalComponent } from "../modals/show-change-notes-modal/show-change-notes-modal.component";

@Injectable()
export class AuditModalService {

	constructor(
		private modalService: ModalService,
		@Inject(ApiCommunicationService) private api: ApiCommunicationService
	) {}
  //req 7
  public openShowChangeNotesModal(audit: AuditTrail,topic ){
	//public openShowChangeNotesModal(audit: AuditTrail) {

		if (audit.taxId !== null) {

			this.api.changeNotes().getTaxChangeLog(audit.relatedEntityId).subscribe((changes: TaxChangeNotes) => {
				changes.message = "alternative";

				const modalRef = this.modalService.open(ShowChangeNotesModalComponent);
				modalRef.componentInstance.context = {
					changeNotes: [changes],
          topics:topic,
					updatedAt: audit.createdAt
				};
			});
		} else {
			this.api.changeNotes().getUserChangeLog(audit.relatedEntityId).subscribe((changes: UserChangeNotes) => {
				const modalRef = this.modalService.open(ShowChangeNotesModalComponent);
				modalRef.componentInstance.context = {
					changeNotes: [UserChangeNotes.transform(changes)],
          topics:[],
					updatedAt: audit.createdAt
				};
			});
		}

	}
}






