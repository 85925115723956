import { Injectable } from '@angular/core';

import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Injectable()
export class LongDateParserFormatter extends NgbDateParserFormatter {
  constructor(private dateAdapter: NgbDateAdapter<Date>) {
    super();
  }

  parse(value: string): NgbDateStruct | null {
    if (!value) {
      return null;
    }

    const momentDate = moment(value, 'DD-MM-YYYY');
    const date = momentDate.isValid() ? momentDate.toDate() : new Date(value);

    return this.dateAdapter.fromModel(date);
  }

  format(date: NgbDateStruct | null): string {
    return !date ? null : new Intl.DateTimeFormat('en-GB', { dateStyle: 'long' }).format(this.dateAdapter.toModel(date));
  }
}
