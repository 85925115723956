import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { RoutePaths } from '@rar/commons/constants/route-paths';
import { NoAccessPageComponent } from '@rar/commons/pages/no-access-page/no-access-page.component';

import { DownloadAttachmentPageComponent } from './commons/pages/download-attachment-page/download-attachment-page.component';
import { AuthGuardService } from './user/guard/auth-guard.service';

export const routes: Routes = [
  {
    path: `${RoutePaths.download.page}/:${RoutePaths.download.attachmentIdParameter}`,
    component: DownloadAttachmentPageComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: RoutePaths.noAccess,
    component: NoAccessPageComponent,
  },
  {
    path: '**',
    redirectTo: RoutePaths.notFound,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
