<!--empty message-->
<app-empty-placeholder [text]="'0 results found'" *ngIf="changes && !changes.length"></app-empty-placeholder>

<!--Loading indicator-->
<div class="ui active inline centered loader" *ngIf="!changes"></div>

<!--Tax table-->
<table id="changes-table" class="ui table custom-table padded-table" *ngIf="changes && changes.length">
  <thead>
    <tr>
      <th>{{ 'tax.tax-change-notes.changes' | translate | uppercase }}</th>
    </tr>
  </thead>
  <tbody>
    <tr class="table-row" *ngFor="let changeNote of changes">
      <td class="changes">
        <!-- req 7 -->
        <div class="occurrence" *ngIf="(dataClick!='no')" [ngClass]="{ clickable: changeNote.topicShortCode }" (click)="onTopicClick(changeNote.topicShortCode)">
          {{ changeNote.occurrence }}
          {{ (changeNote.original == null ? 'audit.added' : changeNote.new == null ? 'audit.deleted' : 'audit.edited') | translate }}
        </div>
        <div class="occurrence" *ngIf="(dataClick==='no')" >
          {{ changeNote.occurrence }}
          {{ (changeNote.original == null ? 'audit.added' : changeNote.new == null ? 'audit.deleted' : 'audit.edited') | translate }}
        </div>

        <!--normal display-->
        <div class="change align" *ngIf="!changeNote.alternativeDisplay">
          <span [outerHTML]="changeNote.new || '' | diffPipe: changeNote.original || ''"></span>
        </div>
        <!--alternative display-->
        <div class="change" *ngIf="changeNote.alternativeDisplay">
          <span class="oldValue">{{ changeNote.original?.toString() }}</span>
          <i class="fa fa-arrow-right"></i>
          <span class="newValue">{{ changeNote.new?.toString() }}</span>
        </div>
      </td>
    </tr>
  </tbody>

  <tfoot class="changes-footer">
    <tr>
      <td class="editor-col editor">
        <div>
          Latest changes have been made by &nbsp;&nbsp;
          <a [href]="'mailto:' + changeNotes[0].user.email" ngbPopover="Send email" placement="left">
            <b>{{ changeNotes[0].user.name }}</b>
            <i class="fa fa-envelope"></i>
          </a>
        </div>

        <!--edit user button-->
        <button *ngIf="isTaxChange" class="ml-5 ui basic button right floated" (click)="openDetailsModal()">
          See detailed list of changes
        </button>
      </td>
    </tr>
  </tfoot>
</table>
