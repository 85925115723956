<div class="modal-header">
  <h4 class="modal-title">
    {{ 'tax.restore-global-template.modal-title' | translate }}
  </h4>
</div>

<div class="modal-body">
  <div class="categories-container" *ngIf="categories?.length; else noCategories">
    <app-tax-restore-modal-tree [categories]="categories"></app-tax-restore-modal-tree>
  </div>
  <ng-template #noCategories>
    <div> {{ 'tax.restore-global-template.empty-restore-list' | translate }}</div>
  </ng-template>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="close()">
    {{ 'commons.modal.cancel' | translate }}
  </button>

  <button type="button" class="btn btn-primary" [disabled]="!categories?.length" (click)="saveTaxesToPropagate()">
    {{ 'tax.restore-global-template.save' | translate }}
  </button>
</div>
