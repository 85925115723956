import {Component, OnInit, Input} from '@angular/core';
import {TopicDifference} from '@rar/model/data/tax/TaxStructureDifferences';
import {SelectTreeElementService} from '@rar/tax/modals/tax-restore-modal/select-tree-element.service';

@Component({
  selector: 'app-tax-restore-modal-tree',
  templateUrl: './tax-restore-modal-tree.component.html',
  styleUrls: ['./tax-restore-modal-tree.component.scss']
})
export class TaxRestoreModalTreeComponent implements OnInit {

  constructor(private selectService: SelectTreeElementService) { }

  @Input()
  categories: TopicDifference[];

  ngOnInit(): void {
  }

  propagateToParents(category: TopicDifference): void {
    this.selectService.select(category);
  }
}
