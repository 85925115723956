import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { untilDestroyed } from 'ngx-take-until-destroy';

import { TaxParams } from '../../../model/request/tax/TaxParams';
import { TaxSearchParam } from '../../../model/request/tax/TaxSearchParam';
import { ApiCommunicationService } from '../../../model/services/api-communication/api-communication.service';
import { TaxFilterService } from '../../services/tax-filter/tax-filter.service';

@Component({
  selector: 'app-tax-listing',
  templateUrl: './tax-listing.component.html',
  styleUrls: ['./tax-listing.component.scss'],
  providers: [TaxFilterService],
})
export class TaxListingComponent implements OnInit, OnDestroy {
  public taxName: string;
  public territoryName: string;
  public date: Date;

  constructor(
    public taxFilter: TaxFilterService,
    private route: ActivatedRoute,
    @Inject(ApiCommunicationService) private api: ApiCommunicationService,
  ) {}

  ngOnInit() {
    let params = new TaxParams();

    this.route.data.pipe(untilDestroyed(this)).subscribe((response) => {
      const taxSearchParam: TaxSearchParam = response.data;
      if (taxSearchParam) {
        if (taxSearchParam.t) {
          params.type = [taxSearchParam.t];
        } else {
          params.type = [];
        }

        if (taxSearchParam.n) {
          this.taxName = taxSearchParam.n;
        } else {
          this.taxName = undefined;
        }

        if (taxSearchParam.j) {
          params.territory = [taxSearchParam.j];
        }

        if (taxSearchParam.l) {
          this.territoryName = taxSearchParam.l;
        } else {
          this.territoryName = undefined;
        }

        if (taxSearchParam.d) {
          params.date = [this.dateAsString(taxSearchParam.d)];
          this.date = new Date(params.date[0]);
        } else {
          this.date = undefined;
          params.date = [this.dateAsString(new Date())];
        }
        if (taxSearchParam.a) {
          params.all = Boolean(taxSearchParam.a);
        }

        this.taxFilter.getPage(1, params);
      } else {
        params.type = [];
        this.taxFilter.getPage(1, params);
        this.taxName = undefined;
        this.territoryName = undefined;
        this.date = undefined;
      }

      params = new TaxParams();
    });
  }

  ngOnDestroy(): void {}

  private dateAsString(param: string | Date): string {
    let date = new Date(param);
    if (date.getFullYear() < 1900 || date.getFullYear() >= 2100) {
      date = new Date();
    }

    let rv: string;
    rv = date.getFullYear() + '-';
    rv += date.getMonth() + 1 + '-';
    rv += date.getDate();
    return rv;
  }
}
