import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

interface ITaxTopicCommentModalComponentContext {
	shortCode: string;
}

@Component({
  selector: 'app-tax-topic-comment-modal',
  templateUrl: './tax-topic-comment-modal.component.html',
  styleUrls: ['./tax-topic-comment-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaxTopicCommentModalComponent {

	constructor(private activeModal: NgbActiveModal) { }

  public content: string;

  @Input()
	context: ITaxTopicCommentModalComponentContext;

  approve(content: string): void {
    const event = {
      content: content,
      shortCode: this.context.shortCode
    }
		this.activeModal.close(event);
	}

	cancel(): void {
		this.activeModal.dismiss(null);
	}
}
