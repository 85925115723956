export class ChangeNotesParam {

	constructor(isSummary: boolean, userId?: number) {
		this.isSummary = isSummary;

		if (userId) {
			this.userId = userId;
		}

	}

	isSummary: boolean;
	userId?: number;

}
