/**
 * Enumeration of [[User]] Statuses.
 * Should be the same as the contents of the [UserStatus] table in the database.
 * If those do not line up than this enum should be the representative.
 */
export enum UserStatus {

	/**
	 * Active user. Can interact with the system.
	 */
	ACTIVE = 1,

	/**
	 * Archived user. Can not interact with the system.
	 * Virtual equivalent of a deleted user, but keeps the users identifiable.
	 */
	ARCHIVED = 2

	/**
	 * Inactive user. Can not interact with the system.
	 * Redeemed unnecessary, becuase we don't want syncronize with active directory. All users will be made 'inactive' with archiving by administrators.
	 */
	// INACTIVE = 3,


}
