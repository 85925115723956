import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output, OnDestroy,
} from '@angular/core';


interface Column {index: number; columnName: string; }

@Component({
  selector: 'app-tax-table-column',
  templateUrl: './tax-table-column.component.html',
  styleUrls: ['./tax-table-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TaxTableColumnComponent implements OnDestroy {

  constructor() { }

  @Input()
  columnName: string;

  @Input()
  columnIndex: number;

  @Output()
  deleteColumn = new EventEmitter<number>();

  @Output()
  updateColumnName = new EventEmitter<Column>();

  onValueChange(event) {
    const updatedColumn: Column = {
      index: this.columnIndex,
      columnName: event.target.value
    };
    this.updateColumnName.emit(updatedColumn);
  }

  public onDeleteColumn() {
    this.deleteColumn.emit(this.columnIndex);
  }

  ngOnDestroy() {}
}
