import { Component, Input } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { TaxTypeTopic } from '@rar/model/data/tax/TaxTypeTopic';

@Component({
  selector: 'app-tax-type-reorder-category-modal',
  templateUrl: './tax-type-reorder-category-modal.component.html',
  styleUrls: ['./tax-type-reorder-category-modal.component.scss'],
})
export class TaxTypeReorderCategoryModalComponent {
  constructor(private activeModal: NgbActiveModal) {}

  @Input()
  topics: TaxTypeTopic[];

  approve(): void {
    this.activeModal.close(null);
  }

  cancel(): void {
    this.activeModal.dismiss(null);
  }

  public sortTopics(topics: TaxTypeTopic[]): TaxTypeTopic[] {
    return topics ? topics.sort((a, b) => a.order - b.order) : [];
  }

  public changeTopicOrder(index: number, moveUp: boolean) {
    const currentTopicOrder = this.topics[index].order;
    const moveToIndex = moveUp ? index - 1 : index + 1;
    this.topics[index].order = this.topics[moveToIndex].order;
    this.topics[moveToIndex].order = currentTopicOrder;
    this.topics = this.sortTopics(this.topics);
  }
}
