import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// 3rd party
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OAuthModule } from 'angular-oauth2-oidc';

// main modules
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuditModule } from './audit/audit.module';
import { CommonsModule } from './commons/commons.module';
import { BearerInterceptor } from './commons/interceptors/bearer-interceptor';
import { HttpErrorInterceptor } from './commons/interceptors/http-error.interceptor';
import { CollapseService } from './commons/services/collapse.service';
import { SearchService } from './commons/services/search.service';
import { TopicService } from './commons/services/topic.service';
import { DashboardModule } from './dashboard/dashboard.module';
import { ModelModule } from './model/model.module';
import { ApiCommunicationService } from './model/services/api-communication/api-communication.service';
import { BaseHttpService } from './model/utility/base-http.service';
import { TaxModule } from './tax/tax.module';
import { AuthGuardService } from './user/guard/auth-guard.service';
import { NoAuthGuardService } from './user/guard/noauth-guard.service';
import { UserSessionService } from './user/services/user-session/user-session.service';
import { UserModule } from './user/user.module';
import { userSessionStarterFactory } from './user/util/UserSessionStarterFactory';
import { TaxDesignModule } from './tax-design/tax-design.module';

// http loader for translations file
export function HttpLoaderFactory(http: HttpClient) {
  const nonce = new Date().valueOf();
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json?nonce='+nonce);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    // angular modules
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    // 3rd party modules
    // ngx-translate
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    // oauth
    OAuthModule.forRoot(),

    // pwc modules
    CommonsModule,
    AuditModule,
    ModelModule,
    UserModule,
    DashboardModule,
    TaxModule,
    TaxDesignModule,
    // last module, so ** can catch not existing routes
    AppRoutingModule,
  ],
  providers: [
    // unhandled exceptions handling by MS AppInsights
    { provide: ErrorHandler, useClass: ApplicationinsightsAngularpluginErrorService },

    // inject authorization token
    { provide: HTTP_INTERCEPTORS, useClass: BearerInterceptor, multi: true },

    // error interceptor to redirect on 401/403 statuses
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },

    // Title service
    Title,

    // User session handler service
    UserSessionService,

    // Communication service
    ApiCommunicationService,

    // OAuth API client
    BaseHttpService,

    // hook UserSessionService's session start action into app initialization hook
    {
      provide: APP_INITIALIZER,
      useFactory: userSessionStarterFactory,
      deps: [UserSessionService],
      multi: true,
    },

    // Auth guard (prevents unauthorized users from using 'login-required' pages)
    AuthGuardService,
    NoAuthGuardService,

    CollapseService,
    SearchService,
    TopicService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
