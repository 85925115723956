import { HttpHeaders } from '@angular/common/http';

export class FileNameReader {
  public static getFileName(headers: HttpHeaders): string | null {
    let filename = headers.get('content-disposition');
    if (!filename) {
      return null;
    }
    filename = filename.split('filename=')[1].split(';')[0];

    if ((filename.startsWith(`'`) && filename.endsWith(`'`)) || (filename.startsWith(`"`) && filename.endsWith(`"`))) {
      filename = filename.substr(1, filename.length - 2);
    }
    return filename;
  }
}
