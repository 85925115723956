import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { NgbAccordionModule, NgbDatepickerModule, NgbDropdownModule, NgbModalModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { RichTextEditorModule } from '@rar/rich-text-editor/rich-text-editor.module';
import { AttachmentUploadingGuard } from '@rar/tax/guards/attachment-uploading-guard.service';
import { TaxFileUploadModalComponent } from '@rar/tax/modals/tax-file-upload-modal/tax-file-upload-modal.component';

import { AuditModule } from '../audit/audit.module';
import { CommonsModule } from '../commons/commons.module';
import { AuthGuardService } from '../user/guard/auth-guard.service';
import { TaxApproveComponent } from './components/tax-approve/tax-approve.component';
import { TaxChangeNotesComponent } from './components/tax-change-notes/tax-change-notes.component';
import { TaxFilterComponent } from './components/tax-filter/tax-filter.component';
import { TaxHistoryComponent } from './components/tax-history/tax-history.component';
import { TaxInfoComponent } from './components/tax-info/tax-info.component';
import { TaxListingChangesComponent } from './components/tax-listing-changes/tax-listing-changes.component';
import { TaxListingComponent } from './components/tax-listing/tax-listing.component';
import { TaxLoadingComponent } from './components/tax-loading/tax-loading.component';
import { TaxRatesComponent } from './components/tax-rates/tax-rates.component';
import { TaxRulesJsonComponent } from './components/tax-rules/tax-rules-json/tax-rules-json.component';
import { TaxRulesKeyValueComponent } from './components/tax-rules/tax-rules-key-value/tax-rules-key-value.component';
import { TaxRulesComponent } from './components/tax-rules/tax-rules.component';
import { TaxSearchResultComponent } from './components/tax-search-result/tax-search-result.component';
import { TaxEventsComponent } from './components/tax-settings/tax-events/tax-events.component';
import { TaxScheduleComponent } from './components/tax-settings/tax-schedule/tax-schedule.component';
import { TaxSettingsUsersComponent } from './components/tax-settings/tax-settings-users/tax-settings-users.component';
import { TaxSettingsComponent } from './components/tax-settings/tax-settings.component';
import { TaxSubMenuComponent } from './components/tax-sub-menu/tax-sub-menu.component';
import { TaxTableColumnContainerComponent } from './components/tax-table-column-container/tax-table-column-container.component';
import { TaxTableColumnComponent } from './components/tax-table-column-container/tax-table-column/tax-table-column.component';
import { TaxTableComponent } from './components/tax-table/tax-table.component';
import { TaxTopicEmptyComponent } from './components/tax-topic-empty/tax-topic-empty.component';
import { TaxTopicTypeSelectorComponent } from './components/tax-topic-type-selector/tax-topic-type-selector.component';
import { TaxTopicComponent } from './components/tax-topic/tax-topic.component';
import { RevisionStatusDirective } from './directives/revision-status.directive';
import { TaxDiscardChangesGuard } from './guards/tax-discard-changes-guard.service';
import { TaxApproveModalComponent } from './modals/tax-approve-modal/tax-approve-modal.component';
import { TaxApproversListComponent } from './modals/tax-approvers-list/tax-approvers-list.component';
import { TaxAssessmentModalComponent } from './modals/tax-assessment-modal/tax-assessment-modal.component';
import { TaxCloseModalComponent } from './modals/tax-close-modal/tax-close-modal.component';
import { TaxConfirmModalComponent } from './modals/tax-confirm-modal/tax-confirm-modal.component';
import { TaxDiscardModalComponent } from './modals/tax-discard-modal/tax-discard-modal.component';
import { TaxEditModalComponent } from './modals/tax-edit-modal/tax-edit-modal.component';
import { TaxEventModalComponent } from './modals/tax-event-modal/tax-event-modal.component';
import { TaxLockModalComponent } from './modals/tax-lock-modal/tax-lock-modal.component';
import { TaxMultiEditModalComponent } from './modals/tax-multi-edit-modal/tax-multi-edit-modal.component';
import { TaxNotificationUpdatedModalComponent } from './modals/tax-notification-updated-modal/tax-notification-updated-modal.component';
import { TaxRejectModalComponent } from './modals/tax-reject-modal/tax-reject-modal.component';
import { TaxListingPageComponent } from './pages/tax-listing-page/tax-listing-page.component';
import { TaxRevisionPageComponent } from './pages/tax-revision-page/tax-revision-page.component';
import { TaxListingResolverService } from './resolvers/tax-listing-resolver.service';
import { TaxRevisionResolverService } from './resolvers/tax-revision-resolver.service';
import { TaxTopicResolverService } from './resolvers/tax-topic-resolver.service';
import {TaxRestoreModalComponent} from '@rar/tax/modals/tax-restore-modal/tax-restore-modal.component';
import {
  TaxRestoreModalTreeComponent
} from '@rar/tax/modals/tax-restore-modal/tax-restore-modal-tree/tax-restore-modal-tree.component';
import { TaxTopicCommentBarComponent } from './components/tax-topic-comment-bar/tax-topic-comment-bar.component';
import { TaxTopicCommentModalComponent } from './modals/tax-topic-comment-modal/tax-topic-comment-modal.component';
import { TaxTopicCommentsHistoryModalComponent } from './modals/tax-topic-comments-history-modal/tax-topic-comments-history-modal.component';
import { TaxTopicCommentConfirmChangesModalComponent } from './modals/tax-topic-comment-confirm-changes-modal/tax-topic-comment-confirm-changes-modal.component';
import { TaxTopicCommentSuccessModalComponent } from './modals/tax-topic-comment-success-modal/tax-topic-comment-success-modal.component';

const routes: Routes = [
  {
    path: 'tax',
    component: TaxListingPageComponent,
    resolve: {
      data: TaxListingResolverService,
    },
    canActivate: [AuthGuardService],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'tax/:param',
    component: TaxListingPageComponent,
    canActivate: [AuthGuardService],
  },
  // ugly betty
  {
    path: 'tax/:id/:revisionId',
    component: TaxLoadingComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'tax/:id/revision/:revisionId',
    component: TaxRevisionPageComponent,
    canActivate: [AuthGuardService],
    canDeactivate: [TaxDiscardChangesGuard],
    resolve: {
      taxRevision: TaxRevisionResolverService,
    },
    children: [
      {
        path: 'topic/:id',
        component: TaxTopicComponent,
        resolve: {
          topic: TaxTopicResolverService,
        },
        canActivate: [AuthGuardService],
        canDeactivate: [AttachmentUploadingGuard],
        runGuardsAndResolvers: 'always',
      },
      {
        path: 'settings',
        component: TaxSettingsComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'history',
        component: TaxHistoryComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'notes',
        component: TaxChangeNotesComponent,
        canActivate: [AuthGuardService],
      },
      {
        path: 'search',
        component: TaxSearchResultComponent,
        canActivate: [AuthGuardService],
      },
    ],
  },
];

@NgModule({
    imports: [
        CommonModule,
        CommonsModule,
        RouterModule.forChild(routes),
        TranslateModule.forChild(),
        FormsModule,
        NgbDatepickerModule,
        NgbModalModule,
        NgbPopoverModule,
        NgSelectModule,
        NgbDropdownModule,
        NgbAccordionModule,
        AngularSvgIconModule,
        AuditModule,
        RichTextEditorModule,
        ReactiveFormsModule,
    ],
    declarations: [
        TaxListingPageComponent,
        TaxRevisionPageComponent,
        TaxListingComponent,
        TaxListingChangesComponent,
        TaxChangeNotesComponent,
        TaxHistoryComponent,
        TaxInfoComponent,
        TaxTopicComponent,
        TaxRatesComponent,
        TaxRulesComponent,
        TaxFilterComponent,
        TaxTableComponent,
        TaxRulesKeyValueComponent,
        TaxRulesJsonComponent,
        TaxSettingsComponent,
        TaxSettingsUsersComponent,
        TaxScheduleComponent,
        TaxTopicCommentBarComponent,
        RevisionStatusDirective,
        TaxConfirmModalComponent,
        TaxEditModalComponent,
        TaxApproversListComponent,
        TaxTopicEmptyComponent,
        TaxSubMenuComponent,
        TaxApproveComponent,
        TaxLockModalComponent,
        TaxEventModalComponent,
        TaxRejectModalComponent,
        TaxDiscardModalComponent,
        TaxAssessmentModalComponent,
        TaxMultiEditModalComponent,
        TaxLoadingComponent,
        TaxApproveModalComponent,
        TaxCloseModalComponent,
        TaxSearchResultComponent,
        TaxNotificationUpdatedModalComponent,
        TaxEventsComponent,
        TaxTopicTypeSelectorComponent,
        TaxTableColumnContainerComponent,
        TaxTableColumnComponent,
        TaxFileUploadModalComponent,
        TaxRestoreModalComponent,
        TaxRestoreModalTreeComponent,
        TaxTopicCommentModalComponent,
        TaxTopicCommentsHistoryModalComponent,
        TaxTopicCommentConfirmChangesModalComponent,
        TaxTopicCommentSuccessModalComponent
    ],
    providers: [TaxRevisionResolverService, TaxTopicResolverService, TaxListingResolverService],
    exports: [TaxTopicEmptyComponent]
})
export class TaxModule {}
