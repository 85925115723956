<div class="comments-bar">
    <span *ngIf="isEditor">
        <svg-icon
            *ngIf="hasNotApprovedComments()"
            svgClass="alert-icom icon" 
            src="/assets/images/icons/alert.svg"></svg-icon>
        <a (click)="onClickShowTopicComments()" href="javascript:void(0)">{{ "tax.tax-info.display-comments" | translate }} {{getNotApprovedCountMessage()}}</a>
    </span>
    <svg-icon *ngIf="!taxDataService.isEditingMode && !taxDataService.isApprovingMode" svgClass="add-icon icon" src="/assets/images/icons/note_plus.svg" (click)=" onClickAddTopicComment()"></svg-icon>
</div>
