<div class="modal-header">
	<h4 class="modal-title">
		{{ "tax.tax-info.new-comment-title" | translate }}
	</h4>
</div>

<div class="modal-body">
	<div>
		<textarea id="contentArea" [(ngModel)]="content" placeholder="Content..."></textarea>
	</div>
</div>

<div class="modal-footer">
	<div></div>

	<div class="button-group">
		<button class="ui basic button silver" (click)="cancel()">
			{{ "user.user-edit-modal.cancel" | translate }}
		</button>

		<button 
			class="ui basic button rose"
			[disabled]="content === undefined || content === ''"
			(click)="approve(content)">
			Add
		</button>
	</div>
</div>