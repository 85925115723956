import {Inject, Injectable} from "@angular/core";
import {ApiCommunicationService} from "../../../model/services/api-communication/api-communication.service";
import {BehaviorSubject, Observable} from "rxjs";
import {User} from "../../../model/data/user/User";
import {UserListResponse} from "../../../model/response/UserListResponse";
import {DashboardRequest} from "../../../model/request/dashboard/DashboardRequest";

@Injectable()
export class UserFilterService {

	public authUser: User;

	private allUsers: UserListResponse;
	private _users: BehaviorSubject<Array<User>> = new BehaviorSubject<Array<User>>(undefined);

	private _showArchived = true;
	private _showFilter = false;

	// are the next and prev pages available
	private _isNextPage = false;
	private _isPreviousPage = false;

	constructor(@Inject(ApiCommunicationService) private api: ApiCommunicationService) {}

	public getPage(page: number) {
		this.getUsers(page);
	}

	public getNewUsersPage(page: number) {
		this.api.dashboard().getUsers(DashboardRequest.ALL, page).subscribe((users) => {
			// extract data
			this.allUsers = users;

			// update paging
			this._isNextPage = !!users._links.next;
			this._isPreviousPage = !!users._links.next;

			this.update();
		});
	}

	private getUsers(page: number, filter?: User) {
		this.api.user().getUsers(page, filter).subscribe((users) => {
			// extract data
			this.allUsers = users;

			// update paging
			this._isNextPage = this.allUsers._links.next !== null;
			this._isPreviousPage = this.allUsers._links.previous !== null;

			this.update();
		});
	}

	public updateFilter(filter: User) {
		this.getUsers(1, filter);
	}

	private update() {
		this._users.next(this.allUsers._embedded.users);
	}

	get users(): Observable<Array<User>> {
		return this._users.asObservable();
	}

	public setShowArchived(val: boolean) {
		this._showArchived = val;
		this.update();
	}

	public refetchPage() {
		this.getPage(this.allUsers._info.current);
	}

	public fetchNextPage() {
		if (this.isNextPage) {
			this.getPage(this.allUsers._info.current + 1);
		} else {
			console.error("Fetching next page is not allowed.");
		}
	}

	public fetchPreviousPage() {
		if (this.isPreviousPage) {
			this.getPage(this.allUsers._info.current - 1);
		} else {
			console.error("Fetching previous page is not allowed.");
		}
	}

	get showArchived(): boolean {
		return this._showArchived;
	}

	get isNextPage(): boolean {
		return this._isNextPage;
	}

	get isPreviousPage(): boolean {
		return this._isPreviousPage;
	}

	get currentPage(): number {
		return this.allUsers._info.current;
	}

	get totalPages(): number {
		return this.allUsers._info.total;
	}

	get showFilter(): boolean {
		return this._showFilter;
	}

	public setShowFilter(value: boolean) {
		this._showFilter = value;
	}

	public updateUserLocally(user: User) {
		const uIndex = this.allUsers._embedded.users
			.findIndex((u) => u.id === user.id);

		this.allUsers[uIndex] = user;
		this.update();
	}

}
