import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { RoutePaths } from '@rar/commons/constants/route-paths';
import { Role } from '@rar/model/data/enums/Role';

import { CommonsModule } from '../commons/commons.module';
import { UserFormComponent } from './components/user-form/user-form.component';
import { UserListingComponent } from './components/user-listing/user-listing.component';
import { UserPersonalInfoComponent } from './components/user-personal-info/user-personal-info.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { UserRolesPermissionsComponent } from './components/user-roles-permissions/user-roles-permissions.component';
import { UserTableComponent } from './components/user-table/user-table.component';
import { AuthGuardService } from './guard/auth-guard.service';
import { UserPageComponent } from './pages/user-page/user-page.component';
import { UserRolePipe } from './pipes/user-role.pipe';
import { UserFormMapperService } from './services/user-form/user-form-mapper.service';

const routes: Routes = [
  {
    path: 'user/profile',
    component: UserProfileComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: RoutePaths.users.list,
    component: UserListingComponent,
    canActivate: [AuthGuardService],
    pathMatch: 'full',
    data: {
      roles: [Role.SUPER_ADMIN, Role.ADMIN],
    },
  },
  {
    path: RoutePaths.users.edit,
    component: UserPageComponent,
    canActivate: [AuthGuardService],
    data: {
      roles: [Role.SUPER_ADMIN, Role.ADMIN],
    },
  },
  {
    path: RoutePaths.users.addNew,
    component: UserPageComponent,
    canActivate: [AuthGuardService],
    data: {
      roles: [Role.SUPER_ADMIN, Role.ADMIN],
    },
  },
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        HttpClientModule,
        AngularSvgIconModule,
        RouterModule.forChild(routes),
        NgbModule,
        NgSelectModule,
        CommonsModule,
    ],
    declarations: [
        UserListingComponent,
        UserProfileComponent,
        UserTableComponent,
        UserRolePipe,
        UserFormComponent,
        UserPageComponent,
        UserPersonalInfoComponent,
        UserRolesPermissionsComponent,
    ],
    providers: [UserFormMapperService]
})
export class UserModule {}
