import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';

import { AttachmentHandlerService } from '@rar/rich-text-editor/attachments/attachment-handler.service';
import { TaxTopicComponent } from '@rar/tax/components/tax-topic/tax-topic.component';

@Injectable({
  providedIn: 'root',
})
export class AttachmentUploadingGuard implements CanDeactivate<TaxTopicComponent> {
  constructor(private attachmentHandlerService: AttachmentHandlerService) {}

  canDeactivate(
    component: TaxTopicComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.attachmentHandlerService.canDeactivate();
  }
}
