import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';

import { AuditModule } from '../audit/audit.module';
import { CommonsModule } from '../commons/commons.module';
import { AuthGuardService } from '../user/guard/auth-guard.service';
import { UserModule } from '../user/user.module';
import { DashboardLatestChangesComponent } from './components/dashboard-latest-changes/dashboard-latest-changes.component';
import { DashboardMapComponent } from './components/dashboard-map/dashboard-map.component';
import { DashboardRecentlyViewedTableComponent } from './components/dashboard-recently-viewed-table/dashboard-recently-viewed-table.component';
import { DashboardRecentlyViewedComponent } from './components/dashboard-recently-viewed/dashboard-recently-viewed.component';
import { DashboardTaxTableComponent } from './components/dashboard-tax-table/dashboard-tax-table.component';
import { DashboardUpcomingChangesComponent } from './components/dashboard-upcoming-changes/dashboard-upcoming-changes.component';
import { DashboardPageComponent } from './pages/dashboard-page/dashboard-page.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardPageComponent,
    canActivate: [AuthGuardService],
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    // angular modules
    CommonModule,
    CommonsModule,
    RouterModule.forChild(routes),
    TranslateModule.forChild(),

    // pwc modules
    AuditModule,
    UserModule,
    NgbModule,
  ],
  declarations: [
    DashboardPageComponent,

    DashboardMapComponent,
    DashboardUpcomingChangesComponent,
    DashboardLatestChangesComponent,
    DashboardRecentlyViewedComponent,
    DashboardTaxTableComponent,
    DashboardRecentlyViewedTableComponent,
  ],
})
export class DashboardModule {}
