import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

import { Role } from '@rar/model/data/enums/Role';
import { DataSourcesRowForm } from '@rar/user/models/data-sources-row.interface';
import { UserFormDataService } from '@rar/user/services/user-form/user-form-data.service';
import { UserFormFieldsService } from '@rar/user/services/user-form/user-form-fields.service';

@Component({
  selector: 'app-user-roles-permissions',
  templateUrl: './user-roles-permissions.component.html',
  styleUrls: ['./user-roles-permissions.component.scss'],
})
export class UserRolesPermissionsComponent implements OnInit {
  constructor(private userFormFieldsService: UserFormFieldsService, private userFormDataService: UserFormDataService) {}

  @Input()
  rolesAndPermissionsFormGroup!: UntypedFormGroup;

  @Input()
  roles: { id: Role; name: string }[];

  @Output()
  readonly addPermissionClicked = new EventEmitter<void>();

  @Output()
  readonly deletePermissionClicked = new EventEmitter<number>();

  rolesAndPermissionsTable: UntypedFormArray;

  isLoading$ = this.userFormDataService.isCommonDataLoading$;

  ngOnInit() {
    this.rolesAndPermissionsTable = this.rolesAndPermissionsFormGroup.get('rolesAndPermissionsTable') as UntypedFormArray;
  }

  get isFormDisabled(): boolean {
    return this.userFormFieldsService.isUserFormDisabled;
  }

  getDataSourcesForRow(index: number): DataSourcesRowForm {
    return this.userFormFieldsService.getDataSourcesForRow(index);
  }
}
