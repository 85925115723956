<div id="tax-search-result-layout">
  <div id="header">
    <h2>{{ 'tax.tax-topics.search-results' | translate }}</h2>
  </div>

  <!--Display-->
  <div *ngIf="taxDataService.searchResult && taxDataService.searchResult.topics; else empty" class="result-container">
    <button class="ui basic button" *ngFor="let topic of taxDataService.searchResult.topics" (click)="onSelectTopic(topic)">
      {{ topic.name }}
    </button>
  </div>

  <ng-template #empty>
    <app-empty-placeholder [text]="'0 results found'"></app-empty-placeholder>
  </ng-template>
</div>
