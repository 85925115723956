import { IErrorService } from '@microsoft/applicationinsights-angularplugin-js';

import { environment } from '../../../environments/environment';

export class GlobalErrorHandler implements IErrorService {
  handleError(error: any) {
    if (!environment.production) {
      // window.console.error(error);
      let errorMessage = error?.message || error;
      if (typeof errorMessage === 'object') {
        errorMessage = JSON.stringify(errorMessage);
      }
      const body = document.querySelector('body');
      // if (errorMessage && body) {
      //   body.classList.add('global-error');
      //   body.setAttribute('data-globalError', errorMessage);
      // }
    }
  }
}
