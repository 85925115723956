<div id="tax-topic-layout">
  <ng-container *ngIf="topic && topic.parent ? topic.parent : topic as categoryTopic">
    <div id="header">
      <ng-container *ngIf="taxTypeDataService.getTaxTopic(taxTypeDataService.getTax(), categoryTopic.id) as categoryForEdit">
        <input
          #categoryName="ngModel"
          type="text"
          class="title form-control"
          [(ngModel)]="categoryForEdit.name"
          required
          [minlength]="validationConstants.categoryMinLength"
          [maxlength]="validationConstants.categoryMaxLength"
          [pattern]="validationConstants.categoryTopicNamePattern"
          data-test="category heading" />
        <div class="short-code">({{ categoryForEdit.shortCode }})</div>
        <div class="invalid-feedback" *ngIf="categoryName.invalid">
          <div class="invalid-feedback__message">
            <i class="fa fa-exclamation-triangle mr-1"></i>
            <ng-container *ngIf="categoryName.errors.pattern && !categoryName.errors.minlength">
              <span>{{ 'commons.validation.category-topic-pattern' | translate }}</span>
            </ng-container>
            <ng-container *ngIf="categoryName.errors.minlength">
              <span>{{ 'commons.validation.minlength' | translate: { minlength: categoryName.errors.minlength.requiredLength } }}</span>
            </ng-container>
            <ng-container *ngIf="categoryName.errors.required">
              <span>{{ 'commons.validation.required' | translate }}</span>
            </ng-container>
          </div>
        </div>
        <ng-container>
          <button class="btn btn-primary btn-icon" (click)="deleteCategory(categoryTopic.id)">
            <svg-icon class="icon mr-2" src="../../../../assets/images/icons/trash.svg"></svg-icon>
            {{ 'tax.tax-topics.category-delete' | translate }}
          </button>
        </ng-container>
      </ng-container>
    </div>

    <ngb-accordion
      *ngIf="categoryTopic.subTopics && categoryTopic.subTopics.length"
      #accordion
      class="tax-view"
      [closeOthers]="true"
      [activeIds]="activeTopicPanelId"
      (panelChange)="beforeChange($event)">
      <ngb-panel
        [cardClass]="accordion.isExpanded('topic-' + currentTopic.id) ? 'active' : ''"
        *ngFor="let currentTopic of sortTopics(categoryTopic.subTopics); let i = index; last as last; first as first"
        id="topic-{{ currentTopic.id }}">
        <ng-template ngbPanelTitle>
          <div class="header-container" (click)="selectTopic(currentTopic)">
            <div class="header">
              <ng-container *ngIf="taxTypeDataService.getTaxTopic(taxTypeDataService.getTax(), currentTopic.id) as topicForEdit">
                <input
                  #topicName="ngModel"
                  type="text"
                  class="title form-control"
                  [class.show-feedback]="topicName.invalid && (topicForEdit.id > 0 || topicName.touched)"
                  [(ngModel)]="topicForEdit.name"
                  required
                  [minlength]="validationConstants.topicMinLength"
                  [maxlength]="validationConstants.topicMaxLength"
                  [pattern]="validationConstants.categoryTopicNamePattern"
                  data-test="topic heading" />
                <div class="short-code">({{ topicForEdit.shortCode }})</div>
                <input type="hidden" name="topicOrder" [value]="currentTopic?.order" />
                <button
                  class="btn btn-sm btn-outline-primary btn-icon arrow-button mr-2"
                  (click)="changeTopicOrder($event, i, true, categoryTopic)"
                  *ngIf="!first">
                  <svg-icon class="icon arrow-up" src="../../../../assets/images/icons/arrow-down.svg"></svg-icon>
                </button>
                <button
                  class="btn btn-sm btn-outline-primary btn-icon arrow-button mr-2"
                  (click)="changeTopicOrder($event, i, false, categoryTopic)"
                  *ngIf="!last">
                  <svg-icon class="icon" src="../../../../assets/images/icons/arrow-down.svg"></svg-icon>
                </button>
                <button class="btn btn-primary btn-icon" (click)="deleteTopic(i)">
                  <svg-icon class="icon" src="../../../../assets/images/icons/trash.svg"></svg-icon>
                  <span>{{ 'tax.tax-topics.topic-delete' | translate }}</span>
                </button>
                <div class="invalid-feedback" *ngIf="topicName.invalid && (topicForEdit.id > 0 || topicName.touched)">
                  <div class="invalid-feedback__message">
                    <i class="fa fa-exclamation-triangle mr-1"></i>
                    <ng-container *ngIf="topicName.errors.pattern && !topicName.errors.minlength">
                      <span>{{ 'commons.validation.category-topic-pattern' | translate }}</span>
                    </ng-container>
                    <ng-container *ngIf="topicName.errors.minlength">
                      <span>{{
                        'commons.validation.minlength' | translate: { minlength: topicName.errors.minlength.requiredLength }
                      }}</span>
                    </ng-container>
                    <ng-container *ngIf="topicName.errors.required">
                      <span>{{ 'commons.validation.required' | translate }}</span>
                    </ng-container>
                  </div>
                </div>
              </ng-container>
            </div>

            <div class="counter">
              <span i18n>
                { getTopicCounter(currentTopic), plural, =1 { 1 {{ 'tax.tax-topics.rule-singular' | translate }} } other {
                {{ getTopicCounter(currentTopic) }} {{ 'tax.tax-topics.rule-plural' | translate }} } }
              </span>
            </div>

            <svg-icon class="icon indicator" src="/assets/images/icons/arrow-fill.svg"></svg-icon>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <app-tax-type-topic-type-selector></app-tax-type-topic-type-selector>
          <app-tax-type-rates></app-tax-type-rates>
          <app-tax-type-rules></app-tax-type-rules>
        </ng-template>
      </ngb-panel>
    </ngb-accordion>
  </ng-container>

  <div class="flex-flow justify-content-end">
    <!--Add new topic button-->
    <button class="btn btn-primary btn-icon" (click)="addNewTopic()">
      <svg-icon class="icon" src="../../../../assets/images/icons/add2.svg"></svg-icon>
      <span>{{ 'tax.tax-topics.topic-add' | translate }}</span>
    </button>
  </div>
</div>
