<h4>{{ 'tax-design.locations.form.title' | translate }}</h4>

<ng-container *ngIf="isLoaded$ | async; else loader">
  <div [formGroup]="locationsFormGroup">
    <table class="table table-bordered-parts">
      <thead>
        <th class="cw-2 label-required">{{ 'tax-design.locations.form.col-jurisdiction' | translate }}</th>
        <th class="cw-2 label-required">{{ 'tax-design.locations.form.col-subdivisions' | translate }}</th>
        <th class="cw-delete"></th>
      </thead>
      <tbody>
        <ng-container formArrayName="locationsTable" *ngFor="let group of locationsTable.controls; let i = index">
          <tr [formGroupName]="i" *ngIf="getDataSourcesForRow(i) as data">
            <td>
              <ng-select
                formControlName="jurisdiction"
                placeholder="{{ 'tax-design.locations.form.dropdown-placeholder' | translate }}"
                [items]="data.jurisdictions$ | async"
                [searchable]="false"
                bindValue="id"
                class="control-with-feedback"
                data-test="tax type location jurisdiction"></ng-select>
              <app-validation-message [inputControl]="group.get('jurisdiction')"></app-validation-message>
            </td>

            <td>
              <app-multi-select
                [clearable]="false"
                [group]="$any(group)"
                [controlName]="'subdivisions'"
                placeholder="{{ 'tax-design.locations.form.dropdown-placeholder' | translate }}"
                [items]="data.subdivisions$ | async"
                bindValue="id"
                controlLocator="tax type location subdivision"
                itemLocator="subdivision"></app-multi-select>
            </td>

            <td>
              <button
                type="button"
                class="btn btn-sm btn-icon btn-outline-secondary btn-delete"
                (click)="deleteLocationClicked.emit(i)"
                data-test="tax type location delete">
                <svg-icon [src]="'/assets/images/icons/trash.svg'"></svg-icon>
              </button>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <button
    type="button"
    class="btn btn-permission btn-icon btn-outline-secondary"
    (click)="addLocationClicked.emit()"
    data-test="tax type location add">
    <svg-icon [src]="'/assets/images/icons/add2.svg'"></svg-icon>
    <span>
      {{ 'tax-design.actions.add-location' | translate }}
    </span>
  </button>
</ng-container>

<ng-template #loader>
  <div class="ui my-3 active inline centered loader"></div>
</ng-template>
