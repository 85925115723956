import { Directive, EmbeddedViewRef, Input, TemplateRef, ViewContainerRef, ɵstringify as stringify } from '@angular/core';

import { Role } from '@rar/model/data/enums/Role';
import { TaxRevision } from '@rar/model/data/tax/TaxRevision';
import { UserSessionService } from '@rar/user/services/user-session/user-session.service';

import { UserHelper } from '../helpers';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[ifHasRole], [ifHasRoleExact]',
})
export class HasRoleDirective {
  private thenViewRef: EmbeddedViewRef<any> = null;
  private elseViewRef: EmbeddedViewRef<any> = null;
  private elseTemplateRef: TemplateRef<any> | null = null;

  private roles: Role | Role[] = null;
  private exact = false;
  private excludeLocalAdmin = false;
  private excludeGlobalAdmin = false;
  private requestedTax: TaxRevision;

  constructor(
    private thenTemplateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private userSessionService: UserSessionService,
  ) {}

  @Input() set ifHasRole(roles: Role | Role[]) {
    this.roles = roles;
    this.exact = false;
    this.excludeLocalAdmin = false;
    this.excludeGlobalAdmin = false;

    this.recalculateState();
  }

  @Input() set ifHasRoleExcludeLocalAdmin(excludeLocalAdmin: boolean) {
    this.exact = false;
    this.excludeLocalAdmin = excludeLocalAdmin;
    this.excludeGlobalAdmin = false;

    this.recalculateState();
  }

  @Input() set ifHasRoleExcludeGlobalAdmin(excludeGlobalAdmin: boolean) {
    this.exact = false;
    this.excludeLocalAdmin = false;
    this.excludeGlobalAdmin = excludeGlobalAdmin;

    this.recalculateState();
  }

  @Input() set ifHasRoleExact(roles: Role | Role[]) {
    this.roles = roles;
    this.exact = true;
    this.excludeLocalAdmin = false;
    this.excludeGlobalAdmin = false;

    this.recalculateState();
  }

  @Input() set ifHasRoleExactExcludeLocalAdmin(excludeLocalAdmin: boolean) {
    this.exact = true;
    this.excludeLocalAdmin = excludeLocalAdmin;
    this.excludeGlobalAdmin = false;

    this.recalculateState();
  }

  @Input() set ifHasRoleExactExcludeGlobalAdmin(excludeGlobalAdmin: boolean) {
    this.exact = true;
    this.excludeLocalAdmin = false;
    this.excludeGlobalAdmin = excludeGlobalAdmin;

    this.recalculateState();
  }

  @Input() set ifHasRoleElse(templateRef: TemplateRef<any>) {
    assertTemplate('ifHasRoleElse', templateRef);
    this.elseTemplateRef = templateRef;
    this.elseViewRef = null;

    this.recalculateState();
  }

  @Input() set ifHasRoleExactElse(templateRef: TemplateRef<any>) {
    assertTemplate('ifHasRoleExactElse', templateRef);
    this.elseTemplateRef = templateRef;
    this.elseViewRef = null;

    this.recalculateState();
  }

  @Input() set ifHasRoleRequestedTax(tax: TaxRevision) {
    this.requestedTax = tax;

    this.recalculateState();
  }

  @Input() set ifHasRoleExactRequestedTax(tax: TaxRevision) {
    this.requestedTax = tax;

    this.recalculateState();
  }

  private recalculateState(): void {
    const userTaxPermissions = this.userSessionService.userTaxPermissionsValue;
    const hasAcceptableRole = UserHelper.hasAcceptableRole(
      userTaxPermissions,
      this.exact,
      this.excludeLocalAdmin,
      this.excludeGlobalAdmin,
      this.roles,
      this.requestedTax,
    );
    this.renderContent(hasAcceptableRole);
  }

  private renderContent(condition: boolean): void {
    if (condition) {
      if (!this.thenViewRef) {
        this.viewContainer.clear();
        this.elseViewRef = null;
        if (this.thenTemplateRef) {
          this.thenViewRef = this.viewContainer.createEmbeddedView(this.thenTemplateRef);
        }
      }
    } else {
      if (!this.elseViewRef) {
        this.viewContainer.clear();
        this.thenViewRef = null;
        if (this.elseTemplateRef) {
          this.elseViewRef = this.viewContainer.createEmbeddedView(this.elseTemplateRef);
        }
      }
    }
  }
}

function assertTemplate(property: string, templateRef: TemplateRef<any> | null): void {
  const isTemplateRefOrNull = !!(!templateRef || templateRef.createEmbeddedView);
  if (!isTemplateRefOrNull) {
    throw new Error(`${property} must be a TemplateRef, but received '${stringify(templateRef)}'.`);
  }
}
