<div id="tax-events-component">

	<div id="header">
		<p><img class="ui avatar image"
				[src]="'../../../../assets/images/icons/calendar2.svg'">{{"tax.tax-events.title" | translate }}</p>
	</div>

	<div id="content">

		<!--Loading indicator-->
		<div class="ui active inline centered loader" *ngIf="loading"></div>

		<div *ngIf="!loading">

			<!--Events table-->
			<table class="ui table custom-table padded-table selectable" *ngIf="events && events.length">

				<thead>
					<tr>
						<th>{{ "tax.tax-events.table.name" | translate | uppercase }}</th>
						<th>{{ "tax.tax-events.table.eventDate" | translate | uppercase }}</th>
						<th>{{ "tax.tax-events.table.reminderPriorInDays" | translate | uppercase }}</th>
						<th>{{ "tax.tax-events.table.user" | translate | uppercase }}</th>
						<th>{{ "tax.tax-events.table.sent" | translate | uppercase }}</th>
						<th *ngIf="!(taxService.authUser.role < 4)"></th>
					</tr>
				</thead>

				<!--Content-->
				<tbody>
					<tr class="table-row cur-pointer" *ngFor="let event of events">
						<td [title]="event.name">{{ event.name }}</td>
						<td [title]="event.eventDate | date:'MMM dd, yyyy' ">{{ event.eventDate | date:'MMM dd, yyyy' }}</td>
						<td [title]="event.reminderPriorInDays + ' days before'">{{ event.reminderPriorInDays }} days before</td>
						<td [title]="event.user.name">{{ event.user.name }}</td>
						<td [title]="event.sent ?
									('✔️ Sent on ' + (event.sent | date:'MMM dd, yyyy')) :
									('\u23F0 Scheduled for ' + (event.activation | date:'MMM dd, yyyy'))">
							{{ event.sent ?
							('✔️ ' + (event.sent | date:'MMM dd, yyyy')) :
							('\u23F0' + (event.activation | date:'MMM dd, yyyy'))}}
						</td>
						<!--action buttons-->
						<td class="actions" *ngIf="!(taxService.authUser.role < 4)">

							<!--edit user button-->
							<button class="ui basic button icon"
								ngbPopover="Edit Event" placement="right" (click)="editEvent(event)">
								<img [src]="'../../../../assets/images/icons/edit.svg'">
							</button>

						</td>
					</tr>
				</tbody>

			</table>

			<!--empty message-->
			<app-empty-placeholder class="footer" *ngIf="events && !events.length" text="This tax has no events"></app-empty-placeholder>

			<div class="create-button" *ngIf="!(taxService.authUser.role < 4)">
				<button class="ui basic button done" (click)="createEvent()">Create new event</button>
			</div>

		</div>

	</div>


</div>
