import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { untilDestroyed } from 'ngx-take-until-destroy';

import { RoutePaths } from '@rar/commons/constants/route-paths';

import { User } from '../../../model/data/user/User';
import { UserFilterService } from '../../services/user-filter/user-filter.service';
import { UserSessionService } from '../../services/user-session/user-session.service';

@Component({
  selector: 'app-user-listing',
  templateUrl: './user-listing.component.html',
  styleUrls: ['./user-listing.component.scss'],
  providers: [UserFilterService],
})
export class UserListingComponent implements OnInit, OnDestroy {
  public queryParam: string;

  public userRoute = `/${RoutePaths.users.addNew}`;

  constructor(
    public userFilter: UserFilterService,
    private userSessionService: UserSessionService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.queryParam = this.route.snapshot.queryParams['filter'];

    if (this.queryParam) {
      this.userFilter.getNewUsersPage(1);
    } else {
      this.userFilter.getPage(1);
    }
  }

  public resetFilters() {
    if (this.userFilter.showFilter) {
      this.userFilter.updateFilter(<any>{});
    }
  }

  ngOnInit() {
    this.userSessionService.userData.pipe(untilDestroyed(this)).subscribe((u: User) => {
      this.userFilter.authUser = u;
    });
  }

  ngOnDestroy(): void {}

  get showArchived(): boolean {
    return this.userFilter.showArchived;
  }

  set showArchived(val: boolean) {
    this.userFilter.setShowArchived(val);
  }
}
