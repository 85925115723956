<div>
  <!--loader-->
  <div class="ui active inline centered loader" *ngIf="!users && users !== null"></div>

  <!--error message-->
  <app-error-display *ngIf="users === null" (onRefresh)="updateTable()"></app-error-display>

  <!--tables-->
  <table class="ui table custom-table" *ngIf="users">
    <thead>
      <tr>
        <th>{{ 'commons.table.user.name' | translate | uppercase }}</th>
        <th>{{ 'commons.table.user.email' | translate | uppercase }}</th>
        <th>{{ 'commons.table.user.lastloggedin' | translate | uppercase }}</th>
        <th>{{ 'commons.table.user.createdat' | translate | uppercase }}</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <!--Filter-->
      <tr *ngIf="userFilterService.showFilter">
        <td>
          <div class="ui input">
            <input type="text" [(ngModel)]="filter.name" (ngModelChange)="onChange()" placeholder="Name" />
          </div>
        </td>

        <td>
          <div class="ui input">
            <input type="text" [(ngModel)]="filter.email" (ngModelChange)="onChange()" placeholder="Email" />
          </div>
        </td>

        <td class="date-cell">
          <div class="ui input">
            <div class="input-group">
              <input
                #dp1="ngbDatepicker"
                ngbDatepicker
                autocomplete="off"
                class="form-control"
                [(ngModel)]="filter.lastLoggedIn"
                (ngModelChange)="onChange()"
                placeholder="Last log in"
                placement="bottom-right" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary btn-icon" (click)="dp1.toggle()" [attr.aria-expanded]="dp1.isOpen" type="button">
                  <svg-icon svgClass="icon" src="/assets/images/icons/calendar.svg"></svg-icon>
                </button>
              </div>
            </div>
          </div>
        </td>

        <td class="date-cell">
          <div class="ui input">
            <div class="input-group">
              <input
                #dp2="ngbDatepicker"
                ngbDatepicker
                autocomplete="off"
                class="form-control"
                [(ngModel)]="filter.createdAt"
                (ngModelChange)="onChange()"
                placeholder="Date added"
                placement="bottom-right" />
              <div class="input-group-append">
                <button class="btn btn-outline-secondary btn-icon" (click)="dp2.toggle()" [attr.aria-expanded]="dp2.isOpen" type="button">
                  <svg-icon svgClass="icon" src="/assets/images/icons/calendar.svg"></svg-icon>
                </button>
              </div>
            </div>
          </div>
        </td>

        <td class="dropdown">
          <div class="ui segments">
            <div class="ui segment">
              <div class="ui input">
                <ng-select
                  [(ngModel)]="filter.status"
                  (ngModelChange)="onChange()"
                  placeholder="Status"
                  [items]="statuses"
                  [searchable]="false"
                  bindValue="id"></ng-select>
              </div>
            </div>
          </div>
        </td>
      </tr>

      <!--Content-->
      <tr *ngFor="let user of users" [ngClass]="{ inactive: user.status === 2 }">
        <td [title]="user.name">{{ user.name }}</td>
        <td [title]="user.email">{{ user.email }}</td>
        <td
          [title]="
            user.status === 1
              ? user.lastLoggedIn
                ? (user.lastLoggedIn | date: 'MMM dd, yyyy hh:mm a')
                : 'N/A'
              : ('commons.table.user.user-archived-text' | translate)
          ">
          <span *ngIf="user.status === 1">{{ user.lastLoggedIn ? (user.lastLoggedIn | date: 'MMM dd, yyyy hh:mm a') : 'N/A' }}</span>
          <span *ngIf="user.status === 2">{{ 'commons.table.user.user-archived-text' | translate }}</span>
        </td>
        <td [title]="user.createdAt | date: 'MMM dd, yyyy hh:mm a'">{{ user.createdAt | date: 'MMM dd, yyyy hh:mm a' }}</td>

        <!--Admin users-->
        <td>
          <div class="d-flex justify-content-end">
            <!--archive / activate button-->
            <button
              class="ui basic button archive"
              (click)="toggleUserStatus(user)"
              [attr.data-test]="(user.status === 1 ? 'archive' : 'activate') + ' button'"
              [disabled]="
                userFilterService.authUser.id === user.id || (userFilterService.authUser.isSuperAdmin === false && user.isSuperAdmin)
              ">
              <span *ngIf="user.status === 1">{{ 'user.users-admin.archive' | translate }}</span>
              <span *ngIf="user.status === 2">{{ 'user.users-admin.activate' | translate }}</span>
            </button>

            <!--edit user button-->
            <button
              class="ui basic button icon btn-icon"
              ngbPopover="{{ 'dashboard.edit-user' | translate }}"
              placement="left"
              [routerLink]="'/' + routePaths.getEditPath(user.id)"
              [attr.data-test]="'edit icon'"
              [disabled]="
                userFilterService.authUser.id === user.id ||
                user.status === 2 ||
                (userFilterService.authUser.isSuperAdmin === false && user.isSuperAdmin)
              ">
              <img [src]="'../../../../assets/images/icons/edit.svg'" />
            </button>
          </div>
        </td>
      </tr>
    </tbody>

    <tfoot *ngIf="users.length">
      <tr>
        <th colspan="5">
          <div class="ui right floated pagination menu">
            <button class="ui basic button" [disabled]="!userFilterService.isNextPage" (click)="userFilterService.fetchNextPage()">
              <img class="icon" [src]="'../../../../assets/images/icons/arrow_right.svg'" />
            </button>
          </div>
          <div class="ui right floated pagination menu">
            <button class="ui basic button" [disabled]="!userFilterService.isPreviousPage" (click)="userFilterService.fetchPreviousPage()">
              <img class="icon" [src]="'../../../../assets/images/icons/arrow_left.svg'" />
            </button>
          </div>
          <div class="ui right floated pagination menu description">
            <p>{{ userFilterService.currentPage }} of {{ userFilterService.totalPages }}</p>
          </div>
        </th>
      </tr>
    </tfoot>
  </table>

  <!--empty message-->
  <app-empty-placeholder class="footer" *ngIf="users && !users.length"></app-empty-placeholder>
</div>
