import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { AttachmentHandlerService } from '@rar/rich-text-editor/attachments/attachment-handler.service';

import { TaxRevisionPageComponent } from '../pages/tax-revision-page/tax-revision-page.component';

@Injectable({
  providedIn: 'root',
})
export class TaxDiscardChangesGuard implements CanDeactivate<TaxRevisionPageComponent> {
  constructor(private attachmentHandlerService: AttachmentHandlerService) {}

  canDeactivate(
    component: TaxRevisionPageComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.attachmentHandlerService.isDeactivationDialogVisible().pipe(
      filter((visible) => !visible),
      switchMap(() => component.canDeactivate()),
    );
  }
}
