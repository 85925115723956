<div class="modal-header">
	<h4 class="modal-title">
		{{ "tax.tax-info.comments-title" | translate }}
	</h4>
</div>

<div class="modal-body">
    Your comment has been added successfully.
</div>

<div class="modal-footer">
	<div class="button-group">
		<button class="ui basic button silver" (click)="close()">
			Close
		</button>
	</div>
</div>