import { Inject, Injectable, OnDestroy } from '@angular/core';

import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { clone } from 'lodash-es';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { BehaviorSubject, Observable, Subscription, fromEvent, interval, merge, of } from 'rxjs';
import { distinctUntilChanged, map, sample, share, timeout } from 'rxjs/operators';

import { AuthConfiguration } from '@rar/model/data/auth/AuthConfiguration';
import { Role } from '@rar/model/data/enums/Role';
import { UserTaxPermission } from '@rar/model/data/user/UserTaxPermission';
import { BaseHttpService } from '@rar/model/utility/base-http.service';

import { environment } from '../../../../environments/environment';
import { User } from '../../../model/data/user/User';
import { ApiCommunicationService } from '../../../model/services/api-communication/api-communication.service';
import { AuthConfigTypeDef } from '../../../model/utility/AuthConfigTypeDef';

/**
 * Different reasons for login rejection.
 */
export enum LoginRejectionReason {
  ACCEPTED = '',
  UNKNOWN = 'account.login-modal.login-error-unknown',
  SERVICE_UNAVAILABLE = 'account.login-modal.login-error-service-unavailable',
  BAD_CREDENTIALS = 'account.login-modal.login-error-wrong-credentials',
  TOKEN_EXPIRED = 'account.login-modal.login-error-token-expired',
}

/**
 * This service is responsible for handling the browser session of the user.
 * Common tasks are:
 * - Handling Login
 * - Handling Logout
 */
@Injectable()
export class UserSessionService implements OnDestroy {
  private postLoginRedirectUrl: string = String();

  // object keeping the actual user data
  private _userData: BehaviorSubject<User> = new BehaviorSubject(undefined);
  private _userDataRequested = false;
  private _userDataCached = false;

  // object keeping the actual user logged in status
  private _authConfig: AuthConfigTypeDef;
  private timeoutSubscription: Subscription = new Subscription();
  silentRefreshIFrameName = 'silentIFrame';

  constructor(
    @Inject(ApiCommunicationService) private api: ApiCommunicationService,
    @Inject(BaseHttpService) private oAuthApiClient: BaseHttpService,
    @Inject(OAuthService) private oauthService: OAuthService,
  ) {}

  ngOnDestroy(): void {}

  /**
   * This action logs out current user.
   * @returns {Promise<void>}
   */
  public logoutAction(): void {
    this.api
      .auth()
      .clearUserCache()
      .toPromise()
      .then(() => {
        this._userDataRequested = false;
        this._userDataCached = false;
        this.oauthService
          .revokeTokenAndLogout(undefined, true)
          .then(
            () => {},
            () => {
              // on error try to log out without rejecting token
              this.oauthService.logOut();
            },
          );
      });
  }

  /**
   * This method starts user session based on the token stored in local storage.
   * This method is executed on startup, so user is automatically logged in while the token is valid.
   * @returns {Promise<LoginRejectionReason>} Returns a promise containing the status of the
   */
  public startSession(): Promise<LoginRejectionReason | void> {
    // creating a promise
    return new Promise<LoginRejectionReason | void>(async (resolve, reject) => {
      // get auth config
      await this.fetchAuthConfiguration();

      // try to resolve token
      if (this._authConfig !== 'mock') {
        // NORMAL MODE
        try {
          this.setupAuth();
          resolve();
        } catch (e) {
          this.redirectToLogin();
        }
      } else {
        // MOCKED MODE
        this.resolveUserProfile();
        resolve();
      }

      this.setupSessionTimeout();
    });
  }

  public silentRefresh(): Observable<any> {
    this.oauthService.silentRefresh();
    return of();
  }

  public setupSessionTimeout(): void {
    if (this.timeoutSubscription) {
      this.timeoutSubscription.unsubscribe();
    }
    const eventSources = environment.sessionActivityEvents.map((sae) => fromEvent(sae.target, sae.event));
    this.timeoutSubscription = merge(...eventSources)
      .pipe(
        sample(interval((Number(environment.sessionTimeoutInSeconds) / 6) * 1000)),
        map((_) => {
          this.silentRefresh();
        }),
        timeout(Number(environment.sessionTimeoutInSeconds) * 1000),
        untilDestroyed(this),
      )
      .subscribe();
  }

  public redirectToLogin() {
    // redirect to login page
    this.tryLogIn();
  }

  public async parseTokenFromUrl(): Promise<{ hasValidToken: boolean }> {
    await this.oauthService.tryLoginCodeFlow({ disableOAuth2StateCheck: true });
    return { hasValidToken: this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken() };
  }

  private setupAuth(): void {
    const config = this.getAuthConfiguration() as AuthConfiguration;

    const authConfig: AuthConfig = {
      loginUrl: config.authUrl,
      responseType: 'code',
      clientId: config.clientID,
      redirectUri: config.redirectUrl,
      scope: 'openid email profile',
      nonceStateSeparator: ';',
      issuer: config.issuer,
      logoutUrl: config.logoutUrl,
      postLogoutRedirectUri: window.location.origin,
      silentRefreshRedirectUri: `${window.location.origin}/silent-refresh.html`,
      timeoutFactor: Number(0.75),
      tokenEndpoint: config.tokenUrl,
      revocationEndpoint: config.revocationUrl,
    };
    this.oauthService.configure(authConfig);
    this.oauthService.setStorage(sessionStorage);
    this.oauthService.setupAutomaticSilentRefresh({ disableOAuth2StateCheck: true }, undefined, true);

    this.setupSessionTimeout();
  }

  /**
   * Retrieves auth config
   */
  private async fetchAuthConfiguration(): Promise<void> {
    // call api to retrieve config
    return new Promise<void>((resolve) => {
      this.api
        .auth()
        .getAuthConfiguration()
        .subscribe((c) => {
          this._authConfig = c;
          resolve();
        });
    });
  }

  public getAuthConfiguration(): AuthConfigTypeDef {
    return this._authConfig;
  }

  /**
   * Retrieves user profile from server
   */
  public async resolveUserProfile(): Promise<User | null> {
    if (this._userDataRequested) {
      return this.userDataValue;
    }
    this._userDataRequested = true;
    try {
      const user = await this.api.auth().getInternalUserProfile().toPromise();
      this._userDataCached = true;
      this._userData.next(user);
      return user;
    } catch (e) {
      this._userDataCached = false;
      this._userData.next(null);
      return null;
    }
  }

  /**
   * Starts session, but without rejecting promise, so it won't prevent the whole application from loading.
   */
  public startSessionOnApplicationBootstrap(): Promise<void> {
    return new Promise<void>((resolve) => {
      // We don't care about the results.
      // Just start the session to see if user is logged in or not.
      this.startSession()
        .then(() => resolve())
        .catch(() => resolve());
    });
  }

  get userData(): Observable<User> {
    return this._userData.asObservable().pipe(distinctUntilChanged());
  }

  get userDataValue(): User {
    return this._userData.getValue();
  }

  get userTaxPermissionsValue(): UserTaxPermission[] {
    const userRoles = clone(this.userDataValue.userTaxPermissions || []);

    if (this.userDataValue.isSuperAdmin) {
      userRoles.push({ role: Role.SUPER_ADMIN, taxType: null, location: null } as UserTaxPermission);
    }

    return userRoles;
  }

  public isUserDataCached(): boolean {
    return this._userDataCached;
  }

  public tryLogIn(): void {
    this.oauthService.initCodeFlow(this.postLoginRedirectUrl);
  }

  public tryRefresh() {
    this.oauthService.logOut(false);
    this.oauthService.initCodeFlow();
  }

  public isUserLoggedIn(): boolean {
    return !!(this.oauthService.getAccessToken() && this.oauthService.getIdentityClaims() && this.isUserTokenValid());
  }

  public isUserLoggedInAndAuthorized(): boolean {
    return this.isUserLoggedIn() && this._userDataCached;
  }

  public isUserTokenValid(): boolean {
    return this.oauthService.hasValidAccessToken() && this.oauthService.hasValidIdToken();
  }

  public getState(): string {
    return this.oauthService.state;
  }

  public getToken(): string {
    return this.oauthService.authorizationHeader();
  }
}
