import { Component, EventEmitter, Inject, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { cloneDeep } from 'lodash-es';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { map } from 'rxjs/operators';

import { UserHelper } from '@rar/commons/helpers';
import { TaxStructureDifferences, TopicDifference } from '@rar/model/data/tax/TaxStructureDifferences';
import { AttachmentHandlerService } from '@rar/rich-text-editor/attachments/attachment-handler.service';

import { TopicService } from '../../../commons/services/topic.service';
import { Role } from '../../../model/data/enums/Role';
import { TaxRevisionStatus } from '../../../model/data/enums/TaxRevisionStatus';
import { TaxRevision } from '../../../model/data/tax/TaxRevision';
import { User } from '../../../model/data/user/User';
import { ApiCommunicationService } from '../../../model/services/api-communication/api-communication.service';
import { TaxDataService } from '../../services/tax-data/tax-data.service';
import { TaxModalService } from '../../services/tax-modal/tax-modal.service';

@Component({
  selector: 'app-tax-info',
  templateUrl: './tax-info.component.html',
  styleUrls: ['./tax-info.component.scss'],
})
export class TaxInfoComponent implements OnInit, OnDestroy {
  public tax: TaxRevision;
  public isSearchMode: boolean;
  public readonly Role = Role;
  public readonly isDoneEditingDisabled$ = this.attachmentHandlerService.areFilesBeingUploaded$;

  @Output()
  readonly importFile = new EventEmitter<File>();

  constructor(
    private modalService: TaxModalService,
    public taxDataService: TaxDataService,
    public route: ActivatedRoute,
    private topicService: TopicService,
    private attachmentHandlerService: AttachmentHandlerService,
    @Inject(ApiCommunicationService) private api: ApiCommunicationService,
  ) {
    this.isSearchMode = false;
  }

  role = Role;

  globalTaxToRestore: TopicDifference[];

  ngOnInit() {
    // subscribe to tax revision status change
    this.taxDataService.taxRevisionStatus.pipe(untilDestroyed(this)).subscribe((status: TaxRevisionStatus) => {
      this.tax.status = status;
    });

    // subscribe to tax revision effective from change
    this.taxDataService.taxRevisionEffectiveFrom.pipe(untilDestroyed(this)).subscribe((value: Date) => {
      this.tax.effectiveFrom = value;
    });

    // subscribe to tax change
    this.taxDataService.tax.pipe(untilDestroyed(this)).subscribe((tax: TaxRevision) => {
      this.tax = cloneDeep(tax);
    });
  }

  ngOnDestroy(): void {}

  public onApproverList() {
    this.api
      .permissions()
      .getAssignedUsers(this.tax.id, this.tax.status === TaxRevisionStatus.PENDING_GLOBAL ? Role.SUPER_ADMIN : Role.APPROVER)
      .pipe(untilDestroyed(this))
      .subscribe((approvers: Array<User>) => {
        this.modalService.openTaxApproverListModal(approvers);
      });
  }

  public onClickSearch() {
    this.isSearchMode = !this.isSearchMode;
    if (!this.isSearchMode) {
      this.taxDataService.searchResult = undefined;
      this.taxDataService.searchCriteria = '';
      this.topicService.selectTopic.emit(null);
    }
  }

  public triggerNavigationPredicate(): boolean {
    // magic, do not touch
    try {
      return this.route.snapshot.firstChild.firstChild.url[0].path === 'empty';
    } catch (e) {
      return false;
    }
  }

  public get isApprover() {
    return UserHelper.canApproveTax(this.taxDataService.authUser, this.tax);
  }

  public get isEditor() {
    return UserHelper.canEditTax(this.taxDataService.authUser, this.tax);
  }

  public onImportClick(event, input: HTMLInputElement) {
    this.taxDataService.isLoading = true;

    const file: File = event.files[0];
    input.value = null;

    this.importFile.emit(file);
  }

  public getRole(): Role {
    return UserHelper.getHighestRole(this.taxDataService.authUser);
  }

  public getStatusPendingLocal() {
    return TaxRevisionStatus.PENDING_LOCAL;
  }

  public getStatusPendingGlobal() {
    return TaxRevisionStatus.PENDING_GLOBAL;
  }

  public getStatusRejected() {
    return TaxRevisionStatus.REJECTED;
  }

  public restoreGlobalTemplate() {
    this.taxDataService
      .getTaxStructureDifferences(this.tax.id, this.tax.revisionNumber)
      .pipe(map((taxStructure) => taxStructure.categories))
      .subscribe((categories: TopicDifference[]) => {
        this.globalTaxToRestore = categories;
        this.modalService.openTaxRestoreModal(categories, this.tax.id);
      });
  }
}
