<div class="modal-header">
  <h4 class="modal-title">Save changes?</h4>
</div>

<div class="modal-body">
  <div>
    You are about to make changes to the following tax:
    <strong>{{ context.tax.name }} - {{ context.tax.location.name }}.</strong>
  </div>

  <br />

  <div>
    Validity of the changes shall be effective as of:
    <strong>{{ context.tax.effectiveFrom | date: 'MMM dd, yyyy' }} - {{ context.tax.effectiveTo | date: 'MMM dd, yyyy' }}</strong>
  </div>

  <br />

  <ng-container *ngIf="!context.autoApprove">
    <div>Designated approver(s) for {{ context.tax.name }} - {{ context.tax.location.name }}:</div>

    <strong *ngIf="!context.approvers.length; else approvers"> There is no assigned approver for this tax. </strong>

    <ng-template #approvers>
      <div *ngFor="let approver of context.approvers">
        <strong>{{ approver.name }}</strong>
      </div>
    </ng-template>

    <br />

    <div><strong>Note: </strong>Tax changes are subject to approval.</div>
  </ng-container>

  <div *ifHasRole="[role.ADMIN, role.SUPER_ADMIN]; requestedTax: context.tax"><strong>Note: </strong>The changes will be automatically approved.</div>
  <div *ifHasRole="[role.APPROVER]; requestedTax: context.tax"><strong>Note: </strong>Tax changes are subject to global approval.</div>
</div>

<div class="modal-footer">
  <div></div>

  <div class="button-group">
    <button class="ui basic button silver" (click)="deny()">
      {{ 'user.user-edit-modal.cancel' | translate }}
    </button>
    <button class="ui basic button rose" (click)="approve()">
      {{ 'user.user-edit-modal.ok' | translate | uppercase }}
    </button>
  </div>
</div>
