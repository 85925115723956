import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultiSelectComponent {
  private static totalInstances = 0;
  public readonly instanceId = ++MultiSelectComponent.totalInstances;

  @ViewChild('selectComponent', { static: true })
  public selectComponent: NgSelectComponent;

  @Input()
  public group: UntypedFormGroup;

  @Input()
  public controlName: string;

  @Input()
  public placeholder: string;

  @Input()
  public items: any[];

  @Input()
  public bindValue: string;

  @Input()
  public controlLocator: string;

  @Input()
  public itemLocator: string;

  @Input()
  public clearable = true;

  public combineItemLabels(items: any[]): string {
    return items.map((item) => this.getItemLabel(item)).join(', ');
  }

  public getItemLabel(item: any): string {
    return item[this.selectComponent.bindLabel]?.toString() || '';
  }

  public sortItems(items: any[]): any[] {
    // get items in the order of the input items
    return this.items.filter((item) => items.includes(item));
  }
}
