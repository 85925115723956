import Quill from 'quill';

import { AttachmentEmbedData } from '@rar/rich-text-editor/attachments/attachment-embed-data';
import { AttachmentsQuillModule } from '@rar/rich-text-editor/attachments/attachments-quill-module';

import { NullableFields } from '../helpers/generic-types';

const BlockEmbed = Quill.import('blots/block/embed');

export class AttachmentEmbed extends BlockEmbed {
  constructor(node: HTMLElement) {
    super(node);
    node.addEventListener(
      'click',
      (event) => {
        AttachmentsQuillModule.attachmentHandlerService.handleClick(event);
      },
      { capture: true },
    );
  }

  static create(value: AttachmentEmbedData): HTMLElement | null {
    const node: HTMLDivElement = super.create(AttachmentEmbed.tagName);
    let errors: string | null = null;
    if (value && typeof value === 'object' && value.file && value.file.name && !isNaN(value.file.size) && value.file.type) {
      AttachmentsQuillModule.attachmentHandlerService.render(node, value);
      return node;
    }

    errors = '';
    if (!value || typeof value !== 'object') {
      errors += 'Attachment is not a value object.\n';
    }
    if (!value.file) {
      errors += 'Missing file data.\n';
    } else {
      if (!value.file.name) {
        errors += 'Missing file name.\n';
      }
      if (isNaN(value.file.size)) {
        errors += 'Missing file size.\n';
      }
      if (!value.file.type) {
        errors += 'Missing file type.\n';
      }
    }
    AttachmentsQuillModule.attachmentHandlerService.render(node, errors);
    return node;
  }

  static value(node: HTMLElement): NullableFields<AttachmentEmbedData> {
    return AttachmentsQuillModule.attachmentHandlerService.serialize(node);
  }
}

AttachmentEmbed.blotName = 'attachment';
AttachmentEmbed.className = 'ql-attachment';
AttachmentEmbed.tagName = 'DIV';
