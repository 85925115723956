import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter } from 'rxjs/operators';

import { ArticleType } from '../../../model/data/enums/ArticleType';
import { TaxRule } from '../../../model/data/tax/TaxRule';
import { TaxDataService } from '@rar/tax/services/tax-data/tax-data.service';

@Component({
  selector: 'app-tax-rules',
  templateUrl: './tax-rules.component.html',
  styleUrls: ['./tax-rules.component.scss'],
})
export class TaxRulesComponent implements OnInit, OnDestroy {
  public readonly ArticleType = ArticleType;

  @Input()
  articleType: ArticleType = ArticleType.KEY_VALUE;

  private rules: TaxRule[];

  public rulesJSON: TaxRule[];
  public rulesKEY_VALUE: TaxRule[];

  private topicId: number;

  constructor(private route: ActivatedRoute, public taxService: TaxDataService) {}

  ngOnInit() {
    // resolve data
    this.route.data
      .pipe(
        filter((data) => data.topic),
        untilDestroyed(this),
      )
      .subscribe((data) => {
        this.rules = data.topic.rules;
        this.topicId = data.topic.id;

        // group by ArticleType
        this.rulesJSON = this.rules.filter((rule) => rule.articleTypeId === ArticleType.JSON);
        this.rulesKEY_VALUE = this.rules.filter((rule) => rule.articleTypeId === ArticleType.KEY_VALUE);
      });

    this.taxService.tableUpdate$.pipe(untilDestroyed(this)).subscribe(() => {
      this.rulesJSON = this.taxService.getTaxTopic(this.taxService.taxCloneForEditing, this.topicId).rules;
    });
  }

  ngOnDestroy(): void {}
}
